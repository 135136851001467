import { Component, inject, OnInit, Signal } from '@angular/core';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { HttpClientService } from '@shared/http-client/http-client.service';
import { environment } from '@env/environment.prod';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { LoaderComponent } from '@comp/loader/loader.component';
import { Page, RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    IonicModule,
    LoaderComponent
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent implements OnInit {
  private readonly _modalCtrl = inject(ModalController);
  private readonly _httpClient = inject(HttpClientService);
  private readonly _store = inject(Store);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  protected loading = false;
  protected page: Page;

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.loading = true;
    const lang = this.me().preferences?.interfaceLanguage || this.me().language;
    this._httpClient.doGetUnauthorized<Page>(environment.contentUrl + '/pages/privacy-policy', { paramLang: lang })
      .pipe(
        finalize(() => this.loading = false),
        untilDestroyed(this)).subscribe(res => {
      this.page = res;
    });
  }

  closePage() {
    this._modalCtrl.dismiss();
  }

}
