<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button auto-hide="false"></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <div class="w-full flex justify-center"><img (click)="goHome()" [ngSrc]="siteInfo()?.logo ? environment.serverUrl + '/v1/site/logo?t=' + siteInfo().logo :  '/assets/images/logo/finexword-logo.svg'" alt="" height="25" width="200" class="logo cursor-pointer"/></div>
    </ion-title>
    <ion-buttons slot="primary">
      <app-scan-qr-code-button></app-scan-qr-code-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
