import { Component, inject } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {
  private _modalCtrl = inject(ModalController);

  constructor() {
    addIcons({ closeOutline });
  }

  closePage() {
    this._modalCtrl.dismiss();
  }

}
