<div class="avatar-container">
  @if (hasAvatar) {
    <div class="image-container relative"
         [ngClass]="{'box-shadow-avatar': !!file}"
         [ngStyle]="{backgroundImage: 'url(' + file + ')'}">
      @if (canEdit) {
        <ng-container *ngTemplateOutlet="upload"></ng-container>
      }
    </div>
  } @else {
    <div class="image-container avatar-placeholder">
      @if (initials) {
        <div class="pb-4">{{ _utilsService.extractFirstLetters(initials) }}</div>
      }
      @if (canEdit) {
        <ng-container *ngTemplateOutlet="upload"></ng-container>
      }
    </div>
  }

  <ng-template #upload>
    <input type="file" #fileInput id="avatar-input-file" accept="image/*" (change)="onFileChange($event)"/>
    <div class="icon-wrapper" [matMenuTriggerFor]="menu">
      <ion-icon name="image" class="text-4xl"></ion-icon>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="selectFile()">
        <ion-icon name="image" class="ltr:mr-2.5 rtl:ml-2.5 text-2xl"></ion-icon>
        <i class="fa-regular fa-image ltr:mr-2.5 rtl:ml-2.5"></i>
        <span>{{ 'menu.select.image' | transloco }}</span>
      </button>
      @if (canDelete) {
        <button mat-menu-item (click)="removeAvatar()">
          <ion-icon name="trash" class="ltr:mr-2.5 rtl:ml-2.5 text-2xl"></ion-icon>
          <span>{{ 'menu.delete' | transloco }}</span>
        </button>
      }
    </mat-menu>

  </ng-template>
</div>
