import { Component, inject, NgZone, OnInit } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { appComponentRef } from '@shared/app-component';
import { Cookies, RoomAction, RoomUser } from '@shared/transport.interface';
import { LangService } from '@shared/service/lang.service';
import { TranslocoService } from '@ngneat/transloco';
import { RxStompService } from '@shared/service/rx-stomp/rx-stomp.service';
import { map, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SaveLanguagesAction } from '@shared/store/languages/languages.actions';
import { RoomService } from '@shared/service/room.service';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { NavMenuComponent } from '@shared/component/nav-menu/nav-menu.component';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Pages } from '@app/pages';
import { NotyService } from '@service/noty.service';
import { DialogService } from '@service/dialog.service';
import { AuthService } from '@service/auth/auth.service';
import { StorageService } from '@service/storage.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
  imports: [
    IonicModule,
    NavMenuComponent
  ]
})
export class AppComponent implements OnInit {
  private readonly _langService = inject(LangService);
  private readonly _translate = inject(TranslocoService);
  private readonly _ws = inject(RxStompService);
  private readonly _store = inject(Store);
  private readonly _roomService = inject(RoomService);
  private readonly _platform = inject(Platform);
  private readonly _zone = inject(NgZone);
  private readonly _router = inject(Router);
  private readonly _noty = inject(NotyService);
  private readonly _dialogService = inject(DialogService);
  private readonly _authService = inject(AuthService);
  private readonly _storageService = inject(StorageService);

  private user$: Observable<RoomUser> = this._store.select(UserState.getUser);
  private mySubs: Subscription;

  private user: RoomUser;

  constructor() {
  }

  ngOnInit(): void {
    appComponentRef(this);
    this.statusBarConfig();
    this.initializeApp();
    this.languageConfig();
    this.afterWSConnected();
    this.aiLangs();
  }

  private aiLangs() {
    this._langService.langsFroAI()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._store.dispatch(new SaveLanguagesAction(res));
      });
  }

  private afterWSConnected() {
    this._ws.connected$
      .subscribe(() => {
        this.user$.subscribe(res => {
          this.user = res;
          if (this.user.preferences?.interfaceLanguage) {
            this._translate.setActiveLang(this.user.preferences?.interfaceLanguage);
          }
          this.subscribeToMyEvents(res);
        });
      });
  }

  private languageConfig() {
    this._storageService.get(Cookies.UI_LANGUAGE).then((lang: string) => {
      if (!lang) {
        lang = this._langService.getBrowserLang();
        this._storageService.set(Cookies.UI_LANGUAGE, lang);
      }
      this._translate.setActiveLang(lang);
      this._langService.checkForRtl(lang);
    });
  }

  private statusBarConfig() {
    this._platform.ready().then(() => {
      if (this._platform.is('mobile')) {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setBackgroundColor({ color: '#EEF4FF' });
      }
    });
  }


  invalidSessionEvent() {
    console.log('[invalidSessionEvent]');
    this._storageService.clear();
    this._noty.success('message.session.expired');
    this._dialogService.closeAll();
    this._router.navigate([ '/', Pages.LOGIN ]);
    this._authService.loggedIn.set(false);
  }

  maintenanceEvent() {

  }

  private subscribeToMyEvents(user: RoomUser) {
    if (this.mySubs) {
      this.mySubs.unsubscribe();
    }
    this.mySubs = this._ws.subscribe(`/topic/user/${ user.id }/events`)
      .pipe(untilDestroyed(this), map(res => JSON.parse(res.body)))
      .subscribe(res => {
        this._roomService.sendMyEvent(res);
        if (res.action === RoomAction.UPDATE_TOKEN) {
          if (res.accessToken) {
            this._storageService.saveAccessToken(res.accessToken);
          }
        }
        if (res.action === RoomAction.TIME_LEFT) {
          this.user.duration = res.duration;
          if (this.user.duration <= 0) {
            this.user.duration = 0;
          }
          this._store.dispatch(new SaveUserAction(this.user));
        }
      });
  }

  private initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._zone.run(() => {
        const url = event.url;
        if (url && url.indexOf(Pages.JOIN_ROOM) >= 0) {
          const code = url.split(`${ Pages.JOIN_ROOM }/`)[ 1 ];
          if (code) {
            this._router.navigate([ '/', Pages.JOIN_ROOM, code ]);
          }
        }
      });
    });
  }

}
