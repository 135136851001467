<div class="flex flex-col flex-auto overflow-y-auto p-5 pt-0">
  @if (loading) {
    <app-loader></app-loader>
  } @else {
    @if (!upgradeTariff) {
      @for (transcript of transcripts; track transcript.id; let ind = $index; let last = $last; let first = $first) {
        <app-date-line [first]="first" [lastDate]="transcripts[ind - 1]?.createDate" [currentDate]="transcript.createDate"></app-date-line>
        <div class="flex mb-2">
          <div class="flex-auto cursor-pointer" (click)="showTranscript(transcript)">
            <div class="line-clamp-2 title text-2xl">{{ transcript.title }}</div>
            <div class="flex items-center text-gray-400 text-sm mt-1">
              <div class="pr-2.5 mr-2.5 border-r border-r-gray-300 flex items-center whitespace-nowrap">
                <i class="fa-regular fa-calendar text-gray-400 fa-sm"></i>
                <div class="ltr:ml-1.5 rtl:mr-1.5">{{ transcript?.createDate | prettyDate | async }}</div>
              </div>
              <div class="flex items-center flex-auto overflow-hidden">
                <app-initials-avatar [avatarId]="transcript?.user?.avatarId" [user]="{name: transcript?.user?.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
                <div class="ltr:ml-2 rtl:mr-2 flex w-full overflow-hidden">
                  <div class="text-gray-400 truncate">{{ transcript?.user?.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            @if (me().id === transcript.user.id) {
              <button
                class="ml-auto"
                mat-icon-button
                [matMenuTriggerFor]="conversationHeaderMenu">
                <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                <mat-menu #conversationHeaderMenu>
<!--                  <button mat-menu-item (click)="shareTranscript(transcript)">-->
<!--                    <i class="fa-solid fa-arrow-up-from-bracket pr-2"></i>-->
<!--                    <span class="text-base">{{ 'menu.share' | transloco }}</span>-->
<!--                  </button>-->
                  <!--                  TODO -->
                  <!--                  <button mat-menu-item (click)="downloadPdf(protocol)">-->
                  <!--                    <i class="fa-solid fa-arrow-up-from-bracket pr-2"></i>-->
                  <!--                    <span class="text-base">{{ 'menu.download.pdf' | transloco }}</span>-->
                  <!--                  </button>-->
                  <button mat-menu-item (click)="deleteTranscript(transcript)">
                    <i class="fa-solid fa-trash pr-2"></i>
                    <span class="text-base">{{ 'menu.delete' | transloco }}</span>
                  </button>
                </mat-menu>
              </button>
            }
          </div>
        </div>
        @if (!last && (transcripts[ind + 1]?.createDate | utcDate: 'd') === (transcript.createDate | utcDate: 'd')) {
        <div class="flex w-full h-[1px] mb-2">
          <div class="flex-auto border-b border-b-gray-300">&nbsp;</div>
        </div>
        }
      } @empty {
        <div class="flex flex-col justify-center mt-5 items-center">
          <i class="fa-solid fa-magnifying-glass fa-3x"></i>
          <div class="title text-4xl mt-2.5">{{ 'title.no.protocol.history' | transloco }}</div>
          <div class="mt-2.5 text-center text-base">{{ 'text.no.protocol.history' | transloco }}</div>
        </div>
      }
      @if (loadingMore) {
        <app-more-loader></app-more-loader>
      }
    } @else {
      <div class="mb-1">{{ 'text.tariff.upgrade.message' | transloco }}</div>
      <div class="blurred">
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="mb-2">&nbsp;</div>
      </div>

    }
  }
</div>
