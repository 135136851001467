<ion-icon name="close-outline" class="close-icon z-99999 text-4xl cursor-pointer" (click)="closeModal()"></ion-icon>

<div class="flex flex-col justify-center text-center p-5">
  <div class="title text-2xl">{{ 'title.reactivate.account' | transloco }}</div>
  <div class="mt-4"><span [innerHTML]="'text.reactivate.account' | transloco"></span></div>
  <div class="w-full flex items-center justify-center relative mt-5">
    <button class="button w-full" (click)="reactivateAccount()" [disabled]="pending" [class.loading]="pending">{{ 'button.reactivate' | transloco }}</button>
    <button class="button stroked w-full" (click)="closeModal()" [disabled]="pending">{{ 'button.cancel' | transloco }}</button>
  </div>
</div>
