import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgClass } from '@angular/common';
import { IonicModule, Platform } from '@ionic/angular';
import { ImageResizeService } from '@service/image-resize.service';
import { PhotoType } from '@shared/transport.interface';
import { addIcons } from 'ionicons';
import { camera, chevronBack, chevronForward, image } from 'ionicons/icons';
import { StorageService } from '@service/storage.service';

@UntilDestroy()
@Component({
  selector: 'app-photo-source-dialog',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgClass,
    IonicModule,

  ],
  templateUrl: './photo-source-dialog.component.html',
  styleUrl: './photo-source-dialog.component.scss',
})
export class PhotoSourceDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected readonly platform = inject(Platform);
  private readonly _storageService = inject(StorageService);

  hasSwiped = false;  // Track if a swipe has occurred
  showFirstDiv = true;
  swipeDirection: 'left' | 'right' = 'left';

  constructor() {
    addIcons({ chevronForward, chevronBack, camera, image });
  }

  ngOnInit(): void {
    this._storageService.getPhotoMode().then(photoMode => {
      this.showFirstDiv = 'description' === photoMode;
      if (!this.showFirstDiv) {
        this.swipeDirection = 'right';
        this.hasSwiped = true;
      }
    });
  }

  takePhoto(event: Event, type: PhotoType) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[ 0 ]) {
      const file = input.files[ 0 ];
      this.closeModal({ file, type });
      input.value = null;
    }
  }

  onSwipe(direction: 'left' | 'right') {
    if (direction === 'left' && this.showFirstDiv) {
      return;
    }
    if (direction === 'right' && !this.showFirstDiv) {
      return;
    }
    this.hasSwiped = true;  // Set to true after the first swipe
    this.swipeDirection = direction;
    this.showFirstDiv = !this.showFirstDiv;
    if (this.showFirstDiv) {
      this._storageService.savePhotoMode('description');
    } else {
      this._storageService.savePhotoMode('transcript');
    }
  }
}
