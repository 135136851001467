import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {
  }

  secondsToString(time: number): string {
    const hours = `${ Math.floor(time / 60 / 60) }`.padStart(2, "0");
    time -= +hours * 60 * 60;
    const minutes = `${ Math.floor(time / 60) }`.padStart(2, "0");
    const seconds = `${ time - +minutes * 60 }`.padStart(2, "0");
    let outStr = '';
    if (isFinite(+hours) && +hours) {
      outStr += `${ hours }<span class="text-xl">h.</span> `;
    }
    if (isFinite(+minutes) && minutes) {
      outStr += `${ minutes }<span class="text-xl">m.</span> `;
    }
    if (isFinite(+seconds) && +seconds) {
      outStr += `${ seconds }<span class="text-xl">s</span>`;
    }
    return outStr ? outStr : '00m. 00s.';
  }

  secondsToTimerString(time: number): string {
    time = Math.floor(time / 1000);
    const minutes = `${ Math.floor(time / 60) }`.padStart(2, "0");
    const seconds = `${ time - +minutes * 60 }`.padStart(2, "0");
    let outStr = '';
    if (minutes) {
      outStr += `${ minutes }:`;
    } else {
      outStr += `00:`;
    }
    if (seconds) {
      outStr += `${ seconds }`;
    }
    return outStr;
  }

  extractFirstLetters(name: string | undefined): string {
    if (!name) {
      return '-';
    }
    let split = name.split(' ');
    if (split?.length > 1) {
      return split[ 0 ].substring(0, 1) + split[ 1 ].substring(0, 1);
    }
    return split[ 0 ].substring(0, 2);
  }

  scrollToId(id: string): void {
    if (id && document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  }

  getLogo(logo: string): Observable<Blob> {
    return fromPromise(fetch(logo)).pipe(switchMap(result => fromPromise(result.blob())));
  }

  public customPasswordMatching(control: FormGroup): ValidationErrors | null {
    const newPassword = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (newPassword !== confirmPassword) {
      control.get('confirmPassword').setErrors({ passwordsMismatch: true });
    } else {
      control.get('confirmPassword').setErrors(null);
    }
    return null;
  }
}
