import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatStepperNext } from '@angular/material/stepper';
import { Room, RoomUser } from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { QrCodeComponent } from '@shared/component/ui/qr-code/qr-code.component';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { FuseAlertComponent } from '@comp/ui/alert';

@UntilDestroy()
@Component({
  selector: 'app-block-user',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    QrCodeComponent,
    TranslocoPipe,
    MatStepperNext,
    IonicModule,
    FuseAlertComponent,
  ],
  templateUrl: './block-user.component.html',
  styleUrl: './block-user.component.scss'
})
export class BlockUserComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected user: RoomUser;
  protected room: Room;
  protected pending = false;
  error = null;

  private _roomService = inject(RoomService);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.user = this.data.user;
      this.room = this.data.room;
    }
  }

  blockUser(): void {
    this.pending = true;
    this._roomService.blockParticipant(this.room, this.user)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: res => {
          this.closeModal(true);
        }, error: (err) => {
          this.error = err;
        }
      });
  }
}
