import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Chat } from '../../transport.interface';
import { SaveBottomChatWindowAction, SaveChatAction, SaveTopChatWindowAction } from './chat.actions';

export interface ChatStateModel {
  chat: Chat;
}

const defaults = {
  chat: {
    top: {
      showOriginalText: false,
      photoMode: false,
      editMode: false
    },
    bottom: {
      showOriginalText: false,
      photoMode: false,
      editMode: false
    },
  }
};

@State<ChatStateModel>({
  name: 'chat',
  defaults
})
@Injectable()
export class ChatState {
  @Action(SaveChatAction)
  save(ctx: StateContext<ChatStateModel>, action: SaveChatAction): void {
    return ctx.setState({ chat: action.payload });
  }

  @Action(SaveTopChatWindowAction)
  saveTopChatWindow(ctx: StateContext<ChatStateModel>, action: SaveTopChatWindowAction): void {
    return ctx.setState({ chat: { top: action.payload, bottom: ctx.getState().chat.bottom } });
  }

  @Action(SaveBottomChatWindowAction)
  saveBottomChatWindow(ctx: StateContext<ChatStateModel>, action: SaveBottomChatWindowAction): void {
    return ctx.setState({ chat: { top: ctx.getState().chat.top, bottom: action.payload } });
  }

  @Selector()
  static getChat(state: ChatStateModel) {
    return state.chat;
  }
}
