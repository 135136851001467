import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { RoomProtocol } from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-delete-protocol',
  standalone: true,
  imports: [
    MatIconButton,
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './delete-protocol.component.html',
  styleUrl: './delete-protocol.component.scss'
})
export class DeleteProtocolComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected protocol: RoomProtocol;
  protected pending = false;

  private _roomService = inject(RoomService);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.protocol = this.data.protocol;
    }
  }

  deleteProtocol(): void {
    this.pending = true;
    this._roomService.deleteProtocol(this.protocol.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.closeModal(res);
      });
  }
}
