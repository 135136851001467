import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgTemplateOutlet } from '@angular/common';
import { Subscription } from 'rxjs';
import { RxStompState } from '@stomp/rx-stomp';
import { IonicModule } from '@ionic/angular';
import { WaveAnimationComponent } from '../../ui/wave-animation/wave-animation.component';
import { addIcons } from 'ionicons';
import { alertCircle } from 'ionicons/icons';
import { AIChatError, RoomAction, TranslateStatus } from '../../../transport.interface';
import { RxStompService } from '../../../service/rx-stomp/rx-stomp.service';
import { RoomService } from '../../../service/room.service';
import { ErrorCodes } from '../../../errorcodes.const';
import { appComponentRef } from '../../../app-component';

@UntilDestroy()
@Component({
  selector: 'app-photo-analyzing-dialog',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgTemplateOutlet,
    WaveAnimationComponent,
    IonicModule,
    WaveAnimationComponent
  ],
  templateUrl: './photo-analyzing-dialog.component.html',
  styleUrl: './photo-analyzing-dialog.component.scss',
})
export class PhotoAnalyzingDialogComponent implements OnInit, OnDestroy {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;
  @Output() closedEvent: EventEmitter<void> = new EventEmitter<void>();

  wsConnected = false;
  status: TranslateStatus | RoomAction = TranslateStatus.READY;
  error: AIChatError = null;

  protected readonly TranslateStatus = TranslateStatus;
  protected readonly RoomAction = RoomAction;

  private readonly _ws: RxStompService = inject(RxStompService);
  private readonly _roomService: RoomService = inject(RoomService);
  private readonly _wsSubscription: Subscription;
  private readonly _wsStateSubscription: Subscription;
  private readonly _subscriptions: Subscription[] = [];

  constructor() {
    addIcons({ alertCircle });
    if (this._wsStateSubscription) {
      this._wsStateSubscription.unsubscribe();
    }
    this._wsStateSubscription = this._ws.connectionState$
      .subscribe((res) => {
        if (res === RxStompState.CLOSED) {
          this.wsConnected = false;
        }
      });
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    this._wsSubscription = this._ws.connected$
      .subscribe((res) => {
        if (res === RxStompState.OPEN) {
          this.wsConnected = true;
          if (this._subscriptions?.length) {
            this._subscriptions.forEach(s => s.unsubscribe());
          }
          this._subscriptions.push(this._roomService.getMyEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(action => {
              if (action.action === RoomAction.STOP_RECORD) {
                this.closeRecordWindow();
              }
            }));
          this._subscriptions.push(this._roomService.getStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.status = res;
            }));
          this._subscriptions.push(this._roomService.getMyEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              if (res.action === RoomAction.TRANSCRIPT || res.action === RoomAction.TRANSLATE) {
                this.status = res.action;
              }
            }));
          this._subscriptions.push(this._roomService.myErrorEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.error = res;
              this.status = TranslateStatus.ERROR;
              if (this.error.errorCode === ErrorCodes.INVALID_TOKEN) {
                appComponentRef().invalidSessionEvent();
              }
            }));
        }
      });
  }

  ngOnInit(): void {
    console.log();
  }

  closeRecordWindow() {
    this.closeModal();
  }

  ngOnDestroy(): void {
    this._wsSubscription.unsubscribe();
    if (this._subscriptions?.length) {
      this._subscriptions.forEach(s => s.unsubscribe());
    }
  }
}
