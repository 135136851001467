import { Component, ElementRef, EventEmitter, inject, Input, Output, Signal, ViewChild } from '@angular/core';
import { PrettyDatePipe } from '@shared/pipe/pretty-date.pipe';
import { TranslocoPipe } from '@ngneat/transloco';
import { RoomProtocol, RoomUser } from '@shared/transport.interface';
import { AsyncPipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { DialogService } from '@shared/service/dialog.service';
import { UtilsService } from '@shared/service/utils.service';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { UserCardComponent } from '@shared/component/ui/user-card/user-card.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline, shareOutline, trashOutline } from 'ionicons/icons';

@Component({
  selector: 'app-show-protocol',
  standalone: true,
  imports: [
    PrettyDatePipe,
    TranslocoPipe,
    InitialsAvatarComponent,
    AsyncPipe,
    MatIconButton,
    UserCardComponent,
    IonicModule
  ],
  templateUrl: './show-protocol.component.html',
  styleUrl: './show-protocol.component.scss'
})
export class ShowProtocolComponent {
  @Input() protocol: RoomProtocol;
  @Output() closeEvent: EventEmitter<RoomProtocol> = new EventEmitter<RoomProtocol>();

  @ViewChild('scroll', { static: true }) scrollEl: ElementRef;

  private _dialogService = inject(DialogService);
  private _utilsService = inject(UtilsService);
  private _store: Store = inject(Store);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  constructor() {
    addIcons({ closeOutline, calendarOutline, shareOutline, trashOutline });
  }

  closeDialog(): void {
    this.closeEvent.next(this.protocol);
  }

  shareProtocol(): void {
    this._dialogService.shareProtocol(this.protocol)
      .subscribe(res => {
        if (res) {
          this.protocol.shared = true;
        }
      });
  }

  deleteProtocol(): void {
    this._dialogService.deleteProtocol(this.protocol)
      .subscribe(res => {
        if (res) {
          this.protocol.deleted = true;
          this.closeDialog();
        }
      });
  }

  scrollToId(id: string): void {
    this._utilsService.scrollToId(id);
  }

}
