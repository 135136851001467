<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">&nbsp;
    </ion-buttons>
    <ion-title>
      {{ 'title.invite.participants' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeDialog()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden p-5">
  <div class="mt-2 text-center">{{ 'text.scan.qr.code' | transloco }}</div>
  @if (roomId) {
    <app-qr-code [url]="url"></app-qr-code>
  }
  <div class="w-full flex items-center justify-center relative">
    @if (copyUrlFlag) {
      <div class="bg-black text-white py-1.5 px-5 text-center text-xs absolute max-w-32 top-[-20px] left-0 right-0 m-auto">{{ 'text.copied' | transloco }}</div>
    }
    <div>
      <button mat-icon-button (click)="copyUrl()">
        <ion-icon name="copy-outline" class="text-4xl"></ion-icon>
      </button>
    </div>
    <div class="ml-2">
      <button mat-icon-button (click)="shareUrl()">
        <ion-icon name="share-outline" class="text-4xl"></ion-icon>
      </button>
    </div>
  </div>

  <div class="mt-4 flex flex-col flex-auto w-full">
    <div class="flex flex-col flex-auto">
      <div class="flex justify-between items-center">
        <div class="title text-2xl">{{ 'title.connection.requests' | transloco }}</div>
        @if (users.length >= 1) {
          <button class="button button-m tiny" (click)="acceptAllUsers()" [class.loading]="acceptAllRequest" [disabled]="acceptAllRequest">{{ 'button.accept.all' | transloco }}
          </button>
        }
      </div>
      <div class="text-md mb-4">{{ 'text.waiting.users.full' | transloco: { count: users.length } }}</div>
      <div class="flex-auto">
        <ion-content>
          @for (user of users; track user.id) {
            <div class="border-b border-b-gray-300 w-full"></div>
            <div class="flex items-center h-[66px] justify-between">
              <div class="flex items-center">
                <app-initials-avatar [avatarId]="user.avatarId" [user]="{name: user.name}" [width]="40" [textSize]="'text-xs'"></app-initials-avatar>
                <div class="ml-2 font-bold">{{ user.name }}</div>
              </div>
              <div class="ml-2 flex items-center">
                @if (!user.accepted && !user.blocked) {
                  <button class="button button-m min-w-[80px]" [disabled]="pending[user.id]" (click)="acceptUser(user)"
                          [class.loading]="pending[user.id]">{{ 'button.accept' | transloco }}
                  </button>
                } @else if (!user.blocked) {
                  <div class="flex items-center">
                    <ion-icon name="checkmark-outline" class="text-2xl text-green-600"></ion-icon>
                    <div class="ml-2">{{ 'status.accepted' | transloco }}</div>
                  </div>
                }
                <div class="ml-4 flex items-center">
                  @if (!user.accepted && !user.blocked) {
                    <ion-icon name="ban-outline" class="text-red-500 text-2xl cursor-pointer" (click)="blockUser(user)"></ion-icon>
                  } @else if (user.blocked) {
                    <div class="flex items-center" (click)="unblockUser(user)">
                      <ion-icon name="ban-outline" class="text-red-500 text-2xl cursor-pointer"></ion-icon>
                      <div class="ml-2">{{ 'status.blocked' | transloco }}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </ion-content>
      </div>
    </div>

  </div>
</div>
