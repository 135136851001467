import { Component, inject, Input, OnChanges, OnInit, Signal, SimpleChanges } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { PrettyDatePipe } from '@shared/pipe/pretty-date.pipe';
import { TranslocoPipe } from '@ngneat/transloco';
import { UserService } from '@shared/service/user.service';
import { DialogService } from '@shared/service/dialog.service';
import { Store } from '@ngxs/store';
import { HistoryFilter, Paging, RoomProtocol, RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { UtcDatePipe } from '@shared/pipe/utc-date.pipe';
import { DateLineComponent } from '@shared/component/ui/date-line/date-line.component';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { ellipsisVertical, searchOutline, trashOutline } from 'ionicons/icons';
import { InfiniteScrollCustomEvent, IonicModule } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-protocol-history',
  standalone: true,
  imports: [
    AsyncPipe,
    DateLineComponent,
    InitialsAvatarComponent,
    LoaderComponent,
    PrettyDatePipe,
    TranslocoPipe,
    UtcDatePipe,
    IonicModule
  ],
  templateUrl: './protocol-history.component.html',
  styleUrl: './protocol-history.component.scss'
})
export class ProtocolHistoryComponent implements OnInit, OnChanges {
  @Input() filterChanged = false;
  @Input() filter: HistoryFilter;

  private _userService = inject(UserService);
  private _dialogService = inject(DialogService);
  private _store = inject(Store);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protocols: RoomProtocol[] = [];
  loading = false;
  upgradeTariff = false;
  private _paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  protected loadingMore = false;
  protected empty = false;

  constructor() {
    addIcons({ ellipsisVertical, trashOutline, searchOutline });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterChanged?.currentValue === true) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this._userService.historyProtocols(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize, this.filter)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe({
        next: res => {
          this._paging.totalPages = res.total;
          this.protocols = res.list;
        },
        error: err => {
          this.upgradeTariff = true;
        }
      });
  }

  loadMoreData(ev: InfiniteScrollCustomEvent): void {
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this._paging.currentPage++;
    this._userService.historyProtocols(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loadingMore = false))
      .subscribe(res => {
        this.protocols = this.protocols.concat(res.list);
        if (!res.list.length || res.list.length < this._paging.pageSize) {
          this.empty = true;
        }
        ev.target.complete().then();
      });
  }

  deleteProtocol(protocol: RoomProtocol): void {
    this._dialogService.deleteProtocol(protocol)
      .subscribe(res => {
        this.deleteProtocolFromArray(res);
      });
  }

  shareProtocol(protocol: RoomProtocol): void {

  }

  downloadPdf(protocol: RoomProtocol): void {
    alert('Not implemented');
  }

  showProtocol(protocol: RoomProtocol): void {
    this._dialogService.showProtocol(protocol)
      .then(res => {
        if (res?.deleted) {
          this.deleteProtocolFromArray(res);
        }
      });
  }

  private deleteProtocolFromArray(res: RoomProtocol) {
    if (res) {
      const ind = this.protocols.findIndex(s => s.id === res.id);
      if (ind >= 0) {
        this.protocols.splice(ind, 1);
      }
    }
  }
}
