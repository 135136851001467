import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { QrCodeComponent } from '../../ui/qr-code/qr-code.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { banOutline, checkmarkOutline, closeOutline, copyOutline, shareOutline } from 'ionicons/icons';
import { environment } from '@env/environment';
import { Share } from '@capacitor/share';
import { RoomService } from '@service/room.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoomAction, RoomMode, RoomUser } from '@shared/transport.interface';
import { InitialsAvatarComponent } from '@comp/ui/initials-avatar/initials-avatar.component';
import { finalize, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';

@UntilDestroy()
@Component({
  selector: 'app-qr-code-dialog',
  standalone: true,
  imports: [
    QrCodeComponent,
    MatIconButton,
    TranslocoPipe,
    IonicModule,
    InitialsAvatarComponent,
  ],
  templateUrl: './qr-code-dialog.component.html',
  styleUrl: './qr-code-dialog.component.scss'
})
export class QrCodeDialogComponent implements OnInit, OnDestroy {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  private readonly _roomService = inject(RoomService);
  private readonly _router = inject(Router);

  protected url: string = environment.serverUrl + '/join-room/';

  protected roomId: string;
  protected isCompany: boolean;
  protected users: RoomUser[] = [];
  protected pending = {};
  protected acceptAllRequest = false;

  protected readonly navigator = navigator;

  private _subs: Subscription;

  constructor() {
    addIcons({ closeOutline, copyOutline, shareOutline, banOutline, checkmarkOutline });
  }

  ngOnDestroy(): void {
    if (this._subs) {
      this._subs.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.roomId = this.data.roomId;
      this.url += this.roomId;
      this.isCompany = this.data.isCompany;
    }
    if (this._subs) {
      this._subs.unsubscribe();
    }
    this._subs = this._roomService.getMyEvents$()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (user.action === RoomAction.USER_WAITING || user.action === RoomAction.USER_WAITING_LEFT) {
          this._roomService.waitingUsers = user.users!;
          this.users = this._roomService.waitingUsers;
        }
      });
    this._roomService.waitingList(this.roomId)
      .pipe(untilDestroyed(this))
      .subscribe(users => {
        this.users = users;
        this._roomService.waitingUsers = users;
      });
  }

  async shareUrl() {
    await Share.share({
      title: environment.appName + ': Speak freely. Translate Instantly.',
      url: this.url,
      dialogTitle: 'Share',
    });
  }

  copyUrlFlag = false;

  copyUrl(): void {
    Clipboard.write({ string: this.url }).then();
    this.copyUrlFlag = true;
    setTimeout(() => this.copyUrlFlag = false, 2000);
  }

  acceptUser(user: RoomUser): void {
    if (this.pending[ user.id ] === true) {
      return;
    }
    this.pending[ user.id ] = true;
    if (this._roomService.mode() === RoomMode.SINGLE) {
      this._router.navigate([ '/', Pages.CHAT ], { queryParams: { mode: 'conference' } }).then(() => {
        this._roomService.joinMyRoom(RoomMode.MULTI)
          .pipe(untilDestroyed(this))
          .subscribe(room => {
            this.acceptUser0(user);
          });
      });
      return;
    }
    this.acceptUser0(user);
  }

  private acceptUser0(user: RoomUser) {
    this._roomService.acceptUser(user, this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending[ user.id ] = false))
      .subscribe(() => {
        user.accepted = true;
      });
  }

  acceptAllUsers(): void {
    if (this.acceptAllRequest) {
      return;
    }
    this.acceptAllRequest = true;
    if (this._roomService.mode() === RoomMode.SINGLE) {
      this._router.navigate([ '/', Pages.CHAT ], { queryParams: { mode: 'conference' } }).then(() => {
        this._roomService.joinMyRoom(RoomMode.MULTI)
          .pipe(untilDestroyed(this))
          .subscribe(room => {
            this.acceptAllUsers0();
          });
      });
      return;
    }
    this.acceptAllUsers0();
  }

  private acceptAllUsers0() {
    this._roomService.acceptAllUsers(this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.acceptAllRequest = false))
      .subscribe(() => {
        this.users.forEach(user => {
          if (!user.blocked) {
            user.accepted = true;
          }
        })
      });
  }

  blockUser(user: RoomUser) {
    if (this.pending[ user.id ] === true) {
      return;
    }
    this.pending[ user.id ] = true;
    this._roomService.blockUser(user, this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending[ user.id ] = false))
      .subscribe(() => {
        user.blocked = true;
      });
  }

  unblockUser(user: RoomUser) {
    if (this.pending[ user.id ] === true) {
      return;
    }
    this.pending[ user.id ] = true;
    this._roomService.unblockUser(user, this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending[ user.id ] = false))
      .subscribe(() => {
        user.blocked = false;
      });
  }

  closeDialog() {
    this._modalCtrl.dismiss();
  }
}
