import { Component, inject, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleAuthComponent } from '@shared/component/social/google-auth/google-auth.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Store } from '@ngxs/store';
import { finalize } from 'rxjs';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { NotyService } from '@shared/service/noty.service';
import { ModalController } from '@ionic/angular/standalone';
import { AuthService } from '@service/auth/auth.service';
import { FuseAlertComponent } from '@comp/ui/alert';
import { IonicModule } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-complete-registration',
  standalone: true,
  imports: [
    MatIcon,
    TranslocoPipe,
    FormsModule,
    GoogleAuthComponent,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    FuseAlertComponent,
    IonicModule
  ],
  templateUrl: './complete-registration.component.html',
  styleUrl: './complete-registration.component.scss'
})
export class CompleteRegistrationComponent {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  commonError = null;
  pending = false;
  form: FormGroup;

  private _store = inject(Store);
  private _fb = inject(FormBuilder);
  private _authService = inject(AuthService);
  private _notyService = inject(NotyService);

  constructor() {
    this.form = this._fb.group({
      email: [ '', [ Validators.required, Validators.email ] ],
      firstName: [ '', [ Validators.required ] ],
      lastName: [ '', [ Validators.required ] ],
    });
  }

  completeRegistration(): void {
    if (this.form.invalid) {
      return;
    }
    const req = this.form.value;
    this.pending = true;
    this._authService.completeRegistration(req.email, req.firstName, req.lastName)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this._store.dispatch(new SaveUserAction(res));
        this._notyService.success('title.registration.successful');
        this.closeModal(res);
      });
  }

  completeGoogleRegistration($event: string): void {
    this.pending = true;
    this._authService.completeGoogleRegistration($event)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this._store.dispatch(new SaveUserAction(res));
        this._notyService.success('title.registration.successful');
        this.closeModal(res);
      });
  }

  closeModal(res?: any) {
    this._modalCtrl.dismiss(res);
  }
}
