import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { RoomService } from '@shared/service/room.service';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { StorageService } from '@service/storage.service';
import { FuseAlertComponent } from '@comp/ui/alert';

@UntilDestroy()
@Component({
  selector: 'app-new-room-dialog',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule,
    FuseAlertComponent
  ],
  templateUrl: './new-room-dialog.component.html',
  styleUrl: './new-room-dialog.component.scss'
})
export class NewRoomDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;
  pending = false;
  commonError = null;
  roomId = null;

  private _roomService = inject(RoomService);
  private _storageService = inject(StorageService);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data.roomId) {
      this.roomId = this.data.roomId;
    }
  }

  exitRoom(): void {
    this.pending = true;
    this._roomService.exitRoom(this.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: (res) => {
          this._storageService.clearRoomId();
          this.closeModal(true);
        }, error: err => {
          this.commonError = err;
        }
      });
  }
}
