import { inject, Injectable } from '@angular/core';
import { IMessage, RxStomp } from '@stomp/rx-stomp';
import { StompHeaders } from '@stomp/stompjs/src/stomp-headers';
import { Observable } from 'rxjs';
import { StorageService } from '@service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RxStompService extends RxStomp {
  // socket = io('http://localhost:8080/audio',
  //   { // adapt to your server
  //     reconnection: true,             // default setting at present
  //     reconnectionDelay: 1000,        // default setting at present
  //     reconnectionDelayMax: 5000,    // default setting at present
  //     reconnectionAttempts: Infinity  // default setting at present
  //   });

  private readonly _storageService = inject(StorageService);

  constructor() {
    super();
  }

  subscribe(topic: string): Observable<IMessage> {
    return this.watch({ destination: topic, subscribeOnlyOnce: true })
  }

  sendToTopic(topic: string, body: any, headers?: StompHeaders): void {
    this._storageService.accessToken().then(token => {
      this.stompClient.publish({ destination: topic, body: JSON.stringify(body), headers: { ...headers, token } });
    });
  }

  sendBinaryToTopic(topic: string, body: any, headers?: StompHeaders): void {
    this._storageService.accessToken().then(token => {
      this.stompClient.publish({ destination: topic, body: body, headers: { ...headers, token } });
    });
  }
}
