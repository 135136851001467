import { Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { TariffTitleComponent } from '@comp/tariff-title/tariff-title.component';
import { DialogService } from '@service/dialog.service';
import { UtilsService } from '@service/utils.service';
import { RoomUser, UserRole } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { Pages } from '@app/pages';
import { StorageService } from '@service/storage.service';

@Component({
  selector: 'app-tariff-info',
  standalone: true,
  imports: [
    TranslocoPipe,
    TariffTitleComponent
  ],
  templateUrl: './tariff-info.component.html',
  styleUrl: './tariff-info.component.scss'
})
export class TariffInfoComponent {
  @Input() upgradeLink = true;
  @Output() upgradeTariff: EventEmitter<void> = new EventEmitter<void>();

  private readonly _store = inject(Store);
  private readonly _router = inject(Router);
  private readonly _dialogService = inject(DialogService);
  private readonly _storageService = inject(StorageService);
  protected readonly _utils = inject(UtilsService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected logged = false;

  constructor() {
    this._storageService.logged().then(logged => {
      this.logged = logged && this.me()?.role !== UserRole.TEMP_USER;
    })
  }

  buyTariff(): void {
    this.upgradeTariff.next();
    this._dialogService.closeAll();
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this._dialogService.tariffs();
  }
}
