import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { PhotoType, RoomUser, SuperUser } from '@shared/transport.interface';
import { map, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public static readonly accessTokenTTLStr: string = 'accessTokenTTL';
  public static readonly accessTokenStr: string = 'accessToken';
  public static readonly lastUsedStr: string = 'lastUsed';
  public static readonly externalIdStr: string = 'externalId';
  public static readonly roomIdStr: string = 'roomId';
  public static readonly user: string = 'user';
  public static readonly lang: string = 'lang';
  public static readonly theme: string = 'theme';
  public static readonly config: string = 'config';
  public static readonly mode: string = 'mode';
  static readonly USER_UID = 'userUid';
  static readonly TAKE_PHOTO_MODE = 'photoMode';

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
  }

  async init() {
    if (this._storage != null) {
      return;
    }
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public async set<T>(key: string, value: T): Promise<void> {
    await this.init();
    this._storage?.set(key, value).then();
  }

  public delete(key: string) {
    this._storage.remove(key);
  }

  public async get<T>(key: string): Promise<T> {
    await this.init();
    return await this._storage?.get(key) as T;
  }

  public async saveAccessToken(token: string) {
    await this.set(StorageService.accessTokenStr, token);
  }

  public async roomId(): Promise<string> {
    return await this.get(StorageService.roomIdStr);
  }

  public clear(): void {
    this._storage.remove(StorageService.accessTokenStr).then();
    this._storage.remove(StorageService.user).then();
    console.log('clear storage');
  }

  public clearRoomId(): void {
    this._storage.remove(StorageService.roomIdStr).then();
  }

  public async lastUsedLangs(): Promise<string[]> {
    let str: string = await this.get(StorageService.lastUsedStr);
    return str ? JSON.parse(str) : [];
  }

  public saveRoomId(roomId: string): void {
    if (!roomId) {
      return;
    }
    this.set(StorageService.roomIdStr, roomId);
  }

  public async logged(): Promise<boolean> {
    return await this.get(StorageService.accessTokenStr) !== null;
  }

  public logged$(): Observable<boolean> {
    if (!this._storage) {
      return of(false);
    }
    return fromPromise(this.get(StorageService.accessTokenStr)).pipe(map(token => {
      return token !== null && token !== undefined;
    }));
  }

  public removeExternalId(): void {
    this._storage.remove(StorageService.externalIdStr).then();
  }

  public async getPhotoMode(): Promise<PhotoType> {
    return await this.get(StorageService.TAKE_PHOTO_MODE) as PhotoType;
  }

  public savePhotoMode(mode: PhotoType): void {
    if (mode) {
      this.set(StorageService.TAKE_PHOTO_MODE, mode);
    }
  }

  public saveUser(user: SuperUser): void {
    if (user) {
      this.set(StorageService.user, JSON.stringify(user));
    }
  }

  public async externalId(): Promise<string> {
    return await this.get(StorageService.externalIdStr) as string;
  }

  public saveAccessTokenTTL(time: number): void {
    this.set(StorageService.accessTokenTTLStr, time + '');
  }

  public async accessToken(): Promise<string> {
    return await this.get(StorageService.accessTokenStr) as string;
  }

  public async getUser(): Promise<RoomUser | null> {
    const item = await this.get<string>(StorageService.user);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  public getUser$(): Observable<RoomUser | null> {
    return fromPromise(this.get(StorageService.user))
      .pipe(map((item: string) => {
        if (item) {
          return JSON.parse(item);
        }
        return null;
      }));
  }

  public async accessTokenTTL(): Promise<number> {
    return await this.get(StorageService.accessTokenTTLStr) as number;
  }

  public saveExternalId(id: string) {
    return this.set(StorageService.externalIdStr, id);
  }

  public async addUsedLang(lang: string): Promise<string[]> {
    const lastUsed = await this.lastUsedLangs();
    if (lastUsed.indexOf(lang) >= 0) {
      return this.lastUsedLangs();
    }
    if (lastUsed.length > 2) {
      lastUsed.pop();
    }
    lastUsed.unshift(lang);
    this.set(StorageService.lastUsedStr, JSON.stringify(lastUsed));
    return this.lastUsedLangs();
  }

  saveUserUid(token: string): void {
    this.set(StorageService.USER_UID, token);
  }

  async getUserUid(): Promise<string> {
    return await this.get(StorageService.USER_UID) as string;
  }

}
