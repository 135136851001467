import { Component, inject, Input, Signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { IonicModule } from '@ionic/angular';
import { RoomUser } from '../../../../transport.interface';
import { UserState } from '../../../../store/user/user-state.service';
import { UserService } from '../../../../service/user.service';
import { SaveUserAction } from '../../../../store/user/user.actions';

@UntilDestroy()
@Component({
  selector: 'app-delete-avatar',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './delete-avatar.component.html',
  styleUrl: './delete-avatar.component.scss'
})
export class DeleteAvatarComponent {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  private _store = inject(Store);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected pending = false;

  private _userService = inject(UserService);

  deleteAvatar(): void {
    if (!this.me().avatarId) {
      return;
    }
    this.pending = true;
    this._userService.deleteAvatar(this.me().avatarId)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.me().avatarId = null;
        this._store.dispatch(new SaveUserAction(this.me()));
        this.closeModal(res);
      });
  }
}
