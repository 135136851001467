<div
  class="fuse-alert-container"
  *ngIf="!dismissible || dismissible && !dismissed"
  [@fadeIn]="!dismissed"
  [@fadeOut]="!dismissed">

  <!-- Border -->
  @if (appearance === 'border') {
    <div class="fuse-alert-border"></div>
  }

  <!-- Icon -->
  @if (showIcon) {
    <div class="fuse-alert-icon">

      <!-- Custom icon -->
      <div class="fuse-alert-custom-icon">
        <ng-content select="[fuseAlertIcon]"></ng-content>
      </div>

      <!-- Default icons -->
      <div class="fuse-alert-default-icon">

        @if ([ 'primary', 'accent', 'basic', 'success' ].indexOf(type) >= 0) {
          <ion-icon name="checkmark-circle-outline" class="text-2xl"></ion-icon>
        }

        @if ([ 'error', 'warn' ].indexOf(type) >= 0) {
          <ion-icon name="close-circle-outline" class="text-2xl"></ion-icon>
        }

        @if ([ 'info' ].indexOf(type) >= 0) {
          <ion-icon name="alert-circle-outline" class="text-2xl"></ion-icon>
        }

        @if ([ 'warning' ].indexOf(type) >= 0) {
          <ion-icon name="warning-outline" class="text-2xl"></ion-icon>
        }
      </div>

    </div>
  }

  <!-- Content -->
  <div class="fuse-alert-content">

    <div class="fuse-alert-title">
      <ng-content select="[fuseAlertTitle]"></ng-content>
    </div>

    <div class="fuse-alert-message">
      <ng-content></ng-content>
    </div>

  </div>

  <!-- Dismiss button -->
  <button
    class="fuse-alert-dismiss-button"
    mat-icon-button
    (click)="dismiss()">
    <ion-icon name="close-outline" class="text-4xl cursor-pointer"></ion-icon>
  </button>

</div>
