<div class="overflow-hidden w-full flex flex-col bg-white">
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="title text-4xl">{{ 'title.delete.avatar' | transloco }}</div>
      <ion-icon name="close-outline" class="text-4xl" (click)="closeModal()"></ion-icon>
    </div>
    <div class="mt-5">
      <button class="button red w-full" (click)="deleteAvatar()" [disabled]="pending" [class.loading]="pending">{{'button.delete' | transloco}}</button>
      <button class="button stroked w-full" (click)="closeModal()" [disabled]="pending">{{'button.cancel' | transloco}}</button>
    </div>
  </div>
</div>
