<div class="flex flex-col flex-auto overflow-y-auto p-5 pt-0">
  @if (loading) {
    <app-loader></app-loader>
  } @else {
    @if (!upgradeTariff) {
      <ion-content scroll-y="true" #scrollArea>
        @for (chat of chats; track chat.roomId; let ind = $index; let first = $first; let last = $last) {
          <app-date-line [first]="first" [lastDate]="chats[ind - 1]?.createTime" [currentDate]="chat.createTime"></app-date-line>
          <app-room-history-card [showLine]="(chats[ind + 1]?.createTime | utcDate: 'd') === (chat.createTime | utcDate: 'd')" [room]="chat" class="cursor-pointer" [last]="last"
                                 (click)="showRoomHistory(chat)"></app-room-history-card>
        } @empty {
          <div class="flex flex-col justify-center mt-5 items-center">
            <i class="fa-solid fa-magnifying-glass fa-3x"></i>
            <div class="title text-4xl mt-2.5">{{ 'title.no.rooms.history' | transloco }}</div>
            <div class="mt-2.5 text-center text-base">{{ 'text.no.rooms.history' | transloco }}</div>
          </div>
        }
        <ion-infinite-scroll position="bottom" (ionInfinite)="loadMoreData($event)" [disabled]="empty">
          <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    } @else {
      <div class="mb-1">{{ 'text.tariff.upgrade.message' | transloco }}</div>
      <div class="blurred">
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="font-bold mt-5 blank-text">&nbsp;</div>
        <div class="mb-2">&nbsp;</div>
      </div>

    }
  }
</div>
