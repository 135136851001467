<div class="flex flex-col w-full flex-auto p-5">
  <div class="flex flex-col w-full bg-white py-2.5">
    <div class="flex justify-between px-4">
      <div>{{ 'label.participants.count' | transloco: { count: roomParticipants?.participants?.length } }}</div>
      @if (me().id === room?.creator.id) {
        <div class="error-text cursor-pointer" (click)="blockedUsers()">{{ 'label.blocked' | transloco: { count: roomParticipants?.blocked?.length } }}</div>
      }
    </div>
    <div class="relative flex flex-col w-full px-4 overflow-y-auto overflow-x-hidden min-h-[200px]">
      @if (loading) {
        <app-loader></app-loader>
      } @else {
        @if (roomParticipants?.participants?.length) {
          <div class="w-full mt-2.5 border-b border-b-gray-300">
            @for (user of roomParticipants?.participants; track user.id) {
              <div class="py-2.5 border-t border-t-gray-300 flex justify-between items-center">
                <app-user-card [me]="user"></app-user-card>
                <div class="flex items-center">
                  <div class="text-base link-color">
                    @if (user.id === me().id && me().id === room?.creator.id) {
                      <div class="flex items-center">
                        <div>{{ 'label.role.COMPANY_HOST' | transloco }}</div>
                        <button class="button button-sm red ltr:ml-2.5 rtl:mr-2.5" (click)="exitRoom()">{{ 'button.exit' | transloco }}</button>
                      </div>
                    } @else if (user.id === room?.creator.id) {
                      <b>{{ 'label.role.COMPANY_HOST' | transloco }}</b>
                    } @else {
                      <div class="flex items-center">
                        <div>{{ 'label.role.' + user.role | transloco }}</div>
                        @if (user.id === me().id) {
                          <button class="button button-sm red ltr:ml-2.5 rtl:mr-2.5" (click)="exitRoom()">{{ 'button.exit' | transloco }}</button>
                        }
                      </div>
                    }
                  </div>
                  <div>
                    @if (user.id !== me().id && me().id === room?.creator.id) {
                      <button
                        class="ml-auto"
                        mat-icon-button
                        [matMenuTriggerFor]="conversationHeaderMenu">
                        <ion-icon name="ellipsis-vertical"></ion-icon>
                        <mat-menu #conversationHeaderMenu>
                          @if (me().id === room.creator.id) {
                            <button mat-menu-item (click)="blockUser(user)">
                              <i class="fa-solid fa-ban pr-2"></i>
                              <span class="text-base">{{ 'label.block.and.delete' | transloco }}</span>
                            </button>
                          }
                        </mat-menu>
                      </button>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="flex flex-col justify-center mt-5 items-center text-center">
            <i class="fa-solid fa-magnifying-glass fa-3x"></i>
            <div class="title text-4xl mt-2.5">{{ 'title.no.participants' | transloco }}</div>
          </div>
        }
      }
    </div>
  </div>
</div>
