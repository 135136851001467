import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { HttpClientService } from '../http-client/http-client.service';
import { SiteInfo } from '../transport.interface';
import { SaveSiteInfoAction } from '../store/site/site.actions';
import { ApiUrl } from '../api-url';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private _store = inject(Store);

  constructor(private _http: HttpClientService) {
  }

  siteInfo(): Observable<SiteInfo> {
    return this._http.doGet<SiteInfo>(ApiUrl.SITE_INFO)
      .pipe(switchMap((site: SiteInfo) => {
        this._store.dispatch(new SaveSiteInfoAction(site));
        return of(site);
      }));
  }
}
