<div class="flex flex-col flex-auto overflow-y-auto p-5 pt-0">
  @if (loading) {
    <app-loader></app-loader>
  } @else {
    <ion-content scroll-y="true" #scrollArea>
      @if (!upgradeTariff) {
        @for (summary of summaries; track summary.id; let ind = $index; let last = $last; let first = $first) {
          <app-date-line [first]="first" [lastDate]="summaries[ind - 1]?.createDate" [currentDate]="summary.createDate"></app-date-line>
          <div class="flex mb-2">
            <div class="flex-auto cursor-pointer" (click)="showSummary(summary)">
              <div class="line-clamp-2 title text-2xl">{{ summary.title }}</div>
              <div class="flex items-center text-gray-400 text-sm mt-1">
                <div class="pr-2.5 mr-2.5 border-r border-r-gray-300 flex items-center whitespace-nowrap">
                  <i class="fa-regular fa-calendar text-gray-400 fa-sm"></i>
                  <div class="ltr:ml-1.5 rtl:mr-1.5">{{ summary?.createDate | prettyDate | async }}</div>
                </div>
                <div class="flex items-center flex-auto overflow-hidden">
                  <app-initials-avatar [avatarId]="summary?.user?.avatarId" [user]="{name: summary?.user?.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
                  <div class="ltr:ml-2 rtl:mr-2 flex w-full overflow-hidden">
                    <div class="text-gray-400 truncate">{{ summary?.user?.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              @if (me().id === summary.user.id) {
                <button
                  class="ml-auto"
                  mat-icon-button
                  [matMenuTriggerFor]="conversationHeaderMenu">
                  <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                  <mat-menu #conversationHeaderMenu>
                    <!--                  <button mat-menu-item (click)="shareSummary(summary)">-->
                    <!--                    <i class="fa-solid fa-arrow-up-from-bracket pr-2"></i>-->
                    <!--                    <span class="text-base">{{ 'menu.share' | transloco }}</span>-->
                    <!--                  </button>-->
                    <!--                  TODO -->
                    <!--                  <button mat-menu-item (click)="downloadPdf(summary)">-->
                    <!--                    <i class="fa-solid fa-arrow-up-from-bracket pr-2"></i>-->
                    <!--                    <span class="text-base">{{ 'menu.download.pdf' | transloco }}</span>-->
                    <!--                  </button>-->
                    <button mat-menu-item (click)="deleteSummary(summary)">
                      <i class="fa-solid fa-trash pr-2"></i>
                      <span class="text-base">{{ 'menu.delete' | transloco }}</span>
                    </button>
                  </mat-menu>
                </button>
              }
            </div>
          </div>
          @if (!last && (summaries[ind + 1]?.createDate | utcDate: 'd') === (summary.createDate | utcDate: 'd')) {
            <div class="flex w-full h-[1px] mb-2">
              <div class="flex-auto border-b border-b-gray-300">&nbsp;</div>
            </div>
          }
        } @empty {
          <div class="flex flex-col justify-center mt-5 items-center">
            <i class="fa-solid fa-magnifying-glass fa-3x"></i>
            <div class="title text-4xl mt-2.5">{{ 'title.no.summary.history' | transloco }}</div>
            <div class="mt-2.5 text-center text-base">{{ 'text.no.summary.history' | transloco }}</div>
          </div>
        }
      } @else {
        <div class="mb-1">{{ 'text.tariff.upgrade.message' | transloco }}</div>
        <div class="blurred">
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="mb-2">&nbsp;</div>
        </div>

      }
      <ion-infinite-scroll position="top" (ionInfinite)="loadMoreData($event)" [disabled]="empty">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  }
</div>
