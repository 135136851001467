import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserService } from '../../../service/user.service';
import { Pages } from '../../../../pages';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-cancel-subscription',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './cancel-subscription.component.html',
  styleUrl: './cancel-subscription.component.scss'
})
export class CancelSubscriptionComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected pending = false;

  private _userService = inject(UserService);
  private _router = inject(Router);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    //
  }

  deleteProtocol(): void {
    this.pending = true;
    this._userService.cancelTariff()
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(() => {
        this._router.navigate([ '/', Pages.SUBSCRIPTION_CANCELED ]);
        this.closeModal(true);
      });
  }
}
