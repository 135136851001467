<ion-icon name="close-outline" class="close-icon z-99999 text-4xl cursor-pointer" (click)="closeModal()"></ion-icon>

<div class="flex flex-col justify-center text-center p-5">
  <div class="title text-3xl">{{'title.invite.participants' | transloco}}</div>
  <div class="mt-2">{{'text.scan.qr.code' | transloco}}</div>
  @if (roomId) {
    <app-qr-code [url]="url"></app-qr-code>
  }
  <div class="w-full flex items-center justify-center relative">
    @if (copyUrlFlag) {
      <div class="bg-black text-white py-1.5 px-5 text-center text-xs absolute max-w-32 top-[-20px] left-0 right-0 m-auto">{{ 'text.copied' | transloco }}</div>
    }
    <div>
      <button mat-icon-button (click)="copyUrl()">
        <ion-icon name="copy-outline" class="text-4xl"></ion-icon>
      </button>
    </div>
    <div class="ml-2">
      <button mat-icon-button (click)="shareUrl()">
        <ion-icon name="share-outline" class="text-4xl"></ion-icon>
      </button>
    </div>
  </div>
</div>
