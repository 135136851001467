import { Component, effect, Input, input, OnInit, ViewChild } from '@angular/core';
import { ChatMessageDto } from '@shared/transport.interface';
import { IonContent } from '@ionic/angular/standalone';
import { LoaderComponent } from '@comp/loader/loader.component';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-realtime-messages',
  templateUrl: './realtime-messages.component.html',
  styleUrls: [ './realtime-messages.component.scss' ],
  standalone: true,
  imports: [
    IonContent,
    LoaderComponent,
    TranslocoPipe
  ]
})
export class RealtimeMessagesComponent implements OnInit {
  @ViewChild('scrollArea', { static: true })
  private scrollbar!: IonContent;

  @Input()
  messages: ChatMessageDto[] = [];

  counter = input<number>(0);

  constructor() {
    effect(() => {
      console.log(this.counter());
      if (this.counter()) {
        this.scrollbar.scrollToBottom(100);
      }
    });
  }

  ngOnInit() {
    //
  }

}
