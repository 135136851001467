import { Component, inject, Input, OnInit } from '@angular/core';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatInput } from '@angular/material/input';
import { SelectItem } from '@shared/transport.interface';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-selectbox-item-selector',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoPipe,
    MatInput,
    MatLabel,
    MatPrefix,
    IonicModule
  ],
  templateUrl: './selectbox-item-selector.component.html',
  styleUrl: './selectbox-item-selector.component.scss'
})
export class SelectboxItemSelectorComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  itemFilterCtrl: FormControl<string> = new FormControl<string>(null);
  filteredItems: ReplaySubject<SelectItem[]> = new ReplaySubject<SelectItem[]>(1);
  selectedItem: SelectItem;
  items: SelectItem[];
  search = true;

  constructor() {
  }

  ngOnInit(): void {
    if (this.data) {
      this.selectedItem = this.data.selectedItem;
      this.items = this.data.items;
      this.search = this.data.search;
      this.filterItems();
    }
    this.itemFilterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterItems();
      });
  }

  protected filterItems() {
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredItems.next(
      this.items.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  close(): void {
    this.closeModal(this.selectedItem);
  }

  selectItem(lang: SelectItem): void {
    this.selectedItem = lang;
    this.close();
  }

  closeModal(data?: any) {
    this._modalCtrl.dismiss(data);
  }
}
