import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { UtcDatePipe } from '@pipe/utc-date.pipe';
import { Tariff, TariffPackage } from '@shared/transport.interface';
import { UserService } from '@service/user.service';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

@UntilDestroy()
@Component({
  selector: 'app-minutes-package-card',
  standalone: true,
  imports: [
    DatePipe,
    CurrencyPipe,
    TranslocoPipe,
    UtcDatePipe
  ],
  templateUrl: './minutes-package-card.component.html',
  styleUrl: './minutes-package-card.component.scss'
})
export class MinutesPackageCardComponent implements OnInit {
  @Input() pack: TariffPackage;
  @Input() tariff: Tariff;
  pending = false;
  isWeb = signal(true);

  private _userService = inject(UserService);
  private _platform = inject(Platform);

  ngOnInit(): void {
    this.isWeb.set(!this._platform.is('ios') && !this._platform.is('android'));
  }

  buyMinutes(): void {
    this.pending = true;
    this._userService.buyTariffPackage(this.tariff.id, this.pack.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        Browser.open({ url: res.url }).then();
      });
  }
}
