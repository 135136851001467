import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-tariff-title',
  standalone: true,
    imports: [
        TranslocoPipe
    ],
  templateUrl: './tariff-title.component.html',
  styleUrl: './tariff-title.component.scss'
})
export class TariffTitleComponent {
  @Input() tariffId: string;
}
