import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { RxStompService } from './rx-stomp/rx-stomp.service';
import { HttpClientService } from '../http-client/http-client.service';
import { UserService } from './user.service';
import { Store } from '@ngxs/store';
import { BehaviorSubject, filter, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import {
  AIChatError,
  ChatMessageDto,
  ChatMessages,
  ChatMessagesHistory,
  CommonLanguage,
  HostRoom,
  PhotoType,
  RealtimeMessage,
  Room,
  RoomAction,
  RoomMode,
  RoomParticipants,
  RoomProtocol,
  RoomSummary,
  RoomTranscript,
  RoomUser,
  RoomUserAction,
  TranslateStatus,
  WSReqActions
} from '../transport.interface';
import { LanguagesState } from '../store/languages/languages-state.service';
import { UserState } from '../store/user/user-state.service';
import { ApiUrl } from '../api-url';
import { Topics } from '../topics';
import { SaveUserAction } from '../store/user/user.actions';
import { ErrorCodes } from '../errorcodes.const';
import { appComponentRef } from '../app-component';
import { StorageService } from '@service/storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RoomService {
  private readonly _ws = inject(RxStompService);
  private readonly _http = inject(HttpClientService);
  private readonly _userService = inject(UserService);
  private readonly _storageService = inject(StorageService);
  private readonly _store = inject(Store);

  private languages$: Observable<CommonLanguage[]> = this._store.select(LanguagesState.getLanguages);
  private _me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  private _roomArchived$: BehaviorSubject<Room> = new BehaviorSubject<Room>(null);

  private _languages: CommonLanguage[] = [];
  private _room: Room = { roomId: '', users: [], myLanguage: '', waitingUsers: [], createTime: 0, inited: false };
  private _hostRoom!: HostRoom | null;
  private _transcriptedText$ = new Subject<ChatMessageDto>();
  private _realtimeText$ = new Subject<RealtimeMessage>();
  private _userTranscriptedText$ = new Subject<ChatMessageDto>();
  private _errors$ = new Subject<AIChatError>();
  private _status$ = new Subject<TranslateStatus>();
  private _userEvents$ = new Subject<RoomUserAction>();
  private _roomEvents$ = new Subject<RoomUserAction>();
  private _myErrorEvents$ = new Subject<AIChatError>();
  private _myEvents$ = new Subject<RoomUserAction>();
  private _newRoom$ = new Subject<Room>();
  private _roomMode$ = new BehaviorSubject<RoomMode>(RoomMode.SINGLE);

  private roomSubs!: Subscription;
  private myErrorSubs!: Subscription;
  private statusSubs!: Subscription;
  private userSubs!: Subscription;
  private textSubs!: Subscription;
  private realtimeSubs!: Subscription;
  private userTextSubs!: Subscription;
  private errorSubs!: Subscription;
  private _wsSubscription!: Subscription;

  private _mode = signal(RoomMode.SINGLE);

  constructor() {
    this.languages$.subscribe(res => {
      this._languages = res;
    });
  }

  init(roomId: string, users: RoomUser[], waitingUsers: RoomUser[], creator: RoomUser) {
    if (this._room?.inited) {
      return;
    }
    this._room.roomId = roomId;
    this._room.creator = creator;
    this._room.users = users;
    this._room.waitingUsers = waitingUsers;
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    this._wsSubscription = this._ws.connected$
      .subscribe(() => {
        this.subscribeToRoomEvents();
        this.subscribeToMyErrorEvents();
        this.subscribeToTextMessages();
        this.subscribeToRealtimetMessages();
        this.subscribeToUserTextMessages();
        this.subscribeToErrors();
        this.subscribeToStatusEvent();
        this.subscribeToUserEvent();
      });
    this._room.inited = true;
  }

  sendMessage(message: string, lang: string): Observable<void> {
    return this._http
      .doPost<void>(ApiUrl.SEND_MESSAGE(this.room.roomId), { message, lang });
  }

  editMessage(messageId: number, message: string, lang: string): Observable<ChatMessageDto> {
    return this._http
      .doPatch<ChatMessageDto>(ApiUrl.SEND_MESSAGE(this.room.roomId) + '/edit', { messageId, message, lang })
      .pipe(map(res => {
        this.processMessage(res);
        return res;
      }));
  }

  updateMessage(messageId: number, lang: string, swap?: boolean): Observable<ChatMessageDto> {
    return this._http
      .doPatch<ChatMessageDto>(ApiUrl.SEND_MESSAGE(this.room.roomId), { messageId, lang, swap })
      .pipe(map(res => {
        this.processMessage(res);
        return res;
      }));
  }

  joinRoom(roomId: string): Observable<Room> {
    return this._http
      .doPostSilent<Room>(ApiUrl.ROOMS + `/${ roomId }`)
      .pipe<Room>(switchMap((value: Room) => {
        if (value) {
          this._room = value;
        }
        return of(value);
      }));
  }

  joinWaitingList(roomId: string): Observable<Room> {
    return this._http
      .doPostSilent<Room>(ApiUrl.ROOMS + `/${ roomId }/join-waiting-list`)
      .pipe<Room>(switchMap((value: Room) => {
        if (value) {
          this._room = value;
        }
        return of(value);
      }));
  }

  joinCompanyRoom(roomId: string): Observable<Room> {
    return this._http
      .doPostSilent<Room>(ApiUrl.JOIN_COMPANY_ROOM(roomId))
      .pipe<Room>(switchMap((value: Room) => {
        if (value) {
          this._room = value;
        }
        return of(value);
      }));
  }

  joinMyRoom(mode: RoomMode): Observable<Room> {
    return this._http
      .doPost<Room>(ApiUrl.JOIN_MY_ROOM, { mode })
      .pipe<Room>(switchMap((value: Room) => {
        if (value) {
          this._room = value;
        }
        this._mode.set(mode);
        return of(value);
      }));
  }

  activeRoom(mode: RoomMode): Observable<Room> {
    return this._http.doGet<Room>(ApiUrl.MY_ACTIVE_ROOM, { mode });
  }

  waitingList(roomId?: string): Observable<RoomUser[]> {
    return this._http.doGet<RoomUser[]>(ApiUrl.ROOM_WAITING_LIST(roomId ? roomId : this.room.roomId));
  }

  leaveWaitingList(roomId: string): Observable<void> {
    return this._http.doGet<void>(ApiUrl.ROOM_LEAVE_WAITING_LIST(roomId));
  }

  acceptUser(user: RoomUser, roomId?: string): Observable<void> {
    return this._http.doPatch<void>(ApiUrl.ACCEPT_USER_TO_ROOM(roomId ? roomId : this.room.roomId, user.id))
  }

  acceptAllUsers(roomId?: string): Observable<void> {
    return this._http.doPatch<void>(ApiUrl.ACCEPT_ALL_USERS_TO_ROOM(roomId ? roomId : this.room.roomId))
  }

  blockUser(user: RoomUser, roomId?: string): Observable<void> {
    return this._http.doPatch<void>(ApiUrl.BLOCK_USER_IN_ROOM(roomId ? roomId : this.room.roomId, user.id))
  }

  unblockUser(user: RoomUser, roomId?: string): Observable<void> {
    return this._http.doPatch<void>(ApiUrl.UNBLOCK_USER_IN_ROOM(roomId ? roomId : this.room.roomId, user.id))
  }

  hostRoomInfo(roomId: string): Observable<HostRoom> {
    return this._http.doGet<HostRoom>(ApiUrl.COMPANY_HOST_ROOM_INFO(roomId))
      .pipe(switchMap(hostRoom => {
        this._hostRoom = hostRoom;
        return of(hostRoom);
      }));
  }

  roomInfo(roomId: string): Observable<Room> {
    return this._http.doGet<Room>(ApiUrl.ROOM_INFO(roomId));
  }

  cancelRecord(): void {
    this._ws.sendToTopic(Topics.AUDIO, { type: WSReqActions.CANCEL, userId: this._me().id, roomId: this._room.roomId }, { roomId: this.room.roomId });
  }

  lastMessages(roomId: string, limit = 50, offset = 0): Observable<ChatMessages> {
    return this._http.doGet<ChatMessages>(ApiUrl.ROOM_MESSAGES, { roomId, limit, offset })
      .pipe(map(val => {
        val.list.forEach(v => {
          this.processMessage(v);
        })
        val.list = val.list.reverse();
        return val;
      }));
  }

  messagesHistory(roomId: string, limit = 50, offset = 0): Observable<ChatMessagesHistory> {
    return this._http.doGet<ChatMessagesHistory>(ApiUrl.MESSAGES_HISTORY(roomId), { limit, offset })
      .pipe(map(val => {
        val.messages.list.forEach(v => {
          this.processMessage(v);
        })
        val.messages.list = val.messages.list.reverse();
        return val;
      }));
  }

  roomMessages(roomId: string, limit = 50, offset = 0): Observable<ChatMessagesHistory> {
    return this._http.doGet<ChatMessagesHistory>(ApiUrl.MY_ROOM_MESSAGES(roomId), { limit, offset })
      .pipe(map(val => {
        val.messages.list.forEach(v => {
          this.processMessage(v);
        })
        val.messages.list = val.messages.list.reverse();
        return val;
      }));
  }

  lastMessage(roomId: string): Observable<ChatMessageDto> {
    return this._http.doGet<ChatMessageDto>(ApiUrl.ROOM_LAST_MESSAGE, { roomId })
      .pipe(map(val => {
        if (!val.originalText) {
          return {};
        }
        this.processMessage(val);
        return val;
      }));
  }

  exitRoom(roomId: string): Observable<void> {
    return this._http.doGet<void>(ApiUrl.LEAVE_ROOM(roomId));
  }

  muteRoom(mute: boolean): void {
    if (this._ws.stompClient.connected) {
      this._ws.sendToTopic(Topics.AUDIO, { type: mute ? WSReqActions.MUTE : WSReqActions.UNMUTE, userId: this._me().id, roomId: this._room.roomId }, { roomId: this.room.roomId });
    }
  }

  async disconnect() {
    const roomId = await this._storageService.roomId();
    this._ws.sendToTopic(Topics.AUDIO, { type: WSReqActions.DISCONNECT, userId: this._me().id }, { roomId: this.room.roomId || roomId });
    this._hostRoom = null;
  }

  createSummary(roomId: string, from: number, to: number): Observable<RoomSummary> {
    return this._http.doPost<RoomSummary>(ApiUrl.ROOM_SUMMARY(roomId), { lang: this._me().language, from, to });
  }

  createTranscript(roomId: string, from: number, to: number): Observable<RoomTranscript> {
    return this._http
      .doPost<RoomTranscript>(ApiUrl.ROOM_TRANSCRIPTS(roomId), { lang: this._me().language, from, to })
      .pipe(switchMap(transcript => {
        transcript.messages.forEach(m => {
          this.processMessage(m);
        })
        return of(transcript);
      }));
  }

  createProtocol(roomId: string, from: number, to: number): Observable<RoomProtocol> {
    return this._http
      .doPost<RoomProtocol>(ApiUrl.ROOM_PROTOCOLS(roomId), { lang: this._me().language, from, to });
  }

  summaries(roomId: string): Observable<RoomSummary[]> {
    return this._http.doGet<RoomSummary[]>(ApiUrl.ROOM_SUMMARIES(roomId));
  }

  getSharedSummary(code: string): Observable<RoomSummary> {
    return this._http.doGet<RoomSummary>(ApiUrl.SHARED_ROOM_SUMMARY(code));
  }

  getSharedProtocol(code: string): Observable<RoomProtocol> {
    return this._http.doGet<RoomProtocol>(ApiUrl.SHARED_ROOM_PROTOCOL(code));
  }

  getSharedTranscript(code: string): Observable<RoomTranscript> {
    return this._http.doGet<RoomTranscript>(ApiUrl.SHARED_ROOM_TRANSCRIPT(code)).pipe(switchMap(transcript => {
      transcript.messages.forEach(m => {
        this.processMessage(m);
      })
      return of(transcript);
    }));
  }

  transcripts(roomId: string): Observable<RoomTranscript[]> {
    return this._http.doGet<RoomTranscript[]>(ApiUrl.ROOM_TRANSCRIPTS(roomId));
  }

  protocols(roomId: string): Observable<RoomProtocol[]> {
    return this._http.doGet<RoomProtocol[]>(ApiUrl.ROOM_PROTOCOLS(roomId));
  }

  getTranscript(id: number): Observable<RoomTranscript> {
    return this._http.doGet<RoomTranscript>(ApiUrl.ROOM_TRANSCRIPT(id)).pipe(switchMap(transcript => {
      transcript.messages.forEach(m => {
        this.processMessage(m);
      })
      return of(transcript);
    }));
  }

  getProtocol(id: number): Observable<RoomProtocol> {
    return this._http.doGet<RoomProtocol>(ApiUrl.ROOM_PROTOCOL(id));
  }

  deleteSummary(summaryId: number): Observable<RoomSummary> {
    return this._http.doDelete<RoomSummary>(ApiUrl.DELETE_ROOM_SUMMARY(summaryId));
  }

  deleteProtocol(protocolId: number): Observable<RoomProtocol> {
    return this._http.doDelete<RoomProtocol>(ApiUrl.DELETE_ROOM_PROTOCOL(protocolId));
  }

  deleteTranscript(summaryId: number): Observable<RoomTranscript> {
    return this._http.doDelete<RoomTranscript>(ApiUrl.DELETE_ROOM_TRANSCRIPT(summaryId));
  }

  deleteArchivedRoom(roomId: string): Observable<Room> {
    return this._http.doDelete<Room>(ApiUrl.ROOM(roomId));
  }

  summaryMessages(roomId: string, time: number, start: boolean, end: boolean, limit: number, offset: number): Observable<ChatMessages> {
    return this._http.doGet<ChatMessages>(ApiUrl.ROOM_SUMMARY_MESSAGES(roomId), { time, start, end, limit, offset }).pipe(switchMap(msg => {
      msg.list.forEach(m => {
        this.processMessage(m);
      });
      return of(msg);
    }));
  }

  roomParticipants(roomId: string): Observable<RoomParticipants> {
    return this._http.doGet<RoomParticipants>(ApiUrl.ROOM_PARTICIPANTS(roomId));
  }

  blockParticipant(room: Room, user: RoomUser): Observable<void> {
    return this._http.doPost<void>(ApiUrl.ROOM_PARTICIPANT_BLOCK(room.roomId, user.id));
  }

  unblockParticipants(room: Room, users: number[]): Observable<void> {
    return this._http.doPost<void>(ApiUrl.ROOM_PARTICIPANTS_UNBLOCK(room.roomId), { userIds: users });
  }

  shareSummary(roomId: string, summaryId: number): Observable<RoomSummary> {
    return this._http.doGet<RoomSummary>(ApiUrl.SHARE_ROOM_SUMMARY(roomId, summaryId));
  }

  shareProtocol(roomId: string, protocolId: number): Observable<RoomProtocol> {
    return this._http.doGet<RoomProtocol>(ApiUrl.SHARE_ROOM_PROTOCOL(roomId, protocolId));
  }

  shareTranscript(roomId: string, transcriptId: number): Observable<RoomTranscript> {
    return this._http.doGet<RoomTranscript>(ApiUrl.SHARE_ROOM_TRANSCRIPT(roomId, transcriptId));
  }

  get room(): Room {
    return this._room;
  }

  get waitingUsers(): RoomUser[] {
    return this._room.waitingUsers!;
  }

  set waitingUsers(users: RoomUser[]) {
    this._room.waitingUsers = users;
  }

  get hostUser(): RoomUser {
    return this._room.creator!;
  }

  get hostRoom(): HostRoom {
    return this._hostRoom!;
  }

  get creator(): boolean {
    return this._room.creator?.id === this._me().id;
  }

  get userId(): number {
    return this._me().id;
  }

  get users(): RoomUser[] {
    return this._room.users!;
  }

  get me(): RoomUser {
    return this._me();
  }

  set me(value: RoomUser) {
    this._store.dispatch(new SaveUserAction(value));
  }

  get mode(): WritableSignal<RoomMode> {
    return this._mode;
  }

  get isSingleMode(): boolean {
    return this._mode() === RoomMode.SINGLE;
  }

  get isMultiMode(): boolean {
    return this._mode() === RoomMode.MULTI;
  }

  myErrorEvents$(): Observable<AIChatError> {
    return this._myErrorEvents$.asObservable();
  }

  getTranscriptText$(): Observable<ChatMessageDto> {
    return this._transcriptedText$.asObservable().pipe(filter(t => t.originalText !== ''), map(chatMessage => {
      this.processMessage(chatMessage);
      return chatMessage;
    }));
  }

  getRealtimeText$(): Observable<RealtimeMessage> {
    return this._realtimeText$.asObservable().pipe(filter(t => t.message !== ''));
  }

  getUserTranscriptText$(): Observable<ChatMessageDto> {
    return this._userTranscriptedText$.asObservable().pipe(filter(t => t.originalText !== ''), map(chatMessage => {
      this.processMessage(chatMessage);
      return chatMessage;
    }));
  }

  getChatErrors$(): Observable<AIChatError> {
    return this._errors$.asObservable();
  }

  getStatus$(): Observable<TranslateStatus> {
    return this._status$.asObservable();
  }

  getUserEvents$(): Observable<RoomUserAction> {
    return this._userEvents$.asObservable().pipe();
  }

  getMyEvents$(): Observable<RoomUserAction> {
    return this._myEvents$.asObservable()
      .pipe(map(t => {
        if (t.messages?.length > 0) {
          t.messages.forEach(chatMessage => {
            this.processMessage(chatMessage);
          });
        }
        return t;
      }));
  }

  sendMyEvent(event: RoomUserAction) { // /topic/user/${ user.id }/events
    this._myEvents$.next(event)
  }

  getRoomEvents$(): Observable<RoomUserAction> {
    return this._roomEvents$.asObservable();
  }

  getRoomMode$(): Observable<RoomMode> {
    return this._roomMode$.asObservable();
  }

  removeWaitingUser(user: RoomUser): RoomUser[] {
    const index = this.waitingUsers.findIndex(u => u.id === user.id);
    if (index >= 0) {
      this.waitingUsers.splice(index, 1);
    }
    return this.waitingUsers;
  }

  addUser(roomUser: RoomUser): void {
    const ind = this._room.users!.findIndex(user => user.id === roomUser.id);
    if (ind >= 0) {
      this._room.users![ ind ] = roomUser;
    } else {
      this._room.users!.push(roomUser);
    }
  }

  removeUser(roomUser: RoomUser): void {
    const ind = this._room.users!.findIndex(user => user.id === roomUser.id);
    if (ind >= 0) {
      this._room.users!.splice(ind, 1);
    }
  }

  getMyOtherLang(): string {
    return this._me().secondLanguage || '';
  }

  setMyLangs(firstLang: string, secondLang: string): Observable<void> {
    return this._userService.updateLangs(firstLang, secondLang)
      .pipe(map(res => {
        const user = this.setUserLangs(this._me(), res.language, res.secondLanguage || '');
        this._store.dispatch(new SaveUserAction(user));
      }));
  }

  setUserLangs(user: RoomUser, firstLang: string, secondLang: string): RoomUser {
    user.language = firstLang;
    user.secondLanguage = secondLang;
    let commonLanguage = this._languages.find(l => l.code === firstLang);
    if (commonLanguage) {
      user.languageVersion = commonLanguage.version;
    }
    commonLanguage = this._languages.find(l => l.code === secondLang);
    if (commonLanguage) {
      user.secondLanguageVersion = commonLanguage.version;
    }
    return user;
  }

  getMyCurrentLang(): string {
    return this._me().language;
  }

  scanQR(): void {
    // TODO
    // this._dialogService.qrCodeScanner()
    //   .subscribe(url => {
    //     if (url) {
    //       const split = url.split('?');
    //       const params = split[ 1 ].split('=');
    //       if (params[ 0 ] !== 'k') {
    //         throw new ErrorObject(-1, 'Room not found');
    //       }
    //       if (history.pushState) {
    //         const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?k=' + params[ 1 ];
    //         window.history.pushState({ path: newUrl }, '', newUrl);
    //       }
    //     }
    //   });
  }

  get newRoom(): Observable<Room> {
    return this._newRoom$.asObservable();
  }

  newRoomEvent(room: Room): void {
    this._room = room;
    return this._newRoom$.next(room);
  }

  sendConnectedMessage(): void {
    this._ws.sendToTopic(Topics.AUDIO, { type: WSReqActions.CONNECT, userId: this._me().id, roomId: this._room.roomId }, { roomId: this.room.roomId });
  }

  improveMessage(text: string, fromLang: string): void {
    this._ws.sendToTopic(Topics.AUDIO, { type: WSReqActions.IMPROVE_TEXT, text, fromLang, userId: this._me().id, roomId: this._room.roomId }, { roomId: this._room.roomId });
  }

  sendServerLog(text: string): void {
    this._ws.sendToTopic(Topics.LOGS, { type: WSReqActions.LOG, userId: this._me().id, roomId: this._room.roomId, message: text }, { roomId: this.room.roomId });
  }

  translatePicture(picture: Blob, type: PhotoType): Observable<RoomUser> {
    const data = new FormData();
    if (picture) {
      data.append("photo", picture);
    }
    data.set('mode', this.mode() + '');
    data.set('desc', (type !== 'transcript') + '');
    return this._http.doPost<RoomUser>(ApiUrl.TRANSLATE_PHOTO(this._room.roomId), data);
  }

  searchRoom(roomId: string): Observable<{ exists: boolean }> {
    return this._http.doGet<{ exists: boolean }>(ApiUrl.SEARCH_ROOM + '/' + roomId);
  }

  retryTranslation() {
    this._ws.sendToTopic(Topics.AUDIO, { type: WSReqActions.RETRY, userId: this._me().id, roomId: this._room.roomId }, { roomId: this.room.roomId });
  }

  unsubscribeFromAll(): void {
    this.unsubscribeFromStatusEvent();
    this.unsubscribeFromRoomEvents();
    this.unsubscribeFromMyErrorEvents();
    this.unsubscribeFromUserEvent();
    this.unsubscribeFromErrors();
    this.unsubscribeFromTextMessages();
  }

  roomArchivedEvent(room: Room): void {
    this._roomArchived$.next(room);
  }

  roomArchivedObs(): Observable<Room> {
    return this._roomArchived$.asObservable();
  }

  private unsubscribeFromStatusEvent() {
    if (this.statusSubs) {
      this.statusSubs.unsubscribe();
    }
  }

  private subscribeToStatusEvent() {
    if (this.statusSubs) {
      this.statusSubs.unsubscribe();
    }
    this.statusSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/status`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._status$.next(res.body as TranslateStatus);
      });
  }

  private unsubscribeFromRoomEvents() {
    if (this.roomSubs) {
      this.roomSubs.unsubscribe();
    }
  }

  private subscribeToRoomEvents() {
    if (this.roomSubs) {
      this.roomSubs.unsubscribe();
    }
    this.roomSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/events`)
      .pipe(
        untilDestroyed(this),
        map(res => JSON.parse(res.body)))
      .subscribe(res => {
        this._roomEvents$.next(res);
      });
  }

  private unsubscribeFromMyErrorEvents() {
    if (this.myErrorSubs) {
      this.myErrorSubs.unsubscribe();
    }
  }

  private subscribeToMyErrorEvents() {
    if (this.myErrorSubs) {
      this.myErrorSubs.unsubscribe();
    }
    this.myErrorSubs = this._ws.subscribe(`/topic/user/${ this._me().id }/errors`)
      .pipe(untilDestroyed(this),
        map(res => JSON.parse(res.body)))
      .subscribe(res => {
        this._myErrorEvents$.next(res);
        if (res.errorCode === ErrorCodes.INVALID_TOKEN) {
          appComponentRef().invalidSessionEvent();
        }
      });
  }

  private unsubscribeFromUserEvent(): void {
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
  }

  private subscribeToUserEvent(): void {
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
    this.userSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/user`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        let value = JSON.parse(res.body);
        this._userEvents$.next(value);
        if (value.action === RoomAction.LEFT) {
          const ind = this._room.users!.findIndex(user => user.id === value.roomUser.userId);
          if (ind >= 0) {
            this._room.users!.splice(ind, 1);
          }
        } else if (value.action === RoomAction.UPDATED) {
          if (!value.roomUser) {
            return;
          }
          if (value.roomUser?.id === this._me().id) {
            this._me().language = value.roomUser.language;
            this._me().secondLanguage = value.roomUser.secondLanguage;
            this._me().avatarId = value.roomUser.avatarId;
            this._store.dispatch(new SaveUserAction(this._me()));
          } else {
            const index = this.users.findIndex(u => u.id === value.roomUser.id);
            if (index >= 0) {
              this.users[ index ].language = value.roomUser.language;
              this.users[ index ].secondLanguage = value.roomUser.secondLanguage;
              this.users[ index ].avatarId = value.roomUser.avatarId;
            }
          }
        }
      });
  }

  private unsubscribeFromErrors() {
    if (this.errorSubs) {
      this.errorSubs.unsubscribe();
    }
  }

  private subscribeToErrors() {
    if (this.errorSubs) {
      this.errorSubs.unsubscribe();
    }
    this.errorSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/errors`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._errors$.next(JSON.parse(res.body));
      });
  }

  private unsubscribeFromTextMessages() {
    if (this.textSubs) {
      this.textSubs.unsubscribe();
    }
  }

  private subscribeToTextMessages() {
    if (this.textSubs) {
      this.textSubs.unsubscribe();
    }
    this.textSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/text`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._transcriptedText$.next(JSON.parse(res.body));
      });
  }

  private subscribeToRealtimetMessages() {
    if (this.realtimeSubs) {
      this.realtimeSubs.unsubscribe();
    }
    this.realtimeSubs = this._ws.subscribe(`/topic/room/${ this._room.roomId }/realtime`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._realtimeText$.next(JSON.parse(res.body));
      });
  }

  private subscribeToUserTextMessages() {
    if (this.userTextSubs) {
      this.userTextSubs.unsubscribe();
    }
    this.userTextSubs = this._ws.subscribe(`/topic/user/${ this._me().id }/text`)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this._userTranscriptedText$.next(JSON.parse(res.body));
      });
  }

  private processMessage(msg: ChatMessageDto): void {
    if (msg.originalText) {
      msg.originalText = msg.originalText.replaceAll('\n', '<br/>');
    }
    if (msg.szdMessages) {
      if (typeof msg.szdMessages === 'string') {
        try {
          msg.szdMessages = JSON.parse(msg.szdMessages);
        } catch (e) {
          //
        }
      }
      if (msg.szdMessages) {
        Object.keys(msg.szdMessages).forEach(key => {
          if (!msg.szdMessages[ key ]) {
            return;
          }
          // @ts-ignore
          msg.szdMessages[ key ] = msg.szdMessages[ key ].replaceAll('\n', '<br/>');
        });
      }
    }
    if (msg.summaryData?.title) {
      msg.summaryData.title = (msg.summaryData.title as string).replaceAll('\\n', '<br/>');
      msg.summaryData.title = JSON.parse(msg.summaryData.title as string);
    } else if (msg.protocolData?.title) {
      msg.protocolData.title = (msg.protocolData.title as string).replaceAll('\\n', '<br/>');
      msg.protocolData.title = JSON.parse(msg.protocolData.title as string);
    } else if (msg.transcriptData?.title) {
      msg.transcriptData.title = (msg.transcriptData.title as string).replaceAll('\\n', '<br/>');
      msg.transcriptData.title = JSON.parse(msg.transcriptData.title as string);
    }
    if (msg.users) {
      msg.users = JSON.parse(msg.users as string);
    }
  }

}
