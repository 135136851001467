import { Component, inject } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { DialogService } from '@shared/service/dialog.service';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';
import { NavMenuComponent } from '@shared/component/nav-menu/nav-menu.component';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { qrCodeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-scan-qr-code-button',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatIcon,
    MatIconButton,
    NavMenuComponent,
    IonIcon
  ],
  templateUrl: './scan-qr-code-button.component.html',
  styleUrl: './scan-qr-code-button.component.scss'
})
export class ScanQrCodeButtonComponent {
  private _dialogService = inject(DialogService);
  private _router = inject(Router);

  constructor() {
    addIcons({ qrCodeOutline });
  }

  scanQRCode(): void {
    this._dialogService.qrCodeScanner().subscribe(code => {
      if (code) {
        this._router.navigate([ '/', Pages.JOIN_ROOM, code ]);
      }
    });
  }
}
