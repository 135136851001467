<ion-icon name="close-outline" class="close-icon text-4xl cursor-pointer" (click)="closeModal()"></ion-icon>

<div class="flex flex-col w-full p-5">
  <div class="flex flex-col flex-auto">
    <div class="title text-3xl">{{ 'title.new.conversation' | transloco }}</div>
    <div class="mt-4" [innerHTML]="'text.new.conversation' | transloco"></div>
    @if (commonError?.code > 0) {
      <app-alert [type]="'error'" [appearance]="'fill'" class="mt-4">
        {{ (commonError.message?.message || commonError.message) | transloco }}
      </app-alert>
    }
  </div>

  <div class="w-full flex flex-col justify-center relative mt-8">
    <button class="button w-full" (click)="exitRoom()" [class.loading]="pending" [disabled]="pending">{{ 'button.new' | transloco }}</button>
    <button class="button stroked w-full mt-4" [disabled]="pending" (click)="closeModal()">{{ 'button.cancel' | transloco }}</button>
  </div>
</div>
