import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgTemplateOutlet } from '@angular/common';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { RxStompState } from '@stomp/rx-stomp';
import {
  AIChatError,
  AudioPlayerStatus,
  MessageTts,
  MicPosition,
  RecorderMode,
  RecorderStatus,
  RoomAction,
  RoomUser,
  Switcher,
  TranslateStatus,
  WSReqActions
} from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { RxStompService } from '@service/rx-stomp/rx-stomp.service';
import { AudioService } from '@service/audio.service';
import { RoomService } from '@service/room.service';
import { UtilsService } from '@service/utils.service';
import { ErrorCodes } from '@shared/errorcodes.const';
import { appComponentRef } from '@shared/app-component';
import { IonicModule, Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { alertCircleOutline, closeCircleOutline, closeOutline, createOutline, mic, paperPlaneOutline, settingsOutline, sparklesOutline, square, syncOutline } from 'ionicons/icons';
import { WaveAnimationComponent } from '@comp/ui/wave-animation/wave-animation.component';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { IosAudioVisualizerComponent } from '@comp/ios-audio-visualizer/ios-audio-visualizer.component';
import { AudioVisualizerComponent } from '@comp/audio-visualizer/audio-visualizer.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Topics } from '@shared/topics';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { Capacitor } from '@capacitor/core';
import { iconBounce } from '@shared/animation/icon-bounce';
import { iconRotate } from '@shared/animation/icon-rotate';
import { MatTooltip } from '@angular/material/tooltip';
import { LangService } from '@service/lang.service';

@UntilDestroy()
@Component({
  selector: 'app-room-action-buttons',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgTemplateOutlet,
    IonicModule,
    WaveAnimationComponent,
    IosAudioVisualizerComponent,
    AudioVisualizerComponent,
    ReactiveFormsModule,
    MatTooltip,
  ],
  templateUrl: './room-action-buttons.component.html',
  styleUrl: './room-action-buttons.component.scss',
  animations: [ iconBounce, iconRotate ]
})
export class RoomActionButtonsComponent implements OnInit, OnDestroy {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;
  @Output() closedEvent: EventEmitter<void> = new EventEmitter<void>();

  private readonly MAX_RECORD_TIME = 10 * 60; // 10 minutes max

  speakerMe = true;
  language: string;
  mode: RecorderMode = RecorderMode.SINGLE;
  needPreferences = true;
  insertText = false;
  isIosPhone = false;

  private readonly _store = inject(Store);
  private readonly _platform = inject(Platform);
  private readonly _langService = inject(LangService);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  wsConnected = false;
  errorRetry = false;
  status: TranslateStatus | RoomAction = TranslateStatus.READY;
  recorderStatus: RecorderStatus = RecorderStatus.READY;
  error: AIChatError = null;
  timer = '00:00';
  autoTranslate = new FormControl<boolean>(false);
  autoImprove = new FormControl<boolean>(false);

  protected settingsFlag = signal(false);
  protected settingsHover = signal(false);

  protected readonly TranslateStatus = TranslateStatus;
  protected readonly RecorderStatus = RecorderStatus;
  protected readonly RoomAction = RoomAction;
  protected readonly MicPosition = MicPosition;

  private readonly _ws: RxStompService = inject(RxStompService);
  private readonly _audioService: AudioService = inject(AudioService);
  private readonly _roomService: RoomService = inject(RoomService);
  private readonly _utilsService: UtilsService = inject(UtilsService);

  private _wsSubscription: Subscription;
  private _timerSubscription: Subscription;
  private _wsStateSubscription: Subscription;
  private _subscriptions: Subscription[] = [];

  constructor() {
    addIcons({ mic, square, syncOutline, closeCircleOutline, alertCircleOutline, closeOutline, settingsOutline, createOutline, paperPlaneOutline, sparklesOutline });

    this.isIosPhone = (this._platform.is('iphone') || this._platform.is('ipad')) && !this._platform.is('mobileweb');
    if (this.me().preferences?.autoTranslation) {
      this.autoTranslate.setValue(this.me().preferences?.autoTranslation === Switcher.OFF);
    }
    if (this.me().preferences?.autoImproveText) {
      this.autoImprove.setValue(this.me().preferences?.autoImproveText === Switcher.ON);
    }
  }

  init() {
    if (this._wsStateSubscription) {
      this._wsStateSubscription.unsubscribe();
    }
    this._wsStateSubscription = this._ws.connectionState$
      .subscribe((res) => {
        if (res === RxStompState.CLOSED) {
          this.wsConnected = false;
        } else if (res === RxStompState.OPEN) {
          this.error = null;
        }
      });
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    this._wsSubscription = this._ws.connected$
      .subscribe((res) => {
        if (res === RxStompState.OPEN) {
          this.wsConnected = true;
          if (this._subscriptions?.length) {
            this._subscriptions.forEach(s => s.unsubscribe());
          }
          this._subscriptions.push(this._roomService.getMyEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(action => {
              if (action.action === RoomAction.STOP_RECORD) {
                if (this.me().preferences?.messageTts === MessageTts.MANUAL || this.me().preferences?.autoTranslation === Switcher.OFF || this._roomService.isMultiMode) {
                  this.closeRecordWindow();
                }
              } else if (action.action === RoomAction.TRANSCRIPT || action.action === RoomAction.TRANSLATE) {
                this.status = action.action;
              } else if (action.action === RoomAction.CANCEL) {
                this.closeRecordWindow();
              }
            }));
          this._subscriptions.push(this._audioService.getAudioPlayerStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(action => {
              if (action === AudioPlayerStatus.PLAYING) {
                this.closeRecordWindow();
              }
            }));
          this._subscriptions.push(this._roomService.getStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.status = res;
            }));
          this._subscriptions.push(this._roomService.myErrorEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.errorRetry = false;
              this.error = res;
              this.status = TranslateStatus.ERROR;
              if (this.error.errorCode === ErrorCodes.NO_MINUTES_LEFT) {
                if (this.recorderStatus === RecorderStatus.RECORDING) {
                  this.stopRecording();
                } else {
                  this.cancelRecording();
                }
              } else if (this.error.errorCode === ErrorCodes.RECORD_START_ERROR) {
                this.cancelRecording();
              } else if (this.error.errorCode === ErrorCodes.RECOGNITION_ERROR) {
                this.status = TranslateStatus.RETRY;
                this.errorRetry = true;
              } else if (this.error.errorCode === ErrorCodes.INVALID_TOKEN) {
                this.cancelRecording();
                appComponentRef().invalidSessionEvent();
              }
            }));
          this._subscriptions.push(this._audioService.getRecorderStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.recorderStatus = res;
              console.log('[Recorder Status] START', this.recorderStatus);
              if (this.recorderStatus === RecorderStatus.RECORDING) {
                this.status = TranslateStatus.RECORDING;
              } else if (this.recorderStatus === RecorderStatus.ERROR) {
                this.status = TranslateStatus.READY;
                this.recorderStatus = RecorderStatus.READY;
                this.cancelRecording();
              } else if (this.recorderStatus === RecorderStatus.RETRY) {
                this.status = TranslateStatus.RETRY;
                this.recorderStatus = RecorderStatus.READY;
              }
            }));
        }
      });
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    this._timerSubscription = this._audioService.timer$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.timer = this._utilsService.secondsToTimerString(res);
        if ((this.me().duration || this._roomService.room.timeLeft) <= Math.floor(res / 1000) || (res / 1000) >= this.MAX_RECORD_TIME) {
          this.stopRecording();
        }
      });
  }

  ngOnInit(): void {
    this.init();
    if (this.data) {
      this.speakerMe = this.data.speakMe;
      this.language = this.data.speakLang;
      this.mode = this.data.roomMode;
      this.needPreferences = this.data.needPreferences;
      this.insertText = this.data.insertText;
      this.startRecord();
    }
  }

  startRecord() {
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    } else {
      this._audioService.initAudioForIos();
    }
    this.error = null;
    this.timer = '00:00';
    console.log('Realtime enabled:', this.me().preferences?.realtime === Switcher.ON);
    this._audioService.startRecording(this.language, this.me().preferences?.realtime === Switcher.ON && this._roomService.isSingleMode);
    this.status = TranslateStatus.READY;
    this.recorderStatus = RecorderStatus.READY;
  }

  closeRecordWindow() {
    if (this.recorderStatus === RecorderStatus.RECORDING) {
      this.cancelRecording();
    }
    this.closeModal();
  }

  stopRecording(): void {
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    if (this.recorderStatus === RecorderStatus.READY) {
      return;
    }
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    this._audioService.stopRecording(false, this.insertText);
    if (!this.wsConnected) {
      this.status = TranslateStatus.RETRY;
      this.error = {
        errorCode: -1,
        message: 'error.internet.connection.lost'
      };
    }
  }

  retry(): void {
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    if (!this.wsConnected) {
      return;
    }
    if (this.recorderStatus === RecorderStatus.RECORDING) {
      return;
    }
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    this.status = RoomAction.TRANSCRIPT;
    if (this.errorRetry) {
      if (this._audioService.buffer?.length) {
        this._audioService.afterConnect(this.insertText);
      } else {
        this._roomService.retryTranslation();
      }
    } else {
      this._audioService.afterConnect(this.insertText);
    }
  }

  cancelRecording(): void {
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    if (this.status === TranslateStatus.CANCEL || this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    this.status = TranslateStatus.CANCEL;
    this.timer = '00:00';
    this._roomService.cancelRecord();
    this._audioService.abortRecording();
    this.recorderStatus = RecorderStatus.READY;
  }

  ngOnDestroy(): void {
    this._wsSubscription.unsubscribe();
    this._timerSubscription.unsubscribe();
    if (this._subscriptions?.length) {
      this._subscriptions.forEach(s => s.unsubscribe());
    }
  }

  toggleAutoTranslation(tooltip1: MatTooltip) {
    this.autoTranslate.setValue(!this.autoTranslate.value);
    this._ws.sendToTopic(Topics.AUDIO,
      { type: WSReqActions.AUTO_TRANSLATE, userId: this.me().id, roomId: this._roomService.room.roomId, preferences: { autoTranslation: this.autoTranslate.value ? 1 : 0 } },
      { roomId: this._roomService.room.roomId });
    if (!this.me().preferences) {
      this.me().preferences = {};
    }
    this.me().preferences.autoTranslation = this.autoTranslate.value ? Switcher.OFF : Switcher.ON;
    this._store.dispatch(new SaveUserAction(this.me()));
    if (tooltip1._isTooltipVisible()) {
      tooltip1.hide();
    }
    setTimeout(() => {
      tooltip1.show();
    }, 10);
  }

  toggleAutoImprove(tooltip2: MatTooltip) {
    this.autoImprove.setValue(!this.autoImprove.value);
    this._ws.sendToTopic(Topics.AUDIO,
      { type: WSReqActions.AUTO_IMPROVE, userId: this.me().id, roomId: this._roomService.room.roomId, preferences: { autoImproveText: this.autoImprove.value ? 0 : 1 } },
      { roomId: this._roomService.room.roomId });
    if (!this.me().preferences) {
      this.me().preferences = {};
    }
    this.me().preferences.autoImproveText = this.autoImprove.value ? Switcher.ON : Switcher.OFF;
    this._store.dispatch(new SaveUserAction(this.me()));
    if (tooltip2._isTooltipVisible()) {
      tooltip2.hide();
    }
    setTimeout(() => {
      tooltip2.show();
    }, 10);
  }

  isRtl(): boolean {
    return this._langService.isActiveLangRtl();
  }

}
