import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, Signal, SimpleChanges } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgTemplateOutlet } from '@angular/common';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { RxStompState } from '@stomp/rx-stomp';
import { AIChatError, MicPosition, RecorderMode, RecorderStatus, RoomAction, RoomUser, TranslateStatus } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { RxStompService } from '@service/rx-stomp/rx-stomp.service';
import { AudioService } from '@service/audio.service';
import { RoomService } from '@service/room.service';
import { UtilsService } from '@service/utils.service';
import { ErrorCodes } from '@shared/errorcodes.const';
import { appComponentRef } from '@shared/app-component';
import { IonicModule, Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { alertCircleOutline, closeCircleOutline, closeOutline, mic, square, syncOutline } from 'ionicons/icons';
import { WaveAnimationComponent } from '@comp/ui/wave-animation/wave-animation.component';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { IosAudioVisualizerComponent } from '@comp/ios-audio-visualizer/ios-audio-visualizer.component';
import { AudioVisualizerComponent } from '@comp/audio-visualizer/audio-visualizer.component';

@UntilDestroy()
@Component({
  selector: 'app-room-action-buttons',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgTemplateOutlet,
    IonicModule,
    WaveAnimationComponent,
    IosAudioVisualizerComponent,
    AudioVisualizerComponent,
  ],
  templateUrl: './room-action-buttons.component.html',
  styleUrl: './room-action-buttons.component.scss',
})
export class RoomActionButtonsComponent implements OnInit, OnDestroy {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;
  @Output() closedEvent: EventEmitter<void> = new EventEmitter<void>();

  speakerMe = true;
  language: string;
  mode: RecorderMode = RecorderMode.SINGLE;
  isIos = false;

  private readonly _store = inject(Store);
  private readonly _platform = inject(Platform);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  wsConnected = false;
  status: TranslateStatus | RoomAction = TranslateStatus.READY;
  recorderStatus: RecorderStatus = RecorderStatus.READY;
  error: AIChatError = null;
  timer = '00:00';

  protected readonly TranslateStatus = TranslateStatus;
  protected readonly RecorderStatus = RecorderStatus;
  protected readonly RecorderMode = RecorderMode;
  protected readonly RoomAction = RoomAction;
  protected readonly MicPosition = MicPosition;

  private _ws: RxStompService = inject(RxStompService);
  private _audioService: AudioService = inject(AudioService);
  private _roomService: RoomService = inject(RoomService);
  private _utilsService: UtilsService = inject(UtilsService);
  private _wsSubscription: Subscription;
  private _timerSubscription: Subscription;
  private _wsStateSubscription: Subscription;
  private _subscriptions: Subscription[] = [];

  constructor() {
    addIcons({ mic, square, syncOutline, closeCircleOutline, alertCircleOutline, closeOutline });
    this.isIos = this._platform.is('ios');
  }

  init() {
    if (this._wsStateSubscription) {
      this._wsStateSubscription.unsubscribe();
    }
    this._wsStateSubscription = this._ws.connectionState$
      .subscribe((res) => {
        if (res === RxStompState.CLOSED) {
          this.wsConnected = false;
        }
      });
    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }
    this._wsSubscription = this._ws.connected$
      .subscribe((res) => {
        if (res === RxStompState.OPEN) {
          this.wsConnected = true;
          if (this._subscriptions?.length) {
            this._subscriptions.forEach(s => s.unsubscribe());
          }
          this._subscriptions.push(this._roomService.getMyEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(action => {
              if (action.action === RoomAction.STOP_RECORD) {
                this.closeRecordWindow();
              } else if (action.action === RoomAction.TRANSCRIPT || action.action === RoomAction.TRANSLATE) {
                this.status = action.action;
              }
            }));
          this._subscriptions.push(this._roomService.getStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.status = res;
            }));
          this._subscriptions.push(this._roomService.myErrorEvents$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.error = res;
              this.status = TranslateStatus.ERROR;
              if (this.error.errorCode === ErrorCodes.NO_MINUTES_LEFT) {
                this.cancelRecording();
              } else if (this.error.errorCode === ErrorCodes.INVALID_TOKEN) {
                this.cancelRecording();
                appComponentRef().invalidSessionEvent();
              }
            }));
          this._subscriptions.push(this._audioService.getRecorderStatus$()
            .pipe(untilDestroyed(this))
            .subscribe(res => {
              this.recorderStatus = res;
              console.log('[Recorder Status] START', this.recorderStatus);
              if (this.recorderStatus === RecorderStatus.RECORDING) {
                this.status = TranslateStatus.RECORDING;
              } else if (this.recorderStatus === RecorderStatus.ERROR) {
                this.status = TranslateStatus.READY;
                this.recorderStatus = RecorderStatus.READY;
                this.cancelRecording();
              }
            }));
        }
      });
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    this._timerSubscription = this._audioService.timer$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.timer = this._utilsService.secondsToTimerString(res);
        if ((this.me().duration || this._roomService.room.timeLeft) <= Math.floor(res / 1000)) {
          this.stopRecording();
        }
      });
  }

  ngOnInit(): void {
    this.init();
    if (this.data) {
      this.speakerMe = this.data.speakMe;
      this.language = this.data.speakLang;
      this.mode = this.data.roomMode;
      this.startRecord();
    }
  }

  startRecord() {
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    if (this._platform.is('mobile')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    } else {
      this._audioService.initAudioForIos();
    }
    this.error = null;
    this.timer = '00:00';
    this._audioService.startRecording(this.language);
    this.status = TranslateStatus.READY;
    this.recorderStatus = RecorderStatus.READY;
  }

  closeRecordWindow() {
    if (this.recorderStatus === RecorderStatus.RECORDING) {
      this.cancelRecording();
    }
    this.closeModal();
  }

  stopRecording(): void {
    if (this._platform.is('mobile')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    if (this.status === TranslateStatus.ERROR) {
      this.error = null;
      this._audioService.startRecording(this.language);
    } else {
      this._audioService.stopRecording();
    }
  }

  cancelRecording(): void {
    if (this._platform.is('mobile')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    if (this.status === TranslateStatus.CANCEL || this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    this.status = TranslateStatus.CANCEL;
    this.timer = '00:00';
    this._roomService.cancelRecord();
    this._audioService.abortRecording();
    this.recorderStatus = RecorderStatus.READY;
  }

  ngOnDestroy(): void {
    this._wsSubscription.unsubscribe();
    this._timerSubscription.unsubscribe();
    if (this._subscriptions?.length) {
      this._subscriptions.forEach(s => s.unsubscribe());
    }
  }
}
