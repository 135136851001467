import { Component, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, Observable, of } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { AvatarComponent } from '../../../ui/avatar/avatar.component';
import { UserService } from '../../../../service/user.service';
import { NotyService } from '../../../../service/noty.service';
import { UtilsService } from '../../../../service/utils.service';
import { RoomUser, UserRole } from '../../../../transport.interface';
import { UserState } from '../../../../store/user/user-state.service';
import { SaveUserAction } from '../../../../store/user/user.actions';
import { IonicModule } from '@ionic/angular';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@UntilDestroy()
@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [
    TranslocoPipe,
    AvatarComponent,
    ReactiveFormsModule,
    JsonPipe,
    AvatarComponent,
    IonicModule,
    MatLabel,
    MatInput,
    MatFormField
  ],
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit {
  private _fb = inject(FormBuilder);
  private _store = inject(Store);
  private _userService = inject(UserService);
  private _noty = inject(NotyService);
  private _utilsService = inject(UtilsService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly UserRole = UserRole;
  protected form: FormGroup;
  protected pending = false;

  constructor() {
    this.form = this._fb.group({
      email: [ null ],
      firstName: [ '', [ Validators.required ] ],
      lastName: [ '', [ Validators.required ] ],
      language: [ null, [ Validators.required ] ],
      companyRole: [ '' ],
      companyRoleDesc: [ '' ],
      avatar: [ null ],
    });
  }

  ngOnInit(): void {
    this.form.patchValue(this.me());
    if (this.me().avatarId) {
      this.form.controls.avatar.setValue('/v1/avatars/' + this.me().avatarId);
    }
  }

  saveProfile(): void {
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const req = this.form.value;
    let observable: Observable<Blob> = of(null);
    if (req.avatar) {
      observable = this._utilsService.getLogo(req.avatar);
    }
    this.pending = true;
    observable.subscribe(avatarBlob => {
      this._userService.updateProfile(req.firstName, req.lastName, req.language, req.companyRole, req.companyRoleDesc, avatarBlob)
        .pipe(untilDestroyed(this), finalize(() => this.pending = false))
        .subscribe(res => {
          this.me().name = res.name || '';
          this.me().firstName = res.firstName || '';
          this.me().lastName = res.lastName || '';
          this.me().companyRole = res.companyRole || '';
          this.me().companyRoleDesc = res.companyRoleDesc || '';
          this.me().avatarId = res.avatarId;
          this._store.dispatch(new SaveUserAction(this.me()));
          this._noty.success('message.profile.updated');
        });
    });

  }

}
