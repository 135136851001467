import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatStepperNext } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { QrCodeComponent } from '@shared/component/ui/qr-code/qr-code.component';
import { Room, RoomTranscript } from '@shared/transport.interface';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-share-protocol',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    QrCodeComponent,
    TranslocoPipe,
    MatStepperNext,
    IonicModule
  ],
  templateUrl: './share-transcript.component.html',
  styleUrl: './share-transcript.component.scss'
})
export class ShareTranscriptComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected transcript: RoomTranscript;
  protected room: Room;
  protected pending = false;

  private _roomService = inject(RoomService);

  ngOnInit(): void {
    if (this.data) {
      this.transcript = this.data.transcript;
      this.room = this.transcript.room;
    }
  }

  shareTranscript(): void {
    this.pending = true;
    this._roomService.shareTranscript(this.room.roomId, this.transcript.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.closeModal(res);
      });
  }
}
