import { Component, EventEmitter, inject, Input, OnInit, Output, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe, DatePipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { PrettyDatePipe } from '../../pipe/pretty-date.pipe';
import { InitialsAvatarComponent } from '../ui/initials-avatar/initials-avatar.component';
import { RoomMode, RoomTranscript, RoomUser } from '../../transport.interface';
import { UserState } from '../../store/user/user-state.service';
import { DialogService } from '../../service/dialog.service';
import { UtilsService } from '../../service/utils.service';
import { UserCardComponent } from '@shared/component/ui/user-card/user-card.component';
import { IonicModule } from '@ionic/angular';
import { MatIconButton } from '@angular/material/button';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline, shareOutline, trashOutline } from 'ionicons/icons';

@Component({
  selector: 'app-show-transcript',
  standalone: true,
  imports: [
    PrettyDatePipe,
    TranslocoPipe,
    InitialsAvatarComponent,
    AsyncPipe,
    UserCardComponent,
    DatePipe,
    IonicModule,
    MatIconButton
  ],
  templateUrl: './show-transcript.component.html',
  styleUrl: './show-transcript.component.scss'
})
export class ShowTranscriptComponent implements OnInit {
  @Input() transcript: RoomTranscript;
  @Output() closeEvent: EventEmitter<RoomTranscript> = new EventEmitter<RoomTranscript>();

  private _store: Store = inject(Store);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  private _dialogService = inject(DialogService);
  private _utilsService = inject(UtilsService);
  users = {};
  participantsLangs = [];

  constructor() {
    addIcons({ closeOutline, calendarOutline, shareOutline, trashOutline });
  }

  ngOnInit(): void {
    if (this.transcript) {
      this.transcript.participants.forEach(p => {
        this.users[ p.id ] = p;
        if (this.participantsLangs.indexOf(p.language) === -1) {
          this.participantsLangs.push(p.language);
        }
      });
    }
  }

  closeDialog(): void {
    this.closeEvent.next(this.transcript);
  }

  shareTranscript(): void {
    this._dialogService.shareTranscript(this.transcript)
      .subscribe(res => {
        if (res) {
          this.transcript.shared = true;
        }
      });
  }

  deleteTranscript(): void {
    this._dialogService.deleteTranscript(this.transcript)
      .subscribe(res => {
        if (res) {
          this.transcript.deleted = true;
          this.closeDialog();
        }
      });
  }

  scrollToId(id: string): void {
    this._utilsService.scrollToId(id);
  }

  protected readonly RoomMode = RoomMode;
}
