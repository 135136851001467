<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ room.creator?.name }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden bg-color-1">
  <ion-content class="bg-color-1">
    <div class="flex w-full justify-center items-center mt-6 pl-2 pr-2">
      <!-- Contact info -->
      <div class="flex w-full items-center">
        <div class="flex lg:ml-0 w-full">
          <div class="relative flex justify-between w-full">
            <div class="flex flex-col items-center justify-center flex-auto overflow-hidden">
              @if (room) {
                <div>
                  <app-initials-avatar [avatarId]="room.creator?.avatarId" [user]="{name: room.creator?.name}" [width]="80" [textSize]="'text-3xl'"></app-initials-avatar>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-auto w-full flex flex-col" [class.overflow-y-auto]="_roomService.mode() === RoomMode.MULTI" [class.overflow-hidden]="_roomService.mode() === RoomMode.SINGLE">
      <div class="flex flex-col w-full flex-auto p-5" [ngClass]="{'flex-auto overflow-hidden': _roomService.mode() === RoomMode.SINGLE}"
           [class.pb-0]="_roomService.mode() === RoomMode.MULTI">
        <div class="flex flex-col w-full bg-white p-5" [ngClass]="{'flex-auto overflow-hidden': _roomService.mode() === RoomMode.SINGLE}">
          <div class="flex justify-between">
            <div class="flex">
              <div class="flex items-center cursor-pointer" (click)="showRoomResultTypeSelector(type)">
                <div class="cursor-pointer">{{ 'title.chat.result.type.' + type | transloco }}</div>
                <div class="ml-2 fa-2xs h-[15px]">
                  <ion-icon name="chevron-down"></ion-icon>
                </div>
              </div>
            </div>
            @if (me().id === room.creator.id) {
              <div><a (click)="createSummary()">{{ 'title.create' | transloco }}</a></div>
            }
          </div>
          <div class="relative flex flex-col w-full overflow-y-auto overflow-x-hidden"
               [ngClass]="{'h-[230px]': !expandSummaries && _roomService.mode() === RoomMode.MULTI, 'flex-auto': _roomService.mode() === RoomMode.SINGLE}">
            @if (loading) {
              <app-loader></app-loader>
            }
            @if (type === ChatResultType.SUMMARY) {
              <ng-container *ngTemplateOutlet="summaryList"></ng-container>
            } @else if (type === ChatResultType.TRANSCRIPT) {
              <ng-container *ngTemplateOutlet="transcriptList"></ng-container>
            } @else if (type === ChatResultType.PROTOCOL) {
              <ng-container *ngTemplateOutlet="protocolsList"></ng-container>
            }
          </div>
        </div>
      </div>
      @if (room?.roomId && _roomService.mode() === RoomMode.MULTI) {
        <app-room-participants [room]="room" [readOnly]="readOnly"></app-room-participants>
      }
    </div>
  </ion-content>

</div>

<ng-template #summaryList>
  @for (summary of summaries; track summary.id) {
    <div class="flex items-center pt-2.5 mt-2.5 border-t border-t-gray-300">
      <div class="flex-auto cursor-pointer" (click)="showSummary(summary)">
        <div class="font-semibold line-clamp-2">{{ summary.title }}</div>
        <div class="flex items-center text-gray-400">
          <ion-icon name="calendar-outline"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm">{{ summary?.createDate | prettyDate | async }}</div>
        </div>
      </div>
      @if (me().id === room.creator?.id) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteSummary(summary)">
            <ion-icon name="trash-outline" class="pr-2"></ion-icon>
            <span>{{ 'button.delete' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </div>
  } @empty {
    <div class="flex flex-col justify-center mt-5 items-center text-center">
      <ion-icon name="search-outline" class="text-6xl"></ion-icon>
      <div class="title text-4xl mt-2.5">{{ 'title.no.summary' | transloco }}</div>
      <div class="mt-2.5 text-center">{{ 'text.create.new.summary' | transloco }}</div>
    </div>
  }
</ng-template>

<ng-template #protocolsList>
  @for (protocol of protocols; track protocol.id) {
    <div class="flex items-center pt-2.5 mt-2.5 border-t border-t-gray-300">
      <div class="flex-auto cursor-pointer" (click)="showProtocol(protocol)">
        <div class="font-semibold line-clamp-2">{{ protocol.title }}</div>
        <div class="flex items-center text-gray-400">
          <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm leading-none normal-text">{{ protocol?.createDate | prettyDate | async }}</div>
        </div>
      </div>
      @if (me().id === room.creator?.id) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteProtocol(protocol)">
            <ion-icon name="trash-outline" class="pr-2"></ion-icon>
            <span>{{ 'button.delete' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </div>
  } @empty {
    <div class="flex flex-col justify-center mt-5 items-center text-center">
      <ion-icon name="search-outline" class="text-6xl"></ion-icon>
      <div class="title text-4xl mt-2.5">{{ 'title.no.protocol' | transloco }}</div>
      <div class="mt-2.5 text-center">{{ 'text.create.new.protocol' | transloco }}</div>
    </div>
  }
</ng-template>

<ng-template #transcriptList>
  @for (transcript of transcripts; track transcript.id) {
    <div class="flex items-center pt-2.5 mt-2.5 border-t border-t-gray-300">
      <div class="flex-auto cursor-pointer" (click)="showTranscript(transcript)">
        <div class="font-semibold line-clamp-2">{{ transcript.title }}</div>
        <div class="flex items-center text-gray-400">
          <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm">{{ transcript?.createDate | prettyDate | async }}</div>
        </div>
      </div>
      @if (me().id === room.creator?.id) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteTranscript(transcript)">
            <ion-icon name="trash-outline" class="pr-2"></ion-icon>
            <span>{{ 'button.delete' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </div>
  } @empty {
    <div class="flex flex-col justify-center mt-5 items-center text-center">
      <ion-icon name="search-outline" class="text-6xl"></ion-icon>
      <div class="title text-4xl mt-2.5">{{ 'title.no.transcripts' | transloco }}</div>
      <div class="mt-2.5 text-center">{{ 'text.create.new.transcript' | transloco }}</div>
    </div>
  }
</ng-template>
