<div class="text-base rtl:text-right">{{ 'title.current.tariff' | transloco }}</div>
<div class="flex justify-between items-end mt-2 border-b border-b-gray-300 pb-4">
  <app-tariff-title [tariffId]="me().tariff?.uid"></app-tariff-title>
  @if (upgradeLink) {
    <div class="font-light leading-[1] text-base mb-2"><a (click)="buyTariff()">{{ 'link.upgrade' | transloco }}</a></div>
  }
</div>
<div class="flex flex-col mt-2.5 rtl:text-right">
  <div class="text-base">{{ 'text.minutes.remaining' | transloco }}</div>
  <div class="title text-3xl leading-[1.1] mt-2" [innerHTML]="_utils.secondsToString(me().duration)"></div>
</div>
