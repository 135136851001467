<ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (click)="closeModal()"></ion-icon>

<div class="w-full flex items-center justify-center overflow-hidden flex-auto p-5 relative">
  <div class="flex items-center justify-center cursor-pointer px-2 py-4 min-w-[25px]" (click)="onSwipe('left')">
    @if (!showFirstDiv) {
      <ion-icon name="chevron-back" class="text-2xl"></ion-icon>
    }
  </div>
  <div class="flex w-full overflow-hidden">
    <div
      class="container flex-auto"
      [ngClass]="{
      'swipe-transition': hasSwiped,
      'swiped-left': hasSwiped && swipeDirection === 'left',
      'swiped-right': hasSwiped && swipeDirection === 'right'
    }"
      (swipeleft)="onSwipe('right')"
      (swiperight)="onSwipe('left')"
    >
      <div class="box flex flex-col" [ngClass]="{'active': showFirstDiv}">
        <div class="text-center title text-3xl">{{ 'title.photo.to.text' | transloco }}</div>
        <div class="text-center mt-2">{{ 'text.photo.to.text' | transloco }}</div>
        <div class="flex justify-center items-center w-full mt-4">
          <div class="flex flex-auto justify-center items-center">
            @if (!platform.is('ios')) {
              <div class="px-8">
                <input type="file" id="fileInput1" (change)="takePhoto($event, 'transcript')" hidden capture="environment" accept="image/*"/>
                <label for="fileInput1">
                  <ion-icon name="camera" class="text-5xl cursor-pointer"></ion-icon>
                </label>
              </div>
            }
            <div class="px-8">
              <input type="file" id="fileInput2" (change)="takePhoto($event, 'transcript')" hidden accept="image/*"/>
              <label for="fileInput2">
                <ion-icon [name]="platform.is('ios') ? 'camera' : 'image'" class="text-5xl cursor-pointer"></ion-icon>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="box flex flex-col" [ngClass]="{'active': !showFirstDiv}">
        <div class="text-center title text-3xl">{{ 'title.guessit' | transloco }}</div>
        <div class="text-center mt-2">{{ 'text.guessit' | transloco }}</div>
        <div class="flex justify-center items-center w-full mt-4">
          <div class="flex flex-auto justify-center items-center">
            @if (!platform.is('ios')) {
              <div class="px-8 relative">
                <input type="file" id="fileInput3" (change)="takePhoto($event, 'description')" hidden capture="environment" accept="image/*"/>
                <label for="fileInput3">
                  <ion-icon name="camera" class="text-5xl cursor-pointer"></ion-icon>
                </label>
                <div class="ai-stars absolute text-gray-400"></div>
              </div>
            }
            <div class="px-8 relative">
              <input type="file" id="fileInput4" (change)="takePhoto($event, 'description')" hidden accept="image/*"/>
              <label for="fileInput4">
                <ion-icon [name]="platform.is('ios') ? 'camera' : 'image'" class="text-5xl cursor-pointer"></ion-icon>
              </label>
              <div class="ai-stars absolute"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-center cursor-pointer px-2 py-4 min-w-[25px]" (click)="onSwipe('right')">
    @if (showFirstDiv) {
      <ion-icon name="chevron-forward" class="text-2xl"></ion-icon>
    }
  </div>
</div>
