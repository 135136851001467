import { ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, EnvironmentInjector, inject, Injectable } from '@angular/core';
import { map, Observable, Subject, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ModalComponent } from '@comp/modal/modal.component';
import { ChatResultType, HistoryFilter, Room, RoomMode, RoomProtocol, RoomSummary, RoomTranscript, RoomUser, SelectItem, Tariff } from '../transport.interface';
import { RoomActionButtonsComponent } from '@comp/room-action-buttons/room-action-buttons.component';
import { TariffsComponent } from '@comp/tariffs/tariffs.component';
import { CancelSubscriptionComponent } from '@dialog/cancel-subscription/cancel-subscription.component';
import { LangSelectorComponent } from '@dialog/lang-selector/lang-selector.component';
import { PhotoAnalyzingDialogComponent } from '@dialog/photo-analyzing-dialog/photo-analyzing-dialog.component';
import { PhotoSourceDialogComponent } from '@dialog/photo-source-dialog/photo-source-dialog.component';
import { ExitRoomDialogComponent } from '@dialog/exit-room-dialog/exit-room-dialog.component';
import { ImageCropperComponent } from '@comp/image-cropper/image-cropper.component';
import { DeleteAvatarComponent } from '@dialog/profile/delete-avatar/delete-avatar.component';
import { ProfileComponent } from '@dialog/profile/profile.component';
import { SelectboxItemSelectorComponent } from '@comp/ui/selectbox-item-selector/selectbox-item-selector.component';
import { QrCodeDialogComponent } from '@dialog/qr-code-dialog/qr-code-dialog.component';
import { RoomInfoComponent } from '@dialog/room-info/room-info.component';
import { CreateSummaryComponent } from '@dialog/create-summary/create-summary.component';
import { ShareSummaryComponent } from '@dialog/share-summary/share-summary.component';
import { DeleteSummaryComponent } from '@dialog/delete-summary/delete-summary.component';
import { ShareTranscriptComponent } from '@dialog/share-transcript/share-transcript.component';
import { DeleteTranscriptComponent } from '@dialog/delete-transcript/delete-transcript.component';
import { ShowSummaryDialogComponent } from '@shared/component/_dialog/show-summary-dialog/show-summary-dialog.component';
import { ShowProtocolDialogComponent } from '@shared/component/_dialog/show-protocol-dialog/show-protocol-dialog.component';
import { ShowTranscriptDialogComponent } from '@shared/component/_dialog/show-transcript-dialog/show-transcript-dialog.component';
import { DeleteProtocolComponent } from '@shared/component/_dialog/delete-protocol/delete-protocol.component';
import { BlockUserComponent } from '@shared/component/_dialog/block-user/block-user.component';
import { UnblockUsersComponent } from '@shared/component/_dialog/unblock-users/unblock-users.component';
import { ShareProtocolComponent } from '@shared/component/_dialog/share-protocol/share-protocol.component';
import { NewRoomDialogComponent } from '@shared/component/_dialog/new-room-dialog/new-room-dialog.component';
import { HistoryComponent } from '@shared/component/_dialog/history/history.component';
import { HistoryFilterComponent } from '@shared/component/_dialog/history/history-filter/history-filter.component';
import { MessagesHistoryComponent } from '@shared/component/_dialog/messages-history/messages-history.component';
import { RoomMessagesComponent } from '@shared/component/_dialog/room-messages/room-messages.component';
import { TextDialogComponent } from '@shared/component/_dialog/text-dialog/text-dialog.component';
import { ParticipantsListComponent } from '@shared/component/_dialog/participants-list/participants-list.component';
import { CapacitorBarcodeScanner, CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner';
import { Pages } from '@app/pages';
import { ModalController, Platform } from '@ionic/angular/standalone';
import { BuyPackageComponent } from '@dialog/buy-package/buy-package.component';
import { MicrophoneAccessDialogComponent } from '@dialog/microphone-access-dialog/microphone-access-dialog.component';
import { HelpComponent } from '@app/page/help/help.component';
import { TermsComponent } from '@app/page/terms/terms.component';
import { CompleteRegistrationComponent } from '@dialog/complete-registration/complete-registration.component';
import { QrCodeScannerDialogComponent } from '@dialog/qr-code-scanner-dialog/qr-code-scanner-dialog.component';
import { JoinRoomByCodeComponent } from '@dialog/join-room-by-code/join-room-by-code.component';
import { ReactivateAccountComponent } from '@dialog/reactivate-account/reactivate-account.component';
import { QrCodeScannerAccessDialogComponent } from "@dialog/qr-code-scanner-access-dialog/qr-code-scanner-access-dialog.component";
import { ShareAppQrCodeDialogComponent } from '@dialog/share-app-qr-code-dialog/share-app-qr-code-dialog.component';
import { PrivacyComponent } from '@app/page/privacy/privacy.component';
import { DeleteArchivedRoomComponent } from '@dialog/delete-archived-room/delete-archived-room.component';
import { MyDocumentsComponent } from '@dialog/my-documents/my-documents.component';
import { RoomParticipantsDialogComponent } from '@dialog/room-participants-dialog/room-participants-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private readonly appRef = inject(ApplicationRef);
  private readonly environmentInjector = inject(EnvironmentInjector);
  private readonly modalController = inject(ModalController);
  private readonly _plarform = inject(Platform);

  private _modalRefs: ComponentRef<any>[] = [];
  private _installAppEvent$: Subject<boolean> = new Subject();

  constructor() {
  }

  async selectLanguage(currentLang: string, secondLang: string, selectFirst: boolean): Promise<{
    firstLang: string,
    secondLang: string
  }> {
    const modal = await this.modalController.create({
      component: LangSelectorComponent,
      cssClass: 'm-0 p-0',
      componentProps: {
        data: {
          firstLang: currentLang,
          secondLang: secondLang,
          selectFirst,
        }
      }
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async selectItem(items: SelectItem[], selectedItem: SelectItem, search: boolean = true): Promise<SelectItem> {
    const modal = await this.modalController.create({
      component: SelectboxItemSelectorComponent,
      componentProps: {
        data: {
          items, selectedItem, search,
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async historyFilter(filter?: HistoryFilter): Promise<HistoryFilter> {
    const modal = await this.modalController.create({
      component: HistoryFilterComponent,
      componentProps: {
        data: {
          filter
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async qrCode(roomId: string, isCompany?: boolean): Promise<void> {
    const modal = await this.modalController.create({
      component: QrCodeDialogComponent,
      componentProps: {
        data: {
          roomId, isCompany
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  shareAppQrCode(ref: string): void {
    this.open(ShareAppQrCodeDialogComponent, { ref, title: 'title.invite.user', text: 'text.invite.user' });
  }

  startRecord(speakMe: boolean, speakLang: string, roomMode: RoomMode, needPreferences: boolean = true, insertText: boolean = false): Observable<any> {
    return this.open(RoomActionButtonsComponent, { speakMe, speakLang, roomMode, needPreferences, insertText, klass: 'm-0 p-0' });
  }

  photoAnalyzing(): Observable<any> {
    return this.open(PhotoAnalyzingDialogComponent, { klass: 'm-0 p-0' });
  }

  photoSource(): Observable<any> {
    return this.open(PhotoSourceDialogComponent, { klass: 'm-0 p-0' });
  }

  qrCodeScanner(): Observable<string> {
    if (this._plarform.is('mobileweb') || this._plarform.is('desktop')) {
      return this.open(QrCodeScannerDialogComponent, { maxWidth: '360px', minHeight: '65vh' });
    } else if (this._plarform.is('ios') && !this._plarform.is('mobileweb')) {
      return this.open(QrCodeScannerAccessDialogComponent, { maxWidth: '360px' })
        .pipe(switchMap(access => {
          return fromPromise(CapacitorBarcodeScanner
            .scanBarcode({ hint: CapacitorBarcodeScannerTypeHint.QR_CODE }))
            .pipe(
              map(res => res.ScanResult),
              map(url => {
                if (url && url.indexOf(Pages.JOIN_ROOM) >= 0) {
                  url = url.split(`${ Pages.JOIN_ROOM }/`)[ 1 ];
                  return url;
                }
                return null;
              }));
        }))
    } else {
      return fromPromise(CapacitorBarcodeScanner
        .scanBarcode({ hint: CapacitorBarcodeScannerTypeHint.QR_CODE }))
        .pipe(
          map(res => res.ScanResult),
          map(url => {
            if (url && url.indexOf(Pages.JOIN_ROOM) >= 0) {
              url = url.split(`${ Pages.JOIN_ROOM }/`)[ 1 ];
              return url;
            }
            return null;
          }));
    }
  }

  async tariffs(): Promise<any> {
    const modal = await this.modalController.create({
      component: TariffsComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async myDocuments(): Promise<any> {
    const modal = await this.modalController.create({
      component: MyDocumentsComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async roomsHistory(): Promise<any> {
    const modal = await this.modalController.create({
      component: HistoryComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  // register(): Observable<RoomUser> {
  //   return this._dialog.open(RegisterDialogComponent,
  //     {
  //       maxHeight: '95dvh',
  //       height: '95dvh',
  //       maxWidth: '600px',
  //       minWidth: '340px',
  //       data: {}
  //     }).afterClosed();
  // }
  //
  // createHostRoom(room?: HostRoom): Observable<HostRoom> {
  //   return this._dialog.open(CreateHostRoomComponent,
  //     {
  //       maxHeight: '95dvh',
  //       maxWidth: '600px',
  //       minWidth: '340px',
  //       data: { room }
  //     }).afterClosed();
  // }
  //
  // createCompanyUser(user?: User): Observable<User> {
  //   return this._dialog.open(CreateCompanyUserComponent,
  //     {
  //       maxHeight: '95dvh',
  //       maxWidth: '600px',
  //       minWidth: '340px',
  //       data: { user }
  //     }).afterClosed();
  // }
  //
  showText(title: string, text: string): Observable<void> {
    return this.open(TextDialogComponent,
      {
        title, text
      }
    );
  }

  exitRoom(roomId: string): Observable<Room> {
    return this.open(ExitRoomDialogComponent,
      { roomId }
    );
  }

  async roomInfo(room: Room, readOnly: boolean = false): Promise<void> {
    const modal = await this.modalController.create({
      component: RoomInfoComponent,
      componentProps: {
        data: {
          room, readOnly
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async roomParticipants(room: Room, readOnly: boolean = false): Promise<void> {
    const modal = await this.modalController.create({
      component: RoomParticipantsDialogComponent,
      componentProps: {
        data: {
          room, readOnly
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async createSummary(room: Room, type?: ChatResultType): Promise<RoomSummary | RoomTranscript | RoomProtocol> {
    const modal = await this.modalController.create({
      component: CreateSummaryComponent,
      componentProps: {
        data: {
          room, type
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async showSummary(summary: RoomSummary): Promise<RoomSummary> {
    const modal = await this.modalController.create({
      component: ShowSummaryDialogComponent,
      componentProps: {
        data: {
          summary
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async showProtocol(protocol: RoomProtocol, load = true): Promise<RoomProtocol> {
    const modal = await this.modalController.create({
      component: ShowProtocolDialogComponent,
      componentProps: {
        data: {
          protocol, load
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async showTranscript(transcript: RoomTranscript, load = true): Promise<RoomTranscript> {
    const modal = await this.modalController.create({
      component: ShowTranscriptDialogComponent,
      componentProps: {
        data: {
          transcript, load
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  deleteSummary(summary: RoomSummary): Observable<RoomSummary> {
    return this.open(DeleteSummaryComponent,
      { summary }
    );
  }

  shareSummary(summary: RoomSummary): Observable<RoomSummary> {
    return this.open(ShareSummaryComponent,
      { summary }
    );
  }

  shareProtocol(protocol: RoomProtocol): Observable<RoomProtocol> {
    return this.open(ShareProtocolComponent,
      { protocol }
    );
  }

  shareTranscript(transcript: RoomTranscript): Observable<RoomTranscript> {
    return this.open(ShareTranscriptComponent,
      { transcript }
    );
  }

  deleteProtocol(protocol: RoomProtocol): Observable<RoomProtocol> {
    return this.open(DeleteProtocolComponent,
      { protocol }
    );
  }

  deleteTranscript(transcript: RoomTranscript): Observable<RoomTranscript> {
    return this.open(DeleteTranscriptComponent,
      { transcript }
    );
  }

  deleteArchivedRoom(room: Room): Observable<Room> {
    return this.open(DeleteArchivedRoomComponent,
      { room }
    );
  }

  async buyPackage(tariff: Tariff): Promise<void> {
    const modal = await this.modalController.create({
      component: BuyPackageComponent,
      componentProps: {
        tariff
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }


  async profile(): Promise<void> {
    const modal = await this.modalController.create({
      component: ProfileComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  openAvatarEditor(image: string): Observable<any> {
    return this.open(ImageCropperComponent, {
      width: '100%',
      image,
    });
  }

  deleteAvatar(): Observable<any> {
    return this.open(DeleteAvatarComponent, {});
  }

  blockParticipant(room: Room, user: RoomUser): Observable<boolean> {
    return this.open(BlockUserComponent,
      { room, user }
    );
  }

  async blockedUsers(room: Room, users: RoomUser[]): Promise<number[]> {
    const modal = await this.modalController.create({
      component: UnblockUsersComponent,
      componentProps: {
        data: {
          room, users
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async participants(users: RoomUser[]): Promise<void> {
    const modal = await this.modalController.create({
      component: ParticipantsListComponent,
      componentProps: {
        data: {
          users
        }
      },
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  //
  // installPWA(): Observable<boolean> {
  //   return this.open(InstallDialogComponent,
  //     {}
  //   );
  // }
  //
  cancelSubscription(): Observable<boolean> {
    return this.open(CancelSubscriptionComponent,
      {}
    );
  }

  async help(): Promise<void> {
    const modal = await this.modalController.create({
      component: HelpComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async terms(): Promise<void> {
    const modal = await this.modalController.create({
      component: TermsComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  async privacy(): Promise<void> {
    const modal = await this.modalController.create({
      component: PrivacyComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
  }

  //
  // microphoneAccess(): void {
  //   this.open(MicrophoneAccessDialogComponent, {});
  // }
  //
  async completeRegistration(): Promise<any> {
    const modal = await this.modalController.create({
      component: CompleteRegistrationComponent,
      cssClass: 'm-0 p-0'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  joinRoom(): Observable<Room> {
    return this.open(JoinRoomByCodeComponent, {});
  }

  showDeleteAccountReminder(): Observable<void> {
    return this.open(ReactivateAccountComponent,
      {}
    );
  }

  closeAll(): void {
    try {
      this.modalController.getTop().then(res => {
        if (res) {
          this.modalController.dismiss();
        }
      });
    } catch (e) {
      //
    }
    this._modalRefs.forEach(ref => {
      try {
        this.appRef.detachView(ref.hostView);
        ref.destroy();
      } catch (e) {
        //
      }
    });
  }

  private open(component: any, data: any): Observable<any> {

    const componentRef = createComponent(ModalComponent, {
      environmentInjector: this.environmentInjector
    });

    componentRef.instance.childComponentType = component;
    componentRef.instance.data = data;
    this._modalRefs.push(componentRef);
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[ 0 ] as HTMLElement;
    document.getElementsByTagName('ion-app')[ 0 ].appendChild(domElem);

    return fromPromise(new Promise((resolve) => {
      componentRef.instance.closeModal = (result: any) => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
        const number = this._modalRefs.findIndex(modal => modal === componentRef);
        if (number >= 0) {
          this._modalRefs.splice(number, 1);
        }
        resolve(result);
      };
    }));
  }

  get installAppEvent$(): Observable<boolean> {
    return this._installAppEvent$.asObservable();
  }

  installAppEvent(ev: boolean) {
    this._installAppEvent$.next(ev);
  }

  microphoneAccess(): void {
    this.open(MicrophoneAccessDialogComponent, {});
  }
}
