<div class="p-5">
  <ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (click)="closeRecordWindow()"></ion-icon>

  <div class="title text-3xl">{{ 'recorder.speak.lang' | transloco: { lang: (language | transloco) } }}</div>
  <div class="flex justify-between">
    <div class="text-sm">
      @if (recorderStatus === RecorderStatus.RECORDING) {
        <span class="text-green-600">{{ 'recorder.status.recording' | transloco }}</span>
      } @else if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
        {{ 'recorder.status.recognition' | transloco }}
      } @else if (status === TranslateStatus.ERROR) {
        <span class="error-text">{{ 'recorder.status.error' | transloco }}</span>
      } @else if (status !== TranslateStatus.CANCEL) {
        {{ 'recorder.status.wait' | transloco }}
      } @else {
        {{ 'recorder.status.ready' | transloco }}
      }
    </div>
    <div class="text-sm text-right">{{ timer }}</div>
  </div>

  <div class="w-full h-32 flex items-center justify-center overflow-hidden flex-auto">
    @if (!error) {
      @if (recorderStatus === RecorderStatus.RECORDING) {
        <div class="flex w-full overflow-hidden flex-auto items-center h-full py-5">
          @if (isIos) {
            <app-ios-audio-visualizer class="h-full w-full"></app-ios-audio-visualizer>
          } @else {
            <app-audio-visualizer class="h-full w-full"></app-audio-visualizer>
          }
        </div>
      } @else if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
        <div class="flex w-full overflow-hidden flex-auto h-full py-5">
          <app-wave-animation></app-wave-animation>
        </div>
      }
    } @else {
      <div class="flex w-full flex-col justify-center items-center error-text px-5">
        <ion-icon name="alert-circle-outline" class="text-4xl"></ion-icon>
        <div class="mt-2">{{ error.message | transloco }}</div>
      </div>
    }
  </div>

  @if (me()?.preferences?.micPosition === MicPosition.LEFT) {
    <ng-container *ngTemplateOutlet="leftHand"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="rightHand"></ng-container>
  }

  <ng-template #rightHand>
    <div class="flex items-center">
      @if (mode === RecorderMode.SINGLE && !speakerMe) {
        <div class="flex w-[80px]">
          <ng-container *ngTemplateOutlet="recordButton"></ng-container>
        </div>
        <div class="flex-1 flex justify-center">
          <div class="dotted-line"></div>
        </div>
      }
      @if (speakerMe) {
        <div class="w-[48%] text-base text-red-500 pr-2.5 text-right">{{ 'label.tap.to.cancel' | transloco }}</div>
      }
      <div class="w-[30px] flex items-center">
        <ion-icon name="close-circle-outline" class="text-4xl text-red-500 cursor-pointer" (click)="cancelRecording()"></ion-icon>
      </div>
      @if (!speakerMe) {
        <div class="w-[48%] text-base text-red-500 pl-2.5">{{ 'label.tap.to.cancel' | transloco }}</div>
      }
      @if (speakerMe) {
        <div class="flex-1 flex justify-center">
          <div class="dotted-line"></div>
        </div>
        <div class="flex w-[80px]">
          <ng-container *ngTemplateOutlet="recordButton"></ng-container>
        </div>
      }
    </div>
  </ng-template>

  <ng-template #leftHand>
    <div class="flex items-center px-5 pb-5">
      @if (speakerMe) {
        <div class="flex w-[80px]">
          <ng-container *ngTemplateOutlet="recordButton"></ng-container>
        </div>
        <div class="flex-1 flex justify-center">
          <div class="dotted-line"></div>
        </div>
      }
      @if (!speakerMe) {
        <div class="w-[48%] text-base text-red-500 pr-2.5 text-right">{{ 'label.tap.to.cancel' | transloco }}</div>
      }
      <div class="w-[30px] flex items-center">
        <ion-icon name="close-circle-outline" class="text-4xl text-red-500 cursor-pointer" (click)="cancelRecording()"></ion-icon>
      </div>
      @if (speakerMe) {
        <div class="w-[48%] text-base text-red-500 pl-2.5">{{ 'label.tap.to.cancel' | transloco }}</div>
      }
      @if (mode === RecorderMode.SINGLE && !speakerMe) {
        <div class="flex-1 flex justify-center">
          <div class="dotted-line"></div>
        </div>
        <div class="flex w-[80px]">
          <ng-container *ngTemplateOutlet="recordButton"></ng-container>
        </div>
      }
    </div>
  </ng-template>

  <ng-template #recordIcon>
    @if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
      <ion-icon name="sync-outline" class="text-5xl spin-animation"></ion-icon>
    } @else if (status === TranslateStatus.ERROR || status === TranslateStatus.CANCEL) {
      <ion-icon name="mic" class="text-5xl cursor-pointer"></ion-icon>
    } @else {
      <ion-icon name="square" class="text-5xl cursor-pointer"></ion-icon>
    }
  </ng-template>

  <ng-template #recordButton>
    @if (status === TranslateStatus.CANCEL || status === TranslateStatus.ERROR) {
      <div class="stop-record-button" (click)="startRecord()" [class.other]="!speakerMe">
        <ng-container *ngTemplateOutlet="recordIcon"></ng-container>
      </div>
    } @else {
      <div class="stop-record-button" [class.recording]="recorderStatus === RecorderStatus.RECORDING" (click)="stopRecording()" [class.other]="!speakerMe">
        <ng-container *ngTemplateOutlet="recordIcon"></ng-container>
      </div>
    }
  </ng-template>

</div>
