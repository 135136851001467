import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcDate',
  standalone: true
})
export class UtcDatePipe implements PipeTransform {

  transform(date: Date | string | number, format: string): unknown {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      date += 'Z';
    }
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

}
