import { Component, inject, input } from '@angular/core';
import { finalize } from 'rxjs';
import { Store } from '@ngxs/store';
import { IonicModule, Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { addCircle } from 'ionicons/icons';
import { DialogService } from '../../service/dialog.service';
import { RoomService } from '../../service/room.service';
import { NotyService } from '../../service/noty.service';
import { UserState } from '../../store/user/user-state.service';
import { PhotoType } from '../../transport.interface';
import { ImageResizeService } from '@service/image-resize.service';

@Component({
  selector: 'app-take-photo',
  standalone: true,
  imports: [
    IonicModule
  ],
  templateUrl: './take-photo.component.html',
  styleUrl: './take-photo.component.scss'
})
export class TakePhotoComponent {

  protected readonly platform = inject(Platform);
  private readonly _dialogs = inject(DialogService);
  private readonly _roomService = inject(RoomService);
  private readonly _notyService = inject(NotyService);
  private readonly _store = inject(Store);
  private readonly _imageResizeService = inject(ImageResizeService);
  private me = this._store.selectSignal(UserState.getUser);

  buttonDisabled = input<boolean>(false);

  constructor() {
    addIcons({ addCircle });
  }

  private translatePhoto(file: { file: File; type: PhotoType }) {
    this._dialogs.photoAnalyzing().subscribe();
    if (this._roomService.isSingleMode) {
      this._roomService.translatePicture(file.file, file.type).subscribe({
        next: res => {
          // if (res) {
          //   if (res.secondLanguage !== this.me().secondLanguage) {
          //     this.me().secondLanguage = res.secondLanguage;
          //     this._store.dispatch(new SaveUserAction(this.me()));
          //   }
          // }
        },
        error: err => {
          if (err.message.indexOf('error.') >= 0) {
            this._notyService.error(err.message);
          } else {
            this._notyService.error('error.upload.photo');
          }
          this._dialogs.closeAll();
        }
      });
    } else {
      this._roomService
        .translatePicture(file.file, file.type)
        .pipe(finalize(() => {
          this._dialogs.closeAll();
        }))
        .subscribe({
          next: () => {
          },
          error: err => {
            if (err.message.indexOf('error.') >= 0) {
              this._notyService.error(err.message);
            } else {
              this._notyService.error('error.upload.photo');
            }
            this._dialogs.closeAll();
          }
        });
    }
  }

  showSources($event: MouseEvent) {
    this.checkButtonDisabled($event);
    if (this.buttonDisabled()) {
      return;
    }
    this._dialogs.photoSource().subscribe((res: { file: File, type: PhotoType }) => {
      if (res) {
        this._imageResizeService.compressImage(res.file, 1024, 1024, 70).then((file: File) => {
          this.translatePhoto({ file: file, type: res.type });
        })
      }
    });
  }


  checkButtonDisabled($event: MouseEvent) {
    if (this.buttonDisabled()) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
}
