import { inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Howl } from 'howler';
import { environment } from '@env/environment';
import { CommonLanguage } from '@shared/transport.interface';
import { Observable } from 'rxjs';
import { LanguagesState } from '@shared/store/languages/languages-state.service';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AudioQueueService {
  private readonly _store = inject(Store);
  private audioQueue: Howl[] = []; // Array to hold Howl instances for the audio queue
  private isPlaying = false; // Flag to check if audio is playing
  private _languages: CommonLanguage[] = [];
  private languages$: Observable<CommonLanguage[]> = this._store.select(LanguagesState.getLanguages);

  constructor() {
    this.languages$.subscribe(res => {
      this._languages = res;
    });
  }

  // Function to add TTS audio to the queue
  addToQueue(time: number, lang: string, roomId: string): void {
    const v = this.findSpeechVersion(lang);
    const audio = new Howl({
      src: [ `${ environment.serverUrl }/${ v ? v : 'v1' }/speech?key=${ roomId }&time=${ time }&lang=${ lang }` ], // Replace with your TTS service URL
      onend: () => {
        // When this audio ends, check if there's another one to play
        this.isPlaying = false;
        this.playNextInQueue(); // Call the function to play the next audio
      }
    });

    // Add the audio to the queue
    this.audioQueue.push(audio);
    if (!this.isPlaying) {
      this.playNextInQueue();
    }
  }

  // Function to play the next audio in the queue
  private playNextInQueue(): void {
    if (this.audioQueue.length > 0) {
      this.isPlaying = true;
      const nextAudio = this.audioQueue.shift()!; // Get the next audio from the queue (with non-null assertion)
      nextAudio.play(); // Play it
    }
  }

  private findSpeechVersion(lang: string): string {
    return <string>this._languages.find(l => l.code === lang)?.version;
  }
}
