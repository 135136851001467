<app-header></app-header>

<div class="bg-color-1 flex-auto overflow-y-auto inner-page__content">
  <div class="bg-white w-full py-6 pt-4 px-5 border-b border-b-gray-300">
    <div class="items-center mx-auto w-full max-w-[400px]">
      <div class="title uppercase text-3xl">{{ 'title.speak.freely' | transloco }}</div>
      <div class="text-base	 mt-4">{{ 'text.speak.freely' | transloco }}</div>
    </div>
  </div>

  <div class="flex flex-col bg-color-1 w-full p-5 flex-auto">
    <div class="items-center mx-auto w-full max-w-[400px]">
      <div class="header text-3xl mb-2">{{ 'title.login' | transloco }}</div>
      @if (commonError?.code! > 0) {
        <app-alert [type]="'warning'" [appearance]="'fill'" class="mt-4">
          {{ commonError.message | transloco }}
        </app-alert>
      }
      <form [formGroup]="form">
        <mat-form-field class="text-base w-full mt-4" subscriptSizing="dynamic">
          <mat-label>{{ 'label.email' | transloco }}</mat-label>
          <input
            matInput
            formControlName="email">
        </mat-form-field>
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.password' | transloco }}</mat-label>
          <input
            type="password"
            matInput
            formControlName="password">
        </mat-form-field>

        <div class="flex justify-center items-center w-full mt-6">
          <button class="button w-full" (click)="login()" [disabled]="pending || form.invalid" [class.loading]="pending">
            {{ 'button.login' | transloco }}
          </button>
        </div>
      </form>
      <div class="my-3 flex items-center justify-center">
        <div class="flex-auto border-b border-b-gray-300"></div>
        <div class="mx-4 flex-0 text-sm font-semibold leading-5 text-gray-300">
          {{ 'text.or' | transloco }}
        </div>
        <div class="flex-auto border-b border-b-gray-300"></div>
      </div>

      <div class="w-full flex justify-center items-center">
        <app-google-auth (loggingIn)="pending = true" (loginComplete)="pending = false" (errorEvent)="pending = false"></app-google-auth>
        <!--            <div class="social-login facebook-icon ml-4" (click)="signInWithFB()">&nbsp;</div>-->
      </div>
      @if (!_siteInfo().corporate) {
        <div class="flex items-baseline text-base mt-4">
          <a class="ml-1  hover:underline" [routerLink]="[ '/', Pages.REGISTER ]">{{ 'link.register' | transloco }}</a>
        </div>
      }
      <div class="flex items-baseline text-base mt-4">
        <a class="ml-1  hover:underline" [routerLink]="[ '/', Pages.FORGOT_PASSWORD ]">{{ 'link.forgot.password' | transloco }}</a>
      </div>
    </div>
  </div>
</div>
