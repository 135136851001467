<div class="flex flex-col w-full flex-auto" [formGroup]="form">
  <ion-content>
    <div class="w-full flex flex-col flex-auto p-5">
      <app-selectbox [disabled]="true" [placeholder]="'placeholder.voice'" [defaultItem]="form.controls.voice.value" [items]="voices" [search]="false"
                     (itemSelected)="changeVoice($event)"></app-selectbox>
      <app-selectbox [placeholder]="'placeholder.message.tts'" [defaultItem]="form.controls.messageTts.value" [items]="messageTts" [search]="false"
                     (itemSelected)="changeMessageTts($event)" class="mt-5"></app-selectbox>
      <app-select-lang [defaultLang]="form.controls.language.value" (langSelected)="setLang($event)" class="mt-5"></app-select-lang>
      <app-select-lang [placeholder]="'placeholder.interface.language'" [defaultLang]="form.controls.interfaceLanguage.value" (langSelected)="setInterfaceLang($event)"
                       class="mt-5"></app-select-lang>
      <app-selectbox [placeholder]="'placeholder.mic.position'" [items]="micPosition" [defaultItem]="form.controls.micPosition.value" [search]="false"
                     (itemSelected)="changeMicPosition($event)" class="mt-5"></app-selectbox>
      <app-selectbox [placeholder]="'placeholder.edit.mode'" [items]="autoTranslation" [defaultItem]="form.controls.autoTranslation.value" [search]="false"
                     (itemSelected)="changeAutoTranslation($event)" class="mt-5"></app-selectbox>
      @if (isMobile()) {
        <app-selectbox [placeholder]="'placeholder.realtime.mode'" [items]="realtime" [defaultItem]="form.controls.realtime.value" [search]="false"
                       (itemSelected)="changeRealtime($event)" class="mt-5"></app-selectbox>
      }

      <div class="mt-8">
        <div class="text-base font-bold">{{ 'label.font.size' | transloco }}</div>
        <div>
          <ion-range
            [min]="10"
            [max]="20"
            [value]="form.controls.chatFontSize.value || 14"
            [pin]="true"
            [ticks]="true"
            [snaps]="true"
            (ionChange)="fontSizeChanged($event)"
          >
            <ion-icon slot="start" name="text-outline" class="text-xs"></ion-icon>
            <ion-icon slot="end" name="text-outline" class="text-2xl"></ion-icon>
          </ion-range>
        </div>
        <div class="mt-2" [style.font-size]="this.form.controls.chatFontSize.value + 'px'">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget ex rutrum, pharetra mi non, rutrum lorem. Etiam sagittis, erat.
        </div>
      </div>
      <!--    <div class="mt-8">-->
      <!--      <div class="text-base font-bold">{{ 'label.better.text.temperature' | transloco }}</div>-->
      <!--      <div>-->
      <!--        <ion-range-->
      <!--          [min]="1"-->
      <!--          [max]="3"-->
      <!--          [value]="form.controls.textTemperature.value || 1"-->
      <!--          [pin]="true"-->
      <!--          [ticks]="true"-->
      <!--          [snaps]="true"-->
      <!--          (ionChange)="textTemperatureChanged($event)"-->
      <!--        >-->
      <!--          <ion-icon slot="start" name="snow-outline" class="text-2xl"></ion-icon>-->
      <!--          <ion-icon slot="end" name="sunny-outline" class="text-2xl"></ion-icon>-->
      <!--        </ion-range>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <div class="pb-2 pt-1">
        <button class="button w-full" [class.loading]="pending" [disabled]="pending || form.invalid" (click)="savePreferences()">{{ 'button.save' | transloco }}</button>
      </div>
    </ion-toolbar>
  </ion-footer>
</div>
