<div class="pt-5 pb-5">
  <ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (mousedown)="$event.preventDefault()" (click)="closeRecordWindow()"></ion-icon>

  <div class="title text-2xl px-5">{{ 'recorder.speak.lang' | transloco: { lang: (language | transloco) } }}</div>
  <div class="flex justify-between px-5">
    <div class="text-sm">
      @if (recorderStatus === RecorderStatus.RECORDING) {
        <span class="text-green-600">{{ 'recorder.status.recording' | transloco }}</span>
      } @else if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
        {{ 'recorder.status.recognition' | transloco }}
      } @else if (status === TranslateStatus.ERROR) {
        <span class="error-text">{{ 'recorder.status.error' | transloco }}</span>
      } @else if (status === TranslateStatus.RETRY) {
        <span class="error-text">{{ 'recorder.status.retry' | transloco }}</span>
      } @else if (status !== TranslateStatus.CANCEL) {
        {{ 'recorder.status.wait' | transloco }}
      } @else {
        {{ 'recorder.status.ready' | transloco }}
      }
    </div>
    <div class="text-sm text-right">{{ timer }}</div>
  </div>
  <!--  <div class="flex items-center justify-between px-5 mt-2">-->
  <!--    <span class="text-sm">{{ 'placeholder.auto.translate' | transloco }}</span>-->
  <!--    <app-switch [klass]="'small'" [formControl]="autoTranslate"></app-switch>-->
  <!--  </div>-->
  <!--  <div class="flex items-center justify-between px-5 mt-2">-->
  <!--    <div>&nbsp;</div>-->
  <!--    <div class="flex">-->
  <!--      @if (!autoTranslate.value) {-->
  <!--        <div class="settings-icon small active">-->
  <!--          <ion-icon name="create-outline" class="text-xl"></ion-icon>-->
  <!--        </div>-->
  <!--      }-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="w-full h-32 flex items-center justify-center overflow-hidden flex-auto">
    @if (!error) {
      @if (recorderStatus === RecorderStatus.RECORDING) {
        <div class="flex w-full overflow-hidden flex-auto items-center h-full py-5">
          @if (isIosPhone) {
            <app-ios-audio-visualizer class="h-full w-full"></app-ios-audio-visualizer>
          } @else {
            <app-audio-visualizer class="h-full w-full"></app-audio-visualizer>
          }
        </div>
      } @else if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
        <div class="flex w-full overflow-hidden flex-auto h-full py-5">
          <app-wave-animation></app-wave-animation>
        </div>
      }
    } @else {
      <div class="flex w-full flex-col justify-center items-center error-text px-5">
        <ion-icon name="alert-circle-outline" class="text-4xl"></ion-icon>
        <div class="mt-2">{{ error.message | transloco }}</div>
      </div>
    }
  </div>

  @if (me()?.preferences?.micPosition === MicPosition.LEFT) {
    <ng-container *ngTemplateOutlet="leftHand"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="rightHand"></ng-container>
  }

</div>

<ng-template #rightHand>
  <div class="flex items-center px-5 justify-between">
    @if (!speakerMe) {
      <div class="flex w-[80px]">
        <ng-container *ngTemplateOutlet="recordButton"></ng-container>
      </div>
      <div class="flex justify-center items-center">
        @if (needPreferences) {
          <ng-container *ngTemplateOutlet="settingsIcons; context: {right: false}"></ng-container>
        } @else {
          &nbsp;
        }
      </div>
    } @else {
      <div class="flex justify-center items-center">
        @if (needPreferences) {
          <ng-container *ngTemplateOutlet="settingsIcons; context: {right: true}"></ng-container>
        } @else {
          &nbsp;
        }
      </div>
      <div class="flex w-[80px]">
        <ng-container *ngTemplateOutlet="recordButton"></ng-container>
      </div>
    }
  </div>
</ng-template>

<ng-template #leftHand>
  <div class="flex items-center px-5 justify-between">
    @if (!speakerMe) {
      <div class="flex justify-center items-center">
        @if (needPreferences) {
          <ng-container *ngTemplateOutlet="settingsIcons; context: {right: true}"></ng-container>
        } @else {
          &nbsp;
        }
      </div>
      <div class="flex w-[80px] items-center justify-center">
        <ng-container *ngTemplateOutlet="recordButton"></ng-container>
      </div>
    } @else {
      <div class="flex w-[80px] items-center justify-center">
        <ng-container *ngTemplateOutlet="recordButton"></ng-container>
      </div>
      <div class="flex justify-center items-center">
        @if (needPreferences) {
          <ng-container *ngTemplateOutlet="settingsIcons; context: {right: false}"></ng-container>
        } @else {
          &nbsp;
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #recordIcon>
  @if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
    <ion-icon name="sync-outline" class="text-5xl spin-animation"></ion-icon>
  } @else if (status === TranslateStatus.ERROR || status === TranslateStatus.CANCEL) {
    <ion-icon name="mic" class="text-5xl cursor-pointer"></ion-icon>
  } @else if (status === TranslateStatus.RETRY) {
    <ion-icon name="paper-plane-outline" class="text-5xl cursor-pointer"></ion-icon>
  } @else {
    <ion-icon name="square" class="text-5xl cursor-pointer"></ion-icon>
  }
</ng-template>

<ng-template #settingsIcons let-right="right">
  <div class="flex relative items-center max-h-[80px] min-w-[80px]" [class.flex-row-reverse]="!right || isRtl()">
    <!--    <ion-icon-->
    <!--      [@iconRotate]="settingsHover() ? 'hover' : 'normal'"-->
    <!--      name="settings-outline" class="text-4xl cursor-pointer" (click)="toggleSettings()"></ion-icon>-->

    <div class="flex items-center relative" [class.ml-20]="right || isRtl()" [class.mr-20]="!right || isRtl()">
      <!--      @if (settingsFlag()) {-->
      <div class="icon flex" [class.flex-row-reverse]="!right" [@iconBounce]>
        <div class="setting-icon-wrapper">
          <div class="settings-icon cursor-pointer" [class.active]="autoTranslate.value" (click)="toggleAutoTranslation(tooltip1)"
               #tooltip1="matTooltip"
               matTooltip="{{(autoTranslate.value ? 'message.edit.mode.enabled' : 'message.edit.mode.disabled') | transloco}}"
          >
            <ion-icon src="/assets/icons/awesome/icon-pen.svg" class="text-2xl"></ion-icon>
          </div>
          <div class="text-xs text-center mt-2 line-clamp-2">{{ 'label.edit.mode' | transloco }}</div>
        </div>
        <div class="setting-icon-wrapper" [class.ml-6]="right || isRtl()" [class.mr-6]="!right || isRtl()">
          <div class="settings-icon cursor-pointer" [class.active]="autoImprove.value" (click)="toggleAutoImprove(tooltip2)"
               #tooltip2="matTooltip"
               matTooltip="{{(autoImprove.value ? 'message.auto.improve.text.enabled' : 'message.auto.improve.text.disabled') | transloco}}"
          >
            <ion-icon name="sparkles-outline" class="text-2xl"></ion-icon>
          </div>
          <div class="text-xs text-center mt-2 line-clamp-2">{{ 'label.better.text' | transloco }}</div>
        </div>
      </div>
      <!--      }-->
    </div>

    <!--      <div class="settings-icon cursor-pointer" [class.active]="!autoTranslate.value" (click)="toggleAutoTranslation()">-->
    <!--        <ion-icon src="/assets/icons/awesome/icon-pen.svg" class="text-2xl"></ion-icon>-->
    <!--      </div>-->
    <!--    <ion-fab>-->
    <!--      <ion-fab-button>-->
    <!--        <ion-icon name="settings-outline"></ion-icon>-->
    <!--      </ion-fab-button>-->
    <!--      <ion-fab-list side="end">-->
    <!--        <ion-fab-button>-->
    <!--          <div class="settings-icon cursor-pointer">-->
    <!--            <ion-icon src="/assets/icons/awesome/icon-pen.svg" class="text-2xl"></ion-icon>-->
    <!--          </div>-->
    <!--        </ion-fab-button>-->
    <!--      </ion-fab-list>-->
    <!--    </ion-fab>-->
  </div>
</ng-template>

<ng-template #recordButton>
  @if (status === TranslateStatus.CANCEL || status === TranslateStatus.ERROR) {
    <div class="stop-record-button" (mousedown)="$event.preventDefault()" (click)="startRecord()" [class.other]="!speakerMe">
      <ng-container *ngTemplateOutlet="recordIcon"></ng-container>
    </div>
  } @else if (status === TranslateStatus.RETRY) {
    <div class="stop-record-button" (mousedown)="$event.preventDefault()" (click)="retry()" [class.other]="!speakerMe">
      <ng-container *ngTemplateOutlet="recordIcon"></ng-container>
    </div>
  } @else {
    <div class="stop-record-button" [class.recording]="recorderStatus === RecorderStatus.RECORDING" (mousedown)="$event.preventDefault()" (click)="stopRecording()"
         [class.other]="!speakerMe">
      <ng-container *ngTemplateOutlet="recordIcon"></ng-container>
    </div>
  }
</ng-template>
