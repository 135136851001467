<div class="flex flex-col w-full flex-auto overflow-y-auto">
  @if (loading) {
    <app-loader></app-loader>
  }
  <div class="flex flex-col w-full p-5 flex-auto">
    <div class="items-center w-full">
      <div class="flex flex-col justify-center">
        <div class="w-full">
          <div class="relative">
            @for (invoice of invoices?.list; track invoice.id) {
              <app-invoice-card [invoice]="invoice" class="mb-5 block"></app-invoice-card>
              <ion-infinite-scroll (ionInfinite)="loadMoreInvoices($event)">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
              </ion-infinite-scroll>
            } @empty {
              <div class="flex flex-col justify-center mt-5 items-center">
                <i class="fa-solid fa-magnifying-glass fa-3x"></i>
                <div class="title text-2xl mt-2.5">{{ 'title.no.invoices' | transloco }}</div>
                <div class="mt-2.5 text-center">{{ 'text.no.invoices' | transloco }}</div>
              </div>
            }
            <!--            @if (loadingMore) {-->
            <!--              <app-more-loader></app-more-loader>-->
            <!--            }-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
