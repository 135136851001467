import { Component, ElementRef, inject, Input, OnChanges, Signal, SimpleChanges, ViewChild } from '@angular/core';
import * as QRCodeStyling from "qr-code-styling";
import { SiteInfo } from '@shared/transport.interface';
import { SiteInfoState } from '@shared/store/site/site-state.service';
import { Store } from '@ngxs/store';
import { environment } from '@env/environment';

@Component({
  selector: 'app-qr-code',
  standalone: true,
  imports: [],
  templateUrl: './qr-code.component.html',
  styleUrl: './qr-code.component.scss'
})
export class QrCodeComponent implements OnChanges {
  @Input() url: string;
  @ViewChild("canvas", { static: true }) canvas: ElementRef;

  private readonly _store = inject(Store);
  protected readonly siteInfo: Signal<SiteInfo> = this._store.selectSignal(SiteInfoState.getSiteInfo);

  ngOnChanges(changes: SimpleChanges): void {
    if (!QRCodeStyling) {
      return;
    }
    // @ts-ignore
    const qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      margin: 14,
      type: 'svg',
      data: this.url,
      image: this.siteInfo()?.logo ? environment.serverUrl + '/v1/site/logo?t=' + this.siteInfo().logo : '/assets/images/logo/qr-logo.png',
      dotsOptions: {
        color: this.siteInfo()?.commonSettings?.textFg || "#4a86ff",
        type: "rounded"
      },
      backgroundOptions: {
        color: "#ffffff"
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10
      }
    });
    this.canvas.nativeElement.innerHTML = '';
    qrCode.append(this.canvas.nativeElement);
  }
}
