import { Component, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatIcon } from '@angular/material/icon';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-text-dialog',
  standalone: true,
  imports: [
    TranslocoPipe,
    MatIcon,
    IonIcon,
  ],
  templateUrl: './text-dialog.component.html',
  styleUrl: './text-dialog.component.scss'
})
export class TextDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  title: string;
  text: string;

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.text = this.data.text;
    }
  }

}
