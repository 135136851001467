@if (user) {
  <div class="relative flex justify-center items-center font-bold uppercase rounded-full overflow-hidden" [style.width.px]="width" [style.height.px]="width">
    @if (avatarId) {
      <img [src]="environment.serverUrl + '/v1/avatars/' + avatarId" [alt]="user.name" [style.width.px]="width" [style.height.px]="width"/>
    } @else {
      <div [title]="user.name" [style.width.px]="width" [style.height.px]="width"
           class="font-bold flex items-center justify-center w-full h-full rounded-full {{textSize}} uppercase {{bgColor}} {{textColor}} dark:bg-gray-700 dark:text-gray-200 overflow-hidden"
           [class.inactive]="user.status === userStatus.NEW">
        {{ extractInitials() }}
      </div>
    }
    @if (creator) {
      <div class="creator-icon"></div>
    }
    @if (muted) {
      <ion-icon name="volume-mute-outline"></ion-icon>
    }
  </div>
}
