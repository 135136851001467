import { Component, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JsonPipe } from '@angular/common';
import { finalize } from 'rxjs';
import { UserService } from '../../../../service/user.service';
import { NotyService } from '../../../../service/noty.service';
import { UtilsService } from '../../../../service/utils.service';
import { CommonLanguage, RoomUser, UserRole } from '../../../../transport.interface';
import { UserState } from '../../../../store/user/user-state.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { eye, eyeOff } from 'ionicons/icons';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@UntilDestroy()
@Component({
  selector: 'app-security',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    JsonPipe,
    IonicModule,
    MatLabel,
    MatInput,
    MatFormField,
  ],
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss'
})
export class SecurityComponent {
  private _fb = inject(FormBuilder);
  private _store = inject(Store);
  private _userService = inject(UserService);
  private _noty = inject(NotyService);
  private _utilsService = inject(UtilsService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly UserRole = UserRole;
  protected form: FormGroup;
  protected langs: CommonLanguage[];
  protected pending = false;
  oldPassword: 'password' | 'text' = 'password';
  newPassword: 'password' | 'text' = 'password';
  confirmPassword: 'password' | 'text' = 'password';

  constructor() {
    addIcons({ eye, eyeOff })
    this.form = this._fb.group({
      oldPassword: [ null, [ Validators.required ] ],
      password: [ null, [ Validators.required ] ],
      confirmPassword: [ null, [ Validators.required ] ],
    }, { validators: this._utilsService.customPasswordMatching });
  }

  changePassword(): void {
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const req = this.form.value;
    this._userService.changePassword(req.oldPassword, req.password)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this._noty.success('message.password.changed');
      });
  }

  showPassword(field: string): void {
    if (field === 'old') {
      this.oldPassword = this.oldPassword === 'text' ? 'password' : 'text';
    } else if (field === 'new') {
      this.newPassword = this.newPassword === 'text' ? 'password' : 'text';
    } else if (field === 'confirm') {
      this.confirmPassword = this.confirmPassword === 'text' ? 'password' : 'text';
    }
  }
}
