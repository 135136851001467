import { Component, inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { RoomUser } from '@shared/transport.interface';
import { NgClass } from '@angular/common';
import { UserCardComponent } from '@shared/component/ui/user-card/user-card.component';
import { ModalController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-participants-list',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe,
    UserCardComponent,
    NgClass,
    IonicModule
  ],
  templateUrl: './participants-list.component.html',
  styleUrl: './participants-list.component.scss'
})
export class ParticipantsListComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  users: RoomUser[];

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data?.users) {
      this.users = this.data.users;
    }
  }

  closePage() {
    this._modalCtrl.dismiss();
  }
}
