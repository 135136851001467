import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { AudioPlayerStatus, CommonLanguage, RecorderStatus, WSReqActions } from '../transport.interface';
import { LanguagesState } from '../store/languages/languages-state.service';
import { Howl } from 'howler';
import { RxStompService } from './rx-stomp/rx-stomp.service';
import { RoomService } from './room.service';
import { Topics } from '../topics';
import { RecordingStatus, VoiceRecorder } from 'fpmk-capacitor-voice-recorder';
import { environment } from '@env/environment';
import { Platform } from '@ionic/angular';
import { AudioMotionAnalyzer } from 'fpmk-audiomotion-analyzer';
import AudioRecorder from 'fpmk-simple-audio-recorder';
import { DialogService } from '@service/dialog.service';
import { Capacitor } from '@capacitor/core';
import { RxStompState } from '@stomp/rx-stomp';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private readonly _store = inject(Store);
  private readonly _platform = inject(Platform);
  private readonly _dialogs = inject(DialogService);
  private readonly _audioPlayerStatus$ = new Subject<AudioPlayerStatus>();
  private readonly _recorderStatus$ = new Subject<RecorderStatus>();
  private languages$: Observable<CommonLanguage[]> = this._store.select(LanguagesState.getLanguages);

  private _languages: CommonLanguage[] = [];
  private _abort = false;
  private interval: number = -1;
  private iosInited = false;
  private lastPlayer: Howl;
  private lastPlayerIos: Howl;
  private playerStatus = AudioPlayerStatus.READY;
  private recordStatus = RecorderStatus.READY;

  private _timer$ = new Subject<number>();
  private _timer = signal(1000);
  private currentVolume = 1;
  private muted = false;
  private inited = false;
  private recordLang = '';
  private timerIntervalId = -1;
  private firstChunk = false;
  private lastUrl: string;
  private insertText: boolean;

  // only for android
  private audio: HTMLAudioElement;

  // only for browsers
  private _recorder: AudioRecorder;

  // @ts-ignore
  private audioContext: AudioContext = new (window.AudioContext || window.webkitAudioContext)();
  private _dataStream$: BehaviorSubject<AudioBuffer> = new BehaviorSubject<AudioBuffer>(undefined);
  audioMotion: AudioMotionAnalyzer;
  buffer: string[] = [];
  isBrowser = false;
  sendAgain = false;
  wsConnected = false;

  constructor(private _websocket: RxStompService, private _roomService: RoomService) {
    this._websocket.connectionState$.subscribe(res => {
      if ((res === RxStompState.CLOSED || res === RxStompState.CLOSING) && this.buffer?.length) {
        this.sendAgain = true;
        this.wsConnected = false;
      } else if (res === RxStompState.OPEN) {
        this.wsConnected = true;
      }
    });
    if (this._platform.is('desktop') || this._platform.is('mobileweb')) {
      this.isBrowser = true;
    }
    this.languages$.subscribe(res => {
      this._languages = res;
    });
    if (Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform()) {
      return;
    }
    if (Capacitor.getPlatform() === 'android' && Capacitor.isNativePlatform()) {
      this.audio = new Audio();
      this.audioMotion = new AudioMotionAnalyzer(
        null,
        {
          gradient: 'orangered',
          colorMode: 'bar-index',
          showScaleX: false,
          showScaleY: false,
          smoothing: 0.6,
          minFreq: 20,
          maxFreq: 8000,
          frequencyScale: 'log',
          fftSize: 16384,
          minDecibels: -120,
          maxDecibels: -40,
          linearAmplitude: true,
          barSpace: 0.25,
          lineWidth: 1.5,
          fillAlpha: 0.3,
          showPeaks: false,
          showBgColor: false,
          linearBoost: 3.5,
          reflexRatio: 0.5,
          reflexAlpha: 1,
          reflexBright: 1,
          roundBars: true,
          height: 88,
          volume: 0,
          mode: 2,
          overlay: true,
          source: this.audio
        }
      );
    }
  }

  init(): void {
    this.stopRecording(true);
    if (!this.inited) {
      if (this.isBrowser) {
        this.initBrowserRecorder();
      } else {
        this.initAppRecorder();
      }
      this.inited = true;
    }
    console.log('[Microphone]', 'Inited');
  }

  afterConnect(insertText = false) {
    if (this.buffer?.length && this.recordStatus !== RecorderStatus.RECORDING) {
      this._websocket.sendToTopic(Topics.LOGS, { message: '--------- Connection restored, sending audio ----------' });
      this._websocket.sendToTopic(Topics.AUDIO, { type: WSReqActions.CANCEL, userId: this._roomService.me.id, roomId: this._roomService.room.roomId },
        { roomId: this._roomService.room.roomId });
      this._websocket.sendToTopic(Topics.AUDIO, { type: WSReqActions.START, fromLang: this.recordLang, userId: this._roomService.me.id },
        { roomId: this._roomService.room.roomId });
      setTimeout(() => {
        let headerFound = false;
        this.buffer.forEach(b => {
          if (headerFound) {
            this._websocket.sendBinaryToTopic(Topics.AUDIO, b,
              { roomId: this._roomService.room.roomId, userId: this._roomService.me.id + '', type: this.isBrowser ? 'mp3' : 'wav' });
          } else {
            headerFound = this.isWavBase64(b);
            if (headerFound) {
              this._websocket.sendBinaryToTopic(Topics.AUDIO, b,
                { roomId: this._roomService.room.roomId, userId: this._roomService.me.id + '', type: this.isBrowser ? 'mp3' : 'wav' });
            }
          }
        });
        this._websocket.sendToTopic(Topics.AUDIO, { type: WSReqActions.PAUSE, fromLang: this.recordLang, userId: this._roomService.me.id, insertText },
          { roomId: this._roomService.room.roomId });
        this._websocket.sendToTopic(Topics.LOGS, { message: '--------- Audio was sent ---------' });
        this.sendAgain = false;
        this.buffer = [];
      }, 300);
    }
  }

  private initAppRecorder() {
    if (Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform()) {
      VoiceRecorder.addListener('onAudioChunk', (chunk: any) => {
        if (!this.buffer.length) {
          if (!this.isWavBase64(chunk.data)) {
            return;
          }
        }
        this.buffer.push(chunk.data);
        if (this.wsConnected) {
          this._websocket.sendBinaryToTopic(Topics.AUDIO, chunk.data, { roomId: this._roomService.room.roomId, userId: this._roomService.me.id + '', type: 'wav' });
        }
        this.processIosStream(chunk);
      }).then();
    } else if (Capacitor.getPlatform() === 'android' && Capacitor.isNativePlatform()) {
      VoiceRecorder.addListener('onAudioChunk', (chunk: any) => {
        if (!this.buffer.length) {
          if (!this.isWavBase64(chunk.data)) {
            return;
          }
        }
        this.buffer.push(chunk.data);
        if (this.wsConnected) {
          this._websocket.sendBinaryToTopic(Topics.AUDIO, chunk.data, { roomId: this._roomService.room.roomId, userId: this._roomService.me.id + '', type: 'wav' });
        }
        this.processAndroidStream(chunk);
      }).then();
    }
  }

  private isWavBase64(base64String: string): boolean {
    // Decode Base64 to binary data
    const binaryString = atob(base64String);
    const binaryData = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      binaryData[ i ] = binaryString.charCodeAt(i);
    }

    // Check if it matches the WAV header
    const isRiff = binaryData[ 0 ] === 0x52 && // 'R'
      binaryData[ 1 ] === 0x49 && // 'I'
      binaryData[ 2 ] === 0x46 && // 'F'
      binaryData[ 3 ] === 0x46;   // 'F'

    const isWave = binaryData[ 8 ] === 0x57 && // 'W'
      binaryData[ 9 ] === 0x41 && // 'A'
      binaryData[ 10 ] === 0x56 && // 'V'
      binaryData[ 11 ] === 0x45;   // 'E'

    return isRiff && isWave;
  }

  private initBrowserRecorder() {
    console.log('[initBrowserRecorder]');
    this._recorder = new AudioRecorder({
      recordingGain: 1, // Initial recording volume
      encoderBitRate: 96, // MP3 encoding bit rate
      streaming: true, // Data will be returned in chunks (ondataavailable callback) as it is encoded,
      streamBufferSize: 4096,
      // rather than at the end as one large blob
      constraints: { // Optional audio constraints, see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        channelCount: 1, // Set to 2 to hint for stereo if it's available, or leave as 1 to force mono at all times
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false
      },
    });
    this._recorder.ondataavailable = (data: any) => {
      if (this._recorder.audioContext.state === 'running') {
        let body = this.int8ArrayToBase64(data);
        this.buffer.push(body);
        if (this.wsConnected) {
          this._websocket.sendBinaryToTopic(Topics.AUDIO, body, { roomId: this._roomService.room.roomId, userId: this._roomService.me.id + '', type: 'mp3' })
        }
      }
    };
    this._recorder.onstart = () => {
      console.log('[Microphone] Recording...');
      this.audioMotion = new AudioMotionAnalyzer(
        null,
        {
          gradient: 'orangered',
          colorMode: 'bar-index',
          showScaleX: false,
          showScaleY: false,
          smoothing: 0.8,
          minFreq: 20,
          maxFreq: 22000,
          frequencyScale: 'log',
          fftSize: 16384,
          minDecibels: -120,
          maxDecibels: -40,
          linearAmplitude: true,
          barSpace: 0.5,
          lineWidth: 1.5,
          fillAlpha: 0.3,
          showPeaks: false,
          showBgColor: false,
          linearBoost: 3.5,
          reflexRatio: 0.5,
          reflexAlpha: 1,
          reflexBright: 1,
          roundBars: true,
          height: 88,
          volume: 0,
          mode: 3,
          overlay: true,
          audioCtx: this._recorder.audioContext,
          source: this._recorder.sourceNode
        }
      );
      if (this.timerIntervalId >= 0) {
        clearInterval(this.timerIntervalId);
      }
      this._websocket.sendToTopic(Topics.AUDIO, { type: (this.realtime ? WSReqActions.START_REALTIME : WSReqActions.START), fromLang: this.recordLang, userId: this._roomService.me.id },
        { roomId: this._roomService.room.roomId });
      this.recordStatus = RecorderStatus.RECORDING;
      this._recorderStatus$.next(this.recordStatus);
      this.timerIntervalId = +setInterval(() => {
        this._timer$.next(this._recorder.time);
      }, 1000);
    };
    this._recorder.onstop = () => {
      console.log('[Microphone] Stopped');
      if (this.timerIntervalId >= 0) {
        clearInterval(this.timerIntervalId);
      }
      this.recordStatus = RecorderStatus.READY;
      this._recorderStatus$.next(this.recordStatus);
      if (!this._abort && this.wsConnected) {
        if (this.sendAgain) {
          this.afterConnect(this.insertText);
        } else {
          this._websocket.sendToTopic(Topics.AUDIO, { type: this.realtime ? WSReqActions.STOP_REALTIME : WSReqActions.PAUSE, fromLang: this.recordLang, userId: this._roomService.me.id, insertText: this.insertText },
            { roomId: this._roomService.room.roomId });
          this.buffer = [];
        }
      }
    };
    this._recorder.onerror = (error) => {
      if (this.timerIntervalId >= 0) {
        clearInterval(this.timerIntervalId);
      }
      if (this._recorder.audioContext?.state === 'running') {
        this._recorder.stop();
      }
      this.recordStatus = RecorderStatus.ERROR;
      this._recorderStatus$.next(this.recordStatus);
      if (error?.name === 'NotAllowedError') {
        this._dialogs.microphoneAccess();
      }
      // this._noty.error(error);
      console.log("[Microphone] Error:", error);
      this._roomService.sendServerLog("[Microphone] Error: " + JSON.stringify(error));
    };
  }

  private processIosStream(chunk: any) {
    if (this.firstChunk) {
      this.emitData(chunk.data, chunk.mimeType);
    } else {
      this.headerBase64 = chunk.data;
      this.firstChunk = true;
    }
  }

  headerBase64: string;

  private processAndroidStream(chunk: any) {
    let data: string;
    if (!this.firstChunk) {
      this.headerBase64 = chunk.data;
      data = this.headerBase64;
      this.firstChunk = true;
    } else {
      const bothData = atob(this.headerBase64) + atob(chunk.data); // binary string
      data = btoa(bothData); // base64 encoded
    }
    let mimeType = chunk.mimeType ? chunk.mimeType : 'webm;codecs=opus';
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      mimeType = 'audio/wav';
    }
    if (!this.firstChunk) {
      this.firstChunk = true;
    } else {
      this.audio.src = `data:${ mimeType };base64,${ data }`;
    }
  }

  startTimer() {
    console.log('[Microphone] Recording...');
    this.recordStatus = RecorderStatus.RECORDING;
    this._recorderStatus$.next(this.recordStatus);
    if (this.timerIntervalId >= 0) {
      clearInterval(this.timerIntervalId);
    }
    this.timerIntervalId = +setInterval(() => {
      this._timer$.next(this._timer());
      this._timer.update(value => {
        return value + 1000;
      });
    }, 1000);
  }

  startRecording(fromLang: string, realtime = false): void {
    this.realtime = realtime;
    this.buffer = [];
    this.insertText = null;
    this.stopAudio();
    if (this._platform.is('android') && !this._platform.is('mobileweb')) {
      this.audio.autoplay = true;
      this.audio.play();
    }
    this._abort = false;
    this.recordLang = fromLang;
    if (this.isBrowser) {
      this._recorder.start();
    } else {
      this._timer.set(1000);
      this._websocket.sendToTopic(Topics.AUDIO,
        { type: (this.realtime ? WSReqActions.START_REALTIME : WSReqActions.START), fromLang: this.recordLang, userId: this._roomService.me.id },
        { roomId: this._roomService.room.roomId });
      this.firstChunk = false;
      VoiceRecorder.startRecording().then(() => {
        this.startTimer();
      });
    }
  }

  getAudioPlayerStatus$(): Observable<AudioPlayerStatus> {
    return this._audioPlayerStatus$.asObservable();
  }

  getRecorderStatus$(): Observable<RecorderStatus> {
    return this._recorderStatus$.asObservable();
  }

  abortRecording(): void {
    this._abort = true;
    this.insertText = null;
    this.buffer = [];
    this.stopMedia();
  }

  realtime = false;

  stopRecording(abort = false, insertText = false): void {
    this.stopAudioPlay();
    if (this.isBrowser) {
      if (this._recorder?.audioContext?.state === 'running') {
        this.insertText = insertText;
        this._recorder.stop();
      }
    } else {
      VoiceRecorder.getCurrentStatus().then(res => {
        if (RecordingStatus.RECORDING === res.status || RecordingStatus.PAUSED === res.status) {
          console.log('[Microphone] Stopping microphone');
          VoiceRecorder.stopRecording();
          console.log('[Microphone] Stopped');
          if (this.timerIntervalId >= 0) {
            clearInterval(this.timerIntervalId);
          }
          if (!this._abort || abort) {
            if (this.recordStatus === RecorderStatus.RECORDING && this.wsConnected) {
              this.recordStatus = RecorderStatus.READY;
              this._recorderStatus$.next(this.recordStatus);
              if (!this.sendAgain) {
                this._websocket.sendToTopic(Topics.AUDIO, {
                    type: this.realtime ? WSReqActions.STOP_REALTIME : WSReqActions.PAUSE,
                    fromLang: this.recordLang,
                    insertText,
                    userId: this._roomService.me.id
                  },
                  { roomId: this._roomService.room.roomId });
                this.buffer = [];
              } else {
                this.afterConnect(insertText);
              }
            } else if (!this.wsConnected) {
              if (this.buffer.length) {
                this.recordStatus = RecorderStatus.RETRY;
                this._recorderStatus$.next(this.recordStatus);
              } else {
                this.recordStatus = RecorderStatus.READY;
                this._recorderStatus$.next(this.recordStatus);
              }
            }
          }
        }
      });
    }
  }

  play(time: number, lang: string, roomId: string): boolean {
    if (this.muted) {
      return false;
    }
    const v = this.findSpeechVersion(lang);
    this.playAudio(`${ environment.serverUrl }/${ v ? v : 'v1' }/speech?key=${ roomId }&time=${ time }&lang=${ lang }`, 1);
    return true;
  }

  mute(): void {
    this.muted = true;
    if (this.lastPlayer) {
      this.lastPlayer.mute(true);
    }
  }

  unmute(): void {
    this.muted = false;
    if (this.lastPlayer) {
      this.lastPlayer.mute(false);
    }
  }

  stopAudio(): void {
    if (this.lastPlayer) {
      try {
        if (this.lastPlayer.playing()) {
          this.lastPlayer.stop();
        }
      } catch (e) {
        //
      }
    }
  }

  setVolume(vol: number): void {
    this.currentVolume = vol;
    if (this.lastPlayer) {
      this.lastPlayer.volume(vol);
    }
  }

  playAudio(url: string, volume?: number, callback?: Function): void {
    if (this.muted) {
      return;
    }
    if (this.lastPlayer && this.lastPlayer.playing()) {
      this.stopAudio();
    }
    this.lastPlayer = new Howl({
      src: [ url ],
      format: [ 'flac', 'mp3', 'aac' ],
      html5: true,
      volume: isFinite(<number>volume) ? volume : this.currentVolume,
      onend: () => {
        if (callback) {
          callback();
        }
        this.playerStatus = AudioPlayerStatus.STOP;
        this._audioPlayerStatus$.next(this.playerStatus);
        console.log('[playAudio.onend]', this.playerStatus);
      },
      onplay: () => {
        this.playerStatus = AudioPlayerStatus.PLAYING;
        this._audioPlayerStatus$.next(this.playerStatus);
        console.log('[playAudio.onplay]', this.playerStatus);
      },
      onstop: () => {
        this.playerStatus = AudioPlayerStatus.STOP;
        this._audioPlayerStatus$.next(this.playerStatus);
        console.log('[playAudio.onstop]', this.playerStatus);
      },
      onloaderror: (error: any) => {
        console.log('onloaderror', error);
        this.playerStatus = AudioPlayerStatus.ERROR;
        this._audioPlayerStatus$.next(this.playerStatus);
        console.log('[playAudio.onloaderror]', this.playerStatus);
        this._roomService.sendServerLog("[PlayAudio] onloaderror: " + JSON.stringify(error));
      },
    });
    // this.playerStatus = AudioPlayerStatus.PLAYING;
    // this._audioPlayerStatus$.next(this.playerStatus);
    this.lastPlayer.play();
  }

  playAudioIos(url: string): void {
    if (this.lastPlayerIos && this.lastPlayerIos.playing()) {
      try {
        this.lastPlayerIos.stop();
      } catch (e) {
        //
      }
    }
    this.lastPlayerIos = new Howl({
      src: [ url ],
      format: [ 'flac', 'mp3', 'aac' ],
      html5: true,
      volume: 0
    });
    this.lastPlayerIos.play();
  }

  initAudioForIos(): void {
    if (this.iosInited) {
      return;
    }
    this.playAudioIos('/assets/unlock.mp3');
    this.iosInited = true;
  }

  readText(time: number, lang: string): boolean {
    if (this.playerStatus === AudioPlayerStatus.PLAYING) {
      this.stopAudio();
      return true;
    }
    this.play(time, lang, this._roomService.room.roomId);
    return false;
  }

  get timer$(): Observable<number> {
    return this._timer$.asObservable();
  }

  private stopMedia(): void {
    this.stopRecording(true);
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.timerIntervalId) {
      clearInterval(this.timerIntervalId);
      this._timer.set(0);
      this._timer$.next(this._timer());
    }
  }

  private findSpeechVersion(lang: string): string {
    return <string>this._languages.find(l => l.code === lang)?.version;
  }

  private stopAudioPlay() {
    if (this._platform.is('android') && !this._platform.is('mobileweb')) {
      this.audio.pause();
      this.audio.currentTime = 0;
      if (this.lastUrl) {
        URL.revokeObjectURL(this.lastUrl);
      }
    }
  }

  writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  get dataStream(): Observable<AudioBuffer> {
    return this._dataStream$.asObservable().pipe(filter(data => data !== undefined && data !== null));
  }

  private emitData(base64Audio: string, type: string): void {
    let data;
    const bothData = atob(this.headerBase64) + atob(base64Audio); // binary string
    data = btoa(bothData); // base64 encoded
    let mimeType = type ? type : 'webm;codecs=opus';
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      mimeType = 'audio/wav';
    }

    const audioData = Uint8Array.from(atob(data), c => c.charCodeAt(0));
    this.audioContext.decodeAudioData(audioData.buffer).then(res => {
      this._dataStream$.next(res);
    }).catch(e => console.error(JSON.stringify(e)));
  }

  private int8ArrayToBase64(int8Array: ArrayBuffer): string {
    const uint8Array = new Uint8Array(int8Array);
    const binaryString = Array.from(uint8Array)
      .map(byte => String.fromCharCode(byte))
      .join('');
    return btoa(binaryString);
  }
}
