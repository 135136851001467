import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map, Observable, of } from 'rxjs';
import * as _moment from 'moment-timezone';
import { Moment } from 'moment';

const moment = _moment;

@Pipe({
  name: 'prettyDate',
  standalone: true
})
export class PrettyDatePipe implements PipeTransform {

  private _translate = inject(TranslocoService);
  private localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  transform(value: Date | string | number): Observable<string> {
    const date = moment.utc(value).tz(this.localTZ);
    const today = moment.utc(new Date()).tz(this.localTZ);
    const yesterday = moment.utc(new Date()).tz(this.localTZ).subtract(1, 'd');
    if (this.isSameDay(date, today)) {
      return this._translate.selectTranslate('title.today', null, this._translate.getActiveLang())
        .pipe(map(val => `${ val } - ${ date.format('HH:mm') }`));
    } else if (this.isSameDay(date, yesterday)) {
      return this._translate.selectTranslate('title.yesterday', null, this._translate.getActiveLang())
        .pipe(map(val => `${ val } - ${ date.format('HH:mm') }`));
    } else {
      return of(date.format('DD.MM.yyyy - HH:mm'));
    }
  }

  private isSameDay(date1: Moment, date2: Moment): boolean {
    return date1.year() === date2.year() &&
      date1.month() === date2.month() &&
      date1.date() === date2.date();
  }

}
