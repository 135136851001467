<div class="flex flex-col w-full flex-auto" [formGroup]="form">
  <ion-content>
    <div class="flex flex-col p-5">
      <div class="self-center">
        @if (me()?.id) {
          <app-avatar [canDelete]="!!me().avatarId" [initials]="me().name" [hasAvatar]="!!me().avatarId" formControlName="avatar"></app-avatar>
        }
      </div>
      <div class="w-full flex-auto mt-4" [formGroup]="form">
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.email' | transloco }}</mat-label>
          <input
            matInput
            readonly
            formControlName="email">
        </mat-form-field>
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.first.name' | transloco }}</mat-label>
          <input
            matInput
            formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.last.name' | transloco }}</mat-label>
          <input
            matInput
            formControlName="lastName">
        </mat-form-field>
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.position' | transloco }}</mat-label>
          <input
            matInput
            formControlName="companyRole">
        </mat-form-field>
        <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
          <mat-label>{{ 'label.position.description' | transloco }}</mat-label>
          <input
            matInput
            formControlName="companyRoleDesc">
        </mat-form-field>

      </div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <div class="pb-2 pt-1">
        <button class="button w-full" [class.loading]="pending" [disabled]="pending || form.invalid" (click)="saveProfile()">{{ 'button.save' | transloco }}</button>
      </div>
    </ion-toolbar>
  </ion-footer>
</div>
