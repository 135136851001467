import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SiteInfo } from '../../transport.interface';
import { SaveSiteInfoAction } from './site.actions';

export interface SiteInfoStateModel {
  site: SiteInfo;
}

const defaults = {
  site: {
    corporate: false,
    buttonBg: '',
    buttonFg: '',
    textFg: '',
    linkFg: '',
    iconFg: '',
  }
};

@State<SiteInfoStateModel>({
  name: 'site',
  defaults
})
@Injectable()
export class SiteInfoState {
  @Action(SaveSiteInfoAction)
  save(ctx: StateContext<SiteInfoStateModel>, action: SaveSiteInfoAction): void {
    return ctx.setState({ site: action.payload });
  }

  @Selector()
  static getSiteInfo(state: SiteInfoStateModel) {
    return state.site;
  }
}
