import { Chat, ChatWindow } from '../../transport.interface';

export class SaveChatAction {
  static readonly type = '[Chat] Save';

  constructor(public payload: Chat) {
  }
}

export class SaveTopChatWindowAction {
  static readonly type = '[Chat] Save top chat window';

  constructor(public payload: ChatWindow) {
  }
}

export class SaveBottomChatWindowAction {
  static readonly type = '[Chat] Save bottom chat window';

  constructor(public payload: ChatWindow) {
  }
}
