import { Component, Input } from '@angular/core';
import { InitialsAvatarComponent } from '../initials-avatar/initials-avatar.component';
import { RoomUser } from '../../../transport.interface';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [
    InitialsAvatarComponent,
  ],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent {
  @Input() me: RoomUser;
}
