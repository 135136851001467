<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.my.documents' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div class="flex flex-col items-center w-full flex-auto overflow-hidden relative bg-white">
      <div class="flex flex-col w-full flex-auto overflow-hidden">
        <div class="w-full p-5">
          <app-selectbox [placeholder]="'placeholder.data.type'" [items]="dataTypes" [defaultItem]="dataTypesCtrl.value"
                         (itemSelected)="changeDataType($event)"></app-selectbox>
        </div>
        <div class="link-color cursor-pointer text-base ltr:pl-5 rtl:pr-5 pb-4" (click)="showFilter()">{{ 'link.more.filters' | transloco }} ({{ filtersCount }})</div>
        <div class="w-full flex-auto overflow-hidden">
          @switch (dataTypesCtrl.value.id) {
            @case (HistoryDataType.SUMMARY) {
              <app-summary-history [filter]="filter" [filterChanged]="filterChanged"></app-summary-history>
            }
            @case (HistoryDataType.PROTOCOL) {
              <app-protocol-history [filter]="filter" [filterChanged]="filterChanged"></app-protocol-history>
            }
            @case (HistoryDataType.TRANSCRIPT) {
              <app-transcript-history [filter]="filter" [filterChanged]="filterChanged"></app-transcript-history>
            }
          }
        </div>
      </div>
    </div>
  </div>
</div>
