import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as QRCodeStyling from "qr-code-styling";

@Component({
  selector: 'app-qr-code',
  standalone: true,
  imports: [],
  templateUrl: './qr-code.component.html',
  styleUrl: './qr-code.component.scss'
})
export class QrCodeComponent implements OnChanges {
  @Input()
  url: string;
  @ViewChild("canvas", { static: true }) canvas: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (!QRCodeStyling) {
      return;
    }
    // @ts-ignore
    const qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      margin: 14,
      data: this.url,
      image:
        "/assets/images/logo/qr-logo.png",
      dotsOptions: {
        color: "#4a86ff",
        type: "rounded"
      },
      backgroundOptions: {
        color: "#ffffff"
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10
      }
    });
    this.canvas.nativeElement.innerHTML = '';
    qrCode.append(this.canvas.nativeElement);
  }
}
