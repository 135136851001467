import { Component, inject, Input, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { finalize, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { LangService } from '@shared/service/lang.service';
import { CommonLanguage } from '@shared/transport.interface';
import { LanguagesState } from '@shared/store/languages/languages-state.service';
import { SaveLanguagesAction } from '@shared/store/languages/languages.actions';
import { LoaderComponent } from '../../loader/loader.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline, searchOutline } from 'ionicons/icons';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { StorageService } from '@service/storage.service';
import { IonContent, ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-lang-selector',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoPipe,
    LoaderComponent,
    IonicModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatPrefix
  ],
  templateUrl: './lang-selector.component.html',
  styleUrl: './lang-selector.component.scss'
})
export class LangSelectorComponent implements OnInit {
  private _modalCtrl = inject(ModalController);

  @ViewChild('scroll', { static: true })
  private scroll: IonContent;
  @Input() data: any;

  lastUsedLangs = signal<string[]>([]);

  private _langService = inject(LangService);
  private _store = inject(Store);
  private _translateService = inject(TranslocoService);
  private _storageService = inject(StorageService);

  onlySelectLang = false;
  firstLangSelected = false;

  langFilterCtrl: FormControl<string> = new FormControl<string>('');
  filteredLangs: ReplaySubject<CommonLanguage[]> = new ReplaySubject<CommonLanguage[]>(1);

  loading = false;
  firstLang: string;
  secondLang: string;
  selectedFirst = true;
  selectedSecond = false;
  langsSignal: Signal<CommonLanguage[]> = this._store.selectSignal(LanguagesState.getLanguages);
  completeLangs: CommonLanguage[] = [];

  constructor() {
    addIcons({ closeOutline, searchOutline });
    this._storageService.lastUsedLangs().then(langs => {
      this.lastUsedLangs.set(langs);
    });
  }

  ngOnInit(): void {
    const translation = this._translateService.getTranslation();
    const langsMap = translation.get(this._translateService.getActiveLang());
    this.completeLangs = _.cloneDeep(this.langsSignal());
    this.completeLangs.forEach(lang => {
      if (langsMap[ lang.code ]) {
        lang.activeLangName = langsMap[ lang.code ];
      }
    });
    this.langFilterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterLangs();
      });
    if (this.data) {
      this.firstLang = this.data.firstLang;
      this.secondLang = this.data.secondLang;
      this.onlySelectLang = this.data.onlySelectLang;
      this.firstLangSelected = this.data.firstLangSelected;
      if (!this.onlySelectLang) {
        if (this.firstLangSelected) {
          this.selectFirstLang();
          setTimeout(() => {
            if (document.getElementById(this.firstLang)) {
              this.scroll.scrollToPoint(0, document.getElementById(this.firstLang).offsetTop, 700);
            }
          }, 100);
        } else {
          this.selectSecondLang();
          setTimeout(() => {
            if (document.getElementById(this.secondLang)) {
              this.scroll.scrollToPoint(0, document.getElementById(this.secondLang).offsetTop, 700);
            }
          }, 100);
        }
      }
      if (this.completeLangs?.length) {
        this.initLangs(this.completeLangs);
        return;
      }
      this.loading = true;
      this._langService.langsFroAI()
        .pipe(untilDestroyed(this), finalize(() => this.loading = false))
        .subscribe(res => {
          const ind = res.findIndex(l => l.code === this.secondLang);
          if (ind >= 0) {
            res.splice(ind);
          }
          this._store.dispatch(new SaveLanguagesAction(res));
          this.initLangs(res);
        });
    }
  }

  private initLangs(res: CommonLanguage[]) {
    const ind = this.firstLangSelected ? res.findIndex(lang => this.secondLang === lang.code) : res.findIndex(lang => this.firstLang === lang.code);
    if (ind >= 0) {
      res.splice(ind, 1);
    }
    const langs = res.slice();
    const lastUsed = this.lastUsedLangs();
    if (lastUsed.length > 0) {
      lastUsed.forEach(l => {
        const commonLanguage = langs.findIndex(lang => lang.code === l);
        if (commonLanguage >= 0) {
          langs.splice(commonLanguage, 1);
        }
      });
      const ind = this.firstLangSelected ? lastUsed.findIndex(lang => this.secondLang === lang) : lastUsed.findIndex(lang => this.firstLang === lang);
      if (ind >= 0) {
        lastUsed.splice(ind, 1);
        this.lastUsedLangs.set(lastUsed);
      }
    }
    langs.sort((a: CommonLanguage, b: CommonLanguage) => {
      return a.name.localeCompare(b.name);
    });
    this.filteredLangs.next(langs);
  }

  protected filterLangs() {
    if (!this.completeLangs?.length) {
      return;
    }
    // get the search keyword
    let search = this.langFilterCtrl.value;
    if (!search) {
      this.filteredLangs.next(this.langsSignal().slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLangs.next(
      this.completeLangs.filter(bank => bank.activeLangName.toLowerCase().indexOf(search) > -1)
    );
  }

  close(): void {
    if (!this.onlySelectLang) {
      this.closeModal({ firstLang: this.firstLang, secondLang: this.secondLang });
    } else {
      this.closeModal({ firstLang: this.firstLang });
    }
  }

  selectFirstLang(): void {
    this.selectedFirst = true;
    this.selectedSecond = false;
  }

  selectSecondLang(): void {
    this.selectedFirst = false;
    this.selectedSecond = true;
  }

  swapLangs(): void {
    const first = this.firstLang;
    this.firstLang = this.secondLang;
    this.secondLang = first;
  }

  selectLang(lang: CommonLanguage): void {
    if (this.selectedFirst && this.firstLang === lang.code || this.selectedSecond && this.secondLang === lang.code) {
      return;
    }
    if (this.selectedFirst) {
      this.firstLang = lang.code;
    } else {
      this.secondLang = lang.code;
    }
    this._storageService.addUsedLang(lang.code).then(res => {
      this.lastUsedLangs.set(res);
    })
    this.close();
  }

  selectLangByCode(lang: string): void {
    if (this.selectedFirst && this.firstLang === lang || this.selectedSecond && this.secondLang === lang) {
      return;
    }
    if (this.selectedFirst) {
      this.firstLang = lang;
    } else {
      this.secondLang = lang;
    }
    this.close();
  }

  closeModal(data?: any) {
    this._modalCtrl.dismiss(data);
  }
}
