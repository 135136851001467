import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JsonPipe } from '@angular/common';
import { finalize } from 'rxjs';
import { Invoices, Paging } from '../../../../transport.interface';
import { UserService } from '../../../../service/user.service';
import { IonicModule } from '@ionic/angular';
import { InvoiceCardComponent } from '../../../ui/invoice-card/invoice-card.component';
import { LoaderComponent } from '../../../loader/loader.component';

@UntilDestroy()
@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    JsonPipe,
    IonicModule,
    InvoiceCardComponent,
    LoaderComponent,
  ],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss'
})
export class InvoicesComponent implements OnInit {
  loading = false;
  invoices: Invoices;
  private _paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  protected loadingMore = false;
  protected empty = false;

  private _userService = inject(UserService);

  ngOnInit(): void {
    this.loading = true;
    this._userService.invoices(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.invoices = res;
      });
  }

  loadMoreInvoices($event: any): void {
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this._paging.currentPage++;
    this._userService.invoices(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loadingMore = false))
      .subscribe(res => {
        this._paging.totalPages = res.total;
        this.invoices.list = res.list.concat(this.invoices.list);
        if (!res.list.length || res.list.length < this._paging.pageSize) {
          this.empty = true;
        }
      });
  }
}
