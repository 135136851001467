import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class NotyService {
  _toastController = inject(ToastController);
  _translateService = inject(TranslocoService);

  success(message: string, params?: any): void {
    this.showToast('', message, params);
  }

  error(message: string, params?: any): void {
    this.showToast('error', message, params);
  }

  private showToast(cssClass: string, message: string, params?: any): void {
    this._translateService.selectTranslate(message, params).subscribe(res => {
      this._toastController.create({
        message: res,
        duration: 4000,
        position: 'top',
        swipeGesture: 'vertical',
        cssClass: 'custom-toast ' + cssClass
      }).then(res => {
        res.present();
      });
    });
  }
}
