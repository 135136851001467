import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, inject, Signal } from '@angular/core';
import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { ScanQrCodeButtonComponent } from '@shared/component/ui/scan-qr-code-button/scan-qr-code-button.component';
import { NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { SiteInfo } from '@shared/transport.interface';
import { SiteInfoState } from '@shared/store/site/site-state.service';
import { Store } from '@ngxs/store';
import { environment } from '@env/environment';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    ScanQrCodeButtonComponent,
    NgOptimizedImage
  ]
})
export class HeaderComponent {

  private readonly router = inject(Router);
  private readonly _store = inject(Store);
  protected siteInfo: Signal<SiteInfo> = this._store.selectSignal(SiteInfoState.getSiteInfo);

  goHome(): void {

  }

  protected readonly environment = environment;
}
