import { Component, inject, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { FuseAlertComponent } from '@comp/ui/alert';
import { IonicModule, Platform } from '@ionic/angular';
import { VoiceRecorder } from 'fpmk-capacitor-voice-recorder';
import { GenericResponse } from 'fpmk-capacitor-voice-recorder/dist/esm/definitions';

@Component({
  selector: 'app-qr-code-dialog',
  standalone: true,
  imports: [
    MatIconButton,
    TranslocoPipe,
    FuseAlertComponent,
    IonicModule,
  ],
  templateUrl: './microphone-access-dialog.component.html',
  styleUrl: './microphone-access-dialog.component.scss'
})
export class MicrophoneAccessDialogComponent {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;
  totallyDisabled = false;
  protected _platform = inject(Platform);

  constructor() {
  }

  enableMicrophone(): void {
    const _closeModal = this.closeModal;
    VoiceRecorder.requestAudioRecordingPermission().then(
      (res: GenericResponse) => {
        if (res.value) {
          this.closeModal();
        } else {
          this.totallyDisabled = true;
        }
      }).catch(res => {
      this.totallyDisabled = true;
    });
  }
}
