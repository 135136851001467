<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ room.creator?.name }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden bg-color-1">
  <div class="flex w-full justify-center items-center mt-6 pl-2 pr-2">
    <!-- Contact info -->
    <div class="flex w-full items-center">
      <div class="flex lg:ml-0 w-full">
        <div class="relative flex justify-between w-full">
          <div class="flex flex-col items-center justify-center flex-auto overflow-hidden">
            @if (room) {
              <div>
                <app-initials-avatar [avatarId]="room.creator?.avatarId" [user]="{name: room.creator?.name}" [width]="80" [textSize]="'text-3xl'"></app-initials-avatar>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full p-5 pb-0">
    <app-selectbox [placeholder]="'placeholder.data.type'" [items]="dataTypes" [defaultItem]="dataTypesCtrl.value"
                   (itemSelected)="changeDataType($event)"></app-selectbox>
  </div>
  <ion-content class="bg-color-1 flex flex-auto flex-col">
    <div class="flex flex-col w-full h-full flex-auto p-5">
      <div class="flex flex-col w-full bg-white p-5 flex-auto">
        <!--          <div class="flex justify-between">-->
        <!--            <div class="flex">-->
        <!--              <div class="flex items-center cursor-pointer" (click)="showRoomResultTypeSelector(type)">-->
        <!--                <div class="cursor-pointer">{{ 'title.chat.result.type.' + type | transloco }}</div>-->
        <!--                <div class="ml-2 fa-2xs h-[15px]">-->
        <!--                  <ion-icon name="chevron-down"></ion-icon>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            @if (me().id === room.creator.id) {-->
        <!--              <div><a (click)="createSummary()">{{ 'title.create' | transloco }}</a></div>-->
        <!--            }-->
        <!--          </div>-->
        <div class="relative flex flex-col w-full overflow-y-auto overflow-x-hidden flex-auto">
          @if (loading) {
            <app-loader></app-loader>
          }
          @if (type === ChatResultType.SUMMARY) {
            <ng-container *ngTemplateOutlet="summaryList"></ng-container>
          } @else if (type === ChatResultType.TRANSCRIPT) {
            <ng-container *ngTemplateOutlet="transcriptList"></ng-container>
          } @else if (type === ChatResultType.PROTOCOL) {
            <ng-container *ngTemplateOutlet="protocolsList"></ng-container>
          }
        </div>
      </div>
    </div>
  </ion-content>
  @if (me().id === room.creator.id) {
    <ion-footer>
      <ion-toolbar>
        <div class="pb-2 pt-1">
          <button class="button w-full" (click)="createSummary()">{{ 'button.create' | transloco }}</button>
        </div>
      </ion-toolbar>
    </ion-footer>
  }
</div>

<ng-template #summaryList>

  <ion-list>
    @for (summary of summaries; track summary.id; let ind = $index) {
      <ion-item-sliding>
        <ion-item>
          <ion-label>
            <div class="flex-auto cursor-pointer" (click)="showSummary(summary)">
              <div class="font-semibold line-clamp-2">{{ summary.title }}</div>
              <div class="flex items-center text-gray-400">
                <ion-icon name="calendar-outline"></ion-icon>
                <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm">{{ summary?.createDate | prettyDate | async }}</div>
              </div>
            </div>
          </ion-label>
        </ion-item>
        <ion-item-options>
          <ion-item-option color="danger" (click)="deleteSummary(summary)" [disabled]="loading">{{ 'button.delete' | transloco }}</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    } @empty {
      <div class="flex flex-col justify-center mt-5 items-center text-center">
        <ion-icon name="search-outline" class="text-6xl"></ion-icon>
        <div class="title text-2xl mt-2.5">{{ 'title.no.summary' | transloco }}</div>
        <div class="mt-2.5 text-center">{{ 'text.create.new.summary' | transloco }}</div>
      </div>
    }
  </ion-list>

</ng-template>

<ng-template #protocolsList>
  @for (protocol of protocols; track protocol.id; let ind = $index) {
    <div class="flex items-center pb-2.5 mt-2.5" [ngClass]="{'border-b border-b-gray-300': ind !== (protocols.length - 1) && protocols.length > 1}">
      <div class="flex-auto cursor-pointer" (click)="showProtocol(protocol)">
        <div class="font-semibold line-clamp-2">{{ protocol.title }}</div>
        <div class="flex items-center text-gray-400">
          <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm leading-none normal-text">{{ protocol?.createDate | prettyDate | async }}</div>
        </div>
      </div>
      @if (me().id === room.creator?.id) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteProtocol(protocol)">
            <ion-icon name="trash-outline" class="pr-2"></ion-icon>
            <span>{{ 'button.delete' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </div>
  } @empty {
    <div class="flex flex-col justify-center mt-5 items-center text-center">
      <ion-icon name="search-outline" class="text-6xl"></ion-icon>
      <div class="title text-2xl mt-2.5">{{ 'title.no.protocol' | transloco }}</div>
      <div class="mt-2.5 text-center">{{ 'text.create.new.protocol' | transloco }}</div>
    </div>
  }
</ng-template>

<ng-template #transcriptList>
  @for (transcript of transcripts; track transcript.id; let ind = $index) {
    <div class="flex items-center pb-2.5 mt-2.5" [ngClass]="{'border-b border-b-gray-300': ind !== (transcripts.length - 1) && transcripts.length > 1}">
      <div class="flex-auto cursor-pointer" (click)="showTranscript(transcript)">
        <div class="font-semibold line-clamp-2">{{ transcript.title }}</div>
        <div class="flex items-center text-gray-400">
          <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5 text-sm">{{ transcript?.createDate | prettyDate | async }}</div>
        </div>
      </div>
      @if (me().id === room.creator?.id) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteTranscript(transcript)">
            <ion-icon name="trash-outline" class="pr-2"></ion-icon>
            <span>{{ 'button.delete' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </div>
  } @empty {
    <div class="flex flex-col justify-center mt-5 items-center text-center">
      <ion-icon name="search-outline" class="text-6xl"></ion-icon>
      <div class="title text-2xl mt-2.5">{{ 'title.no.transcripts' | transloco }}</div>
      <div class="mt-2.5 text-center">{{ 'text.create.new.transcript' | transloco }}</div>
    </div>
  }
</ng-template>
