export enum ErrorCodes {
  Internal = 1,
  AccountNotActive = 2,
  ActivationLinkExpired = 3,
  INVALID_TOKEN = 4,
  EntityNotFound = 5,
  BadCredentials = 6,
  TokenIsLoggedOut = 7,
  AccountLocked = 8,
  AccountExpired = 9,
  Validation = 10,
  AuthenticationError = 11,
  AccessDenied = 12,
  EntityNotExist = 13,
  Recovery = 14,
  TypeNotExist = 15,
  WAIT_FOR_HOST = 16,
  INVITE_BLOCKED = 17,
  EMPTY_CHAT_MESSAGE = 46,
  TRANSLATION_ERROR = 47,
  RECOGNITION_ERROR = 48,
  RECORD_START_ERROR = 49,
  NO_MINUTES_LEFT = 50,
  INVALID_VALUE = 100,
  CONFLICT = 300,
  BAD_PARAMS = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500
}
