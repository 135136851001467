import { Component, inject, Input, OnInit } from '@angular/core';
import { RoomSummary } from '@shared/transport.interface';
import { ShowSummaryComponent } from '@shared/component/show-summary/show-summary.component';
import { ModalController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-show-summary-dialog',
  standalone: true,
  imports: [
    ShowSummaryComponent
  ],
  templateUrl: './show-summary-dialog.component.html',
  styleUrl: './show-summary-dialog.component.scss'
})
export class ShowSummaryDialogComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  summary: RoomSummary;

  ngOnInit(): void {
    if (this.data) {
      this.summary = this.data.summary;
    }
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }
}
