<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (stepper.selectedIndex > 0) {
        <button mat-icon-button (click)="stepperBack()">
          <ion-icon name="arrow-back" class="text-4xl"></ion-icon>
        </button>
      }
    </ion-buttons>
    <ion-title>
      @if (stepper.selectedIndex === 0) {
        {{ 'title.summary.discussions' | transloco }}
      } @else if (stepper.selectedIndex === 1) {
        {{ 'title.creation.period' | transloco }}
      } @else if (stepper.selectedIndex === 2) {
        {{ 'title.creation.period' | transloco }}
      }
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div class="flex flex-col items-center w-full flex-auto overflow-x-hidden relative bg-white">
      <mat-stepper [disableRipple]="true" [animationDuration]="'200ms'" class="h-full w-full flex flex-col overflow-hidden" #stepper>
        <mat-step class="flex flex-col">
          <div class="flex flex-col w-full p-5 flex-auto overflow-hidden">
            <div class="mb-5">{{ 'text.summary.discussions' | transloco }}</div>
            <div class="flex flex-col w-full flex-auto overflow-y-auto" #container>
              @for (result of results; track result.type) {
                <div id="data.type.{{result.type}}" class="flex flex-col border border-gray-300 py-5 px-4 mb-5 cursor-pointer" [class.active]="type === result.type"
                     (click)="chooseResult(result)">
                  <div class="w-full flex items-start">
                    <div class="w-[70px] flex items-center justify-center">
                      <ion-icon [src]="result.icon" class="text-5xl"></ion-icon>
                    </div>
                    <div class="ltr:ml-4 rtl:mr-4">
                      <div class="title text-2xl">{{ result.title | transloco }}</div>
                      <div class="mt-1">{{ result.description | transloco }}</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="flex flex-col w-full p-5 flex-auto">
            <div>{{ 'text.chat.result.period.' + type | transloco }}</div>
            <div class="flex flex-auto flex-col">
              <div class="mt-5">
                <div class="flex items-center">
                  <div class="title text-2xl">{{ 'text.begin' | transloco }}</div>
                  <ion-icon name="information-circle" class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ltr:ml-1 rtl:mr-1 cursor-pointer"
                            #tooltip1="matTooltip"
                            matTooltip="{{'tooltip.summary.start.date' | transloco}}"
                            [matTooltipPosition]="isRtl() ? 'left' : 'right'" (click)="tooltip1.toggle()"
                  ></ion-icon>
                </div>
              </div>
              <div class="mt-5">
                <div class="flex date-picker">
                  <mat-form-field class="w-1/2">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                    <!--                      <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>-->
                    <input matInput disabled [formControl]="fromCtrl" [matDatepicker]="pickerDateFrom" [placeholder]="'placeholder.date' | transloco"
                           class="title ltr:pl-1.5 rtl:pr-1.5"
                           (click)="pickerDateFrom.open()" (dateChange)="fromDateSelected($event)">
                    <mat-datepicker #pickerDateFrom disabled="false"></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="ltr:ml-5 rtl:mr-5 w-1/2">
                    <ion-icon name="time-outline" class="text-gray-400 text-2xl ltr:pr-2 rtl:pl-2" matIconPrefix></ion-icon>
                    <input matInput
                           class="title date-time ltr:pl-1.5 rtl:pr-1.5"
                           name="selected_time_g"
                           [format]="24"
                           [formControl]="fromTimeCtrl"
                           [ngxMatTimepicker]="pickerFrom"
                           placeholder="00:00"
                           readonly/>
                  </mat-form-field>
                  <ngx-mat-timepicker #pickerFrom (timeSet)="fromTimeSelected($event)"></ngx-mat-timepicker>
                </div>
              </div>

              <div class="flex flex-col flex-auto">
                <ion-content>
                  <app-summary-chat
                    [room]="_room"
                    [dateFrom]="form.controls.from.value"
                    (messageSelected)="fromMessageSelected($event)"></app-summary-chat>
                </ion-content>
                @if (error) {
                  <app-chat-error [position]="'top'" [error]="error" [translate]="true"></app-chat-error>
                }
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="flex flex-col w-full p-5 flex-auto">
            <div class="flex items-center">
              <div class="title text-2xl">{{ 'text.begin' | transloco }}:</div>
              <div class="ml-2 title text-2xl">{{ fromCtrl.value | date: 'dd.MM.yyyy HH:mm' }}</div>
            </div>
            @if (fromChatMessageSelected) {
              <div class="flex relative items-start max-w-full mt-4">
                @if (fromChatMessageSelected.userId !== me().id) {
                  <div class="lang ltr:mr-2 rtl:ml-2">
                    <ng-container *ngTemplateOutlet="avatarLetters; context: {$implicit: fromChatMessageSelected.name, avatarId: fromChatMessageSelected.avatarId}"></ng-container>
                  </div>
                }
                <div
                  class="flex flex-col flex-auto max-w-full"
                  [ngClass]="{'items-end': fromChatMessageSelected.userId === me().id, 'items-start': fromChatMessageSelected.userId !== me().id}">
                  @if (fromChatMessageSelected.userId !== me().id) {
                    <div class="text-xl title font-semibold">{{ fromChatMessageSelected.name }}</div>
                  }
                  <div
                    class="relative min-w-44 w-full px-3 pb-2 pt-2.5"
                    [ngClass]="{'chat-my-msg-bg text-white': fromChatMessageSelected.userId === me().id, 'chat-msg-bg': fromChatMessageSelected.userId !== me().id}">

                    @if (me().language === fromChatMessageSelected.originalLang) {
                      <div class="leading-5 wrap text-base chat-msg line-clamp-2" [innerHTML]="fromChatMessageSelected.originalText"></div>
                    } @else {
                      <div class="leading-5 wrap text-base chat-msg line-clamp-2"
                           [innerHTML]="fromChatMessageSelected.szdMessages && fromChatMessageSelected.szdMessages[fromChatMessageSelected.users[me().id]] || ''"></div>
                    }

                    <div class="flex justify-between items-center mt-2.5">
                      <div class="text-xs chat-msg-date-color">
                        {{ (fromChatMessageSelected.time * 1000) | date: 'HH:mm' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div class="mt-5">
              <div class="flex items-center">
                <div class="title text-2xl">{{ 'text.end' | transloco }}</div>
                <ion-icon name="information-circle"
                          class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ltr:ml-1 rtl:mr-1 cursor-pointer"
                          #tooltip2="matTooltip"
                          matTooltip="{{'tooltip.summary.end.date' | transloco}}"
                          [matTooltipPosition]="isRtl() ? 'left' : 'right'" (click)="tooltip2.toggle()"
                ></ion-icon>
              </div>
            </div>
            <div class="mt-5">
              <div class="flex date-picker">
                <mat-form-field class="w-1/2">
                  <!--                      <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>-->
                  <mat-datepicker-toggle matIconSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                  <input matInput disabled [formControl]="toCtrl" [matDatepicker]="pickerDateTo" [placeholder]="'placeholder.date' | transloco"
                         class="title text-2xl ltr:pl-1.5 rtl:pr-1.5"
                         (click)="pickerDateTo.open()" (dateChange)="toDateSelected($event)">
                  <mat-datepicker #pickerDateTo disabled="false"></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="ltr:ml-5 rtl:mr-5 w-1/2">
                  <ion-icon name="time-outline" class="text-gray-400 text-2xl ltr:pr-2 rtl:pl-2" matIconPrefix></ion-icon>
                  <input matInput
                         class="title date-time ltr:pl-1.5 rtl:pr-1.5"
                         name="selected_time_g"
                         [format]="24"
                         [formControl]="toTimeCtrl"
                         [ngxMatTimepicker]="pickerTo"
                         placeholder="23:59"
                         readonly/>
                </mat-form-field>
                <ngx-mat-timepicker #pickerTo (timeSet)="toTimeSelected($event)"></ngx-mat-timepicker>
              </div>
            </div>
            <div class="flex flex-col flex-auto">
              <ion-content>
                @if (stepper.selectedIndex === 2) {
                  <app-summary-chat
                    [end]="true"
                    [room]="_room"
                    [dateFrom]="form.controls.to.value"
                    (messageSelected)="toMessageSelected($event)"></app-summary-chat>
                }
              </ion-content>
              @if (error) {
                <app-chat-error [position]="'top'" [error]="error" [translate]="true"></app-chat-error>
              }
            </div>

          </div>
        </mat-step>
        <mat-step>
          <div class="overflow-hidden w-full h-full flex-auto flex flex-col bg-white">
            @if (type === ChatResultType.SUMMARY && summary?.id) {
              <app-show-summary [summary]="summary" (closeEvent)="closeModal($event)"></app-show-summary>
            } @else if (type === ChatResultType.TRANSCRIPT && transcript?.id) {
              <app-show-transcript [transcript]="transcript" (closeEvent)="closeModal($event)"></app-show-transcript>
            } @else if (type === ChatResultType.PROTOCOL && protocol?.id) {
              <app-show-protocol [protocol]="protocol" (closeEvent)="closeModal($event)"></app-show-protocol>
            }
          </div>
        </mat-step>
      </mat-stepper>
    </div>

    <ion-footer>
      <ion-toolbar>
        <div class="pb-2 pt-1">
          @if (stepper.selectedIndex === 0) {
            <button class="button w-full" (click)="resultChosen()">{{ 'button.next' | transloco }}</button>
          } @else if (stepper.selectedIndex === 1) {
            <button class="button w-full" [disabled]="!fromCtrl.value || pending" [class.loading]="pending" (click)="fromDateSelectedEvent()">{{ 'button.next' | transloco }}
            </button>
          } @else if (stepper.selectedIndex === 2) {
            <button class="button w-full" [disabled]="!toCtrl.value || endBeforeStart || form.invalid || pending" [class.loading]="pending"
                    (click)="createSummary()">{{ 'button.create' | transloco }}
            </button>
          }
        </div>
      </ion-toolbar>
    </ion-footer>
  </div>
</div>

<ng-template let-lang let-creator="creator" let-muted="muted" let-avatarId="avatarId" #avatarLetters>
  <app-initials-avatar [avatarId]="avatarId" [user]="{name: lang}" [muted]="muted" [creator]="creator" [width]="30" [textSize]="'text-xs'"></app-initials-avatar>
</ng-template>
