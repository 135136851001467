@if (pack?.id) {
  <div class="border border-gray-300 p-4">
    <div class="flex justify-between pb-2.5 border-b border-b-gray-300">
      <div class="title text-4xl">{{ pack.duration / 60 / 60 }}{{ 'label.hours' | transloco }} | {{ pack.duration / 60 }}{{ 'label.minutes' | transloco }}</div>
      <div class="title text-4xl">{{ pack.price | currency: pack.currency }}</div>
    </div>
    <div class="flex mt-2.5">
      <div class="w-1/2">
        <div class="text-base">{{ 'title.cost.of.minute' | transloco }}</div>
        <div class="font-semibold text-lg">{{ (pack.price / (pack.duration / 60)) | currency: pack.currency: 'symbol' :'1.2-2' }}</div>
      </div>
      <div class="w-1/2">
        <div class="text-base">{{ 'title.expiration.date' | transloco }}</div>
        <div class="font-semibold text-lg">{{ pack.expiration | utcDate: 'dd.MM.yyyy' }}</div>
      </div>
    </div>
    @if (isWeb()) {
      <div class="w-full mt-5">
        <button class="button w-full" (click)="buyMinutes()" [class.loading]="pending" [disabled]="pending">{{ 'button.buy' | transloco }}</button>
      </div>
    }
  </div>
}
