import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { DialogService } from '@service/dialog.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { chevronDown } from 'ionicons/icons';

@Component({
  selector: 'app-select-lang',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './select-lang.component.html',
  styleUrl: './select-lang.component.scss'
})
export class SelectLangComponent {
  @Input() defaultLang: string = 'en';
  @Input() placeholder: string = 'placeholder.your.language';
  @Output() langSelected: EventEmitter<string> = new EventEmitter<string>();

  private _dialogService = inject(DialogService);

  constructor() {
    addIcons({ chevronDown });
  }

  selectLanguage() {
    this._dialogService.selectLanguage(null, this.defaultLang, true, true)
      .then(res => {
        if (res) {
          this.defaultLang = res.firstLang;
          this.langSelected.next(this.defaultLang);
        }
      });
  }
}
