import { Component, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JsonPipe } from '@angular/common';
import { finalize } from 'rxjs';
import { UserService } from '../../../../service/user.service';
import { NotyService } from '../../../../service/noty.service';
import { LangService } from '../../../../service/lang.service';
import { RoomUser } from '../../../../transport.interface';
import { UserState } from '../../../../store/user/user-state.service';
import { SaveUserAction } from '../../../../store/user/user.actions';
import { MatTooltip } from '@angular/material/tooltip';
import { SwitchComponent } from '../../../ui/switch/switch.component';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    JsonPipe,
    MatTooltip,
    SwitchComponent,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
  private _fb = inject(FormBuilder);
  private _store = inject(Store);
  private _userService = inject(UserService);
  private _noty = inject(NotyService);
  private _langService = inject(LangService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected form: FormGroup;
  protected pending = false;

  constructor() {
    this.form = this._fb.group({
      tariffExtension: [],
      timeExpiration: [],
      roomInvitation: [],
      roomInvitationEmail: [],
      tariffInvoiceEmail: [],
      packageInvoiceEmail: [],
    });
  }

  ngOnInit(): void {
    this.form.patchValue(this.me().notifications);
  }

  saveNotifications() {
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const req = this.form.value;
    this._userService.updateNotifications(req)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this._noty.success('message.notifications.changed');
        this.me().notifications = res.notifications;
        this._store.dispatch(new SaveUserAction(this.me()));
      });

  }

  isRtl(): boolean {
    return this._langService.isActiveLangRtl();
  }
}
