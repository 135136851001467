import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import Cropper from 'cropperjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgOptimizedImage } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-image-cropper',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.scss'
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  sanitizedUrl!: SafeUrl;
  cropper!: Cropper;
  image: string;

  constructor(private sanitizer: DomSanitizer) {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data?.image) {
      this.image = this.data.image;
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.image);
    }
  }

  ngAfterViewInit() {
    this.initCropper();
  }

  initCropper() {
    const image = document.getElementById('image') as HTMLImageElement;
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      guides: true,
    });
  }

  getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas');
    var context: any = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  crop() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    let roundedImage = document.createElement('img');

    if (roundedImage) {
      this.closeModal(roundedCanvas.toDataURL());
    } else {
      return this.closeModal(null);
    }
  }

  reset() {
    this.cropper.clear();
    this.cropper.crop();
  }
}
