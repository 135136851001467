<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.select.language' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div>
      @if (!onlySelectLang) {
        <div class="mt-4 px-5 text-base">{{ 'text.select.language' | transloco }}</div>
      }
      <div class="w-full px-5">
        <mat-form-field class="w-full">
          <mat-label>{{ 'label.search' | transloco }}</mat-label>
          <ion-icon matPrefix name="search-outline" class="text-gray-300 text-3xl pr-1"></ion-icon>
          <input matInput [formControl]="langFilterCtrl"/>
        </mat-form-field>
      </div>
    </div>
    <ion-content #scroll>
      <div class="flex flex-col items-center w-full flex-auto overflow-hidden relative bg-white">
        @if (!loading) {
          <div class="w-full flex flex-col overflow-hidden">
            @if (lastUsedLangs().length > 0) {
              <div class="w-full px-5 mb-4 pr-[1.65rem]">
                <div class="flex justify-center items-center title text-3xl text-gray-300 mb-2">{{ 'title.last.used.languages' | transloco }}</div>
                @for (lang of lastUsedLangs(); track lang; let ind = $index) {
                  <div (click)="selectLangByCode(lang)" class="w-full px-2.5 py-3.5 border-b border-b-gray-300 lang-item" [class.border-t-gray-300]="ind === 0"
                       [class.border-t]="ind === 0"
                       [class.active]="selectedFirst && firstLang === lang || selectedSecond && secondLang === lang">
                    <div class="capitalize">{{ lang | transloco }}</div>
                  </div>
                }
              </div>
            }
            <div class="flex flex-auto justify-center items-center title text-3xl text-gray-300 mb-2">{{ 'title.other.languages' | transloco }}</div>
            <div class="w-full flex-auto px-5">
              @for (lang of filteredLangs | async; track lang.code; let ind = $index) {
                <div [id]="lang.code" (click)="selectLang(lang)" class="w-full px-2.5 py-3.5 border-b border-b-gray-300 lang-item" [class.border-t-gray-300]="ind === 0"
                     [class.border-t]="ind === 0"
                     [class.active]="selectedFirst && firstLang === lang.code || selectedSecond && secondLang === lang.code">
                  <div class="capitalize">{{ lang.code | transloco }}</div>
                </div>
              }
            </div>
          </div>
        } @else {
          <div class="flex justify-center items-center relative">
            <app-loader></app-loader>
          </div>
        }
      </div>
    </ion-content>
  </div>
</div>
