<div class="flex flex-col w-full min-h-[250px] max-h-[400px] border border-gray-300 overflow-y-auto relative p-2.5">
  @if (loading) {
    <app-loader></app-loader>
  }
  @if (text?.length) {
    <app-chat-messages [text]="text" (messageSelected)="messageSelectedEvent($event)"></app-chat-messages>
  } @else {
    <div class="flex flex-col justify-center items-center w-full flex-auto">
      @if (start) {
        <div class="flex flex-col w-full h-full p-5 items-center justify-center">
          <i class="fa-regular fa-comment-dots fa-3x"></i>
          <div class="mt-2.5 flex flex-col items-center justify-center">
            <div class="title text-3xl">{{ 'text.select.start.date' | transloco }}</div>
            <div class="mt-1 text-center">{{ 'text.start.date.messages' | transloco }}</div>
          </div>
        </div>
      } @else {
        <div class="flex flex-col w-full h-full p-5 items-center justify-center">
          <i class="fa-regular fa-comment-dots fa-3x"></i>
          <div class="mt-2.5 flex flex-col items-center justify-center">
            <div class="title text-3xl">{{ 'text.select.end.date' | transloco }}</div>
            <div class="mt-1 text-center">{{ 'text.start.date.messages' | transloco }}</div>
          </div>
        </div>
      }
    </div>
  }
</div>
