export interface ChatMessages {
  list: ChatMessageDto[];
  total: number;
}

export interface ChatMessageDto {
  id?: number;
  userId?: number;
  name?: string;
  time?: number;
  originalText?: string;
  originalLang?: string;
  szdMessages?: { [ key: string ]: string } | string;
  users?: { [ key: string ]: string } | string;
  type?: ChatMessageType;
  archived?: boolean;
  photo?: string;
  summaryData?: {
    shareCode: string,
    title: { [ key: string ]: string } | string
  };
  protocolData?: {
    shareCode: string,
    title: { [ key: string ]: string } | string
  };
  transcriptData?: {
    shareCode: string,
    title: { [ key: string ]: string } | string
  };
}

export interface AIError {
  id?: number;
  message: string;
  code: number;
}

export interface AIChatError {
  errorCode: number;
  message: string;
  field: string;
  data?: any;
}

export interface Entity {
  id: number;
}

export enum UserRole {
  TEMP_USER = "TEMP_USER",
  USER = "USER",
  COMPANY_USER = "COMPANY_USER",
  COMPANY_HOST = "COMPANY_HOST",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  ADMIN = "ADMIN",
}

export enum UserStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE'
}

export interface SuperUser extends Entity {
  userName?: string;
  role?: UserRole;
}

export interface User {
  id?: number;
  email?: string;
  name?: string;
  bannedTo?: number;
  regDate?: number;
  status?: UserStatus;
  role?: UserRole;
  duration?: number;
  company?: Company;
  companyRole?: string;
  avatarId?: string;
  tariffId?: number;
  tariffName?: number;
}

export interface Company {
  name: string;
  companyId: string;
  phone: string;
  email: string;
  web: string;
}

export interface CommonLanguage {
  name: string;
  code: string;
  version: string;
  activeLangName?: string;
  top?: boolean;
}

export interface Paging {
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
}

export interface Rooms {
  list: Room[];
  total: number;
}

export interface HostRooms {
  list: HostRoom[];
  total: number;
}

export interface Room {
  hostRoom?: HostRoom;
  myLanguage: string;
  roomId: string;
  creator?: RoomUser;
  createTime?: number | string;
  users?: RoomUser[];
  waitingUsers?: RoomUser[];
  lastMessage?: ChatMessageDto;
  timeLeft?: number;
}

export interface ChatMessagesHistory {
  room: Room;
  messages: {
    list: ChatMessageDto[];
    total: number;
  }
}

export interface HostRoom {
  id: number;
  uid: string;
  name: string;
  description: string;
  createDate: number;
  host: User;
  imageId: string;
}

export interface RoomSummary {
  id: number;
  createDate: number;
  summary: string;
  title: string;
  user: RoomUser;
  room: Room;
  shared: boolean;
  deleted?: boolean;
}

export interface RoomSummaries {
  list: RoomSummary[];
  total: number;
}

export interface RoomProtocols {
  list: RoomProtocol[];
  total: number;
}

export interface RoomTranscripts {
  list: RoomTranscript[];
  total: number;
}

export interface RoomProtocol {
  id: number;
  createDate: number;
  title: string;
  intro: string;
  discuss: string;
  plan: string;
  user: RoomUser;
  room: Room;
  participants: RoomUser[];
  shared: boolean;
  deleted?: boolean;
}

export interface RoomTranscript {
  id: number;
  createDate: number;
  intro: string;
  title: string;
  user: RoomUser;
  room: Room;
  participants: RoomUser[];
  messages: ChatMessageDto[];
  shared: boolean;
  deleted?: boolean;
}

export interface SiteInfo {
  corporate: boolean;
  buttonBg: string;
  buttonFg: string;
  textFg: string;
  linkFg: string;
  iconFg: string;
}

export interface RoomUser {
  id: number;
  externalId?: string;
  avatarId?: string;
  email: string;
  name: string;
  firstName?: string;
  lastName?: string;
  role?: UserRole;
  language: string;
  secondLanguage?: string;
  languageVersion?: string;
  secondLanguageVersion?: string;
  duration?: number;
  tariff?: Tariff;
  accepted?: boolean;
  blocked?: boolean;
  companyRole?: string;
  companyRoleDesc?: string;
  ref?: string;
  preferences?: Preferences;
  notifications?: Notifications;
  demo?: boolean;
}

export interface Preferences {
  language?: string;
  interfaceLanguage?: string;
  messageTts?: MessageTts;
  voice?: Voice;
  micPosition?: MicPosition;
}

export interface Notifications {
  tariffExtension: boolean;
  timeExpiration: boolean;
  roomInvitation: boolean;
  roomInvitationEmail: boolean;
  tariffInvoiceEmail: boolean;
  packageInvoiceEmail: boolean;
}

export interface AuthResult {
  accessValid: number;
  token: string;
  user: RoomUser;
}

export interface RoomUserAction {
  action?: RoomAction;
  roomUser?: RoomUser;
  users?: RoomUser[];
  room?: Room;
  userId?: number;
  duration?: number;
  id?: number;
  shareCode?: string;
  type?: ChatMessageType;
}

export interface RoomParticipants {
  participants: RoomUser[];
  blocked: RoomUser[];
}

export interface RefreshToken {
  accessToken: string;
  accessValid: number;
}

export interface Tariff extends Entity {
  uid: string;
  period: string;
  name: string;
  duration: number;
  price: number;
  currency: string;
  canceled: boolean;
  cancelDate: string;
}

export interface TariffPackage extends Entity {
  title: string;
  price: number;
  duration: number;
  expiration: number;
  currency: string;
}

export interface Invoices {
  list: Invoice[];
  total: number;
}

export interface Invoice extends Entity {
  tariffName: TariffEnum;
  amount: number;
  currency: string;
  invoiceUrl: string;
  transactionId: string;
  duration: number;
  purchaseDate: number;
  renewalDate: number;
  subscription: boolean;
}

export interface BuyTariffPackageSession {
  url: string;
  clientId: string;
}

export interface SelectItem {
  id: string | number;
  title: string;
}

export interface HistoryFilter {
  dateFilter: {
    fromDate: number;
    toDate: number;
  }
}

export interface ChatWindow {
  language?: string;
  nativeLanguage?: boolean;
  showOriginalText?: boolean;
  text?: string;
  photo?: string;
  photoMode?: boolean;
  editMode?: boolean;
  messageId?: number;
}

export interface Chat {
  top: ChatWindow;
  bottom: ChatWindow;
  landingInterface?: boolean;
}

export interface StartRecordParams {
  speakerMe: boolean;
  speakLang: string;
  roomMode: RoomMode;
}

export enum WSReqActions {
  CONNECT = 'connect',
  CANCEL = 'cancel',
  DISCONNECT = 'disconnect',
  START = 'start',
  PAUSE = 'pause',
  MUTE = 'mute',
  UNMUTE = 'unmute',
  LOG = 'log',
}

export enum RoomAction {
  UPDATED = 'UPDATED',
  JOINED = 'JOINED',
  SPEAKING = 'SPEAKING',
  LEFT = 'LEFT',
  TIME_LEFT = 'TIME_LEFT',
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
  CLOSED_BY_HOST = 'CLOSED_BY_HOST',
  USER_WAITING_LEFT = 'USER_WAITING_LEFT',
  USER_WAITING = 'USER_WAITING',
  INVITE_APPROVED = 'INVITE_APPROVED',
  INVITE_BLOCKED = 'INVITE_BLOCKED',
  USER_BLOCKED = 'USER_BLOCKED',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  TRANSLATE = 'TRANSLATE',
  TRANSCRIPT = 'TRANSCRIPT',
  STOP_RECORD = 'STOP_RECORD',
  STOP_SPEAKING = 'STOP_SPEAKING',
  SUMMARY_DELETED = 'SUMMARY_DELETED',
}

export enum TranslateStatus {
  PREPARING = 'preparing',
  READY = 'ready',
  RECORDING = 'recording',
  CANCEL = 'cancel',
  SPEECH = 'speech',
  ERROR = 'error',
}

export enum AudioPlayerStatus {
  READY = 'ready',
  PLAYING = 'playing',
  RECORDING = 'recording',
  ERROR = 'error',
  STOP = 'stop'
}

export enum RecorderStatus {
  READY = 'ready',
  RECORDING = 'recording',
  ERROR = 'error'
}

export enum RoomMode {
  SINGLE,
  MULTI
}

export enum RecorderMode {
  SINGLE,
  MULTI
}

export enum ChatMessageType {
  MESSAGE = 0,
  USER_JOIN = 1,
  USER_LEFT = 2,
  SUMMARY = 3,
  PROTOCOL = 4,
  TRANSCRIPT = 5
}

export enum ChatResultType {
  SUMMARY,
  PROTOCOL,
  TRANSCRIPT
}

export enum HistoryDataType {
  ROOM,
  SUMMARY,
  PROTOCOL,
  TRANSCRIPT
}

export enum TariffEnum {
  PREPAID = 'free',
  PREMIUM = 'premium',
  CORPORATE = 'corporate',
}

export enum ProfileMenuEnum {
  MY_ACCOUNT,
  ADDRESS,
  INVOICES,
  NOTIFICATIONS,
  SECURITY,
  PREFERENCE,
}

export enum MicPosition {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT'
}

export enum Voice {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum MessageTts {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO'
}

export enum Cookies {
  UI_LANGUAGE = 'ui_lang'
}

export type PhotoType = 'transcript' | 'description';
