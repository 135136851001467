<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.more.filters' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <ion-content>
    <div class="w-full flex flex-col justify-center p-5">
      <div class="w-full flex">
        <mat-form-field class="w-1/2 pr-2">
          <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
          <input matInput [formControl]="fromCtrl" [matDatepicker]="pickerDateFrom" [placeholder]="'placeholder.from.date' | transloco" class="title text-3xl ltr:pl-1.5 rtl:pr-1.5"
                 (click)="pickerDateFrom.open()" (dateChange)="fromDateSelected($event)">
          <mat-datepicker #pickerDateFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="w-1/2 pl-2">
          <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
          <input matInput [formControl]="toCtrl" [matDatepicker]="pickerDateTo" [placeholder]="'placeholder.to.date' | transloco" class="title text-3xl ltr:pl-1.5 rtl:pr-1.5"
                 (click)="pickerDateTo.open()" (dateChange)="toDateSelected($event)">
          <mat-datepicker #pickerDateTo></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="w-full mt-5">
        <button class="button w-full" (click)="applyFilter()" [disabled]="invalidFilter">{{ 'button.apply' | transloco }}</button>
        <button class="button w-full stroked" (click)="clearFilter()">{{ 'button.clear' | transloco }}</button>
      </div>
    </div>
  </ion-content>
</div>
