<div class="flex flex-col w-full p-5 flex-auto overflow-y-auto text-base" [formGroup]="form">
  <div class="w-full flex flex-col flex-auto" [formGroup]="form">
<!--    <div class="flex w-full py-4 border-t border-gray-300 justify-between">-->
<!--      <div class="flex items-center flex-auto">-->
<!--        <div class="flex-auto">{{ 'label.auto.renewal.tariff' | transloco }}</div>-->
<!--        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer mr-4"></i>-->
<!--      </div>-->
<!--      <div>-->
<!--        <app-switch formControlName="tariffExtension"></app-switch>-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex w-full py-4 justify-between">
      <div class="flex items-center flex-auto">
        <div class="flex-auto">{{ 'label.minutes.over' | transloco }}</div>
        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer ltr:mr-4 rtl:ml-4"
           #tooltip="matTooltip"
           matTooltip="{{'tooltip.notification.time.expiration' | transloco}}"
           [matTooltipPosition]="!isRtl() ? 'left' : 'right'" (click)="tooltip.toggle()"
        ></i>
      </div>
      <div>
        <app-switch formControlName="timeExpiration"></app-switch>
      </div>
    </div>

<!--    <div class="flex w-full py-4 border-t border-gray-300 justify-between">-->
<!--      <div class="flex items-center flex-auto">-->
<!--        <div class="flex-auto">{{ 'label.chat.invitations' | transloco }}</div>-->
<!--        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer mr-4"></i>-->
<!--      </div>-->
<!--      <div>-->
<!--        <app-switch formControlName="roomInvitation"></app-switch>-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex w-full py-4 border-t border-gray-300 justify-between">
      <div class="flex items-center flex-auto">
        <div class="flex-auto">{{ 'label.chat.invitations.email' | transloco }}</div>
        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer ltr:mr-4 rtl:ml-4"
           #tooltip1="matTooltip"
           matTooltip="{{'tooltip.notification.room.invitation' | transloco}}"
           [matTooltipPosition]="!isRtl() ? 'left' : 'right'" (click)="tooltip1.toggle()"
        ></i>
      </div>
      <div>
        <app-switch formControlName="roomInvitationEmail"></app-switch>
      </div>
    </div>

<!--    <div class="flex w-full py-4 border-t border-gray-300 justify-between">-->
<!--      <div class="flex items-center flex-auto">-->
<!--        <div class="flex-auto">{{ 'label.auto.renewal.email' | transloco }}</div>-->
<!--        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer mr-4"></i>-->
<!--      </div>-->
<!--      <div>-->
<!--        <app-switch formControlName="tariffInvoiceEmail"></app-switch>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="flex w-full py-4 border-t border-b border-gray-300 justify-between">-->
<!--      <div class="flex items-center flex-auto">-->
<!--        <div class="flex-auto">{{ 'label.invoices.email' | transloco }}</div>-->
<!--        <i class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ml-1 cursor-pointer mr-4"></i>-->
<!--      </div>-->
<!--      <div>-->
<!--        <app-switch formControlName="packageInvoiceEmail"></app-switch>-->
<!--      </div>-->
<!--    </div>-->

  </div>
  <div class="pt-5">
    <button class="button w-full" [class.loading]="pending" [disabled]="pending || form.invalid" (click)="saveNotifications()">{{ 'button.save' | transloco }}</button>
  </div>
</div>
