import { Component, inject, Input, OnInit } from '@angular/core';
import { RoomTranscript } from '@shared/transport.interface';
import { ShowTranscriptComponent } from '@shared/component/show-transcript/show-transcript.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { RoomService } from '@shared/service/room.service';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-show-transcript-dialog',
  standalone: true,
  imports: [
    ShowTranscriptComponent,
    LoaderComponent
  ],
  templateUrl: './show-transcript-dialog.component.html',
  styleUrl: './show-transcript-dialog.component.scss'
})
export class ShowTranscriptDialogComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  transcript: RoomTranscript;
  loading = false;

  private _roomService = inject(RoomService);

  ngOnInit(): void {
    if (this.data) {
      this.transcript = this.data.transcript;
      if (!this.data.load) {
        return;
      }
      this.loading = true;
      this._roomService.getTranscript(this.transcript.id)
        .pipe(untilDestroyed(this), finalize(() => this.loading = false))
        .subscribe(res => {
          this.transcript = res;
        });
    }
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }

}
