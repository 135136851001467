<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ 'title.help' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closePage()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="flex p-5 flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white common-text">
        <!--      <div class="w-full">-->
        <!--        <mat-form-field class="w-full mt-[-20px] mb-5" subscriptSizing="dynamic">-->
        <!--          <mat-label>{{ 'label.search' | transloco }}</mat-label>-->
        <!--          <i matPrefix class="fa-solid fa-magnifying-glass pr-2 text-gray-300"></i>-->
        <!--          <input matInput [formControl]="searchCtrl"/>-->
        <!--        </mat-form-field>-->
        <!--      </div>-->
        @for (item of (helpItems | search: '_title': searchCtrl.value); track item.title; let first = $first; let last = $last) {
          <div class="w-full" [ngClass]="{'border-b border-b-gray-300': !last}" [class.pt-5]="!first">
            <div class="flex justify-between items-center">
              <div class="title text-3xl">{{ item.title | transloco }}</div>
              <div class="pl-3 min-w-[40px]">
                <ion-icon [name]="item.state === 'expanded' ? 'remove-circle-outline' : 'add-circle-outline'"
                          class="cursor-pointer active text-4xl"
                          (click)="item.state = item.state === 'expanded' ? 'collapsed' : 'expanded'"
                ></ion-icon>
              </div>
            </div>
            <div class="text-base overflow-hidden mt-5" [class.pb-5]="item.state === 'expanded'" [@expandCollapse]="item.state">{{ item.description | transloco }}</div>
          </div>
        }
      </div>
    </ion-content>
  </div>
</div>
