<div class="flex flex-col w-full flex-auto p-5">
  <div class="flex flex-col w-full flex-auto bg-white py-2.5">
    <div class="flex justify-between px-4">
      <div>{{ 'label.participants.count' | transloco: { count: roomParticipants?.participants?.length } }}</div>
      @if (me().id === room?.creator.id) {
        <div class="error-text cursor-pointer" (click)="blockedUsers()">{{ 'label.blocked' | transloco: { count: roomParticipants?.blocked?.length } }}</div>
      }
    </div>
    <ion-content>
      <div class="relative flex flex-col w-full px-4">
        @if (loading) {
          <app-loader></app-loader>
        } @else {
          @if (roomParticipants?.participants?.length) {
            <div class="w-full mt-2.5">
              @for (user of roomParticipants?.participants; track user.id) {
                <ion-item-sliding>
                  <ion-item>
                    <ion-label>
                      <div class="py-2.5 border-t border-t-gray-300 flex justify-between items-center">
                        <app-user-card [me]="user"></app-user-card>
                        <div class="flex items-center">
                          <div class="text-base link-color">
                            @if (user.id === room?.creator.id) {
                              <b>{{ 'label.role.COMPANY_HOST' | transloco }}</b>
                            }
                          </div>
                        </div>
                      </div>
                    </ion-label>
                  </ion-item>
                  <ion-item-options>
                    @if (user.id !== me().id && me().id === room?.creator.id) {
                      <ion-item-option color="danger" (click)="blockUser(user)" [disabled]="loading">{{ 'label.block.and.delete' | transloco }}</ion-item-option>
                    } @else if (user.id === me().id && me().id === room?.creator.id) {
                      <ion-item-option (click)="exitRoom()" [disabled]="loading">{{ 'button.exit' | transloco }}</ion-item-option>
                    }
                  </ion-item-options>
                </ion-item-sliding>
              }
            </div>
          } @else {
            <div class="flex flex-col justify-center mt-5 items-center text-center">
              <i class="fa-solid fa-magnifying-glass fa-3x"></i>
              <div class="title text-2xl mt-2.5">{{ 'title.no.participants' | transloco }}</div>
            </div>
          }
        }
      </div>
    </ion-content>
  </div>
</div>
