import { Component, Input } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { UtcDatePipe } from '../../../pipe/utc-date.pipe';
import { Invoice, TariffEnum } from '../../../transport.interface';

@Component({
  selector: 'app-invoice-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    TranslocoPipe,
    UtcDatePipe
  ],
  templateUrl: './invoice-card.component.html',
  styleUrl: './invoice-card.component.scss'
})
export class InvoiceCardComponent {
  @Input() invoice: Invoice;
  protected readonly TariffEnum = TariffEnum;
}
