import { Component, inject, input, OnChanges, output, signal, SimpleChanges } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { ChatMessagesComponent } from '../chat-messages/chat-messages.component';
import { LoaderComponent } from '../loader/loader.component';
import { ChatMessageDto, ChatMessages, Paging, Room } from '../../transport.interface';
import { InfiniteScrollCustomEvent, IonicModule } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { RoomService } from '@service/room.service';

@UntilDestroy()
@Component({
  selector: 'app-summary-chat',
  standalone: true,
  imports: [
    ChatMessagesComponent,
    TranslocoPipe,
    LoaderComponent,
    IonicModule
  ],
  templateUrl: './summary-chat.component.html',
  styleUrl: './summary-chat.component.scss'
})
export class SummaryChatComponent implements OnChanges {
  private readonly _roomService = inject(RoomService);

  protected chatMessages: ChatMessages;
  protected start: boolean;
  protected loading: boolean;
  protected topEmpty = signal(false);
  protected bottomEmpty = signal(false);
  protected topLoading = signal(false);
  protected bottomLoading = signal(false);

  room = input.required<Room>();
  end = input<boolean>();
  dateFrom = input.required<number>();

  messageSelected = output<ChatMessageDto>();

  protected _topPaging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 15,
  };
  protected _bottomPaging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 15,
  };
  private firstLoading = signal(true);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateFrom) {
      this.chatMessages = null;
      this.topEmpty.set(false);
      this.bottomEmpty.set(false);
      this._topPaging.currentPage = 1;
      this.loading = true;
      this.loadFromMessages(null, changes.dateFrom.currentValue, true, true);
    }
  }

  messageSelectedEvent($event: ChatMessageDto): void {
    this.messageSelected.emit($event);
  }

  loadTopMessages($event: InfiniteScrollCustomEvent) {
    if (this.topLoading() || this.topEmpty()) {
      $event.target.complete();
      return;
    }
    if (!this.firstLoading()) {
      this._topPaging.currentPage++;
    } else {
      this.firstLoading.set(false);
    }
    this.loadFromMessages($event, this.dateFrom(), true);
  }

  loadBottomMessages($event: InfiniteScrollCustomEvent) {
    if (this.bottomLoading() || this.bottomEmpty()) {
      $event.target.complete();
      return;
    }
    $event.target.complete();
    this._bottomPaging.currentPage++;
    this.loadFromMessages($event, this.dateFrom(), false);
  }

  private loadFromMessages(scroll: InfiniteScrollCustomEvent, date: number, start: boolean, firstTime = false): void {
    if (start) {
      if (this.topEmpty()) {
        return;
      }
      this.topLoading.set(true);
    } else {
      if (this.bottomEmpty()) {
        return;
      }
      this.bottomLoading.set(true);
    }
    this._roomService.summaryMessages(this.room().roomId, date, firstTime ? false : start, firstTime ? this.end() : null,
      start ? this._topPaging.pageSize : this._bottomPaging.pageSize,
      start ? (this._topPaging.currentPage - 1) * this._topPaging.pageSize : (this._bottomPaging.currentPage - 1) * this._bottomPaging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => {
        this.loading = false;
        this.topLoading.set(false);
        this.bottomLoading.set(false);
        if (scroll) {
          scroll.target.complete();
        }
      }))
      .subscribe(res => {
        if (!this.chatMessages) {
          this.chatMessages = res;
          if (this.end()) {
            this.chatMessages.list = this.chatMessages.list.reverse();
          }
        } else {
          if (start) {
            this.chatMessages.list = res.list.reverse().concat(this.chatMessages.list);
          } else {
            if (this.end()) {
              res.list = res.list.reverse();
            }
            this.chatMessages.list = this.chatMessages.list.concat(res.list);
          }
        }
        this.chatMessages.total = res.total;
        if (firstTime) {
          return;
        }
        if (start) {
          if (this.chatMessages.total < this._topPaging.pageSize || res.list.length < this._topPaging.pageSize) {
            this.topEmpty.set(true);
          }
        } else {
          if (this.chatMessages.total < this._bottomPaging.pageSize || res.list.length < this._bottomPaging.pageSize) {
            this.bottomEmpty.set(true);
          }
        }
      });
  }
}
