@for (t of text; track t; let first = $first; let last = $last; let i = $index) {

  @if (first || ((text[i - 1].time * 1000) | date: 'd') !== ((t.time * 1000) | date: 'd')) {
    <div
      class="my-3 flex items-center justify-center"
    >
      <div class="flex-auto border-b border-b-gray-300"></div>
      <div
        class="text-secondary mx-4 flex-0 text-sm font-semibold leading-5"
      >
        {{
          (t.time * 1000) | date: 'dd.MM.yyyy'
        }}
      </div>
      <div class="flex-auto border-b border-b-gray-300"></div>
    </div>
  }

  @if (t.type === ChatMessageType.MESSAGE) {
    <ng-container *ngTemplateOutlet="chatMessage; context: {$implicit: t}"></ng-container>
  } @else if (t.type === ChatMessageType.USER_JOIN) {
    <ng-container *ngTemplateOutlet="userJoinedMessage; context: {$implicit: t}"></ng-container>
  } @else if (t.type === ChatMessageType.USER_LEFT) {
    <ng-container *ngTemplateOutlet="userLeftMessage; context: {$implicit: t}"></ng-container>
  }
}

<ng-template #chatMessage let-t>
  <div class="flex relative items-start max-w-full mb-4" (click)="selectChatMessage(t)" [class.inactive-msg]="selectedMessage && selectedMessage.time !== t.time">
    @if (t.userId !== me().id) {
      <div class="lang ltr:mr-2 rtl:ml-2">
        <ng-container *ngTemplateOutlet="avatarLetters; context: {$implicit: t.name, avatarId: t.avatarId}"></ng-container>
      </div>
    }
    <div
      class="flex flex-col flex-auto max-w-full"
      [ngClass]="{'items-end': t.userId === me().id, 'items-start': t.userId !== me().id}">
      @if (t.userId !== me().id) {
        <div class="text-xl title font-semibold">{{ t.name }}</div>
      }
      <div
        class="relative min-w-44 w-full px-3 pb-2 pt-2.5"
        [ngClass]="{'chat-my-msg-bg text-white': t.userId === me().id, 'chat-msg-bg': t.userId !== me().id}">

        @if (me().language === t.originalLang) {
          <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.originalText"></div>
        } @else {
          <div class="leading-5 wrap text-base chat-msg" [innerHTML]="t.szdMessages && t.szdMessages[t.users[me().id]] || ''"></div>
        }

        <div class="flex justify-between items-center mt-2.5">
          <div class="text-xs chat-msg-date-color">
            {{ (t.time * 1000) | date: 'HH:mm' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userJoinedMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4" (click)="selectChatMessage(message)">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.joined.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>

<ng-template #userLeftMessage let-message>
  <div class="w-full flex justify-between user-joined-message-bg py-1 px-1.5 text-sm mb-4" (click)="selectChatMessage(message)">
    <div class="flex items-center">
      <div>
        <app-initials-avatar [bgColor]="'chat-avatar-bg-color'" [user]="{name: message.name}" [width]="20" [textSize]="'text-[8px]'"></app-initials-avatar>
      </div>
      <div class="ltr:ml-2 rtl:mr-2" [innerHTML]="'text.user.left.chat' | transloco: {user: message.name}"></div>
    </div>
    <div class="time-color">{{ (message.time * 1000) | date: 'HH:mm' }}</div>
  </div>
</ng-template>

<ng-template let-lang let-creator="creator" let-muted="muted" let-avatarId="avatarId" #avatarLetters>
  <app-initials-avatar [avatarId]="avatarId" [user]="{name: lang}" [muted]="muted" [creator]="creator" [width]="30" [textSize]="'text-xs'"></app-initials-avatar>
</ng-template>
