import { Component, effect, inject, OnInit, Signal, viewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { IonicModule, Platform } from '@ionic/angular';
import { NotyService } from '@service/noty.service';
import { LangService } from '@service/lang.service';
import { RoomService } from '@service/room.service';
import { RoomMode, RoomUser, UserRole } from '../../transport.interface';
import { UserState } from '../../store/user/user-state.service';
import { Pages } from '@app/pages';
import { IonMenu } from '@ionic/angular/standalone';
import { UserCardComponent } from '../ui/user-card/user-card.component';
import { TariffInfoComponent } from '../tariff-info/tariff-info.component';
import { addIcons } from 'ionicons';
import { chatbubbleOutline, chatbubblesOutline, closeOutline, personAddOutline, personCircleOutline, qrCodeOutline } from 'ionicons/icons';
import { DialogService } from '@service/dialog.service';
import { StorageService } from '@service/storage.service';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgTemplateOutlet,
    NgOptimizedImage,
    IonicModule,
    UserCardComponent,
    TariffInfoComponent
  ],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss'
})
export class NavMenuComponent implements OnInit {
  protected _roomService = inject(RoomService);
  protected mainMenu = viewChild<IonMenu>('mainMenu');

  private _platform = inject(Platform);
  private _router = inject(Router);
  private _dialogService = inject(DialogService);
  private _store = inject(Store);
  private _notyService = inject(NotyService);
  private _langService = inject(LangService);
  private _storageService = inject(StorageService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly UserRole = UserRole;
  protected logged = false;
  protected readonly Pages = Pages;
  protected pending = false;
  protected hideInstallButton = false;
  protected isMobile = this._platform.is('mobile');

  constructor() {
    addIcons({ closeOutline, qrCodeOutline, chatbubbleOutline, chatbubblesOutline, personAddOutline, personCircleOutline });
    this._storageService.logged().then(logged => {
      this.logged = logged && this.me()?.role !== UserRole.TEMP_USER;
    });
    effect(() => {
      this.logged = !!this.me()?.id;
    });
  }

  ngOnInit(): void {
    this.checkPWAInstallation();
  }

  buyMinutes(): void {
    this.mainMenu()?.close(true);
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this._dialogService.buyPackage(this.me().tariff);
  }

  navigate(page?: Pages): void {
    this._router.navigate([ '/', page || '' ]);
    this.mainMenu()?.close(true);
  }

  openRoomsHistory(): void {
    this.mainMenu()?.close(true);
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this._dialogService.roomsHistory();
  }

  openProfile(): void {
    if (!this.logged) {
      this._router.navigate([ '/', Pages.LOGIN ]);
      return;
    }
    this.mainMenu()?.close(true);
    this._dialogService.profile().then();
  }

  joinRoomByQR(): void {
    this.mainMenu()?.close(true);
    this._dialogService.qrCodeScanner().subscribe(code => {
      if (code) {
        this._router.navigate([ '/', Pages.JOIN_ROOM, code ]);
      }
    });
  }

  startChat(mode: RoomMode): void {
    if (this.me().role === UserRole.TEMP_USER || !this.me()?.id) {
      this._notyService.success('message.register');
      return;
    }
    // this._audioService.initAudioForIos();
    this.pending = true;
    this._roomService.joinMyRoom(mode)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe({
        next: () => {
          this._storageService.clearRoomId();
          this.mainMenu()?.close(true);
          let m = mode === RoomMode.SINGLE ? 'single' : 'conference';
          this._router.navigate([ '/', Pages.CHAT ], { queryParams: { mode: m } });
        }, error: err => {
          this._notyService.error(err.message);
        }
      });
  }

  openPage(page: Pages): void {
    this.mainMenu()?.close(true);
    // this._dialogService.closeAll();
    this._router.navigate([ '/', page ]);
  }

  openHelp(): void {
    this.mainMenu()?.close(true);
    this._dialogService.help();
  }

  openTerms(): void {
    this.mainMenu()?.close(true);
    this._dialogService.terms();
  }

  isRtl(): boolean {
    return this._langService.isActiveLangRtl();
  }

  installApp(): void {
    this.mainMenu()?.close(true);
    // this._dialogService.closeAll();
    this._storageService.delete('hide_pwa');
    // this._dialogService.installAppEvent(true);
  }

  async checkPWAInstallation() {
    if ('getInstalledRelatedApps' in window.navigator) {
      // @ts-ignore
      const apps = await navigator.getInstalledRelatedApps();
      apps.forEach((app: any) => {
        if (app.url.indexOf('voice') >= 0) {
          this.hideInstallButton = true;
        }
      });
    }
    // @ts-ignore
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      this.hideInstallButton = true;
    }
  }

  protected readonly RoomMode = RoomMode;

  completeRegistration(): void {
    this.mainMenu()?.close(true);
    this._dialogService.completeRegistration();
  }
}
