import { Component, inject, Input, OnInit, Signal, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatMessageDto, ChatMessagesHistory, ChatMessageType, Paging, RoomMode, RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { Store } from '@ngxs/store';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { finalize } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { PrettyDatePipe } from '@shared/pipe/pretty-date.pipe';
import { FullScreenViewer } from 'iv-viewer';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { RoomService } from '@shared/service/room.service';
import { IonButtons, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline, languageOutline } from 'ionicons/icons';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-room-messages',
  standalone: true,
  imports: [
    LoaderComponent,
    InitialsAvatarComponent,
    NgClass,
    TranslocoPipe,
    DatePipe,
    NgTemplateOutlet,
    MatIconButton,
    AsyncPipe,
    PrettyDatePipe,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle
  ],
  templateUrl: './room-messages.component.html',
  styleUrls: [ './room-messages.component.scss' ]
})
export class RoomMessagesComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  @ViewChild('scrollArea', { static: true })
  private scrollbar: IonContent;

  private _store = inject(Store);
  protected _roomService = inject(RoomService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  protected showOriginalTexts = {};
  protected copyLang = '';
  protected copyTime = 0;
  protected loading = false;
  protected loadingMore = false;
  protected empty = false;
  protected _roomId: string;
  protected roomMode = RoomMode.SINGLE;
  protected history: ChatMessagesHistory;
  protected paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  viewer: any;

  constructor() {
    addIcons({ closeOutline, languageOutline, calendarOutline });
    this.viewer = new FullScreenViewer();
  }

  ngOnInit(): void {
    if (this.data) {
      this.history = null;
      this.loadingMore = true;
      this.empty = false;
      this.paging.currentPage = 1;
      this._roomId = this.data.roomId;
      this.loading = true;
      this.loadMore(this._roomId, null);
    }
  }

  loadMoreMessages(ev: InfiniteScrollCustomEvent): void {
    console.log('[loadMoreMessages]');
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this.paging.currentPage++;
    this.loadMore(this._roomId, ev, true);
  }

  private loadMore(id: string, ev: InfiniteScrollCustomEvent, saveScrollbarPos: boolean = false): void {
    this._roomService.roomMessages(id, this.paging.pageSize, (this.paging.currentPage - 1) * this.paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => {
        this.loading = false;
        this.loadingMore = false;
      }))
      .subscribe(res => {
        if (res.room?.users?.length > 1) {
          this.roomMode = RoomMode.MULTI;
        }
        this.paging.totalPages = res.messages.total;
        if (!this.history) {
          this.history = res;
        } else {
          this.history.messages.list = res.messages.list.concat(this.history.messages.list);
        }
        if (!saveScrollbarPos) {
          this.scrollToBottom();
        }
        if (!res.messages.list.length || res.messages.list.length < this.paging.pageSize) {
          this.empty = true;
        }
        if (ev) {
          ev.target.complete().then();
        }
      });
  }

  showOriginalText(msg: ChatMessageDto): void {
    this.showOriginalTexts[ msg.time ] = !this.showOriginalTexts[ msg.time ];
  }

  copyText(lang: string, text: string, time: number): void {
    if (this.copyLang && this.copyTime) {
      return;
    }
    Clipboard.write({ string: text }).then();
    this.copyLang = lang;
    this.copyTime = time;
    setTimeout(() => {
      this.copyLang = '';
      this.copyTime = 0;
    }, 2000);
  }

  protected readonly ChatMessageType = ChatMessageType;

  goBack(): void {
    this.closeModal();
    // this._router.navigate([ '/', Pages.HISTORY ]);
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.scrollbar.scrollToBottom().then();
    }, 10);
  }

  getFirstValue(msg: {}): string {
    return Object.values(msg)[ 0 ] + '';
  }

  getFirstKey(msg: {}): string {
    return Object.keys(msg)[ 0 ] + '';
  }

  protected readonly RoomMode = RoomMode;

  showImage(s: string) {
    this.viewer.show(s);
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }

}
