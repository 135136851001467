import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { Tariff, TariffPackage } from '@shared/transport.interface';
import { UserService } from '@service/user.service';
import { IonicModule } from '@ionic/angular';
import { LoaderComponent } from '@comp/loader/loader.component';
import { MinutesPackageCardComponent } from '@comp/minutes-package-card/minutes-package-card.component';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-buy-package',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule,
    LoaderComponent,
    MinutesPackageCardComponent,

  ],
  templateUrl: './buy-package.component.html',
  styleUrl: './buy-package.component.scss'
})
export class BuyPackageComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() tariff: Tariff;

  loading = false;
  tariffPackages: TariffPackage[];


  private _userService = inject(UserService);

  ngOnInit(): void {
    if (this.tariff) {
      this.loading = true;
      this._userService.tariffPackages(this.tariff.id)
        .pipe(untilDestroyed(this), finalize(() => this.loading = false))
        .subscribe(res => {
          this.tariffPackages = res;
        });
    }
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }
}
