<ion-icon name="close-outline" class="text-4xl close-icon z-99999" (click)="closeModal()"></ion-icon>
<div class="w-full flex flex-col justify-center overflow-hidden p-5">
  <div class="w-full flex flex-col flex-auto overflow-hidden mt-12">
    <div class="w-full flex-auto overflow-y-auto">
      @for (type of chatResultTypes; track type; let ind = $index) {
        <div (click)="selectResult(type)" class="w-full px-2.5 py-3.5 border-b border-b-gray-300 lang-item" [class.border-t-gray-300]="ind === 0" [class.border-t]="ind === 0"
             [class.active]="selectedType === type">
          <div class="">{{ 'title.chat.result.type.' + type | transloco }}</div>
        </div>
      }
    </div>
  </div>
</div>
