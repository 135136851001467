<div class="flex flex-col flex-auto w-full min-h-[250px] border border-gray-300 relative p-2.5">
  @if (loading) {
    <app-loader></app-loader>
  }
  @if (chatMessages?.list?.length) {
    @if (chatMessages.total > _topPaging.pageSize && !topEmpty()) {
      <ion-infinite-scroll (ionInfinite)="loadTopMessages($event)" position="top">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    }
    <app-chat-messages [text]="chatMessages?.list" (messageSelected)="messageSelectedEvent($event)"></app-chat-messages>
    @if (chatMessages.total > _bottomPaging.pageSize && !bottomEmpty()) {
      <ion-infinite-scroll (ionInfinite)="loadBottomMessages($event)" position="bottom">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    }
  } @else {
    <div class="flex flex-col justify-center items-center w-full flex-auto">
      @if (!end()) {
        <div class="flex flex-col w-full h-full p-5 items-center justify-center">
          <i class="fa-regular fa-comment-dots fa-3x"></i>
          <div class="mt-2.5 flex flex-col items-center justify-center">
            <div class="title text-2xl">{{ 'text.select.start.date' | transloco }}</div>
            <div class="mt-1 text-center">{{ 'text.start.date.messages' | transloco }}</div>
          </div>
        </div>
      } @else {
        <div class="flex flex-col w-full h-full p-5 items-center justify-center">
          <i class="fa-regular fa-comment-dots fa-3x"></i>
          <div class="mt-2.5 flex flex-col items-center justify-center">
            <div class="title text-2xl">{{ 'text.select.end.date' | transloco }}</div>
            <div class="mt-1 text-center">{{ 'text.start.date.messages' | transloco }}</div>
          </div>
        </div>
      }
    </div>
  }
</div>
