import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { QrCodeComponent } from '../../ui/qr-code/qr-code.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline, copyOutline, shareOutline } from 'ionicons/icons';

@Component({
  selector: 'app-qr-code-dialog',
  standalone: true,
  imports: [
    QrCodeComponent,
    MatIconButton,
    MatIcon,
    TranslocoPipe,
    IonicModule,
  ],
  templateUrl: './qr-code-dialog.component.html',
  styleUrl: './qr-code-dialog.component.scss'
})
export class QrCodeDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected url: string = window.location.protocol + "//" + window.location.host + '/join-room/';

  roomId: string;
  isCompany: boolean;

  protected readonly navigator = navigator;

  constructor() {
    addIcons({ closeOutline, copyOutline, shareOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.roomId = this.data.roomId;
      this.url += this.roomId;
      this.isCompany = this.data.isCompany;
    }
  }

  shareUrl(): void {
    if (!navigator.canShare) {
      // alert('Can not share');
    } else if (navigator.canShare({
      title: 'FINEXWORD: Speak freely. Translate Instantly.',
      url: this.url
    })) {
      navigator.share({
        title: 'FINEXWORD: Speak freely. Translate Instantly.',
        url: this.url
      });
    } else {
      // alert("Specified data cannot be shared.");
    }
  }

  copyUrlFlag = false;

  copyUrl(): void {
    Clipboard.write({ string: this.url }).then();
    this.copyUrlFlag = true;
    setTimeout(() => this.copyUrlFlag = false, 2000);
  }
}
