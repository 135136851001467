import { Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HistoryDataType, HistoryFilter, SelectItem } from '@shared/transport.interface';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DialogService } from '@shared/service/dialog.service';
import _ from 'lodash';
import { SelectboxComponent } from '@shared/component/ui/selectbox/selectbox.component';
import { IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { SummaryHistoryComponent } from '@dialog/my-documents/summary-history/summary-history.component';
import { ProtocolHistoryComponent } from '@dialog/my-documents/protocol-history/protocol-history.component';
import { TranscriptHistoryComponent } from '@dialog/my-documents/transcript-history/transcript-history.component';

@UntilDestroy()
@Component({
  selector: 'app-my-documents',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe,
    SelectboxComponent,
    SummaryHistoryComponent,
    ProtocolHistoryComponent,
    TranscriptHistoryComponent,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle
  ],
  templateUrl: './my-documents.component.html',
  styleUrl: './my-documents.component.scss'
})
export class MyDocumentsComponent {
  private _modalCtrl = inject(ModalController);

  private _dialogService = inject(DialogService);
  protected filterChanged = false;

  filtersCount = 0;
  loading = false;
  searchCtrl = new FormControl<string>('');
  filter: HistoryFilter;
  dataTypes: SelectItem[] = [
    { id: HistoryDataType.SUMMARY, title: `title.data.type.${ HistoryDataType.SUMMARY }` },
    { id: HistoryDataType.TRANSCRIPT, title: `title.data.type.${ HistoryDataType.TRANSCRIPT }` },
    { id: HistoryDataType.PROTOCOL, title: `title.data.type.${ HistoryDataType.PROTOCOL }` },
  ];
  dataTypesCtrl: FormControl<SelectItem> = new FormControl<SelectItem>(this.dataTypes[ 0 ]);

  constructor() {
  }

  changeDataType($event: SelectItem): void {
    this.filterChanged = false;
    this.dataTypesCtrl.setValue($event);
  }

  protected readonly HistoryDataType = HistoryDataType;

  showFilter(): void {
    this.filterChanged = false;
    this._dialogService.historyFilter(this.filter).then(res => {
      if (res) {
        if (!_.isEqual(this.filter, res)) {
          this.filter = res;
          this.filterChanged = true;
        }
        this.filtersCount = 0;
        if (this.filter.dateFilter?.fromDate || this.filter.dateFilter?.toDate) {
          this.filtersCount++;
        }
      }
    });
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }
}
