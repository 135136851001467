import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { AuthResult, RefreshToken, RoomUser, UserRole } from '@shared/transport.interface';
import { ApiUrl } from '@shared/api-url';
import { HttpClientService } from '@shared/http-client/http-client.service';
import { appComponentRef } from '@shared/app-component';
import { StorageService } from '@service/storage.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Store } from '@ngxs/store';
import { SaveUserAction } from '@shared/store/user/user.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  tokenRefreshInterval = -1;
  loggedIn: WritableSignal<boolean> = signal(false);

  private readonly _http = inject(HttpClientService);
  private readonly _storageService = inject(StorageService);
  private readonly _store = inject(Store);

  /**
   * Constructor
   */
  constructor() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  signUp(email: string, firstName: string, lastName: string, language: string): Observable<RoomUser> {
    const { REGISTER } = ApiUrl;
    return fromPromise(this._storageService.externalId())
      .pipe(
        switchMap((externalId: string) => {
          return this._http.doPostSilent<RoomUser>(REGISTER, { email, externalId, firstName, language, lastName });
        })
      );
  }

  completeRegistration(email: string, firstName: string, lastName: string): Observable<RoomUser> {
    return this._http.doPostSilent(ApiUrl.COMPLETE_REGISTRATION, { email, firstName, lastName });
  }

  completeGoogleRegistration(accessToken: string): Observable<RoomUser> {
    return this._http.doPostSilent(ApiUrl.COMPLETE_GOOGLE_REGISTRATION, { accessToken });
  }

  companyUserSignUp(email: string, firstName: string, lastName: string, companyId: string, language: string): Observable<RoomUser> {
    return this._http.doPostSilent(ApiUrl.COMPANY_USER_REGISTER, { email, firstName, lastName, companyId, language });
  }

  createTempUser(name: string, language: string): Observable<AuthResult> {
    return fromPromise(this._storageService.externalId()).pipe(
      switchMap(externalId => {
        return this._http.doPostSilent<AuthResult>(ApiUrl.TEMP_USER, { firstName: name, language, externalId })
          .pipe(
            switchMap((response: AuthResult) => {
              this._storageService.saveAccessToken(response.token);
              this._storageService.saveAccessTokenTTL(response.accessValid);
              this._storageService.saveUser(response.user);
              if (response.user.externalId) {
                this._storageService.saveExternalId(response.user.externalId);
              }
              this.loggedIn.set(true);
              return of(response);
            })
          );
      })
    );
  }

  signIn(email: string, password: string): Observable<AuthResult> {
    return this._http.doPostSilent<AuthResult>(ApiUrl.LOGIN, { email, password })
      .pipe<AuthResult>(
        switchMap((response: AuthResult) => {
          this._storageService.saveAccessToken(response.token);
          this._storageService.saveAccessTokenTTL(response.accessValid);
          this._storageService.saveUser(response.user);
          this._store.dispatch(new SaveUserAction(response.user));
          this.loggedIn.set(true);
          return of(response);
        })
      );
  }

  googleSignIn(credentials: string, token: string, lang: string, externalId: string): Observable<AuthResult> {
    return this._http.doGetNoError(ApiUrl.GOOGLE_LOGIN, { credentials, accessToken: token, lang, externalId })
      .pipe(
        switchMap((response: any) => {
          this._storageService.saveAccessToken(response.token);
          this._storageService.saveUser(response.user);
          this.loggedIn.set(true);
          return of(response);
        })
      );
  }

  resetPassword(token: string, password: string, passwordConfirm: string): Observable<void> {
    return this._http.doPostSilent(ApiUrl.RESET_PASSWORD + '?token=' + token, { password, passwordConfirm });
  }

  forgotPassword(email: string): Observable<void> {
    return this._http.doPostSilent(ApiUrl.FORGOT_PASSWORD, { email });
  }

  refreshToken(): Observable<RefreshToken> {
    return this._http.doGet<RefreshToken>(ApiUrl.REFRESH_TOKEN, { token: this._storageService.accessToken() })
      .pipe(switchMap(token => {
        this._storageService.saveAccessToken(token.accessToken);
        return of(token);
      }));
  }

  /**
   * Sign out
   */
  signOut(): Observable<void> {
    return this._http.doGet<void>(ApiUrl.LOGOUT)
      .pipe(switchMap(response => {
        this._storageService.clear();
        return of(response);
      }));
  }

  check(): Observable<boolean> {
    return this._storageService.logged$();
  }

  role(): Observable<UserRole | undefined> {
    return this._storageService.getUser$()?.pipe(map(user => user?.role));
  }

  async accessToken(): Promise<string> {
    return await this._storageService.accessToken();
  }

  async refreshTokenPeriodically() {
    if (await this._storageService.accessTokenTTL() > 0) {
      if (this.tokenRefreshInterval >= 0) {
        clearInterval(this.tokenRefreshInterval);
      }
      this.tokenRefreshInterval = +setInterval(() => {
        this.refreshToken().subscribe({
          next: () => {
          },
          error: () => {
            if (this.tokenRefreshInterval >= 0) {
              clearInterval(this.tokenRefreshInterval);
            }
            appComponentRef().invalidSessionEvent();
          }
        });
      }, (await this._storageService.accessTokenTTL() / 4 * 1000));
    }
  }

  stopRefreshTokenPeriodically(): void {
    if (this.tokenRefreshInterval >= 0) {
      clearInterval(this.tokenRefreshInterval);
    }
    this.tokenRefreshInterval = -1;
  }

  inviteUser(ref: string, userUid: string): Observable<AuthResult> {
    return this._http.doGetNoError<AuthResult>(ApiUrl.INVITE, { ref, userUid })
      .pipe<AuthResult>(
        switchMap((response: AuthResult) => {
          this._storageService.saveAccessToken(response.token);
          this._storageService.saveAccessTokenTTL(response.accessValid);
          this._storageService.saveUser(response.user);
          this.loggedIn.set(true);
          return of(response);
        })
      );
  }

  createTmpUser(deviceId: string, lang: string): Observable<AuthResult> {
    return this._http.doGetNoError<AuthResult>(ApiUrl.TMP_USER_BY_DEVICE, { deviceId, lang })
      .pipe<AuthResult>(
        switchMap((response: AuthResult) => {
          this._storageService.saveAccessToken(response.token);
          this._storageService.saveAccessTokenTTL(response.accessValid);
          this._storageService.saveUser(response.user);
          this.loggedIn.set(true);
          return of(response);
        })
      );
  }
}
