import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { AIChatError } from '@shared/transport.interface';
import { ErrorObject } from '@shared/http-client/error-object.class';

@Component({
  selector: 'app-chat-error',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './chat-error.component.html',
  styleUrl: './chat-error.component.scss'
})
export class ChatErrorComponent {
  @Input() error: AIChatError | ErrorObject;
  @Input() translate = false;
  @Input() position: 'top' | 'bottom' = 'bottom';
}
