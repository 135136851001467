import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true
})
export class SearchPipe implements PipeTransform {

  transform(data: any[], filterProperty: string, filter: string): any[] {
    if (!filter) {
      return data;
    }
    const filterValue = filter.toLowerCase();
    return filterValue
      ? data.filter(item => item[ filterProperty ].toLowerCase().includes(filterValue))
      : data;
  }

}
