<div class="flex flex-col justify-center items-center self-center flex-auto p-5">
  <div class="title text-2xl">{{'title.qr.scan' | transloco}}</div>
  <div class="mt-2 mb-5">{{'text.qr.scan' | transloco}}</div>

  <div class="flex justify-center items-center w-full w-max-[300px] mt-6">
    <button class="button w-full" (click)="askAccess()">
      {{ 'button.continue.to.camera' | transloco }}
    </button>
  </div>
</div>
