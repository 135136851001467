<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ 'title.terms' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closePage()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="flex p-5 flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white">
        <div class="flex flex-col common-text" [innerHTML]="'text.terms' | transloco">
        </div>
      </div>
    </ion-content>
  </div>
</div>
