import { Component, EventEmitter, inject, input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap } from 'rxjs';
import { InitializeOptions, LoginResult, SocialLogin } from '@capgo/capacitor-social-login';
import { AuthResult, UserRole } from '@shared/transport.interface';
import { Pages } from '@app/pages';
import { AuthService } from '@shared/service/auth/auth.service';
import { LangService } from '@shared/service/lang.service';
import { StorageService } from '@service/storage.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { IonicModule, Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { logoApple, logoGoogle } from 'ionicons/icons';
import { DialogService } from '@service/dialog.service';

export type LoginMethod = 'google' | 'apple';

@UntilDestroy()
@Component({
  selector: 'app-google-auth',
  standalone: true,
  imports: [ IonicModule ],
  templateUrl: './google-auth.component.html',
  styleUrl: './google-auth.component.scss'
})
export class GoogleAuthComponent implements OnInit {
  defaultAction = input(true);

  @Output() loggingIn: EventEmitter<{ token: string, method: LoginMethod, firstName: string, lastName: string }> = new EventEmitter<{
    token: string,
    method: LoginMethod,
    firstName: string,
    lastName: string
  }>();
  @Output() loginComplete: EventEmitter<void> = new EventEmitter<void>();
  @Output() errorEvent: EventEmitter<void> = new EventEmitter<void>();

  user: any = null;
  redirectUrl: string = '';

  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _authService = inject(AuthService);
  private readonly _langService = inject(LangService);
  private readonly _storageService = inject(StorageService);
  private readonly _platform = inject(Platform);
  private readonly _dialogService = inject(DialogService);
  protected isIos = false;

  constructor() {
    addIcons({ logoApple, logoGoogle });
    this._route.queryParams.pipe(untilDestroyed(this), filter(p => !!p[ 'redirectUrl' ])).subscribe(res => {
      this.redirectUrl = res[ 'redirectUrl' ];
    });
    this.isIos = this._platform.is('ios');
  }

  ngOnInit(): void {
    let options: InitializeOptions = {};
    if (this._platform.is('ios')) {
      options = {
        apple: {
          clientId: 'com.finexware.finexword2'
        },
        google: {
          iOSClientId: '855252381111-1b4o8506ph4650p8ibnqho7g4qbp1hk8.apps.googleusercontent.com', // the iOS client id
        },
      }
    } else {
      options = {
        google: {
          webClientId: '855252381111-fra24f36nf6pbo4is7njrh5il02use4g.apps.googleusercontent.com', // the web client id for Android and Web
        },
      };
    }
    SocialLogin.initialize(options).then(res => console.log('Google inited')).catch(err => console.log(err));
  }

  afterLogin(response: AuthResult): void {
    this.loginComplete.emit();
    this._storageService.saveAccessToken(response.token);
    this._storageService.saveAccessTokenTTL(response.accessValid);
    this._storageService.saveUser(response.user);
    if (response.user.role !== UserRole.TEMP_USER) {
      this._storageService.removeExternalId();
    }
    if (this.redirectUrl) {
      this._router.navigateByUrl(this.redirectUrl);
      return;
    }
    if (response.user.deleteTime) {
      setTimeout(() => {
        this._dialogService.showDeleteAccountReminder();
      }, 500);
    }
    if ([ UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ].indexOf(response.user.role!) >= 0) {
      this._router.navigate([ '/', Pages.COMPANY, Pages.PORTAL, Pages.DASHBOARD ]);
    } else {
      this._router.navigate([ '/', Pages.CHAT ]);
    }
  }

  signInWithGoogle() {
    SocialLogin.login({
      provider: 'google',
      options: {}
    }).then((res) => this.processLogin(res)).catch(reason => console.log(reason));
  }

  signInWithApple() {
    SocialLogin.login({
      provider: 'apple',
      options: {}
    }).then((res) => this.processAppleLogin(res)).catch(reason => console.log(reason));
  }

  private async processLogin(res: LoginResult) {
    this.loggingIn.next({ token: res.result[ 'accessToken' ]?.token, method: 'google', firstName: null, lastName: null });
    if (!this.defaultAction()) {
      return;
    }
    return fromPromise(this._storageService.externalId())
      .pipe(
        switchMap((externalId: string) => {
          return this._authService.googleSignIn('', res.result[ 'accessToken' ]?.token, this._langService.getBrowserLang(), externalId)
        })
      )
      .subscribe({
        next: (loginRes) => {
          this.afterLogin(loginRes);
        }, error: () => {
          this.errorEvent.next();
        }
      });
  }

  private async processAppleLogin(res: LoginResult) {
    this.loggingIn.next({ token: res.result[ 'idToken' ], method: 'apple', firstName: res.result[ 'profile' ]?.givenName, lastName: res.result[ 'profile' ]?.familyName });
    if (!this.defaultAction()) {
      return;
    }
    return fromPromise(this._storageService.externalId())
      .pipe(
        switchMap((externalId: string) => {
          return this._authService.appleSignIn(res.result[ 'idToken' ], res.result[ 'profile' ]?.givenName, res.result[ 'profile' ]?.familyName,
            this._langService.getBrowserLang(), externalId)
        })
      )
      .subscribe({
        next: (loginRes) => {
          this.afterLogin(loginRes);
        }, error: () => {
          this.errorEvent.next();
        }
      });
  }
}
