import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { Store } from '@ngxs/store';
import { SiteInfoState } from '../store/site/site-state.service';

export const DefaultSiteGuard: CanActivateFn | CanActivateChildFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const siteInfo = store.selectSignal(SiteInfoState.getSiteInfo);
  if (!siteInfo().corporate) {
    return of(true);
  } else {
    const urlTree = router.parseUrl(`sign-in`);
    return of(urlTree);
  }
};
