<ion-icon name="close-outline" class="close-icon z-99999 text-4xl" (click)="closeModal()"></ion-icon>

<div class="flex flex-col justify-center flex-auto overflow-hidden">
  <div class="flex flex-auto overflow-hidden">
    <img id="image" [src]="sanitizedUrl" alt="">
  </div>
  <div class="flex justify-center mt-5">
    <button class="button mr-4 stroked" (click)="reset()">
      {{'button.reset' | transloco}}
    </button>
    <button class="button" (click)="crop()">
      {{'button.save' | transloco}}
    </button>
  </div>
</div>
