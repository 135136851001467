import { Component, inject, Input, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { finalize, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { LangService } from '@shared/service/lang.service';
import { CommonLanguage } from '@shared/transport.interface';
import { LanguagesState } from '@shared/store/languages/languages-state.service';
import { SaveLanguagesAction } from '@shared/store/languages/languages.actions';
import { LoaderComponent } from '../../loader/loader.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline, searchOutline } from 'ionicons/icons';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { StorageService } from '@service/storage.service';
import { IonContent, ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-lang-selector',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoPipe,
    LoaderComponent,
    IonicModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatPrefix
  ],
  templateUrl: './lang-selector.component.html',
  styleUrl: './lang-selector.component.scss'
})
export class LangSelectorComponent implements OnInit {
  private _modalCtrl = inject(ModalController);

  @ViewChild('scroll', { static: true })
  private scroll: IonContent;
  @Input() data: any;

  lastUsedLangs = signal<string[]>([]);

  private _langService = inject(LangService);
  private _store = inject(Store);
  private _translateService = inject(TranslocoService);
  private _storageService = inject(StorageService);

  langFilterCtrl: FormControl<string> = new FormControl<string>('');
  filteredLangs: ReplaySubject<CommonLanguage[]> = new ReplaySubject<CommonLanguage[]>(1);

  loading = false;
  language: string;
  secondLanguage: string;
  selectFirst = true;

  langsSignal: Signal<CommonLanguage[]> = this._store.selectSignal(LanguagesState.getLanguages);
  completeLangs: CommonLanguage[] = [];

  constructor() {
    addIcons({ closeOutline, searchOutline });
    this._storageService.lastUsedLangs().then(langs => {
      this.lastUsedLangs.set(langs);
    });
  }

  ngOnInit(): void {
    const translation = this._translateService.getTranslation();
    const langsMap = translation.get(this._translateService.getActiveLang());
    this.completeLangs = _.cloneDeep(this.langsSignal());
    this.completeLangs.forEach(lang => {
      if (langsMap[ lang.code ]) {
        lang.activeLangName = langsMap[ lang.code ];
      }
    });
    this.langFilterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterLangs();
      });
    if (this.data) {
      this.language = this.data.firstLang;
      this.secondLanguage = this.data.secondLang;
      this.selectFirst = this.data.selectFirst;
      if (this.selectFirst) {
        setTimeout(() => {
          if (document.getElementById(this.language)) {
            this.scroll.scrollToPoint(0, document.getElementById(this.language).offsetTop, 700);
          }
        }, 100);
      } else {
        setTimeout(() => {
          if (document.getElementById(this.secondLanguage)) {
            this.scroll.scrollToPoint(0, document.getElementById(this.secondLanguage).offsetTop, 700);
          }
        }, 100);
      }
      if (this.completeLangs?.length) {
        this.initLangs(this.completeLangs);
        return;
      }
      this.loading = true;
      this._langService.langsFroAI()
        .pipe(untilDestroyed(this), finalize(() => this.loading = false))
        .subscribe(res => {
          const ind = res.findIndex(l => l.code === this.secondLanguage);
          if (ind >= 0) {
            res.splice(ind);
          }
          this._store.dispatch(new SaveLanguagesAction(res));
          this.initLangs(res);
        });
    }
  }

  private initLangs(res: CommonLanguage[]) {
    this.filteredLangs.next(res.sort((a, b) => a.name.localeCompare(b.name)));
  }

  protected filterLangs() {
    if (!this.completeLangs?.length) {
      return;
    }
    // get the search keyword
    let search = this.langFilterCtrl.value;
    if (!search) {
      this.filteredLangs.next(this.langsSignal().slice().sort((a, b) => a.name.localeCompare(b.name)));
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLangs.next(
      this.completeLangs.filter(bank => bank.activeLangName.toLowerCase().indexOf(search) > -1).sort((a, b) => a.name.localeCompare(b.name))
    );
  }

  close(): void {
    this.closeModal({ firstLang: this.language, secondLang: this.secondLanguage });
  }

  selectLang(lang: CommonLanguage): void {
    this.selectLangByCode(lang.code);
  }

  selectLangByCode(lang: string): void {
    if (this.selectFirst && this.language === lang || !this.selectFirst && this.secondLanguage === lang) {
      return;
    }
    if (this.selectFirst) {
      if (this.secondLanguage === lang) {
        this.secondLanguage = this.language;
      }
      this.language = lang;
    } else {
      if (this.language === lang) {
        this.language = this.secondLanguage;
      }
      this.secondLanguage = lang;
    }
    this._storageService.addUsedLang(lang).then(res => {
      this.lastUsedLangs.set(res);
    })
    this.close();
  }

  closeModal(data?: any) {
    this._modalCtrl.dismiss(data);
  }
}
