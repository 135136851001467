import { Component, inject } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { SearchPipe } from '@shared/pipe/search.pipe';
import { expandCollapse } from '@shared/animation/expand-collapse';
import { addIcons } from 'ionicons';
import { addCircleOutline, closeOutline, removeCircleOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-help',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    NgClass,
    SearchPipe,
    IonicModule
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
  animations: [ expandCollapse ]
})
export class HelpComponent {
  private _modalCtrl = inject(ModalController);

  searchCtrl = new FormControl<string>('');

  constructor() {
    addIcons({ closeOutline, removeCircleOutline, addCircleOutline });
  }

  protected helpItems: HelpItem[] = [
    new HelpItem('help.item.0.title', 'help.item.0.desc', 'expanded'),
    new HelpItem('help.item.1.title', 'help.item.1.desc', 'collapsed'),
  ];

  closePage() {
    this._modalCtrl.dismiss();
  }
}

export class HelpItem {
  private readonly _title: string;
  private readonly _description: string;
  private _state: 'collapsed' | 'expanded';

  constructor(title: string, description: string, state: 'collapsed' | 'expanded') {
    this._title = title;
    this._description = description;
    this._state = state;
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  get state(): 'collapsed' | 'expanded' {
    return this._state;
  }

  set state(value: 'collapsed' | 'expanded') {
    this._state = value;
  }
}
