import { Component, Input, OnInit, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { Pages } from '@app/pages';
import { QrCodeComponent } from '@comp/ui/qr-code/qr-code.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { copyOutline, shareOutline } from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { environment } from '@env/environment';

@Component({
  selector: 'app-share-app-qr-code-dialog',
  standalone: true,
  imports: [
    QrCodeComponent,
    MatIconButton,
    TranslocoPipe,
    IonicModule,
  ],
  templateUrl: './share-app-qr-code-dialog.component.html',
  styleUrl: './share-app-qr-code-dialog.component.scss'
})
export class ShareAppQrCodeDialogComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  title = signal<string>('title.invite.participants');
  text = signal<string>('text.scan.qr.code');
  ref = signal<string>(null);

  protected url: string = null;

  protected readonly navigator = navigator;

  constructor() {
    addIcons({ shareOutline, copyOutline })
  }

  ngOnInit(): void {
    if (this.data.ref) {
      this.ref.set(this.data.ref);
      this.url = `${ environment.serverUrl }/${ Pages.INVITE }?ref=${ this.ref() }`;
    }
    if (this.data.title) {
      this.title.set(this.data.title);
    }
    if (this.data.text) {
      this.text.set(this.data.text);
    }
  }

  async shareUrl() {
    await Share.share({
      title: environment.appName + ': Speak freely. Translate Instantly.',
      url: this.url,
      dialogTitle: 'Share',
    });
  }

  copyUrlFlag = false;

  copyUrl(): void {
    Clipboard.write({ string: this.url }).then();
    this.copyUrlFlag = true;
    setTimeout(() => this.copyUrlFlag = false, 2000);
  }
}
