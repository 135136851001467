import { Component, inject, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HistoryFilter, Room, RoomUser } from '@shared/transport.interface';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DialogService } from '@shared/service/dialog.service';
import _ from 'lodash';
import { RoomHistoryComponent } from '@shared/component/_dialog/history/room-history/room-history.component';
import { ModalController } from '@ionic/angular/standalone';
import { RoomHistoryCardComponent } from '@comp/room-history-card/room-history-card.component';
import { finalize } from 'rxjs';
import { UserService } from '@service/user.service';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { AsyncPipe } from '@angular/common';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MessagesHistoryWidgetComponent } from '@dialog/messages-history-widget/messages-history-widget.component';
import { PrettyDatePipe } from '@pipe/pretty-date.pipe';
import { RoomService } from '@service/room.service';
import { NotyService } from '@service/noty.service';
import { IonicModule } from '@ionic/angular';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@UntilDestroy()
@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe,
    RoomHistoryComponent,
    RoomHistoryCardComponent,
    MatStepper,
    MatStep,
    MessagesHistoryWidgetComponent,
    AsyncPipe,
    PrettyDatePipe,
    IonicModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss'
})
export class HistoryComponent implements OnInit {
  @ViewChild('historyStepper') historyStepper: MatStepper;

  private readonly _modalCtrl = inject(ModalController);
  private readonly _dialogService = inject(DialogService);
  private readonly _userService = inject(UserService);
  private readonly _store = inject(Store);
  private readonly _roomService = inject(RoomService);
  private readonly _notyService = inject(NotyService);
  protected readonly me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly selectedRoom = signal<Room>(null);

  protected filterChanged = false;
  protected myRooms: Room[] = [];

  protected filtersCount = 0;
  protected loading = false;
  protected filter: HistoryFilter;

  constructor() {
  }

  ngOnInit(): void {
    this.loadActiveRooms();
  }

  showFilter(): void {
    this.filterChanged = false;
    this._dialogService.historyFilter(this.filter).then(res => {
      if (res) {
        if (!_.isEqual(this.filter, res)) {
          this.filter = res;
          this.filterChanged = true;
        }
        this.filtersCount = 0;
        if (this.filter.dateFilter?.fromDate || this.filter.dateFilter?.toDate) {
          this.filtersCount++;
        }
      }
    });
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }

  showRoomHistory(room: Room): void {
    this.historyStepper.selectedIndex = 1;
    this.selectedRoom.set(room);
  }

  archiveRoom(chat: Room): void {
    if (!chat.lastMessage) {
      this._notyService.error('error.no.messages.to.archive');
      return;
    }
    this.loading = true;
    this._dialogService
      .exitRoom(chat.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        if (res) {
          const ind = this.myRooms.findIndex(r => r.mode === chat.mode);
          if (ind >= 0) {
            this.myRooms.splice(ind, 1);
          }
          this._roomService.roomArchivedEvent(res);
        }
      });
  }

  showRoomInfo(): void {
    this._dialogService.roomInfo(this.selectedRoom());
  }

  createSummary() {
    this._dialogService
      .createSummary(this.selectedRoom())
      .then(res => {
        if (res) {
          this.showRoomInfo();
        }
      });
  }

  private loadActiveRooms() {
    this.loading = true;
    this._userService.myActiveRooms()
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe({
        next: res => {
          this.myRooms = res;
        }
      });
  }
}
