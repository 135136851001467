import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AudioService } from '@shared/service/audio.service';

@Component({
  selector: 'app-audio-visualizer',
  templateUrl: './audio-visualizer.component.html',
  styleUrls: [ './audio-visualizer.component.scss' ],
  standalone: true
})
export class AudioVisualizerComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
  private _audioService = inject(AudioService);

  ngOnInit(): void {
    console.log(this.canvasRef.nativeElement);
    this._audioService.audioMotion.start(this.canvasRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this._audioService.audioMotion) {
      try {
        this._audioService.audioMotion.stop();
      } catch (e) {
        //
      }
    }
  }

}
