import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User, UserStatus } from '../../../transport.interface';
import { UtilsService } from '../../../service/utils.service';
import { addIcons } from 'ionicons';
import { volumeMuteOutline } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';
import { environment } from '../../../../../environment/environment';

@Component({
  selector: 'app-initials-avatar',
  standalone: true,
  imports: [ CommonModule, IonicModule ],
  templateUrl: './initials-avatar.component.html',
  styleUrls: [ './initials-avatar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialsAvatarComponent {

  @Input() avatarId: string;
  @Input() user: User;
  @Input() width = 56;
  @Input() textSize = 'text-2xl';
  @Input() textColor = 'chat-avatar-fg-color';
  @Input() bgColor = 'chat-avatar-bg-color';
  @Input() creator = false;
  @Input() muted = false;

  private _utils = inject(UtilsService);

  userStatus = UserStatus;

  constructor() {
    addIcons({ volumeMuteOutline });
  }

  extractInitials(): string {
    return this._utils.extractFirstLetters(this.user.name);
  }

  protected readonly environment = environment;
}
