import { Component, inject, input, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatMessageDto, ChatMessagesHistory, ChatMessageType, Paging, Room, RoomMode, RoomUser } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { Store } from '@ngxs/store';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { Clipboard } from '@capacitor/clipboard';
import { finalize } from 'rxjs';
import { FullScreenViewer } from 'iv-viewer';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { RoomService } from '@shared/service/room.service';
import { IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline, copyOutline, languageOutline } from 'ionicons/icons';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { environment } from '@env/environment';

@UntilDestroy()
@Component({
  selector: 'app-messages-history-widget',
  standalone: true,
  imports: [
    LoaderComponent,
    InitialsAvatarComponent,
    NgClass,
    TranslocoPipe,
    DatePipe,
    NgTemplateOutlet,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonIcon
  ],
  templateUrl: './messages-history-widget.component.html',
  styleUrls: [ './messages-history-widget.component.scss' ]
})
export class MessagesHistoryWidgetComponent implements OnInit {
  room = input<Room>();

  @ViewChild('scrollArea', { static: true })
  private scrollbar: IonContent;

  private _store = inject(Store);
  protected _roomService = inject(RoomService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  protected showOriginalTexts = {};
  protected copyLang = '';
  protected copyTime = 0;
  protected loading = false;
  protected loadingMore = false;
  protected empty = signal(true);
  protected roomMode = RoomMode.SINGLE;
  protected history: ChatMessagesHistory;
  protected paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  viewer: any;

  constructor() {
    addIcons({ closeOutline, languageOutline, copyOutline, calendarOutline });
    this.viewer = new FullScreenViewer();
  }

  ngOnInit(): void {
    this.history = null;
    this.empty.set(false);
    this.paging.currentPage = 1;
    this.loadingMore = true;
    this.loading = true;
    this.roomMode = this.room().mode;
    this.loadMore(this.room().roomId, null);
  }

  loadMoreMessages(ev: InfiniteScrollCustomEvent): void {
    if (this.loadingMore || this.empty()) {
      return;
    }
    this.loadingMore = true;
    this.paging.currentPage++;
    this.loadMore(this.room().roomId, ev, true);
  }

  private loadMore(id: string, ev: InfiniteScrollCustomEvent, saveScrollbarPos: boolean = false): void {
    this._roomService.messagesHistory(id, this.paging.pageSize, (this.paging.currentPage - 1) * this.paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => {
        this.loading = false;
        this.loadingMore = false;
      }))
      .subscribe(res => {
        this.paging.totalPages = res.messages.total;
        if (!this.history) {
          this.history = res;
        } else {
          this.history.messages.list = res.messages.list.concat(this.history.messages.list);
        }
        if (!saveScrollbarPos) {
          this.scrollToBottom();
        }
        if (!res.messages.list.length || res.messages.list.length < this.paging.pageSize) {
          this.empty.set(true);
        } else {
          this.empty.set(false);
        }
        if (ev) {
          ev.target.complete().then();
        }
      });
  }

  showOriginalText(msg: ChatMessageDto): void {
    this.showOriginalTexts[ msg.time ] = !this.showOriginalTexts[ msg.time ];
  }

  copyText(lang: string, text: string, time: number): void {
    if (this.copyLang && this.copyTime) {
      return;
    }
    Clipboard.write({ string: text }).then();
    this.copyLang = lang;
    this.copyTime = time;
    setTimeout(() => {
      this.copyLang = '';
      this.copyTime = 0;
    }, 2000);
  }

  protected readonly ChatMessageType = ChatMessageType;

  private scrollToBottom(): void {
    setTimeout(() => {
      this.scrollbar.scrollToBottom().then();
    }, 10);
  }

  getFirstValue(msg: {}): string {
    return Object.values(msg)[ 0 ] + '';
  }

  getFirstKey(msg: {}): string {
    return Object.keys(msg)[ 0 ] + '';
  }

  protected readonly RoomMode = RoomMode;

  showImage(s: string) {
    this.viewer.show(s);
  }

  protected readonly environment = environment;
}
