<div class="flex w-full h-[60px] items-start overflow-hidden">
  <app-initials-avatar [avatarId]="room?.hostRoom ? room.hostRoom.imageId : room.creator.avatarId" [user]="{name: room?.hostRoom ? room.hostRoom.name : room.creator.name}"
                       [width]="60" [textSize]="'text-xl'"></app-initials-avatar>
  <div class="ltr:ml-2.5 rtl:mr-2.5 flex justify-center flex-col flex-auto overflow-hidden">
    <div class="flex items-center w-full justify-between">
      <div class="line-clamp-1 title text-2xl ltr:mr-4 rtl:ml-4">{{ room?.hostRoom ? room.hostRoom.name : room.creator.name }}</div>
      <div class="text-sm text-gray-400">{{ room?.createTime | utcDate: 'HH:mm' }}</div>
    </div>
    @if (room?.lastMessage) {
      <div class="text-sm text-gray-400 mt-1 line-clamp-2">{{ room?.lastMessage.name }}: {{ room?.lastMessage.originalText }}</div>
    }
  </div>
</div>
@if (!last && showLine) {
  <div class="flex w-full h-[1px] mb-2.5 mt-2.5">
    <div class="w-[60px]">&nbsp;</div>
    <div class="flex-auto ml-2.5 border-b border-b-gray-300">&nbsp;</div>
  </div>
}
