<ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (click)="closeModal()"></ion-icon>

<div class="flex flex-col p-5">
  <div class="title text-2xl">{{ 'title.microphone.access.required' | transloco }}</div>
  <div class="mt-2">{{ 'text.microphone.access.required' | transloco }}</div>

  @if (totallyDisabled) {
    <app-alert [type]="'info'" [appearance]="'fill'" class="mt-4">
      @if (_platform.is('android')) {
        <span class="microphone-message" [innerHTML]="'message.microphone.disabled.android' | transloco"></span>
      } @else if (_platform.is('ios')) {
        <span class="microphone-message" [innerHTML]="'message.microphone.disabled.ios' | transloco"></span>
      } @else {
        <span class="microphone-message" [innerHTML]="'message.microphone.disabled' | transloco"></span>
      }
    </app-alert>
  }

  <div class="mt-4 flex justify-center">
    <button mat-icon-button (click)="enableMicrophone()">
      <ion-icon name="mic" class="text-4xl"></ion-icon>
    </button>
  </div>
</div>
