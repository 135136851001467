import { Component, inject, Input, OnInit, Signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngxs/store';
import { ChatMessageType, ChatResultType, Room, RoomAction, RoomMode, RoomProtocol, RoomSummary, RoomTranscript, RoomUser } from '@shared/transport.interface';
import { DialogService } from '@shared/service/dialog.service';
import { RoomService } from '@shared/service/room.service';
import { UserState } from '@shared/store/user/user-state.service';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { PrettyDatePipe } from '@shared/pipe/pretty-date.pipe';
import { RoomParticipantsComponent } from '@shared/component/room-participants/room-participants.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { calendarOutline, chevronDown, closeOutline, ellipsisVertical, searchOutline, trashOutline } from 'ionicons/icons';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-room-info',
  standalone: true,
  imports: [
    MatIconButton,
    InitialsAvatarComponent,
    TranslocoPipe,
    LoaderComponent,
    PrettyDatePipe,
    NgClass,
    AsyncPipe,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    NgTemplateOutlet,
    RoomParticipantsComponent,
    IonicModule
  ],
  templateUrl: './room-info.component.html',
  styleUrl: './room-info.component.scss'
})
export class RoomInfoComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  protected room: Room;
  protected loading = false;
  protected summaries: RoomSummary[] = [];
  protected protocols: RoomProtocol[] = [];
  protected transcripts: RoomTranscript[] = [];
  protected expandSummaries = false;
  protected type = ChatResultType.SUMMARY;
  protected readonly ChatResultType = ChatResultType;
  protected readOnly = false;
  protected readonly RoomMode = RoomMode;

  protected _roomService = inject(RoomService);
  private _dialogService = inject(DialogService);
  private _store = inject(Store);
  me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  constructor() {
    addIcons({ closeOutline, chevronDown, calendarOutline, searchOutline, ellipsisVertical, trashOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.room = this.data.room;
      this.readOnly = this.data.readOnly;
      this.showSummaryTab();
      this._roomService.getRoomEvents$().pipe(untilDestroyed(this))
        .subscribe(res => {
          if (res.action === RoomAction.SUMMARY_DELETED) {
            if (res.type === ChatMessageType.SUMMARY) {
              const ind = this.summaries?.findIndex(msg => (msg.id === res.id));
              if (ind >= 0) {
                this.summaries.splice(ind, 1);
              }
            } else if (res.type === ChatMessageType.PROTOCOL) {
              const ind = this.protocols?.findIndex(msg => (msg.id === res.id));
              if (ind >= 0) {
                this.summaries.splice(ind, 1);
              }
            } else if (res.type === ChatMessageType.TRANSCRIPT) {
              const ind = this.transcripts?.findIndex(msg => (msg.id === res.id));
              if (ind >= 0) {
                this.summaries.splice(ind, 1);
              }
            }
          }
        });
    }
  }

  createSummary(): void {
    if (this.me().id !== this.room.creator.id) {
      return;
    }
    this._dialogService
      .createSummary(this.room, this.type)
      .then(res => {
        if (res) {
          if ('summary' in res) {
            this.summaries.unshift(res as RoomSummary);
          } else if ('messages' in res) {
            this.transcripts.unshift(res as RoomTranscript);
          } else if ('discuss' in res) {
            this.protocols.unshift(res as RoomProtocol);
          }
        }
      });
  }

  showSummary(summary: RoomSummary): void {
    this._dialogService.showSummary(summary)
      .then(res => {
        if (res && res.deleted) {
          const ind = this.summaries.findIndex(s => s.id === res.id);
          if (ind >= 0) {
            this.summaries.splice(ind, 1);
          }
        }
      });
  }

  showProtocol(protocol: RoomProtocol): void {
    this._dialogService.showProtocol(protocol).then(res => {
      if (res && res.deleted) {
        const ind = this.protocols.findIndex(s => s.id === res.id);
        if (ind >= 0) {
          this.protocols.splice(ind, 1);
        }
      }
    });
  }

  showTranscript(transcript: RoomTranscript): void {
    this._dialogService.showTranscript(transcript).then(res => {
      if (res && res.deleted) {
        const ind = this.transcripts.findIndex(s => s.id === res.id);
        if (ind >= 0) {
          this.transcripts.splice(ind, 1);
        }
      }
    });
  }

  deleteSummary(summary: RoomSummary): void {
    this._dialogService.deleteSummary(summary)
      .subscribe(res => {
        if (res) {
          const ind = this.summaries.findIndex(s => s.id === res.id);
          if (ind >= 0) {
            this.summaries.splice(ind, 1);
          }
        }
      });
  }

  deleteProtocol(protocol: RoomProtocol): void {
    this._dialogService.deleteProtocol(protocol)
      .subscribe(res => {
        if (res) {
          const ind = this.protocols.findIndex(s => s.id === res.id);
          if (ind >= 0) {
            this.protocols.splice(ind, 1);
          }
        }
      });
  }

  deleteTranscript(transcript: RoomTranscript): void {
    this._dialogService.deleteTranscript(transcript)
      .subscribe(res => {
        if (res) {
          const ind = this.transcripts.findIndex(s => s.id === res.id);
          if (ind >= 0) {
            this.transcripts.splice(ind, 1);
          }
        }
      });
  }

  showRoomResultTypeSelector(type: ChatResultType): void {
    this._dialogService.selectRoomResultType(type)
      .subscribe(res => {
        if (res >= 0) {
          this.type = res;
          if (this.type === ChatResultType.SUMMARY) {
            this.showSummaryTab();
          } else if (this.type === ChatResultType.TRANSCRIPT) {
            this.showTranscriptTab();
          } else if (this.type === ChatResultType.PROTOCOL) {
            this.showProtocolTab();
          }
        }
      });
  }

  showSummaryTab(): void {
    this.loading = true;
    this._roomService.summaries(this.room.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.summaries = res;
      });
  }

  showTranscriptTab(): void {
    this.loading = true;
    this._roomService.transcripts(this.room.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.transcripts = res;
      });
  }

  showProtocolTab(): void {
    this.loading = true;
    this._roomService.protocols(this.room.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.protocols = res;
      });
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }

}
