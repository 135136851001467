<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div class="w-full text-center p-2 border-b border-b-gray-300 bg-color-1">
      <div class="flex justify-between items-center mx-auto">
        <div class="flex flex-auto items-center title text-4xl pl-4">
          {{ 'title.blocked' | transloco }}
        </div>
        <div class="w-[48px]">
          <ion-icon name="close-outline" class="text-4xl cursor-pointer" (click)="closeModal()"></ion-icon>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white">
      <div class="flex flex-col w-full p-5 flex-auto overflow-y-auto">
        <div class="flex flex-col w-full flex-auto">
          <div class="text-base mb-5">{{ 'text.blocked' | transloco }}</div>
          @for (user of users; track user.id; let ind = $index) {
            <div class="py-2.5 border-t border-t-gray-300 flex justify-between items-center" [formGroup]="formArray.at(ind)">
              <app-user-card [me]="user"></app-user-card>
              <div>
                <app-checkbox formControlName="checked"></app-checkbox>
              </div>
            </div>
          }
        </div>
        <div class="pt-5">
          <button class="button w-full" [class.loading]="pending" [disabled]="pending" (click)="unblock()">{{ 'button.unblock' | transloco }}</button>
        </div>
      </div>
    </div>

  </div>
</div>
