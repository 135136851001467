import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { chevronDown } from 'ionicons/icons';
import { SelectItem } from '@shared/transport.interface';
import { DialogService } from '@service/dialog.service';

@Component({
  selector: 'app-selectbox',
  standalone: true,
  imports: [
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './selectbox.component.html',
  styleUrl: './selectbox.component.scss'
})
export class SelectboxComponent implements OnInit {
  @Input() items: SelectItem[];
  @Input() defaultItem: SelectItem;
  @Input() disabled = false;
  @Input() search = true;
  @Input() placeholder: string = 'label.select';
  @Output() itemSelected: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();
  selectedItem: SelectItem;

  private _dialogService = inject(DialogService);

  constructor() {
    addIcons({ chevronDown });
  }

  ngOnInit(): void {
    if (this.items?.length && (this.defaultItem?.id === null || this.defaultItem?.id === undefined)) {
      this.defaultItem = this.items[ 0 ];
    }
    this.selectedItem = this.defaultItem;
  }

  selectItem() {
    if (this.disabled) {
      return;
    }
    this._dialogService.selectItem(this.items, this.selectedItem, this.search)
      .then(res => {
        if (res) {
          this.selectedItem = res;
          this.itemSelected.next(res);
        }
      });
  }
}
