import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RoomHistoryCardComponent } from '@shared/component/room-history-card/room-history-card.component';
import { HistoryFilter, Paging, Room } from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { UserService } from '@shared/service/user.service';
import { Router } from '@angular/router';
import { DialogService } from '@shared/service/dialog.service';
import { TranslocoPipe } from '@ngneat/transloco';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { UtcDatePipe } from '@shared/pipe/utc-date.pipe';
import { DateLineComponent } from '@shared/component/ui/date-line/date-line.component';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/angular/standalone';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-room-history',
  standalone: true,
  imports: [
    RoomHistoryCardComponent,
    TranslocoPipe,
    LoaderComponent,
    DateLineComponent,
    UtcDatePipe,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  ],
  templateUrl: './room-history.component.html',
  styleUrl: './room-history.component.scss'
})
export class RoomHistoryComponent implements OnInit, OnChanges {
  @Input() filterChanged = false;
  @Input() filter: HistoryFilter;

  private _userService = inject(UserService);
  private _dialogService = inject(DialogService);

  chats: Room[] = [];
  loading = false;
  upgradeTariff = false;
  private _paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  protected loadingMore = false;
  protected empty = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterChanged?.currentValue === true) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadHistory();
  }

  private loadHistory() {
    this._userService.historyRooms(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize, this.filter)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe({
        next: res => {
          this.chats = res.list;
          this._paging.totalPages = res.total;
        },
        error: err => {
          this.upgradeTariff = true;
        }
      });
  }

  showRoomHistory(room: Room): void {
    this._dialogService.messagesHistory(room.roomId);
  }

  loadMoreData(ev: InfiniteScrollCustomEvent): void {
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this._paging.currentPage++;
    this._userService.historyRooms(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loadingMore = false))
      .subscribe(res => {
        this.chats = this.chats.concat(res.list);
        if (!res.list.length || res.list.length < this._paging.pageSize) {
          this.empty = true;
        }
        ev.target.complete();
      });
  }
}
