import { Injectable } from '@angular/core';
import { ChatMultiSettings, ChatSingleSettings, CommonSettings, InnerPageSettings, NavMenuSettings, Preferences, SiteInfo } from '@shared/transport.interface';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() {
  }

  setUIByUserPreferences(preferences: Preferences) {
    if (preferences?.chatFontSize) {
      this.setProperty('--conversation-font-size', preferences.chatFontSize + 'px');
    }
  }

  setUIBySiteSettings(preferences: SiteInfo) {
    if (!preferences) {
      return;
    }
    if (preferences.commonSettings) {
      this.initCommonSettings(preferences.commonSettings);
    }
    if (preferences.chatSingleSettings) {
      this.initChatSingleSettings(preferences.chatSingleSettings);
    }
    if (preferences.chatMultiSettings) {
      this.initChatMultiSettings(preferences.chatMultiSettings);
    }
    if (preferences.navMenuSettings) {
      this.initNavMenuSettings(preferences.navMenuSettings);
    }
    if (preferences.innerPageSettings) {
      this.initInnerPageSettings(preferences.innerPageSettings);
    }
  }

  private setPxProperty(key: string, value: number) {
    document.documentElement.style.setProperty(key, `${ value }px`);
  }

  private setPercentProperty(key: string, value: number) {
    document.documentElement.style.setProperty(key, `${ value }%`);
  }

  private setProperty(key: string, value: string | number) {
    document.documentElement.style.setProperty(key, value + '');
  }

  private initNavMenuSettings(settings: NavMenuSettings) {
    if (settings.bg) {
      this.setProperty('--nav-menu-bg', settings.bg);
    }
    if (settings.toolbarBg) {
      this.setProperty('--nav-menu-toolbar-bg', settings.toolbarBg);
    }
    if (settings.footerFg) {
      this.setProperty('--footer-menu-color', settings.footerFg);
    }
    if (settings.itemFg) {
      this.setProperty('--nav-menu-item-fg', settings.footerFg);
    }
    if (settings.itemIconFg) {
      this.setProperty('--nav-menu-item-fg', settings.itemIconFg);
    }
    if (settings.itemSeparatorFg) {
      this.setProperty('--nav-menu-item-separator-fg', settings.itemSeparatorFg);
    }
    if (settings.creditsTitleFg) {
      this.setProperty('--tariff-premium-color', settings.creditsTitleFg);
      this.setProperty('--user-role-fg', settings.creditsTitleFg);
    }
    if (settings.creditsFg) {
      this.setProperty('--nav-menu-credits-count-fg', settings.creditsFg);
    }
  }

  private initInnerPageSettings(settings: InnerPageSettings) {
    if (settings.bg) {
      this.setProperty('--inner-page-bg', settings.bg);
    }
    if (settings.fg) {
      this.setProperty('--inner-page-fg', settings.fg);
    }
    if (settings.headersFg) {
      this.setProperty('--inner-page-headers-fg', settings.headersFg);
    }
    if (settings.formBg) {
      this.setProperty('--inner-page-form-bg', settings.formBg);
    }
    if (settings.formFg) {
      this.setProperty('--inner-page-form-fg', settings.formFg);
    }
    if (settings.formHeadersFg) {
      this.setProperty('--inner-page-form-headers-fg', settings.formHeadersFg);
    }
    if (settings.formIconsFg) {
      this.setProperty('--inner-page-form-icons-fg', settings.formIconsFg);
    }
    if (settings.formIconsBg) {
      this.setProperty('--inner-page-form-icons-bg', settings.formIconsBg);
    }
    if (settings.strokeButtonBg) {
      this.setProperty('--inner-page-form-button-stroke-bg', settings.strokeButtonBg);
    }
    if (settings.strokeButtonFg) {
      this.setProperty('--inner-page-form-button-stroke-fg', settings.strokeButtonFg);
    }
  }

  private initChatMultiSettings(settings: ChatMultiSettings) {
    if (settings.messageBg) {
      this.setProperty('--chat-multi-message-bg', settings.messageBg);
    }
    if (settings.messageFg) {
      this.setProperty('--chat-multi-message-fg', settings.messageFg);
    }
    if (settings.messageIconsFg) {
      this.setProperty('--chat-multi-message-icons-fg', settings.messageIconsFg);
    }
  }

  private initChatSingleSettings(settings: ChatSingleSettings) {
    if (settings.actionPanelBg) {
      this.setProperty('--chat-single-action-panel-bg-color', settings.actionPanelBg);
    }
    if (settings.editorBorderFg) {
      this.setProperty('--chat-single-border-color', settings.editorBorderFg);
    }
    if (settings.editorBorderRadius) {
      this.setPxProperty('--chat-single-border-radius', settings.editorBorderRadius);
    }
    if (settings.editorHolderBg) {
      this.setProperty('--chat-single-bg-color', settings.editorHolderBg);
    }
    if (settings.iconsFg) {
      this.setProperty('--chat-single-icons-color', settings.iconsFg);
    }
    if (settings.toolbarBorderBg) {
      this.setProperty('--chat-single-toolbar-border-bg', settings.toolbarBorderBg);
    }
    if (settings.rightButtonBg) {
      this.setProperty('--chat-single-right-button-bg-color', settings.rightButtonBg);
    }
    if (settings.rightButtonFg) {
      this.setProperty('--chat-single-right-button-fg-color', settings.rightButtonFg);
    }
    if (settings.rightButtonRadius) {
      this.setPercentProperty('--chat-single-right-button-radius', settings.rightButtonRadius);
    }
    if (settings.leftButtonBg) {
      this.setProperty('--chat-single-left-button-bg-color', settings.leftButtonBg);
    }
    if (settings.leftButtonFg) {
      this.setProperty('--chat-single-left-button-fg-color', settings.leftButtonFg);
    }
    if (settings.leftButtonRadius) {
      this.setPercentProperty('--chat-single-left-button-radius', settings.leftButtonRadius);
    }
    if (settings.separatorBg) {
      this.setProperty('--chat-single-action-panel-separator-bg', settings.separatorBg);
    }
    if (settings.separatorHeight) {
      this.setPxProperty('--chat-single-action-panel-separator-height', settings.separatorHeight);
    }
    if (settings.separatorRadius) {
      this.setPxProperty('--chat-single-action-panel-separator-radius', settings.separatorRadius);
    }
    if (settings.separatorWidth) {
      this.setProperty('--chat-single-action-panel-separator-width', settings.separatorWidth);
    }
  }

  private initCommonSettings(settings: CommonSettings): void {
    if (settings.fontFamily) {
      this.setProperty('--font-family', settings.fontFamily);
    }
    if (settings.headersFontFamily) {
      this.setProperty('--headers-font-family', settings.headersFontFamily);
    }
    if (settings.fontSize) {
      this.setPxProperty('--font-size', settings.fontSize);
    }
    if (settings.headersFontWeight) {
      this.setProperty('--headers-font-weight', settings.headersFontWeight);
    }
    if (settings.headersColor) {
      this.setProperty('--headers-color', settings.headersColor);
    }
    if (settings.buttonCapitalized) {
      this.setProperty('--button-text-transform', settings.buttonCapitalized ? 'capitalized' : 'uppercase');
    }
    if (settings.buttonBg) {
      this.setProperty('--button-bg-color', settings.buttonBg);
    }
    if (settings.buttonFg) {
      this.setProperty('--button-text-color', settings.buttonFg);
    }
    if (settings.delButtonBg) {
      this.setProperty('--del-button-bg-color', settings.delButtonBg);
    }
    if (settings.delButtonFg) {
      this.setProperty('--del-button-fg-color', settings.delButtonFg);
    }
    if (settings.buttonRadius) {
      this.setPxProperty('--button-border-radius', settings.buttonRadius);
    }
    if (settings.toolbarBg) {
      this.setProperty('--ion-toolbar-bg', settings.toolbarBg);
    }
    if (settings.textFg) {
      this.setProperty('--text-color', settings.textFg);
    }
    if (settings.linkFg) {
      this.setProperty('--link-color', settings.linkFg);
    }
    if (settings.iconFg) {
      this.setProperty('--icon-color', settings.iconFg);
    }
    if (settings.logoHeight) {
      this.setPxProperty('--logo-height', settings.logoHeight);
    }
    if (settings.buttonHeight) {
      this.setPxProperty('--button-height', settings.buttonHeight);
    }
    if (settings.checkboxBg) {
      this.setProperty('--checkbox-bg', settings.checkboxBg);
    }
    if (settings.checkboxRadius) {
      this.setPxProperty('--checkbox-border-radius', settings.checkboxRadius);
    }
    if (settings.toastRadius) {
      this.setPxProperty('--toast-radius', settings.toastRadius);
    }
    if (settings.toastBg) {
      this.setProperty('--toast-bg', settings.toastBg);
    }
    if (settings.toastFg) {
      this.setProperty('--toast-fg', settings.toastFg);
    }
    if (settings.inputFg) {
      this.setProperty('--mdc-filled-text-field-input-text-color', settings.inputFg);
    }
    if (settings.inputBorderBg) {
      this.setProperty('--input-border-bg', settings.inputBorderBg);
    }
    if (settings.inputBg) {
      this.setProperty('--input-bg', settings.inputBg);
    }
    if (settings.inputRadius) {
      this.setPxProperty('--input-radius', settings.inputRadius);
    }
  }
}
