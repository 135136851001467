<div class="w-full flex items-center justify-center">
<!--  <div class="flex flex-auto overflow-hidden flex-auto items-center h-full px-4">-->
<!--    &nbsp;-->
<!--  </div>-->
  <div class="stop-record-button" (click)="startRecording()" [class.disabled]="disabled">
    @if (disabled) {
      <ion-icon name="mic-off" class="text-5xl"></ion-icon>
    } @else {
      @if (recorderStatus === RecorderStatus.RECORDING) {
        <ion-icon name="square" class="text-5xl cursor-pointer"></ion-icon>
      } @else if (status === TranslateStatus.ERROR || status === TranslateStatus.CANCEL) {
        <ion-icon name="mic" class="text-5xl cursor-pointer"></ion-icon>
      } @else {
        <ion-icon name="mic" class="text-5xl"></ion-icon>
      }
    }
  </div>
<!--  <div class="flex flex-auto overflow-hidden flex-auto items-center h-full px-4">{{ timer }}</div>-->
</div>
