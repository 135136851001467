<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (historyStepper.selectedIndex > 0) {
        <ion-icon name="arrow-back-outline" class="cursor-pointer text-4xl" (click)="historyStepper.selectedIndex = 0"></ion-icon>
      }
    </ion-buttons>
    <ion-title>
      @if (historyStepper.selectedIndex === 0) {
        {{ 'title.my.conversations' | transloco }}
      } @else if (historyStepper.selectedIndex === 1) {
        <div class="cursor-pointer overflow-hidden" (click)="showRoomInfo()">
          @if (selectedRoom()?.hostRoom) {
            <div class="title text-2xl text-center font-bold truncate">{{ selectedRoom()?.hostRoom.name }}</div>
          } @else {
            <div class="title text-2xl text-center font-bold truncate">{{ selectedRoom()?.creator?.name }}</div>
          }
          <div class="flex items-center text-sm justify-center">
            <div class="flex items-center whitespace-nowrap">
              <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
              <div class="ltr:ml-1.5 rtl:mr-1.5 normal-text leading-none">{{ selectedRoom()?.createTime | prettyDate | async }}</div>
            </div>
          </div>
        </div>
      }
    </ion-title>
    <ion-buttons slot="end">
      @if (historyStepper.selectedIndex === 0) {
        <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
      } @else if (historyStepper.selectedIndex === 1) {
        <ion-button [matMenuTriggerFor]="menu">
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="createSummary()">
            <ion-icon name="document-outline" class="text-2xl"></ion-icon>
            <span>{{ 'button.create' | transloco }}</span>
          </button>
          <button mat-menu-item (click)="showRoomInfo()">
            <ion-icon name="documents-outline" class="text-2xl"></ion-icon>
            <span>{{ 'button.documents' | transloco }}</span>
          </button>
        </mat-menu>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div class="flex flex-col items-center w-full flex-auto overflow-hidden relative bg-white">
      <mat-stepper [disableRipple]="true" [animationDuration]="'200ms'" class="h-full w-full flex flex-col overflow-hidden" #historyStepper>
        <mat-step class="flex flex-col">
          <div class="flex flex-col w-full flex-auto overflow-hidden">
            <div class="flex flex-col p-5 pt-0">
              <div class="my-3 flex">
                <div class="title-2 text-2xl">{{ 'label.active' | transloco }}</div>
              </div>
              <ion-list>
                @for (chat of myRooms; track chat.roomId; let ind = $index; let first = $first; let last = $last) {
                  <ion-item-sliding class="mt-4">
                    <ion-item>
                      <ion-label>
                        <app-room-history-card class="cursor-pointer"
                                               [me]="me()"
                                               [room]="chat"
                                               [last]="last"
                                               [showLine]="false"
                                               (click)="showRoomHistory(chat)"></app-room-history-card>

                      </ion-label>
                    </ion-item>
                    <ion-item-options>
                      <ion-item-option color="danger" (click)="archiveRoom(chat)" [disabled]="loading">{{ 'button.archive' | transloco }}</ion-item-option>
                    </ion-item-options>
                  </ion-item-sliding>
                }
              </ion-list>

            </div>
            <div class="px-5 mt-2 flex">
              <div class="title-2 text-2xl">{{ 'label.archived' | transloco }}</div>
            </div>
            <div class="link-color cursor-pointer text-base ltr:pl-5 rtl:pr-5 pb-4 mt-2" (click)="showFilter()">{{ 'link.more.filters' | transloco }} ({{ filtersCount }})</div>
            <div class="flex-auto">
              <app-room-history [filter]="filter" [filterChanged]="filterChanged" (roomSelected)="showRoomHistory($event)"></app-room-history>
            </div>
          </div>
        </mat-step>
        <mat-step class="flex flex-col">
          @if (historyStepper.selectedIndex === 1) {
            <app-messages-history-widget [room]="selectedRoom()"></app-messages-history-widget>
          }
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
