import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommonLanguage } from '../../transport.interface';
import { SaveLanguagesAction } from './languages.actions';

export class LanguagesStateModel {
  public list: CommonLanguage[] = [];
}

const defaults = {
  list: []
};

@State<LanguagesStateModel>({
  name: 'list',
  defaults
})
@Injectable()
export class LanguagesState {
  @Action(SaveLanguagesAction)
  save({ setState }: StateContext<LanguagesStateModel>, { payload }: SaveLanguagesAction): void {
    setState({ list: payload });
  }

  @Selector()
  static getLanguages(state: LanguagesStateModel) {
    return state.list;
  }
}
