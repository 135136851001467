import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatStepperNext } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { QrCodeComponent } from '../../ui/qr-code/qr-code.component';
import { RoomSummary } from '@shared/transport.interface';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-delete-summary',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    QrCodeComponent,
    TranslocoPipe,
    MatStepperNext,
    IonicModule
  ],
  templateUrl: './delete-summary.component.html',
  styleUrl: './delete-summary.component.scss'
})
export class DeleteSummaryComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected summary: RoomSummary;
  protected pending = false;

  private _roomService = inject(RoomService);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.summary = this.data.summary;
    }
  }

  deleteSummary(): void {
    this.pending = true;
    this._roomService.deleteSummary(this.summary.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.closeModal(res);
      });
  }
}
