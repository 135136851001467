<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.chat.result.type.1' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeDialog()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="overflow-hidden w-full h-full flex-auto flex flex-col bg-white">
  <ion-content>
    <div class="flex flex-col flex-auto overflow-y-auto">
      <div class="px-5 mt-5 flex items-center text-gray-400">
        <div class="pr-2.5 mr-2.5 border-r border-r-gray-300 flex items-center whitespace-nowrap">
          <ion-icon name="calendar-outline" class="text-gray-400"></ion-icon>
          <div class="ltr:ml-1.5 rtl:mr-1.5">{{ protocol?.createDate | prettyDate | async }}</div>
        </div>
        <div class="flex items-center flex-auto overflow-hidden">
          <app-initials-avatar [avatarId]="protocol?.user?.avatarId" [user]="{name: protocol?.user?.name}" [width]="20" [textSize]="'text-xs'"></app-initials-avatar>
          <div class="ltr:ml-2 rtl:mr-2 flex w-full overflow-hidden">
            <div class="text-base text-gray-400 truncate">{{ protocol?.user?.name }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full px-5 flex-auto overflow-y-auto" #scroll>
        <div class="mt-8 flex flex-col">
          <a (click)="scrollToId('participants')">{{ 'link.participants' | transloco }}</a>
          <a (click)="scrollToId('intro')">{{ 'link.intro' | transloco }}</a>
          <a (click)="scrollToId('discussed')">{{ 'link.discussed' | transloco }}</a>
          <a (click)="scrollToId('planned')">{{ 'link.planned' | transloco }}</a>
        </div>
        <div class="h-[1px] border-t border-t-gray-300 w-full my-5"></div>
        <div class="title text-3xl" id="participants">{{ 'link.participants' | transloco }}</div>
        <div class="flex flex-col w-full mt-2.5">
          @for (user of protocol?.participants; track user.id; let ind = $index) {
            <app-user-card [me]="user" [class.mt-2]="ind > 0"></app-user-card>
          }
        </div>

        <div class="title text-3xl mt-8" id="intro">{{ 'link.intro' | transloco }}</div>
        <div class="mt-2.5 flex flex-col w-full">
          <div>{{ protocol?.intro }}</div>
        </div>

        <div class="title text-3xl mt-8" id="discussed">{{ 'link.discussed' | transloco }}</div>
        <div class="mt-2.5 flex flex-col w-full">
          <div>{{ protocol?.discuss }}</div>
        </div>

        <div class="title text-3xl mt-8" id="planned">{{ 'link.planned' | transloco }}</div>
        <div class="mt-2.5 flex flex-col w-full pb-4">
          <div class="common-text" [innerHTML]="protocol?.plan"></div>
        </div>
      </div>
    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <div class="flex justify-center items-center pb-2 pt-1">
        @if (me().id === protocol.user?.id) {
          @if (!protocol.shared) {
            <div>
              <button mat-icon-button class="text-lg" (click)="shareProtocol()">
                <ion-icon name="share-outline" class="text-4xl"></ion-icon>
              </button>
            </div>
            <div class="w-[1px] h-[20px] border-r border-r-gray-300 mx-6"></div>
          }
          <div>
            <button mat-icon-button class="text-lg" (click)="deleteProtocol()">
              <ion-icon name="trash-outline" class="text-4xl"></ion-icon>
            </button>
          </div>
        }
      </div>
    </ion-toolbar>
  </ion-footer>

</div>
