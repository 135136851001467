<div class="flex flex-col w-full p-5 flex-auto overflow-y-auto" [formGroup]="form">
  <div class="self-center">
    @if (me()?.id) {
      <app-avatar [canDelete]="!!me().avatarId" [initials]="me().name" [hasAvatar]="!!me().avatarId" formControlName="avatar"></app-avatar>
    }
  </div>
  <div class="w-full flex-auto mt-4" [formGroup]="form">
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.email' | transloco }}</mat-label>
      <input
        matInput
        readonly
        formControlName="email">
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.first.name' | transloco }}</mat-label>
      <input
        matInput
        formControlName="firstName">
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.last.name' | transloco }}</mat-label>
      <input
        matInput
        formControlName="lastName">
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.position' | transloco }}</mat-label>
      <input
        matInput
        formControlName="companyRole">
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.position.description' | transloco }}</mat-label>
      <input
        matInput
        formControlName="companyRoleDesc">
    </mat-form-field>

  </div>
  <div class="pt-5">
    <button class="button w-full" [class.loading]="pending" [disabled]="pending" (click)="saveProfile()">{{ 'button.save' | transloco }}</button>
  </div>
</div>
