<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ 'title.complete.registration' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="flex flex-col bg-white w-full p-5 flex-auto">
    <div class="items-center mx-auto w-full max-w-[400px]">
      @if (commonError?.code > 0) {
        <app-alert [type]="'warning'" [appearance]="'fill'" class="mt-4">
          {{ commonError.message | transloco }}
        </app-alert>
      }
      <form
        [formGroup]="form">
        <mat-form-field class="text-base w-full w-max-[300px] mt-4" subscriptSizing="dynamic">
          <mat-label>{{ 'label.email' | transloco }}</mat-label>
          <input
            matInput
            formControlName="email">
        </mat-form-field>
        <mat-form-field class="text-base w-full w-max-[300px]" subscriptSizing="dynamic">
          <mat-label>{{ 'label.first.name' | transloco }}</mat-label>
          <input
            matInput
            formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="text-base w-full w-max-[300px]" subscriptSizing="dynamic">
          <mat-label>{{ 'label.last.name' | transloco }}</mat-label>
          <input
            matInput
            formControlName="lastName">
        </mat-form-field>
        <div class="flex justify-center items-center w-full w-max-[300px] mt-6">
          <button class="button w-full" (click)="completeRegistration()" [disabled]="pending || form.invalid" [class.loading]="pending">
            {{ 'button.get.free.minutes' | transloco }}
          </button>
        </div>
      </form>
      <div class="my-3 flex items-center justify-center">
        <div class="flex-auto border-b border-b-gray-300"></div>
        <div class="mx-4 flex-0 text-sm font-semibold leading-5 text-gray-300">
          {{ 'text.or' | transloco }}
        </div>
        <div class="flex-auto border-b border-b-gray-300"></div>
      </div>

      <div class="w-full flex justify-center items-center">
        <app-google-auth [defaultAction]="false" (loggingIn)="completeGoogleRegistration($event)" (errorEvent)="pending = false"></app-google-auth>
      </div>
    </div>
  </div>
</ion-content>
