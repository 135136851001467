<ion-menu contentId="main-content" menuId="nav-menu" #mainMenu class="h-full">
  <ion-header>
    <ion-toolbar class="no-border">
      <div class="flex flex-auto justify-between items-center pl-2.5 pt-2">
        @if (logged) {
          <div class="w-4/5 overflow-hidden flex items-center">
            <app-user-card [me]="me()" class="cursor-pointer pr-4" (click)="openProfile()"></app-user-card>
          </div>
        } @else {
          <div class="flex items-center mt-1 cursor-pointer">
            <div (click)="openPage(Pages.LOGIN)" class="flex items-center">
              <ion-icon name="person-circle-outline" class="link-color text-3xl"></ion-icon>
              <div class="title link-color ml-1 text-3xl leading-[0.9]">{{ 'link.signin' | transloco }}</div>
            </div>
          </div>
        }
        <ion-icon name="close-outline" class="text-4xl cursor-pointer" (click)="mainMenu.close(true)"></ion-icon>
      </div>
    </ion-toolbar>
  </ion-header>
  <div class="flex flex-col h-full overflow-x-hidden w-full menu-bg relative text-left">
    <div class="flex flex-col text-base flex-auto">
      <div class="bg-white pt-2.5 px-4 pb-5">
        <app-tariff-info></app-tariff-info>
        @if (me()?.email && me()?.role === UserRole.USER && (isMobile || isWeb)) {
          <div class="flex flex-col mt-5">
            <button class="button w-full" (click)="buyMinutes()">{{ 'button.buy.credits' | transloco }}</button>
          </div>
        }
        @if (!me()?.email && (me()?.demo || me()?.role >= UserRole.TEMP_USER)) {
          <div class="flex flex-col mt-5">
            <button class="button w-full" (click)="completeRegistration()">{{ 'button.get.free.credits' | transloco }}</button>
          </div>
        }
      </div>
      <div class="flex flex-col flex-auto">
        <ion-content class="nav-menu">
          <div class="flex flex-col flex-auto justify-between h-full">
            <div class="pt-4 nav-menu__item__border-top flex-auto">
              @if (me().id) {
                <ng-container *ngTemplateOutlet="loggedMenu"></ng-container>
              } @else {
                <ng-container *ngTemplateOutlet="guestMenu"></ng-container>
              }
            </div>
            <div class="footer-menu text-xs p-5">
              <div class="text-center justify-center flex flex-wrap w-full pb-5 footer-menu__links">
                <a (click)="openPrivacy()">{{ 'link.privacy' | transloco }}</a>
                <!--          <a (click)="openPage(Pages.RISK)">{{ 'link.risk' | transloco }}</a>-->
                <a (click)="openTerms()">{{ 'link.terms' | transloco }}</a>
                <!--          <a (click)="openPage(Pages.IMPRINT)">{{ 'link.imprint' | transloco }}</a>-->
                <a (click)="openHelp()">{{ 'link.help' | transloco }}</a>
              </div>
              @if (!hideInstallButton) {
                <div class="flex justify-center items-center cursor-pointer mt-4" (click)="installApp()">
                  <!--            TODO -->
                  <!--            <mat-icon svgIcon="heroicons_outline:cloud-arrow-down"></mat-icon>-->
                  <!--            <div class="ml-2">{{ 'link.install.app' | transloco }}</div>-->
                </div>
              }
              <div class="w-full mt-5 items-center justify-center text-center flex">
                <div class="">{{ 'text.powered.by' | transloco }}</div>
                <div class="ml-2.5"><img ngSrc="/assets/images/logo/logo-gray.svg" width="70" height="13" [alt]="environment.appName"/></div>
              </div>
            </div>
          </div>
        </ion-content>
      </div>
    </div>
  </div>
</ion-menu>

<ng-template #guestMenu>
  <div class="menu-item" (click)="joinRoomByQR()">
    <div class="menu-icon">
      <ion-icon name="qr-code-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.join.conversation' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="navigate(Pages.LOGIN)">
    <div class="menu-icon">
      <ion-icon name="chatbubble-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.chat.mode.single' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="navigate(Pages.LOGIN)">
    <div class="menu-icon">
      <ion-icon name="chatbubbles-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.chat.mode.conference' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="navigate(Pages.LOGIN)">
    <div class="menu-icon">
      <ion-icon name="calendar-clear-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.my.conversations' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="navigate(Pages.LOGIN)">
    <div class="menu-icon">
      <ion-icon name="documents-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.my.documents' | transloco }}</div>
  </div>
</ng-template>

<ng-template #loggedMenu>
  <div class="menu-item" (click)="joinRoomByQR()">
    <div class="menu-icon">
      <ion-icon name="qr-code-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.join.conversation' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="startChat(RoomMode.SINGLE)" [class.active]="_roomService.mode() === RoomMode.SINGLE">
    <div class="menu-icon">
      @if (pending[RoomMode.SINGLE]) {
        <ion-icon name="sync-outline" class="text-2xl spin-animation"></ion-icon>
      } @else {
        <ion-icon name="chatbubble-outline" class="text-2xl"></ion-icon>
      }
    </div>
    <div class="label">{{ 'button.chat.mode.single' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="startChat(RoomMode.MULTI)" [class.active]="_roomService.mode() === RoomMode.MULTI">
    <div class="menu-icon">
      @if (pending[RoomMode.MULTI]) {
        <ion-icon name="sync-outline" class="text-2xl spin-animation"></ion-icon>
      } @else {
        <ion-icon name="chatbubbles-outline" class="text-2xl"></ion-icon>
      }
    </div>
    <div class="label">{{ 'button.chat.mode.conference' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="openRoomsHistory()">
    <div class="menu-icon">
      <ion-icon name="calendar-clear-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.my.conversations' | transloco }}</div>
  </div>
  <div class="menu-item" (click)="openDocuments()">
    <div class="menu-icon">
      <ion-icon name="documents-outline" class="text-2xl"></ion-icon>
    </div>
    <div class="label">{{ 'button.my.documents' | transloco }}</div>
  </div>
  @if ([ UserRole.COMPANY_USER, UserRole.COMPANY_HOST, UserRole.COMPANY_ADMIN ].indexOf(me()?.role) >= 0) {
    <div class="menu-item" (click)="navigate()">
      <div class="menu-icon">
        <ion-icon name="chatbubbles-outline" class="text-xl"></ion-icon>
      </div>
      <div class="label">{{ 'button.chats' | transloco }}</div>
    </div>
  }
</ng-template>
