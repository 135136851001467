import { Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { ChatMessageDto, ChatMessageType, RoomUser } from '@shared/transport.interface';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { checkmarkDoneCircle, checkmarkDoneCircleOutline } from 'ionicons/icons';

@Component({
  selector: 'app-chat-messages',
  standalone: true,
  imports: [
    DatePipe,
    NgTemplateOutlet,
    TranslocoPipe,
    InitialsAvatarComponent,
    NgClass,
    IonicModule
  ],
  templateUrl: './chat-messages.component.html',
  styleUrl: './chat-messages.component.scss'
})
export class ChatMessagesComponent {
  @Input() text: ChatMessageDto[] = [];
  @Output() messageSelected: EventEmitter<ChatMessageDto> = new EventEmitter<ChatMessageDto>();
  selectedMessage: ChatMessageDto;

  private _store = inject(Store);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly ChatMessageType = ChatMessageType;

  constructor() {
    addIcons({ checkmarkDoneCircleOutline, checkmarkDoneCircle });
  }

  selectChatMessage(t: ChatMessageDto): void {
    this.selectedMessage = t;
    this.messageSelected.next(t);
  }
}
