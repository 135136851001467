import { Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotyService } from '@shared/service/noty.service';
import { AIChatError, RecorderStatus, RoomAction, RoomUser, Switcher, TranslateStatus } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { DialogService } from '@shared/service/dialog.service';
import { IonicModule, Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { mic, micOff, square } from 'ionicons/icons';
import { VoiceRecorder } from 'fpmk-capacitor-voice-recorder';
import { GenericResponse } from 'fpmk-capacitor-voice-recorder/dist/esm/definitions';
import { AudioService } from '@service/audio.service';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoomService } from '@service/room.service';

@UntilDestroy()
@Component({
  selector: 'app-realtime-stream-button',
  standalone: true,
  imports: [
    IonicModule
  ],
  templateUrl: './realtime-stream-button.component.html',
  styleUrl: './realtime-stream-button.component.scss'
})
export class RealtimeStreamButtonComponent {
  @Input() lang: string;
  @Input() disabled: boolean = false;

  @Output() noMinutesEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() recordStartedEvent: EventEmitter<void> = new EventEmitter<void>();

  status: TranslateStatus | RoomAction = TranslateStatus.READY;
  error: AIChatError = null;
  timer = '00:00';
  recorderStatus: RecorderStatus = RecorderStatus.READY;

  private readonly _dialog = inject(DialogService);
  private readonly _store = inject(Store);
  private readonly _notyService = inject(NotyService);
  private readonly _audioService = inject(AudioService);
  private readonly _platform = inject(Platform);
  private readonly _roomService = inject(RoomService);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected isIosPhone = false;
  protected readonly TranslateStatus = TranslateStatus;
  protected readonly RoomAction = RoomAction;
  protected readonly RecorderStatus = RecorderStatus;

  private audioData = new Float32Array();

  constructor() {
    addIcons({ mic, micOff, square });
    this.isIosPhone = (this._platform.is('iphone') || this._platform.is('ipad')) && !this._platform.is('mobileweb');
    this._audioService.dataStream
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.processBase64Audio(res.getChannelData(0));
      });
  }

  startRecording() {
    // this._audioService.initAudioForIos();
    this._audioService.stopAudio();
    if (!this.me().duration) {
      this.noMinutesEvent.next();
      return;
    }
    if (this.disabled) {
      this._notyService.error('text.select.language');
      return;
    }
    if (this.recorderStatus === RecorderStatus.READY) {
      VoiceRecorder.requestAudioRecordingPermission().then((res: GenericResponse) => {
        if (res.value) {
          this.startRecord0();
          this.recordStartedEvent.emit();
        } else {
          this._dialog.microphoneAccess();
        }
      }).catch(error => console.log(error));
    } else {
      this.stopRecord();
    }
  }

  startRecord0() {
    if (this.status === RoomAction.TRANSCRIPT || this.status === RoomAction.TRANSLATE) {
      return;
    }
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    } else {
      this._audioService.initAudioForIos();
    }
    this.error = null;
    this.timer = '00:00';
    console.log('Realtime enabled:', this.me().preferences?.realtime === Switcher.ON);
    this._audioService.startRecording(null, this._roomService.isSingleMode);
    this.recorderStatus = RecorderStatus.RECORDING;
  }

  stopRecord() {
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      Haptics.impact({ style: ImpactStyle.Light }).then();
    }
    this._audioService.stopRecording(false, false);
    this.recorderStatus = RecorderStatus.READY;
  }

  processBase64Audio(normalizedData: Float32Array) {
    const chunkSize = Math.floor(normalizedData.length);
    this.audioData = new Float32Array();
    const start = 0;
    const end = start + chunkSize;
    const slice = normalizedData.slice(start, end);
    this.audioData[ 0 ] = (slice.reduce((sum, value) => sum + Math.abs(value), 0) / slice.length || 0);
    console.log(this.audioData[ 0 ]);
  }
}
