import { Component, Input, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';
import { ChatResultType } from '@shared/transport.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-room-result-selector',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoPipe,
    MatIconButton,
    MatIcon,
    MatInput,
    LoaderComponent,
    IonicModule
  ],
  templateUrl: './room-result-selector.component.html',
  styleUrl: './room-result-selector.component.scss'
})
export class RoomResultSelectorComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  selectedType: ChatResultType;

  chatResultTypes = [ ChatResultType.SUMMARY, ChatResultType.PROTOCOL, ChatResultType.TRANSCRIPT ];

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.selectedType = this.data.type;
    }
  }

  selectResult(res: ChatResultType): void {
    this.closeModal(res);
  }

}
