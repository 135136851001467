import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, inject } from '@angular/core';
import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { ScanQrCodeButtonComponent } from '@shared/component/ui/scan-qr-code-button/scan-qr-code-button.component';
import { NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    ScanQrCodeButtonComponent,
    NgOptimizedImage
  ]
})
export class HeaderComponent {

  private router = inject(Router);

  goHome(): void {

  }
}
