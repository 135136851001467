<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <div class="w-full text-center p-2 border-b border-b-gray-300 bg-color-1">
      <div class="flex justify-between items-center mx-auto">
        <div class="w-[48px]">&nbsp;</div>
        <div class="flex flex-auto items-center title text-4xl pl-4">
          {{ 'title.members' | transloco }}
        </div>
        <div class="w-[48px]">
          <button mat-icon-button (click)="closeModal()">
            <ion-icon name="close-outline" class="text-4xl cursor-pointer"></ion-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white">
      <div class="flex flex-col w-full p-5 flex-auto overflow-y-auto">
        <div class="flex flex-col w-full flex-auto">
          @for (user of users; track user.id; let ind = $index; let first = $first; let last = $last) {
            <div class="py-2.5" [ngClass]="{'border-t border-t-gray-300': !first}">
              <app-user-card [me]="user"></app-user-card>
            </div>
          } @empty {
            <div class="flex flex-col justify-center mt-5 items-center">
              <ion-icon name="search-outline" class="text-6xl"></ion-icon>
              <div class="title text-4xl mt-2.5">{{ 'title.no.members' | transloco }}</div>
            </div>
          }
        </div>
      </div>
    </div>

  </div>
</div>
