import { Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { Store } from '@ngxs/store';
import { PrettyDatePipe } from '@pipe/pretty-date.pipe';
import { InitialsAvatarComponent } from '../ui/initials-avatar/initials-avatar.component';
import { RoomMode, RoomSummary, RoomUser } from '../../transport.interface';
import { DialogService } from '@service/dialog.service';
import { UserState } from '../../store/user/user-state.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline, shareOutline, trashOutline } from 'ionicons/icons';

@Component({
  selector: 'app-show-summary',
  standalone: true,
  imports: [
    PrettyDatePipe,
    TranslocoPipe,
    InitialsAvatarComponent,
    AsyncPipe,
    MatIconButton,
    IonicModule
  ],
  templateUrl: './show-summary.component.html',
  styleUrl: './show-summary.component.scss'
})
export class ShowSummaryComponent {
  @Input() summary: RoomSummary;
  @Output() closeEvent: EventEmitter<RoomSummary> = new EventEmitter<RoomSummary>();

  private _dialogService = inject(DialogService);
  private _store: Store = inject(Store);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);

  constructor() {
    addIcons({ closeOutline, calendarOutline, shareOutline, trashOutline });
  }

  closeDialog(): void {
    this.closeEvent.next(this.summary);
  }

  shareSummary(): void {
    this._dialogService.shareSummary(this.summary)
      .subscribe(res => {
        if (res) {
          this.summary.shared = true;
        }
      });
  }

  deleteSummary(): void {
    this._dialogService.deleteSummary(this.summary)
      .subscribe(res => {
        if (res) {
          this.summary.deleted = true;
          this.closeDialog();
        }
      });
  }

  protected readonly RoomMode = RoomMode;
}
