<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.gain.more.time' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="flex flex-col items-center w-full flex-auto relative bg-white">
        @if (loading) {
          <app-loader></app-loader>
        } @else {
          <div class="flex flex-col w-full p-5 flex-auto">
            <div class="items-center w-full">
              <div class="flex flex-col justify-center">
                <div class="w-full">
                  <!--                <app-tariff-info [upgradeLink]="false"></app-tariff-info>-->
                  <div class="title text-4xl">{{ 'title.minutes.packages' | transloco }}</div>
                  <div class="mt-5">
                    @for (pack of tariffPackages; track pack.id) {
                      <app-minutes-package-card [tariff]="tariff" [pack]="pack" class="mb-5 block"></app-minutes-package-card>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </ion-content>

  </div>
</div>
