<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (stepper.selectedIndex > 0) {
        <button mat-icon-button (click)="stepperBack()">
          <ion-icon name="arrow-back" class="text-4xl"></ion-icon>
        </button>
      }
    </ion-buttons>
    <ion-title>
      @if (stepper.selectedIndex === 0) {
        {{ 'title.summary.discussions' | transloco }}
      } @else if (stepper.selectedIndex === 1) {
        {{ 'title.creation.period' | transloco }}
      }
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="flex flex-col items-center w-full flex-auto overflow-x-hidden relative bg-white">
        <mat-stepper [disableRipple]="true" [animationDuration]="'200ms'" class="h-full w-full flex flex-col overflow-hidden" #stepper>
          <mat-step class="flex flex-col">
            <div class="flex flex-col w-full p-5 flex-auto overflow-hidden">
              <div class="mb-5">{{ 'text.summary.discussions' | transloco }}</div>
              <div class="flex flex-col w-full flex-auto overflow-y-auto" #container>
                @for (result of results; track result.type) {
                  <div id="data.type.{{result.type}}" class="flex flex-col border border-gray-300 py-5 px-4 mb-5 cursor-pointer" [class.active]="type === result.type"
                       (click)="chooseResult(result)">
                    <div class="w-full flex items-start">
                      <div class="w-[70px] flex items-center justify-center">
                        <ion-icon [src]="result.icon" class="text-5xl"></ion-icon>
                      </div>
                      <div class="ltr:ml-4 rtl:mr-4">
                        <div class="title text-3xl">{{ result.title | transloco }}</div>
                        <div class="mt-1">{{ result.description | transloco }}</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </mat-step>
          <mat-step>
            <div class="flex flex-col w-full p-5 flex-auto overflow-y-auto">
              <div>{{ 'text.chat.result.period.' + type | transloco }}</div>
              <div class="flex flex-auto flex-col">
                <div class="mt-5 flex w-full py-4 border-t border-b border-gray-300 justify-between">
                  <div class="flex items-center">
                    <div>{{ 'text.show.in.chat' | transloco }}</div>
                    <ion-icon name="information-circle" class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ltr:ml-1 rtl:mr-1 cursor-pointer"
                              #tooltip="matTooltip"
                              matTooltip="{{'tooltip.summary.show.chat' | transloco}}"
                              [matTooltipPosition]="isRtl() ? 'left' : 'right'" (click)="tooltip.toggle()"
                    ></ion-icon>
                  </div>
                  <div>
                    <app-switch [formControl]="showChatCtrl"></app-switch>
                  </div>
                </div>

                <div class="mt-5">
                  <div class="flex items-center">
                    <div class="title text-3xl">{{ 'text.begin' | transloco }}</div>
                    <ion-icon name="information-circle" class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ltr:ml-1 rtl:mr-1 cursor-pointer"
                              #tooltip1="matTooltip"
                              matTooltip="{{'tooltip.summary.start.date' | transloco}}"
                              [matTooltipPosition]="isRtl() ? 'left' : 'right'" (click)="tooltip1.toggle()"
                    ></ion-icon>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="flex">
                    <mat-form-field class="w-1/2">
                      <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
                      <input matInput [formControl]="fromCtrl" [matDatepicker]="pickerDateFrom" [placeholder]="'placeholder.date' | transloco"
                             class="title ltr:pl-1.5 rtl:pr-1.5"
                             (click)="pickerDateFrom.open()" (dateChange)="fromDateSelected($event)">
                      <mat-datepicker #pickerDateFrom></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="ltr:ml-5 rtl:mr-5 w-1/2">
                      <ion-icon name="time-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
                      <input matInput
                             class="title date-time ltr:pl-1.5 rtl:pr-1.5"
                             name="selected_time_g"
                             [format]="24"
                             [formControl]="fromTimeCtrl"
                             [ngxMatTimepicker]="pickerFrom"
                             placeholder="00:00"
                             readonly/>
                    </mat-form-field>
                    <ngx-mat-timepicker #pickerFrom (timeSet)="fromTimeSelected($event)"></ngx-mat-timepicker>
                  </div>
                </div>

                @if (showChatCtrl.value) {
                  <app-summary-chat [loading]="fromPending" [text]="fromMessages" [start]="true" (messageSelected)="fromMessageSelected($event)"></app-summary-chat>
                }

                <div class="mt-5">
                  <div class="flex items-center">
                    <div class="title text-3xl">{{ 'text.end' | transloco }}</div>
                    <ion-icon name="information-circle"
                              class="fa-solid fa-circle-info fa-xs text-gray-300 leading-loose	ltr:ml-1 rtl:mr-1 cursor-pointer"
                              #tooltip2="matTooltip"
                              matTooltip="{{'tooltip.summary.end.date' | transloco}}"
                              [matTooltipPosition]="isRtl() ? 'left' : 'right'" (click)="tooltip2.toggle()"
                    ></ion-icon>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="flex">
                    <mat-form-field class="w-1/2">
                      <ion-icon name="calendar-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
                      <input matInput [formControl]="toCtrl" [matDatepicker]="pickerDateTo" [placeholder]="'placeholder.date' | transloco"
                             class="title text-3xl ltr:pl-1.5 rtl:pr-1.5"
                             (click)="pickerDateTo.open()" (dateChange)="toDateSelected($event)">
                      <mat-datepicker #pickerDateTo></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="ltr:ml-5 rtl:mr-5 w-1/2">
                      <ion-icon name="time-outline" class="text-gray-400 text-2xl pr-2" matIconPrefix></ion-icon>
                      <input matInput
                             class="title date-time ltr:pl-1.5 rtl:pr-1.5"
                             name="selected_time_g"
                             [format]="24"
                             [formControl]="toTimeCtrl"
                             [ngxMatTimepicker]="pickerTo"
                             placeholder="23:59"
                             readonly/>
                    </mat-form-field>
                    <ngx-mat-timepicker #pickerTo (timeSet)="toTimeSelected($event)"></ngx-mat-timepicker>
                  </div>
                </div>
                @if (showChatCtrl.value) {
                  <app-summary-chat [loading]="toPending" [text]="toMessages" [start]="false" (messageSelected)="toMessageSelected($event)"></app-summary-chat>
                }
                @if (error) {
                  <app-chat-error [position]="'top'" [error]="error" [translate]="true"></app-chat-error>
                }
              </div>
            </div>
          </mat-step>
          <mat-step>
            <div class="overflow-hidden w-full h-full flex-auto flex flex-col bg-white">
              @if (type === ChatResultType.SUMMARY && summary?.id) {
                <app-show-summary [summary]="summary" (closeEvent)="closeModal($event)"></app-show-summary>
              } @else if (type === ChatResultType.TRANSCRIPT && transcript?.id) {
                <app-show-transcript [transcript]="transcript" (closeEvent)="closeModal($event)"></app-show-transcript>
              } @else if (type === ChatResultType.PROTOCOL && protocol?.id) {
                <app-show-protocol [protocol]="protocol" (closeEvent)="closeModal($event)"></app-show-protocol>
              }
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <div class="pb-2 pt-1">
          @if (stepper.selectedIndex === 0) {
            <button class="button w-full" (click)="resultChosen()">{{ 'button.next' | transloco }}</button>
          } @else if (stepper.selectedIndex === 1) {
            <button class="button w-full" [disabled]="form.invalid || pending" [class.loading]="pending" (click)="createSummary()">{{ 'button.create' | transloco }}</button>
          }
        </div>
      </ion-toolbar>
    </ion-footer>
  </div>
</div>
