<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (stepper.selectedIndex > 0) {
        <ion-icon name="arrow-back-outline" class="cursor-pointer text-4xl" (click)="stepper.selectedIndex = 0"></ion-icon>
      }
    </ion-buttons>
    <ion-title>
      @if (stepper.selectedIndex === 0) {
        {{ 'title.profile' | transloco }}
      } @else if (stepper.selectedIndex === 1) {
        {{ 'title.my.account' | transloco }}
      } @else if (stepper.selectedIndex === 2) {
        {{ 'title.address' | transloco }}
      } @else if (stepper.selectedIndex === 3) {
        {{ 'title.invoices' | transloco }}
      } @else if (stepper.selectedIndex === 4) {
        {{ 'title.notifications' | transloco }}
      } @else if (stepper.selectedIndex === 5) {
        {{ 'title.security' | transloco }}
      } @else if (stepper.selectedIndex === 6) {
        {{ 'title.preference' | transloco }}
      }
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>

      <div class="flex flex-col items-center w-full flex-auto overflow-y-auto overflow-x-hidden relative bg-white">
        <mat-stepper [disableRipple]="true" [animationDuration]="'200ms'" class="h-full w-full flex flex-col overflow-hidden" #stepper>
          <mat-step class="flex flex-col">
            <div class="flex flex-col w-full p-5 flex-auto overflow-y-auto">
              <div class="flex flex-col w-full flex-auto">
                <div class="flex flex-col items-center w-full overflow-hidden">
                  <div>
                    <app-initials-avatar [avatarId]="me()?.avatarId" [user]="{name: me()?.name}" [width]="100" [textSize]="'text-4xl'"></app-initials-avatar>
                  </div>
                  <div class="w-full">
                    <div class="mt-7 title text-4xl text-center truncate">{{ me()?.name }}</div>
                  </div>
                  @if (me()?.companyRole) {
                    <div class="text-base text-center mt-1.5">{{ me()?.companyRole }}</div>
                  }
                  @if (me()?.companyRoleDesc) {
                    <div class="text-base text-center text-gray-400">{{ me()?.companyRoleDesc }}</div>
                  }
                </div>
                <div class="w-full mt-8 border-t border-t-gray-300">
                  @for (item of menu; track item.id) {
                    @if (item.roles.indexOf(me().role) >= 0) {
                      <div class="border-b border-b-gray-300 flex items-center py-5 px-2.5 cursor-pointer menu-item" (click)="menuSelected(item)">
                        <i class="{{item.icon}}"></i>
                        <ion-icon name="{{item.icon}}" class="text-3xl"></ion-icon>
                        <div class="text-base ltr:ml-2.5 rtl:mr-2.5">{{ 'menu.profile.' + item.id | transloco }}</div>
                      </div>
                    }
                  }
                </div>
              </div>
              @if (me().id) {
                <div class="pt-7">
                  <div class="text-xs text-gray-400 text-center">v{{ environment.appVersion }}</div>
                  <button class="button w-full stroked red borderless" [class.loading]="pending" [disabled]="pending" (click)="logout()">{{ 'button.logout' | transloco }}</button>
                </div>
              }
            </div>
          </mat-step>
          <mat-step>
            <!--     1      -->
            <app-edit-profile></app-edit-profile>
          </mat-step>
          <mat-step>
            <!--     2      -->
          </mat-step>
          <mat-step>
            <!--     3      -->
            <app-invoices></app-invoices>
          </mat-step>
          <mat-step>
            <!--     4      -->
            <app-notifications></app-notifications>
          </mat-step>
          <mat-step>
            <!--     5      -->
            <app-security></app-security>
          </mat-step>
          <mat-step>
            <!--     6      -->
            <app-preference></app-preference>
          </mat-step>
        </mat-stepper>
      </div>


    </ion-content>
  </div>
</div>
