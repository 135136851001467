<div class="overflow-hidden w-full flex flex-col bg-white p-5">
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="title text-4xl">{{ 'title.delete.transcript' | transloco }}</div>
      <button mat-icon-button (click)="closeModal()">
        <ion-icon name="close-outline" class="text-4xl cursor-pointer"></ion-icon>
      </button>
    </div>
    <div class="mt-2">
      <span [innerHTML]="'text.delete.transcript.warning' | transloco: {title: transcript?.title}"></span>
    </div>
    <div class="mt-5">
      <button class="button red w-full" (click)="deleteTranscript()" [disabled]="pending" [class.loading]="pending">{{'button.delete' | transloco}}</button>
      <button class="button stroked w-full" (click)="closeModal()" [disabled]="pending">{{'button.cancel' | transloco}}</button>
    </div>
  </div>
</div>
