<div class="flex flex-col relative w-full h-full">
  <ion-content #scrollArea>
    @for (msg of messages; track msg.id; let even = $even) {
      <div class="p-4 w-full relative" [class.bg-gray-50]="even">
        @if (msg.originalText) {
          {{ msg.originalText }}
        } @else {
          <app-loader></app-loader>
        }
      </div>
    } @empty {
      <div class="text-gray-300 p-4 italic">{{'message.start.speak' | transloco}}</div>
    }
  </ion-content>
</div>
