<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ 'title.tariffs' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
    <div class="flex flex-col w-full bg-white">
      @if (loading) {
        <app-loader></app-loader>
      }
      <div class="flex flex-col w-full p-5">
        <div class="items-center w-full">
          <div class="flex flex-col justify-center">
            @for (tariff of tariffs; track tariff.id) {
              <app-tariff-item-card [tariff]="tariff" [tariffDescription]="tariffDescriptions[tariff.uid]" class="mb-5 block"></app-tariff-item-card>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
