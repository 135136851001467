import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Store } from '@ngxs/store';
import { SiteInfo } from '@shared/transport.interface';
import { SiteInfoState } from '@shared/store/site/site-state.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private _platform = inject(Platform);
  private _store = inject(Store);
  private readonly _siteInfo = this._store.selectSignal(SiteInfoState.getSiteInfo);

  constructor() {
  }

  secondsToTimerString(time: number): string {
    time = Math.floor(time / 1000);
    const minutes = `${ Math.floor(time / 60) }`.padStart(2, "0");
    const seconds = `${ time - +minutes * 60 }`.padStart(2, "0");
    let outStr = '';
    if (minutes) {
      outStr += `${ minutes }:`;
    } else {
      outStr += `00:`;
    }
    if (seconds) {
      outStr += `${ seconds }`;
    }
    return outStr;
  }

  extractFirstLetters(name: string | undefined): string {
    if (!name) {
      return '-';
    }
    let split = name.split(' ');
    if (split?.length > 1) {
      return split[ 0 ].substring(0, 1) + split[ 1 ].substring(0, 1);
    }
    return split[ 0 ].substring(0, 2);
  }

  scrollToId(id: string): void {
    if (id && document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  }

  getLogo(logo: string): Observable<Blob> {
    return fromPromise(fetch(logo)).pipe(switchMap(result => fromPromise(result.blob())));
  }

  public customPasswordMatching(control: FormGroup): ValidationErrors | null {
    const newPassword = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (newPassword !== confirmPassword) {
      control.get('confirmPassword').setErrors({ passwordsMismatch: true });
    } else {
      control.get('confirmPassword').setErrors(null);
    }
    return null;
  }

  async statusBar() {
    if (this._platform.is('mobile') && !this._platform.is('mobileweb')) {
      setTimeout(() => {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setBackgroundColor({ color: this._siteInfo()?.commonSettings?.toolbarBg || '#EEF4FF' });
        setTimeout(() => {
          StatusBar.setStyle({ style: Style.Light });
        }, 200);
      }, 200);
    }
  }

  setCaretPositionToEnd(editor: HTMLDivElement): void {
    const selection = window.getSelection();
    const range = document.createRange();

    const lastChild = editor.lastChild;

    if (lastChild) {
      // Set the range to the end of the last child node
      if (lastChild.nodeType === Node.TEXT_NODE) {
        range.setStart(lastChild, lastChild.textContent?.length || 0);
        range.setEnd(lastChild, lastChild.textContent?.length || 0);
      } else if (lastChild.nodeType === Node.ELEMENT_NODE) {
        // If it's an element node, go to its last text node
        range.setStart(lastChild, lastChild.childNodes.length);
        range.setEnd(lastChild, lastChild.childNodes.length);
      }
    } else {
      // If no children, set range to the editor itself
      range.setStart(editor, 0);
      range.setEnd(editor, 0);
    }

    selection.removeAllRanges();
    selection.addRange(range);
  }

  getCaretCharacterOffsetWithin(contentEditableElement) {
    let caretPos = 0;

    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Check if the selection is inside the contenteditable element
      if (contentEditableElement.contains(range.commonAncestorContainer)) {
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(contentEditableElement);
        preCaretRange.setEnd(range.endContainer, range.endOffset);

        caretPos = preCaretRange.toString().length;
      }
    }

    return caretPos;
  }

  insertTextAtCaret(contenteditableDiv, text: string, caretPosition: number) {
    // Focus the contenteditable div to ensure the caret is active
    contenteditableDiv.focus();

    // Handle the range and insertion
    const selection = window.getSelection();
    const range = selection.rangeCount ? selection.getRangeAt(0) : document.createRange();

    // Create the new range and traverse the child nodes
    let node = contenteditableDiv;
    let offset = caretPosition;

    for (let i = 0; i < contenteditableDiv.childNodes.length; i++) {
      const child = contenteditableDiv.childNodes[i];
      const childLength = child.textContent.length;

      if (offset <= childLength) {
        node = child;
        range.setStart(node, offset);
        break;
      } else {
        offset -= childLength;
      }
    }

    range.collapse(true); // Collapse to the start of the range

    // Insert the text at the caret position
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    // Move the caret to after the inserted text
    range.setStartAfter(textNode);
    range.collapse(true);

    // Update the selection to reflect the new range
    selection.removeAllRanges();
    selection.addRange(range);

    // For mobile (iOS), set the caret explicitly using `setSelectionRange`
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStartAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Ensure the div remains focused
    contenteditableDiv.focus();
  }

  getCaretPosition(el: HTMLElement): any {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    return {
      startOffset: range.startOffset,
      endOffset: range.endOffset,
      startContainerPath: this.getNodePath(range.startContainer, el),
      endContainerPath: this.getNodePath(range.endContainer, el),
    };
  }

  // insertTextAtPosition(el: HTMLDivElement, text: string, position: number) {
  //   const content = el.innerHTML;
  //   let beforeText, afterText, finalText;
  //
  //   if (position <= 0) {
  //     // Insert before the sentence
  //     beforeText = `${text} `;
  //     finalText = beforeText + content;
  //   } else if (position >= content.length) {
  //     // Insert after the sentence
  //     const beforeSpace = content[content.length - 1] !== ' ' ? ' ' : '';
  //     const camelText = text.charAt(0).toUpperCase() + text.slice(1);
  //     finalText = content + beforeSpace + camelText;
  //   } else {
  //     // Insert inside the sentence
  //     beforeText = content.slice(0, position);
  //     afterText = content.slice(position);
  //     const spaceBefore = beforeText.endsWith(' ') ? '' : ' ';
  //     const spaceAfter = afterText.startsWith(' ') ? '' : ' ';
  //     const lowerText = text.toLowerCase();
  //
  //     finalText = `${beforeText}${spaceBefore}${lowerText}${spaceAfter}${afterText}`;
  //   }
  //
  //   el.innerText = finalText;
  //
  //   // Restore the caret position after the inserted text
  //   const newPosition = position + text.length + 1; // Account for added spaces
  //   this.setCaretByPosition(el, newPosition);
  // }

  // private setCaretByPosition(el, position) {
  //   const range = document.createRange();
  //   const sel = window.getSelection();
  //
  //   let charCount = 0;
  //   let node;
  //
  //   function traverseNodes(node) {
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       const nextCharCount = charCount + node.length;
  //       if (position >= charCount && position <= nextCharCount) {
  //         const offset = position - charCount;
  //         range.setStart(node, offset);
  //         range.collapse(true);
  //         return true;
  //       }
  //       charCount = nextCharCount;
  //     } else if (node.nodeType === Node.ELEMENT_NODE) {
  //       for (let child of node.childNodes) {
  //         if (traverseNodes(child)) return true;
  //       }
  //     }
  //     return false;
  //   }
  //
  //   traverseNodes(el);
  //   sel.removeAllRanges();
  //   sel.addRange(range);
  // }

  private getNodePath(node: Node, el: HTMLElement): number[] {
    const path = [];
    while (node && node !== el) {
      const parent = node.parentNode;
      if (!parent) break;
      // @ts-ignore
      path.unshift(Array.from(parent.childNodes).indexOf(node));
      node = parent;
    }
    return path;
  }

  setCaretPosition(savedSelection: any, el: HTMLDivElement): void {
    if (!savedSelection) return;
    const selection = window.getSelection();
    const range = document.createRange();

    const startContainer = this.getNodeFromPath(savedSelection.startContainerPath, el);
    const endContainer = this.getNodeFromPath(savedSelection.endContainerPath, el);
    if (startContainer && endContainer) {
      range.setStart(startContainer, savedSelection.startOffset);
      range.setEnd(endContainer, savedSelection.endOffset);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  scrollToCaret(contentEditableDiv: HTMLDivElement) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const containerRect = contentEditableDiv.getBoundingClientRect();

      // Check if the caret is outside the visible area of the contenteditable element
      if (rect.bottom > containerRect.bottom || rect.top < containerRect.top) {
        // Scroll the caret into view
        contentEditableDiv.scrollTop += rect.bottom - containerRect.bottom;
      }
    }
  }

  private getNodeFromPath(path: number[], el: HTMLDivElement): Node | null {
    let node: Node = el;
    for (const index of path) {
      if (node.childNodes[ index ]) {
        node = node.childNodes[ index ];
      } else {
        return null;
      }
    }
    return node;
  }
}
