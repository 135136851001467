import { Component, ElementRef, inject, Input, OnInit, Signal, viewChild, ViewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatIconButton } from '@angular/material/button';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldModule, MatPrefix } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerComponent, NgxMatTimepickerDirective, NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
// tslint:disable-next-line:no-duplicate-imports
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { finalize } from 'rxjs';
import { Store } from '@ngxs/store';
import { SummaryChatComponent } from '../../summary-chat/summary-chat.component';
import { ShowSummaryComponent } from '../../show-summary/show-summary.component';
import { ChatErrorComponent } from '../../ui/chat-error/chat-error.component';
import { ShowTranscriptComponent } from '../../show-transcript/show-transcript.component';
import { ShowProtocolComponent } from '@shared/component/_dialog/show-protocol/show-protocol.component';
import { MatTooltip } from '@angular/material/tooltip';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@app/app.config';
import { RoomService } from '@shared/service/room.service';
import { NotyService } from '@shared/service/noty.service';
import { LangService } from '@shared/service/lang.service';
import { ChatMessageDto, ChatResultType, Room, RoomProtocol, RoomSummary, RoomTranscript, RoomUser, TariffEnum } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { ErrorObject } from '@shared/http-client/error-object.class';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { arrowBack, calendarOutline, informationCircle, timeOutline } from 'ionicons/icons';
import { ModalController } from '@ionic/angular/standalone';
import { DialogService } from '@service/dialog.service';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { InitialsAvatarComponent } from '@comp/ui/initials-avatar/initials-avatar.component';

const moment = _rollupMoment || _moment;

@UntilDestroy()
@Component({
  selector: 'app-create-summary',
  standalone: true,
  imports: [
    TranslocoPipe,
    MatStepper,
    MatStep,
    MatIconButton,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxMatTimepickerModule,
    NgxMatTimepickerComponent,
    NgxMatTimepickerDirective,
    MatPrefix,
    MatDatepicker,
    MatDatepickerInput,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, SummaryChatComponent, ShowSummaryComponent, ChatErrorComponent,
    ShowTranscriptComponent, ShowProtocolComponent, MatTooltip, IonicModule, DatePipe, NgTemplateOutlet, InitialsAvatarComponent, NgClass
  ],
  providers: [ provideMomentDateAdapter(MY_FORMATS) ],
  templateUrl: './create-summary.component.html',
  styleUrl: './create-summary.component.scss'
})
export class CreateSummaryComponent implements OnInit {
  private _modalCtrl = inject(ModalController);

  @Input() data: any;

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  protected readonly _roomService = inject(RoomService);
  private readonly _fb = inject(FormBuilder);
  private readonly _store = inject(Store);
  private readonly _noty = inject(NotyService);
  private readonly _langService = inject(LangService);
  private readonly _dialogService = inject(DialogService);

  protected _room: Room;
  protected type: ChatResultType = ChatResultType.SUMMARY;
  protected form: FormGroup;
  protected fromCtrl = new FormControl({ value: moment(), disabled: true }, [ Validators.required ]);
  protected fromTimeCtrl = new FormControl<string>('00:00');
  protected toCtrl = new FormControl({ value: moment(), disabled: true }, [ Validators.required ]);
  protected toTimeCtrl = new FormControl<string>('23:59');
  protected pending = false;
  protected endBeforeStart = false;
  protected summary: RoomSummary;
  protected transcript: RoomTranscript;
  protected protocol: RoomProtocol;
  protected readonly ChatResultType = ChatResultType;
  protected error = null;
  protected fromChatMessageSelected: ChatMessageDto;
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly container = viewChild<ElementRef>('container');

  protected results = [
    new ChatResult(ChatResultType.SUMMARY, 'title.chat.result.', '/assets/icons/awesome/icon-file-lines.svg', 'text.chat.result.'),
    new ChatResult(ChatResultType.PROTOCOL, 'title.chat.result.', '/assets/icons/awesome/icon-file-invoice.svg', 'text.chat.result.'),
    new ChatResult(ChatResultType.TRANSCRIPT, 'title.chat.result.', '/assets/icons/awesome/icon-file-contract.svg', 'text.chat.result.'),
  ];

  constructor() {
    addIcons({ informationCircle, arrowBack, calendarOutline, timeOutline });
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      from: [ null, [ Validators.required ] ],
      to: [ null, [ Validators.required ] ],
    });
    const date = this.fromCtrl.value as Moment;
    if (date) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      this.form.controls.from.setValue(date.valueOf());
    }
    const toDate = this.toCtrl.value as Moment;
    if (toDate) {
      toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
      this.form.controls.to.setValue(toDate.valueOf());
    }

    if (this.data) {
      this._room = this.data.room;
      this.type = this.data.type || ChatResultType.SUMMARY;
      if (this.data.type) {
        setTimeout(() => {
          document.getElementById(`data.type.${ this.type }`).scrollIntoView({ behavior: "smooth" })
          if (this.data.type >= 0) {
            this.resultChosen();
          }
        }, 10);
      }
    }
  }

  chooseResult(result: ChatResult): void {
    this.type = result.type;
  }

  fromMessageSelected($event: ChatMessageDto): void {
    this.fromChatMessageSelected = $event;
    const time = moment($event.time * 1000) as Moment;
    this.fromCtrl.setValue(time, { onlySelf: true });
    this.fromTimeCtrl.setValue(time.hour() + ':' + time.minute());
  }

  toMessageSelected($event: ChatMessageDto): void {
    this.endBeforeStart = false;
    const time = moment($event.time * 1000) as Moment;
    this.toTimeCtrl.setValue(time.hour() + ':' + time.minute());
    this.toCtrl.setValue(time, { onlySelf: true });
    this.checkToDate(time);
  }

  private checkToDate(time: Moment) {
    if (time.isBefore(this.fromCtrl.value)) {
      this.endBeforeStart = true;
      this._noty.error('message.date.to.before.from');
    }
  }

  createSummary(): void {
    this.error = null;
    const from = this.fromCtrl.value as Moment;
    from.set({ second: 0 });
    const to = this.toCtrl.value as Moment;
    to.set({ second: 59 });
    if (to.isBefore(from)) {
      this._noty.error('message.date.to.before.from');
      return;
    }
    this.pending = true;
    if (this.type === ChatResultType.SUMMARY) {
      this._roomService.createSummary(this._room.roomId, from.valueOf(), to.valueOf())
        .pipe(untilDestroyed(this), finalize(() => this.pending = false))
        .subscribe(res => {
          if (!res.id) {
            this.error = new ErrorObject(-1, 'error.no.summary.created');
            setTimeout(() => this.error = null, 3000);
            return;
          }
          this.summary = res;
          this._dialogService.closeAll();
          this._dialogService.showSummary(this.summary);
        });
    } else if (this.type === ChatResultType.TRANSCRIPT) {
      this._roomService.createTranscript(this._room.roomId, from.valueOf(), to.valueOf())
        .pipe(untilDestroyed(this), finalize(() => this.pending = false))
        .subscribe(res => {
          if (!res.id) {
            this.error = new ErrorObject(-1, 'error.no.transcript.created');
            setTimeout(() => this.error = null, 3000);
            return;
          }
          this.transcript = res;
          this._dialogService.closeAll();
          this._dialogService.showTranscript(this.transcript);
        });
    } else if (this.type === ChatResultType.PROTOCOL) {
      this._roomService.createProtocol(this._room.roomId, from.valueOf(), to.valueOf())
        .pipe(untilDestroyed(this), finalize(() => this.pending = false))
        .subscribe(res => {
          if (!res.id) {
            this.error = new ErrorObject(-1, 'error.no.protocol.created');
            setTimeout(() => this.error = null, 3000);
            return;
          }
          this.protocol = res;
          this._dialogService.closeAll();
          this._dialogService.showProtocol(this.protocol);
        });
    }
  }

  fromDateSelected($event: MatDatepickerInputEvent<any, any>): void {
    const date = $event.value as Moment;
    this.fromCtrl.setValue(date);
    const time = this.fromTimeCtrl.value.split(':');
    date.set({ hour: +time[ 0 ], minute: +time[ 1 ], second: 0 });
    this.form.controls.from.setValue(date.valueOf());
  }

  fromDateSelectedEvent() {
    this.endBeforeStart = false;
    this.stepper.next();
  }

  toDateSelected($event: MatDatepickerInputEvent<any, any>): void {
    const date = $event.value as Moment;
    this.toCtrl.setValue(date);
    const time = this.toTimeCtrl.value.split(':');
    date.set({ hour: +time[ 0 ], minute: +time[ 1 ], second: 59 });
    this.form.controls.to.setValue(date.valueOf());
    this.checkToDate(date);
  }

  fromTimeSelected($event: string): void {
    const date = this.fromCtrl.value as Moment;
    if (date) {
      const time = $event.split(':');
      date.set({ hour: +time[ 0 ], minute: +time[ 1 ], second: 0 });
      this.form.controls.from.setValue(date.valueOf());
    }
  }

  toTimeSelected($event: string): void {
    const date = this.toCtrl.value as Moment;
    if (date) {
      const time = $event.split(':');
      date.set({ hour: +time[ 0 ], minute: +time[ 1 ], second: 59 });
      this.form.controls.to.setValue(date.valueOf());
      this.checkToDate(date);
    }
  }

  stepperBack(): void {
    this.stepper.selectedIndex--;
  }

  resultChosen() {
    if (this.me().tariff.uid === TariffEnum.PREPAID) {
      this._noty.error('text.tariff.upgrade.message');
      return;
    }
    if (!this.me().duration) {
      this._noty.error('error.translation.minutes.expired');
      return;
    }
    this.stepper.next();
  }

  isRtl(): boolean {
    return this._langService.isActiveLangRtl();
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }

}

export class ChatResult {
  title: string;
  icon: string;
  description: string;
  type: ChatResultType;


  constructor(type: ChatResultType, title: string, icon: string, description: string) {
    this.title = title + type;
    this.icon = icon;
    this.description = description + type;
    this.type = type;
  }
}
