import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class NotyService {
  _toastController = inject(ToastController);
  _translateService = inject(TranslocoService);
  private lastToast: HTMLIonToastElement;

  success(message: string, params?: any): void {
    this.showToast('', message, params);
  }

  error(message: string, params?: any): void {
    this.showToast('error', message, params);
  }

  private showToast(cssClass: string, message: string, params?: any): void {
    if (this.lastToast) {
      this.lastToast.dismiss();
    }
    this._toastController.create({
      message: this._translateService.translate(message, params),
      duration: 4000,
      position: 'top',
      swipeGesture: 'vertical',
      cssClass: 'custom-toast ' + cssClass
    }).then(toast => {
      this.lastToast = toast;
      this.lastToast.present();
    });
  }
}
