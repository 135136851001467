@if (invoice?.id) {
  <div class="border border-gray-300 p-4">
    <div class="flex justify-between pb-2.5 border-b border-b-gray-300">
      <div class="title text-3xl">
        @if (invoice.subscription) {
          {{ 'tariff.' + invoice.tariffName | transloco }}
        } @else {
          {{'label.full.credits' | transloco}}
        }
      </div>
      <div class="flex items-center">
        <div class="title text-3xl">{{ invoice.amount | currency: invoice.currency }}</div>
        <div class="ltr:ml-2.5 rtl:mr-2.5">
          @if (invoice.invoiceUrl) {
            <a href="{{invoice.invoiceUrl}}" target="_blank"><i class="fa-solid fa-arrow-up-from-bracket fa-xl cursor-pointer"></i></a>
          }
        </div>
      </div>
    </div>
    <div class="flex mt-2.5 border-b border-b-gray-300 pb-2.5">
      <div class="w-1/2">
        <div class="text-base">{{ 'title.type' | transloco }}</div>
        <div class="font-semibold text-lg">
          @if (invoice.subscription) {
            {{'label.subscription' | transloco}}
          } @else {
            {{'label.package' | transloco}}
          }
        </div>
      </div>
      <div class="w-1/2 text-right">
        <div class="text-base">{{ 'title.included.credits' | transloco }}</div>
        <div class="font-semibold text-lg">{{ invoice.duration }}</div>
      </div>
    </div>
    <div class="flex mt-2.5">
      <div class="w-1/2">
        <div class="text-base">{{ 'title.purchase.date' | transloco }}</div>
        <div class="font-semibold text-lg">{{ invoice.purchaseDate | utcDate: 'dd.MM.yyyy' }}</div>
      </div>
      <div class="w-1/2 text-right">
        @if (invoice.subscription) {
          <div class="text-base">{{ 'title.renewal.date' | transloco }}</div>
          <div class="font-semibold text-lg">{{ invoice.renewalDate | utcDate: 'dd.MM.yyyy' }}</div>
        }
      </div>
    </div>
  </div>
}
