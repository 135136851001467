import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError, timeout } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppComponent } from '@app/app.component';
import { NotyService } from '@service/noty.service';
import { appComponentRef } from '../app-component';
import { ErrorCodes } from '../errorcodes.const';
import { ErrorObject } from './error-object.class';
import { StorageService } from '@service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  public headers!: HttpHeaders;

  private readonly _appComponentRef: AppComponent;
  private readonly _notyService = inject(NotyService);
  private readonly _storageService = inject(StorageService);
  private _http: HttpClient;

  constructor() {
    this._http = inject(HttpClient);
    this._appComponentRef = appComponentRef();
  }

  public handleError(error: any, _noty: NotyService, _appComponentRef: AppComponent, silent: boolean = false): Observable<any> {
    console.error(error);
    let outError: Observable<never>;
    let showToast = !silent;
    const code = error.error?.errorCode || error.error?.code || error.code || error.status;
    let message = error.error?.message || error.message || error.messages;
    if (code <= 400) {
      switch (code) {
        case ErrorCodes.INVALID_TOKEN:
        case ErrorCodes.UNAUTHORIZED:
          this._storageService.clear();
          if (_appComponentRef) {
            _appComponentRef.invalidSessionEvent();
          }
          outError = throwError(() => new ErrorObject(code, message));
          showToast = false;
          break;
        case ErrorCodes.BadCredentials:
        case ErrorCodes.BAD_PARAMS:
          if (message.indexOf('Connection refused') >= 0) {
            message = 'Service temporarily unavailable';
          }
          outError = throwError(() => new ErrorObject(code, message));
          break;
        case ErrorCodes.INTERNAL_ERROR:
          message = 'Service temporarily unavailable';
          if (_appComponentRef) {
            _appComponentRef.maintenanceEvent();
          }
          outError = throwError(() => new ErrorObject(code, message));
          break;
        default:
          outError = throwError(() => new ErrorObject(code, message));
      }
      if (showToast) {
        _noty.error(message);
      }
    } else if (error.status === 401 || error.status === 403) {
      this._storageService.clear();
      if (_appComponentRef) {
        _appComponentRef.invalidSessionEvent();
      }
      outError = throwError(error.error);
    } else if (error.status >= 500) {
      message = 'Service temporarily unavailable';
      if (_appComponentRef) {
        _appComponentRef.maintenanceEvent();
      }
      outError = throwError(() => new ErrorObject(error.status, error.error));
    }
    // @ts-ignore
    return outError;
  }

  public doGet<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      for (const property in params) {
        if (params.hasOwnProperty(property) && params[ property ]) {
          httpParams = httpParams.set(property, params[ property ]);
        }
      }
    }
    return this._http
      .get<T>(url, { headers: this.headers, params: httpParams })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef)));
  }

  public doGetNoError<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      for (const property in params) {
        if (params.hasOwnProperty(property) && params[ property ]) {
          httpParams = httpParams.set(property, params[ property ]);
        }
      }
    }
    return this._http
      .get<T>(url, { headers: this.headers, params: httpParams })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef, true)));
  }

  public doPost<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    return this._http
      .post<T>(url, params, { headers: this.headers })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef)))
      .pipe(timeout(60000));
  }

  public doPatch<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    return this._http
      .patch<T>(url, params, { headers: this.headers })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef)));
  }

  public doPostSilent<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    return this._http
      .post<T>(url, params, { headers: this.headers })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef, true)));
  }

  public doDelete<T>(url: string, params?: any): Observable<T> {
    this.setHeaders();
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      for (const property in params) {
        if (params.hasOwnProperty(property) && params[ property ]) {
          httpParams = httpParams.set(property, params[ property ]);
        }
      }
    }
    return this._http
      .delete<T>(url, { headers: this.headers, params: httpParams })
      .pipe<T>(catchError(error => this.handleError(error, this._notyService, this._appComponentRef)));
  }

  protected async setHeaders(): Promise<void> {
    this.headers = new HttpHeaders();
    let token = await this._storageService.accessToken();
    if (token) {
      this.headers = this.headers.set('Authorization', token);
      this.headers = this.headers.set('X-Authorization', token);
    }
  }
}
