import { AppComponent } from '../app.component';

let appComponent: AppComponent;
export const appComponentRef = (appC?: AppComponent): AppComponent => {
    if (appC) {
        appComponent = appC;
    }

    return appComponent;
};
