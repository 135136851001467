import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-line',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './date-line.component.html',
  styleUrl: './date-line.component.scss'
})
export class DateLineComponent {
  @Input() first: boolean;
  @Input() lastDate: Date | string | number;
  @Input() currentDate: Date | string | number;
}
