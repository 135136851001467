import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';
import { Room } from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { RoomService } from '@shared/service/room.service';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@UntilDestroy()
@Component({
  selector: 'app-delete-archived-room',
  standalone: true,
  imports: [
    MatIconButton,
    TranslocoPipe,
    IonicModule
  ],
  templateUrl: './delete-archived-room.component.html',
  styleUrl: './delete-archived-room.component.scss'
})
export class DeleteArchivedRoomComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  protected room: Room;
  protected pending = false;

  private _roomService = inject(RoomService);

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data) {
      this.room = this.data.room;
    }
  }

  deleteRoom(): void {
    this.pending = true;
    this._roomService.deleteArchivedRoom(this.room.roomId)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this.closeModal(res);
      });
  }
}
