<div class="flex flex-col flex-auto overflow-y-auto p-5 pt-0">
  @if (loading) {
    <app-loader></app-loader>
  } @else {
    <ion-content scroll-y="true" #scrollArea>
      @if (!upgradeTariff) {
        <ion-list>
          @for (protocol of protocols; track protocol.id; let ind = $index; let last = $last; let first = $first) {
            <app-date-line [first]="first" [lastDate]="protocols[ind - 1]?.createDate" [currentDate]="protocol.createDate"></app-date-line>
            <ion-item-sliding>
              <ion-item>
                <ion-label>
                  <div class="flex mb-2">
                    <div class="flex-auto cursor-pointer" (click)="showProtocol(protocol)">
                      <div class="line-clamp-2 title text-2xl">{{ protocol.title }}</div>
                      <div class="flex items-center text-gray-400 text-sm mt-1">
                        <div class="pr-2.5 mr-2.5 border-r border-r-gray-300 flex items-center whitespace-nowrap">
                          <i class="fa-regular fa-calendar text-gray-400 fa-sm"></i>
                          <div>{{ protocol?.createDate | prettyDate | async }}</div>
                        </div>
                        <div class="flex items-center flex-auto overflow-hidden">
                          <app-initials-avatar [avatarId]="protocol?.user?.avatarId" [user]="{name: protocol?.user?.name}" [width]="20"
                                               [textSize]="'text-[8px]'"></app-initials-avatar>
                          <div class="ltr:ml-2 rtl:mr-2 flex w-full overflow-hidden">
                            <div class="text-gray-400 truncate">{{ protocol?.user?.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item-options>
                <ion-item-option color="danger" (click)="deleteProtocol(protocol)" [disabled]="loading">{{ 'button.delete' | transloco }}</ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
            @if (!last && (protocols[ind + 1]?.createDate | utcDate: 'd') === (protocol.createDate | utcDate: 'd')) {
              <div class="flex w-full h-[1px] mb-2 mt-2">
                <div class="flex-auto border-b border-b-gray-300">&nbsp;</div>
              </div>
            }
          } @empty {
            <div class="flex flex-col justify-center mt-5 items-center">
              <ion-icon name="search-outline" class="text-6xl"></ion-icon>
              <div class="title text-2xl mt-2.5">{{ 'title.no.protocol.history' | transloco }}</div>
              <div class="mt-2.5 text-center text-base">{{ 'text.no.protocol.history' | transloco }}</div>
            </div>
          }
        </ion-list>
      } @else {
        <div class="mb-1">{{ 'text.tariff.upgrade.message' | transloco }}</div>
        <div class="blurred">
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="font-bold mt-5 blank-text">&nbsp;</div>
          <div class="mb-2">&nbsp;</div>
        </div>

      }
      <ion-infinite-scroll position="top" (ionInfinite)="loadMoreData($event)" [disabled]="empty">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  }
</div>
