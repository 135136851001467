<div class="flex flex-col w-full flex-auto" [formGroup]="form">
  <ion-content>
    <div class="w-full flex-auto p-5">
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.old.password' | transloco }}</mat-label>
      <input
        matInput
        [type]="oldPassword"
        formControlName="oldPassword">
      <i matIconSuffix class="fa-solid {{oldPassword === 'password' ? 'fa-eye' : 'fa-eye-slash'}} cursor-pointer" (click)="showPassword('old')"></i>
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.new.password' | transloco }}</mat-label>
      <input
        matInput
        [type]="newPassword"
        formControlName="password">
      <i matIconSuffix class="fa-solid {{newPassword === 'password' ? 'fa-eye' : 'fa-eye-slash'}} cursor-pointer" (click)="showPassword('new')"></i>
    </mat-form-field>
    <mat-form-field class="text-base w-full" subscriptSizing="dynamic">
      <mat-label>{{ 'label.confirm.password' | transloco }}</mat-label>
      <input
        matInput
        [type]="confirmPassword"
        formControlName="confirmPassword">
      <i matIconSuffix class="fa-solid {{confirmPassword === 'password' ? 'fa-eye' : 'fa-eye-slash'}} cursor-pointer" (click)="showPassword('confirm')"></i>
      @if (form.get('confirmPassword').getError('passwordsMismatch')) {
        <mat-error>
          {{ 'message.passwords.mismatch' | transloco }}
        </mat-error>
      }
    </mat-form-field>

  </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <div class="pb-2 pt-1">
        <button class="button w-full" [class.loading]="pending" [disabled]="pending" (click)="changePassword()">{{ 'button.change' | transloco }}</button>
      </div>
    </ion-toolbar>
  </ion-footer>
</div>
