import { Component, inject, Input, Signal } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { Store } from '@ngxs/store';
import { TariffTitleComponent } from '../tariff-title/tariff-title.component';
import { UtcDatePipe } from '../../pipe/utc-date.pipe';
import { IonicModule } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { DialogService } from '../../service/dialog.service';
import { UserState } from '../../store/user/user-state.service';
import { RoomUser, Tariff, TariffEnum } from '../../transport.interface';
import { UserService } from '../../service/user.service';
import { expandCollapse } from '../../animation/expand-collapse';

@UntilDestroy()
@Component({
  selector: 'app-tariff-item-card',
  standalone: true,
  imports: [
    DatePipe,
    CurrencyPipe,
    TranslocoPipe,
    TariffTitleComponent,
    UtcDatePipe,
    TariffTitleComponent,
    UtcDatePipe,
    IonicModule
  ],
  templateUrl: './tariff-item-card.component.html',
  styleUrl: './tariff-item-card.component.scss',
  animations: [ expandCollapse ]
})
export class TariffItemCardComponent {
  @Input() tariff: Tariff;
  @Input() tariffDescription: string[];
  private _store = inject(Store);
  private _dialogService = inject(DialogService);
  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  pending = false;
  itemState = 'collapsed';

  protected readonly TariffEnum = TariffEnum;
  private _userService = inject(UserService);

  constructor() {
    addIcons({ checkmarkCircleOutline });
  }


  buyTariff(): void {
    if (this.me().tariff?.uid === this.tariff.uid) {
      return;
    }
    this.pending = true;
    this._userService.buyTariff(this.tariff.id)
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        window.location.href = res.url;
      });
  }

  showTariffDetails() {
    this.itemState = this.itemState === 'collapsed' ? 'expanded' : 'collapsed';
  }

  cancelSubscription(): void {
    this._dialogService.cancelSubscription().subscribe(res => {
      if (res) {
        this._dialogService.closeAll();
      }
    });
  }
}
