import { Component, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { UserService } from '@service/user.service';
import { LangService } from '@service/lang.service';
import { NotyService } from '@service/noty.service';
import { Cookies, MessageTts, MicPosition, RoomUser, SelectItem, Switcher, UserRole, Voice } from '@shared/transport.interface';
import { UserState } from '@shared/store/user/user-state.service';
import { SaveUserAction } from '@shared/store/user/user.actions';
import { SelectboxComponent } from '@comp/ui/selectbox/selectbox.component';
import { SelectLangComponent } from '@comp/ui/select-lang/select-lang.component';
import { StorageService } from '@service/storage.service';
import { IonicModule, RangeCustomEvent } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { snowOutline, sunnyOutline, textOutline } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';

@UntilDestroy()
@Component({
  selector: 'app-preference',
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    SelectboxComponent,
    SelectLangComponent,
    IonicModule,
  ],
  templateUrl: './preference.component.html',
  styleUrl: './preference.component.scss'
})
export class PreferenceComponent implements OnInit {
  private readonly _fb = inject(FormBuilder);
  private readonly _store = inject(Store);
  private readonly _userService = inject(UserService);
  private readonly _langService = inject(LangService);
  private readonly _noty = inject(NotyService);
  private readonly _translate = inject(TranslocoService);
  private readonly _storageService = inject(StorageService);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  protected readonly UserRole = UserRole;
  protected form: FormGroup;
  protected pending = false;
  voices: SelectItem[] = [
    { id: Voice.MALE, title: 'label.male' },
    { id: Voice.FEMALE, title: 'label.female' },
  ];
  messageTts: SelectItem[] = [
    { id: MessageTts.MANUAL, title: 'label.manual' },
    { id: MessageTts.AUTO, title: 'label.auto' },
  ];
  micPosition: SelectItem[] = [
    { id: MicPosition.RIGHT, title: 'label.right' },
    { id: MicPosition.LEFT, title: 'label.left' },
  ];
  autoTranslation: SelectItem[] = [
    { id: Switcher.OFF, title: 'label.off' },
    { id: Switcher.ON, title: 'label.on' },
  ];
  realtime: SelectItem[] = [
    { id: Switcher.OFF, title: 'label.off' },
    { id: Switcher.ON, title: 'label.on' },
  ];

  constructor() {
    addIcons({ textOutline, snowOutline, sunnyOutline });
    this.form = this._fb.group({
      voice: [ null, [ Validators.required ] ],
      messageTts: [ MessageTts.MANUAL, [ Validators.required ] ],
      language: [ null, [ Validators.required ] ],
      interfaceLanguage: [ null, [ Validators.required ] ],
      micPosition: [ null, [ Validators.required ] ],
      autoTranslation: [ Switcher.OFF, [ Validators.required ] ],
      realtime: [ Switcher.OFF, [ Validators.required ] ],
      chatFontSize: [ null ],
      textTemperature: [ 1 ],
    });
  }

  ngOnInit(): void {
    const micPosition = this.me().preferences?.micPosition || this.micPosition[ 0 ].id;
    const voice = this.me().preferences?.voice || this.voices[ 0 ].id;
    const messageTts = this.me().preferences?.messageTts || this.messageTts[ 0 ].id;
    const autoTranslation = this.me().preferences?.autoTranslation || this.autoTranslation[ 0 ].id;
    const realtime = this.me().preferences?.realtime || this.realtime[ 0 ].id;
    this.form.controls.micPosition.setValue(this.micPosition.find(mp => mp.id === micPosition));
    this.form.controls.autoTranslation.setValue(this.autoTranslation.find(mp => mp.id !== autoTranslation));
    this.form.controls.realtime.setValue(this.realtime.find(mp => mp.id === realtime));
    this.form.controls.voice.setValue(this.voices.find(mp => mp.id === voice));
    this.form.controls.messageTts.setValue(this.messageTts.find(mp => mp.id === messageTts));
    this.form.controls.language.setValue(this.me().language);
    if (this.me().preferences?.chatFontSize) {
      this.form.controls.chatFontSize.setValue(this.me().preferences.chatFontSize);
    }
    if (this.me().preferences?.textTemperature) {
      this.form.controls.textTemperature.setValue(this.me().preferences.textTemperature);
    }

    this._storageService.get(Cookies.UI_LANGUAGE).then((res: string) => {
      this.form.controls.interfaceLanguage.setValue(this.me().preferences?.interfaceLanguage || res || this._langService.getBrowserLang());
    })
  }

  savePreferences() {
    if (this.form.invalid) {
      return;
    }
    this.pending = true;
    const lang = this.form.controls.interfaceLanguage.value;
    this._storageService.set(Cookies.UI_LANGUAGE, lang);
    this._translate.setActiveLang(lang);
    const req = this.form.value;
    this._userService.updatePreferences(
      {
        voice: req.voice.id,
        micPosition: req.micPosition.id,
        language: req.language,
        messageTts: req.messageTts.id,
        interfaceLanguage: req.interfaceLanguage,
        autoTranslation: req.autoTranslation.id === Switcher.ON ? Switcher.OFF : Switcher.ON,
        realtime: req.realtime.id,
        chatFontSize: req.chatFontSize,
        textTemperature: req.textTemperature,
      })
      .pipe(untilDestroyed(this), finalize(() => this.pending = false))
      .subscribe(res => {
        this._noty.success('message.preference.changed');
        this.me().preferences = res.preferences;
        this.me().language = res.language;
        this.me().secondLanguage = res.secondLanguage;
        this._langService.checkForRtl(lang);
        this._store.dispatch(new SaveUserAction(this.me()));
      });

  }

  changeMicPosition($event: SelectItem): void {
    this.form.controls.micPosition.setValue($event);
  }

  changeAutoTranslation($event: SelectItem): void {
    this.form.controls.autoTranslation.setValue($event);
  }

  changeRealtime($event: SelectItem): void {
    this.form.controls.realtime.setValue($event);
  }

  changeVoice($event: SelectItem): void {
    this.form.controls.voice.setValue($event);
  }

  changeMessageTts($event: SelectItem): void {
    this.form.controls.messageTts.setValue($event);
  }

  setLang($event: string): void {
    this.form.controls.language.setValue($event);
  }

  setInterfaceLang($event: string) {
    this.form.controls.interfaceLanguage.setValue($event);
  }

  fontSizeChanged($event: RangeCustomEvent) {
    this.form.controls.chatFontSize.setValue($event.detail.value);
  }

  textTemperatureChanged($event: RangeCustomEvent) {
    this.form.controls.textTemperature.setValue($event.detail.value);
  }

  isMobile() {
    return Capacitor.isNativePlatform();
  }
}
