<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.select' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <div class="text-xl flex flex-col w-full flex-auto min-w-0 overflow-hidden">
    <ion-content>
      <div class="w-full flex flex-col justify-center overflow-hidden h-full p-5">
        <div class="w-full flex flex-col flex-auto overflow-hidden">
          @if (search) {
            <div class="w-full">
              <mat-form-field class="w-full mt-[-20px] mb-5" subscriptSizing="dynamic">
                <mat-label>{{ 'label.search' | transloco }}</mat-label>
                <i matPrefix class="fa-solid fa-magnifying-glass pr-2 text-gray-300"></i>
                <input matInput [formControl]="itemFilterCtrl"/>
              </mat-form-field>
            </div>
          }
          <div class="w-full flex-auto overflow-y-auto">
            @for (lang of filteredItems | async; track lang.id; let ind = $index) {
              <div (click)="selectItem(lang)" class="w-full px-2.5 py-3.5 border-b border-b-gray-300 lang-item" [class.border-t-gray-300]="ind === 0" [class.border-t]="ind === 0"
                   [class.active]="selectedItem?.id === lang.id">
                <div class="">{{ lang.title | transloco }}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </ion-content>

  </div>
</div>
