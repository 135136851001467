import { Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { TranslocoPipe } from '@ngneat/transloco';
import { IonicModule } from '@ionic/angular';
import { LoaderComponent } from '@comp/loader/loader.component';
import { Tariff, TariffEnum } from '@shared/transport.interface';
import { UserService } from '@service/user.service';
import { TariffItemCardComponent } from '@comp/tariff-item-card/tariff-item-card.component';
import { ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-tariffs',
  standalone: true,
  imports: [
    LoaderComponent,
    TranslocoPipe,
    IonicModule,
    LoaderComponent,
    TariffItemCardComponent
  ],
  templateUrl: './tariffs.component.html',
  styleUrl: './tariffs.component.scss'
})
export class TariffsComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  private _userService = inject(UserService);

  loading = false;
  tariffs: Tariff[];
  tariffDescriptions = {
    [ TariffEnum.PREPAID ]: [
      'text.tariff.free.desc.1',
      'text.tariff.free.desc.2',
      'text.tariff.free.desc.3',
      'text.tariff.free.desc.4',
      'text.tariff.free.desc.5',
      'text.tariff.free.desc.6',
      'text.tariff.free.desc.7',
      'text.tariff.free.desc.8',
      'text.tariff.free.desc.9',
      'text.tariff.free.desc.10',
    ],
    [ TariffEnum.PREMIUM ]: [
      'text.tariff.premium.desc.1',
      'text.tariff.premium.desc.2',
      'text.tariff.premium.desc.3',
      'text.tariff.premium.desc.4',
      'text.tariff.premium.desc.5',
      'text.tariff.premium.desc.6',
    ],
    [ TariffEnum.CORPORATE ]: [
      'text.tariff.corporate.desc.1',
      'text.tariff.corporate.desc.2',
      'text.tariff.corporate.desc.3',
      'text.tariff.corporate.desc.4',
      'text.tariff.corporate.desc.5',
      'text.tariff.corporate.desc.6',
      'text.tariff.corporate.desc.7',
      'text.tariff.corporate.desc.8',
    ],
  };
  pending = false;

  ngOnInit(): void {
    this.loading = true;
    this._userService.tariffs()
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe(res => {
        this.tariffs = res;
      });
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }
}
