/* eslint-disable */


import { environment } from '../../environment/environment';

export class ApiUrl {
  private static readonly BASE = `${environment.serverUrl}/v1`;
  public static readonly LANGS: string = ApiUrl.BASE + '/lang';
  public static readonly SITE_INFO: string = ApiUrl.BASE + '/site';
  public static readonly AI_LANGS_LIST: string = ApiUrl.LANGS + '/available';
  public static readonly ROOM_MESSAGES: string = ApiUrl.BASE + '/rooms/last-messages';
  public static readonly ROOM_LAST_MESSAGE: string = ApiUrl.BASE + '/rooms/last-message';
  public static readonly ROOMS: string = ApiUrl.BASE + '/rooms';
  public static readonly JOIN_MY_ROOM: string = ApiUrl.BASE + '/rooms/my';
  public static readonly CREATE_MY_ROOM: string = ApiUrl.BASE + '/rooms/create-my';
  public static readonly USER: string = ApiUrl.BASE + '/user';
  public static readonly RESET_PASSWORD: string = ApiUrl.USER + '/reset-password';
  public static readonly CHANGE_PASSWORD: string = ApiUrl.USER + '/change-password';
  public static readonly FORGOT_PASSWORD: string = ApiUrl.USER + '/forgot-password';
  public static readonly AVATARS: string = ApiUrl.BASE + '/avatars';
  public static readonly REGISTER: string = ApiUrl.USER + '/signup';
  public static readonly COMPLETE_REGISTRATION: string = ApiUrl.USER + '/complete-registration';
  public static readonly COMPLETE_GOOGLE_REGISTRATION: string = ApiUrl.USER + '/google/complete-registration';
  public static readonly COMPANY: string = ApiUrl.BASE + '/company';
  public static readonly COMPANY_USER_REGISTER: string = ApiUrl.COMPANY + '/user/register';
  public static readonly COMPANY_HOST_ROOMS: string = ApiUrl.COMPANY + '/host-rooms';
  public static readonly COMPANY_USERS: string = ApiUrl.COMPANY + '/users';
  public static readonly LOGOUT: string = ApiUrl.USER + '/signout';
  public static readonly TOKEN_AUTH: string = ApiUrl.USER + '/auth';
  public static readonly PREFERENCES: string = ApiUrl.USER + '/preferences';
  public static readonly NOTIFICATIONS: string = ApiUrl.USER + '/notifications';
  public static readonly TEMP_USER: string = ApiUrl.USER + '/signup/temp';
  public static readonly LOGIN: string = ApiUrl.USER + '/signin';
  public static readonly GOOGLE_LOGIN: string = ApiUrl.USER + '/google';
  public static readonly UPDATE_LANG: string = ApiUrl.USER + '/lang';
  public static readonly REFRESH_TOKEN: string = ApiUrl.USER + '/token/refresh';
  public static readonly TARIFFS: string = ApiUrl.USER + '/tariffs';
  public static readonly CANCEL_SUBSCRIPTION: string = ApiUrl.TARIFFS + '/cancel';
  public static readonly HISTORY: string = ApiUrl.ROOMS + '/history';
  public static readonly HISTORY_ROOMS: string = ApiUrl.HISTORY + '/rooms';
  public static readonly HISTORY_SUMMARIES: string = ApiUrl.HISTORY + '/summaries';
  public static readonly HISTORY_PROTOCOLS: string = ApiUrl.HISTORY + '/protocols';
  public static readonly HISTORY_TRANSCRIPTS: string = ApiUrl.HISTORY + '/transcripts';
  public static readonly USE_PROMO_CODE: string = ApiUrl.USER + '/promo/use/';
  public static readonly INVITE: string = ApiUrl.USER + '/invite';
  public static readonly TMP_USER_BY_DEVICE: string = ApiUrl.USER + '/session/device';

  public static LEAVE_ROOM(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/leave/${ roomId }`;
  }

  public static ROOM_SUMMARY(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/summary`;
  }

  public static ROOM_TRANSCRIPTS(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/transcripts`;
  }

  public static ROOM_PROTOCOLS(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/protocols`;
  }

  public static ROOM_SUMMARIES(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/summaries`;
  }

  public static SHARED_ROOM_SUMMARY(code: string): string {
    return `${ ApiUrl.BASE }/rooms/summaries/shared/${code}`;
  }

  public static SHARED_ROOM_PROTOCOL(code: string): string {
    return `${ ApiUrl.BASE }/rooms/protocols/shared/${code}`;
  }

  public static SHARED_ROOM_TRANSCRIPT(code: string): string {
    return `${ ApiUrl.BASE }/rooms/transcripts/shared/${code}`;
  }

  public static ROOM_TRANSCRIPT(id: number): string {
    return `${ ApiUrl.BASE }/rooms/transcripts/${ id }`;
  }

  public static ROOM_PROTOCOL(id: number): string {
    return `${ ApiUrl.BASE }/rooms/protocols/${ id }`;
  }

  public static DELETE_ROOM_SUMMARY(summaryId: number): string {
    return `${ ApiUrl.BASE }/rooms/summaries/${ summaryId }`;
  }

  public static SHARE_ROOM_SUMMARY(roomId: string, summaryId: number): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/summaries/${ summaryId }/share`;
  }

  public static SHARE_ROOM_PROTOCOL(roomId: string, protocolId: number): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/protocols/${ protocolId }/share`;
  }

  public static SHARE_ROOM_TRANSCRIPT(roomId: string, transcriptId: number): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/transcripts/${ transcriptId }/share`;
  }

  public static DELETE_ROOM_PROTOCOL(protocolId: number): string {
    return `${ ApiUrl.BASE }/rooms/protocols/${ protocolId }`;
  }

  public static DELETE_ROOM_TRANSCRIPT(transcriptId: number): string {
    return `${ ApiUrl.BASE }/rooms/transcripts/${ transcriptId }`;
  }

  public static ROOM_SUMMARY_MESSAGES(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/summary/messages`;
  }

  public static ROOM_PARTICIPANTS(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/participants`;
  }

  public static ROOM_PARTICIPANTS_UNBLOCK(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/participants/unblock`;
  }

  public static ROOM_PARTICIPANT(roomId: string, userId: number): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/participants/${ userId }`;
  }

  public static TARIFF_PACKAGES(tariffId: number): string {
    return `${ ApiUrl.TARIFFS }/${ tariffId }/packages`;
  }

  public static BUY_TARIFF_PACKAGE(tariffId: number, packageId: number): string {
    return `${ ApiUrl.TARIFFS }/${ tariffId }/packages/${ packageId }/buy`;
  }

  public static BUY_TARIFF(tariffId: number): string {
    return `${ ApiUrl.TARIFFS }/${ tariffId }/buy`;
  }

  public static COMPANY_HOST_ROOM(roomId: number): string {
    return `${ ApiUrl.COMPANY_HOST_ROOMS }/${ roomId }`;
  }

  public static COMPANY_HOST_ROOM_INFO(roomId: string): string {
    return `${ ApiUrl.COMPANY_HOST_ROOMS }/${ roomId }/info`;
  }

  public static ROOM_INFO(roomId: string): string {
    return `${ ApiUrl.ROOMS }/${ roomId }/info`;
  }

  public static SEND_MESSAGE(roomId: string): string {
    return `${ ApiUrl.ROOMS }/${ roomId }/message`;
  }

  public static COMPANY_USER(userId: number): string {
    return `${ ApiUrl.COMPANY_USERS }/${ userId }`;
  }

  public static JOIN_COMPANY_ROOM(roomId: string): string {
    return `${ ApiUrl.COMPANY }/host-rooms/${ roomId }/join`;
  }

  public static ACCEPT_USER_TO_ROOM(roomId: string, userId: number): string {
    return `${ ApiUrl.COMPANY }/host-rooms/${ roomId }/accept/${ userId }`;
  }

  public static BLOCK_USER_FROM_ROOM(roomId: string, userId: number): string {
    return `${ ApiUrl.COMPANY }/host-rooms/${ roomId }/block/${ userId }`;
  }

  public static MESSAGES_HISTORY(roomId: string): string {
    return `${ ApiUrl.ROOMS }/${ roomId }/history/messages`;
  }

  public static MY_ROOM_MESSAGES(roomId: string): string {
    return `${ ApiUrl.ROOMS }/${ roomId }/messages`;
  }

  public static INVOICES(): string {
    return `${ ApiUrl.USER }/payments/invoices`;
  }

  public static GET_AVATAR(avatarId: string): string {
    return `${ ApiUrl.AVATARS }/${ avatarId }`;
  }

  public static TRANSLATE_PHOTO(roomId: string): string {
    return `${ ApiUrl.BASE }/rooms/${ roomId }/message/photo`;
  }
}
