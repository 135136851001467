/* eslint-disable */
export class Pages {
  public static readonly VOICE: string = 'voice';
  public static readonly LANGUAGES: string = 'languages';
  public static readonly REGISTER: string = 'register';
  public static readonly COMPANY: string = 'company';
  public static readonly LOGIN: string = 'sign-in';
  public static readonly TOKEN_AUTH: string = 'auth';
  public static readonly CHAT: string = 'chat';
  public static readonly WELCOME: string = 'welcome';
  public static readonly JOIN: string = 'join';
  public static readonly JOIN_ROOM: string = 'join-room';
  public static readonly TARIFFS: string = 'tariffs';
  public static readonly CHATS: string = 'chats';
  public static readonly TARIFF_PACKAGES: string = 'tariffs/:id/packages';
  public static readonly PAYMENT_SUCCESS: string = 'payment-success';
  public static readonly PAYMENT_FAILED: string = 'payment-failed';
  public static readonly SUBSCRIPTION_CANCELED: string = 'subscription-canceled';
  public static readonly DASHBOARD: string = 'dashboard';
  public static readonly PORTAL: string = 'portal';
  public static readonly HOST_ROOMS: string = 'host-rooms';
  public static readonly COMPANY_USERS: string = 'users';
  public static readonly HISTORY: string = 'history';
  public static readonly TERMS: string = 'terms';
  public static readonly PRIVACY: string = 'privacy';
  public static readonly RISK: string = 'risk';
  public static readonly IMPRINT: string = 'imprint';
  public static readonly HELP: string = 'help';
  public static readonly FORGOT_PASSWORD: string = 'forgot-password';
  public static readonly SET_PASSWORD: string = 'set-password';
  public static readonly PROMO_CODE: string = 'promo-code';
  public static readonly SINGLE_MODE: string = 'single';
  public static readonly INVITE: string = 'invite';
}
