<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>{{ 'title.participants' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closeModal()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden bg-color-1">
  <div class="flex flex-col w-full h-full flex-auto">
    <app-room-participants [room]="room" [readOnly]="readOnly"></app-room-participants>
  </div>
</div>
