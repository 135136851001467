import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RoomUser } from '../../transport.interface';
import { SaveUserAction } from './user.actions';

export interface UserStateModel {
  user: RoomUser;
}

const defaults = {
  user: {
    id: 0,
    name: '',
    email: '',
    language: '',
    secondLanguage: '',
  }
};

@State<UserStateModel>({
  name: 'user',
  defaults
})
@Injectable()
export class UserState {
  @Action(SaveUserAction)
  save(ctx: StateContext<UserStateModel>, action: SaveUserAction): void {
    if (!action.payload) {
      return ctx.setState({ user: defaults.user })
    }
    return ctx.setState({ user: action.payload });
  }

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }
}
