import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { ChatMessagesComponent } from '../chat-messages/chat-messages.component';
import { LoaderComponent } from '../loader/loader.component';
import { ChatMessageDto } from '../../transport.interface';

@Component({
  selector: 'app-summary-chat',
  standalone: true,
  imports: [
    ChatMessagesComponent,
    TranslocoPipe,
    LoaderComponent
  ],
  templateUrl: './summary-chat.component.html',
  styleUrl: './summary-chat.component.scss'
})
export class SummaryChatComponent {
  @Input() text: ChatMessageDto[] = [];
  @Input() selectedMessage: ChatMessageDto;
  @Input() start: boolean;
  @Input() loading: boolean;
  @Output() messageSelected: EventEmitter<ChatMessageDto> = new EventEmitter<ChatMessageDto>();

  messageSelectedEvent($event: ChatMessageDto): void {
    this.messageSelected.next($event);
  }
}
