import { forkJoin, of } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserService } from '@service/user.service';
import { UserState } from '@shared/store/user/user-state.service';
import { SiteService } from '@service/site.service';
import { SiteInfoState } from '@shared/store/site/site-state.service';

export const initialDataResolver = () => {
  const userService = inject(UserService);
  const store = inject(Store);
  const user = store.selectSignal(UserState.getUser);
  if (user()?.id) {
    return of(user());
  }
  return forkJoin([
    userService.profile(),
  ]);
};

export const siteInfoDataResolver = () => {
  const siteService = inject(SiteService);
  const store = inject(Store);
  const site = store.selectSignal(SiteInfoState.getSiteInfo);
  if (site().commonSettings?.buttonBg) {
    return of(site());
  }
  return forkJoin([
    siteService.siteInfo(),
  ]);
};
