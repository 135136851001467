<div class="overflow-hidden w-full flex flex-col bg-white">
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="title text-4xl">{{ 'label.block.and.delete' | transloco }}</div>
      <button mat-icon-button (click)="closeModal()">
        <ion-icon name="close-outline" class="text-4xl cursor-pointer"></ion-icon>
      </button>
    </div>
    <div class="mt-2">
      <span [innerHTML]="'text.block.and.remove.user' | transloco: {name: user.name}"></span>
    </div>
    @if (error?.code > 0) {
      <app-alert [type]="'warning'" [appearance]="'fill'" class="mt-4">
        {{ error.message | transloco }}
      </app-alert>
    }
    <div class="mt-5">
      <button class="button red w-full" (click)="blockUser()" [disabled]="pending" [class.loading]="pending">{{ 'button.block' | transloco }}</button>
      <button class="button stroked w-full" (click)="closeModal()" [disabled]="pending">{{ 'button.cancel' | transloco }}</button>
    </div>
  </div>
</div>
