import { Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HistoryDataType, HistoryFilter, SelectItem } from '@shared/transport.interface';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DialogService } from '@shared/service/dialog.service';
import _ from 'lodash';
import { SelectboxComponent } from '@shared/component/ui/selectbox/selectbox.component';
import { SummaryHistoryComponent } from '@shared/component/_dialog/history/summary-history/summary-history.component';
import { RoomHistoryComponent } from '@shared/component/_dialog/history/room-history/room-history.component';
import { ProtocolHistoryComponent } from '@shared/component/_dialog/history/protocol-history/protocol-history.component';
import { TranscriptHistoryComponent } from '@shared/component/_dialog/history/transcript-history/transcript-history.component';
import { IonButtons, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';

@UntilDestroy()
@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe,
    SelectboxComponent,
    RoomHistoryComponent,
    SummaryHistoryComponent,
    ProtocolHistoryComponent,
    TranscriptHistoryComponent,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss'
})
export class HistoryComponent {
  private _modalCtrl = inject(ModalController);

  private _dialogService = inject(DialogService);
  protected filterChanged = false;

  filtersCount = 0;
  loading = false;
  searchCtrl = new FormControl<string>('');
  filter: HistoryFilter;
  dataTypes: SelectItem[] = [
    { id: HistoryDataType.ROOM, title: `title.data.type.${ HistoryDataType.ROOM }` },
    { id: HistoryDataType.SUMMARY, title: `title.data.type.${ HistoryDataType.SUMMARY }` },
    { id: HistoryDataType.TRANSCRIPT, title: `title.data.type.${ HistoryDataType.TRANSCRIPT }` },
    { id: HistoryDataType.PROTOCOL, title: `title.data.type.${ HistoryDataType.PROTOCOL }` },
  ];
  dataTypesCtrl: FormControl<SelectItem> = new FormControl<SelectItem>(this.dataTypes[ 0 ]);

  constructor() {
  }

  changeDataType($event: SelectItem): void {
    this.filterChanged = false;
    this.dataTypesCtrl.setValue($event);
  }

  protected readonly HistoryDataType = HistoryDataType;

  showFilter(): void {
    this.filterChanged = false;
    this._dialogService.historyFilter(this.filter).then(res => {
      if (res) {
        if (!_.isEqual(this.filter, res)) {
          this.filter = res;
          this.filterChanged = true;
        }
        this.filtersCount = 0;
        if (this.filter.dateFilter?.fromDate || this.filter.dateFilter?.toDate) {
          this.filtersCount++;
        }
      }
    });
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }
}
