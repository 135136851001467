<ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (click)="closeRecordWindow()"></ion-icon>

<div class="title text-2xl px-5 pt-5">{{ 'title.analyzing.photo' | transloco }}</div>
<div class="px-5 flex justify-between">
  <div class="text-sm">
    @if (status === RoomAction.TRANSCRIPT || status === RoomAction.TRANSLATE) {
      {{ 'recorder.status.photo.recognition' | transloco }}
    } @else if (status === TranslateStatus.ERROR) {
      <span class="error-text">{{ 'recorder.status.error' | transloco }}</span>
    } @else {
      {{ 'recorder.status.uploading.photo' | transloco }}
    }
  </div>
</div>

<div class="w-full h-32 flex items-center justify-center overflow-hidden flex-auto">
  @if (!error) {
    <div class="flex w-full overflow-hidden flex-auto h-full py-5">
      <app-wave-animation></app-wave-animation>
    </div>
  } @else {
    <div class="flex w-full flex-col justify-center items-center error-text px-5">
      <ion-icon name="alert-circle" class="text-3xl"></ion-icon>
      <div class="mt-2">{{ error.message | transloco }}</div>
    </div>
  }
</div>
