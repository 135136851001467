<ion-icon name="close-outline" class="close-icon z-99999 text-6xl" (click)="closeModal()"></ion-icon>

<div class="flex flex-col p-5">
  <div class="flex flex-col flex-auto">
    <div class="title text-3xl">{{ 'title.exit.room' | transloco }}</div>
    <div class="mt-4 font-bold">
      <div [innerHTML]="'text.exit.room' | transloco"></div>
    </div>
    @if (commonError?.code > 0) {
      <div class="mt-4 font-bold text-red-600">
        {{ (commonError.message?.message || commonError.message) | transloco }}
      </div>
    }
  </div>

  <div class="w-full flex flex-col justify-center relative mt-8">
    <button class="button w-full red" (click)="exitRoom()" [class.loading]="pending" [disabled]="pending">{{ 'button.exit' | transloco }}</button>
    <button class="button stroked w-full mt-4" [disabled]="pending" (click)="closeModal()">{{ 'button.cancel' | transloco }}</button>
  </div>
</div>
