import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  @Input() transparent = false;

  // private readonly _store = inject(Store);
  // protected siteInfo: Signal<SiteInfo> = this._store.selectSignal(SiteInfoState.getSiteInfo);
}
