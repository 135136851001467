import { Component, input, Input } from '@angular/core';
import { Room, RoomMode, RoomUser } from '@shared/transport.interface';
import { DatePipe } from '@angular/common';
import { UtcDatePipe } from '@shared/pipe/utc-date.pipe';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-room-history-card',
  standalone: true,
  imports: [
    InitialsAvatarComponent,
    DatePipe,
    UtcDatePipe,
    IonicModule
  ],
  templateUrl: './room-history-card.component.html',
  styleUrl: './room-history-card.component.scss'
})
export class RoomHistoryCardComponent {
  @Input() room: Room;
  @Input() last: boolean;
  @Input() showLine = true;
  me = input<RoomUser>();
  protected readonly RoomMode = RoomMode;
}
