/* eslint-disable */


export class Topics {
  public static readonly AUDIO = '/app/audio';
  public static readonly LOGS = '/app/logs';
  public static readonly ROOM = '/topic/room/';

  public static ROOM_USER(roomId: string) {
    return Topics.ROOM + `${ roomId }/user`;
  }
}
