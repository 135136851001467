import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { RoomUser } from '@shared/transport.interface';
import { NgClass } from '@angular/common';
import { CheckboxComponent } from '@shared/component/ui/checkbox/checkbox.component';
import { UserCardComponent } from '@shared/component/ui/user-card/user-card.component';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-participants-list',
  standalone: true,
  imports: [
    CheckboxComponent,
    MatIcon,
    MatIconButton,
    ReactiveFormsModule,
    TranslocoPipe,
    UserCardComponent,
    NgClass,
    IonIcon
  ],
  templateUrl: './participants-list.component.html',
  styleUrl: './participants-list.component.scss'
})
export class ParticipantsListComponent implements OnInit {
  @Input() closeModal: (result?: any) => void;
  @Input() data: any;

  users: RoomUser[];

  constructor() {
    addIcons({ closeOutline });
  }

  ngOnInit(): void {
    if (this.data?.users) {
      this.users = this.data.users;
    }
  }
}
