import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AudioService } from '@service/audio.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-ios-audio-visualizer',
  templateUrl: './ios-audio-visualizer.component.html',
  styleUrls: [ './ios-audio-visualizer.component.scss' ],
  standalone: true
})
export class IosAudioVisualizerComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;

  private _audioService = inject(AudioService);

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  private readonly numBars = 80; // Number of bars
  private readonly barRadius = 15; // Maximum height of each bar
  private readonly alpha = 0.1; // Smoothing factor
  private barWidth = 5; // Width of each bar
  private maxBarHeight = 77; // Maximum height of each bar
  private audioData = new Float32Array(this.numBars);
  private prevAudioData = new Float32Array(this.numBars).fill(0);
  private animationFrameInd = -1;

  constructor() {
  }

  ngOnInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = 1000 * 2;
    this.canvas.height = 285 * 2;
    this.barWidth = this.canvas.width / this.numBars;
    this.maxBarHeight = this.canvas.height / 1.2; // Set maximum bar height to half the canvas height
    this._audioService.dataStream
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.processBase64Audio(res.getChannelData(0));
      });
    this._drawBars();
  }

  ngOnDestroy(): void {
    cancelAnimationFrame(this.animationFrameInd);
  }

  private interpolate(current, target, alpha) {
    return current + (target - current) * alpha;
  }

  private roundRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.arc(x + width - radius, y + radius, radius, 1.5 * Math.PI, 2 * Math.PI);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arc(x + width - radius, y + height - radius, radius, 0, 0.5 * Math.PI);
    ctx.lineTo(x + radius, y + height);
    ctx.arc(x + radius, y + height - radius, radius, 0.5 * Math.PI, Math.PI);
    ctx.lineTo(x, y + radius);
    ctx.arc(x + radius, y + radius, radius, Math.PI, 1.5 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }

  processBase64Audio(normalizedData: Float32Array) {
    const chunkSize = Math.floor(normalizedData.length / this.numBars);
    this.audioData = new Float32Array(this.numBars);
    for (let i = 0; i < this.numBars; i++) {
      const start = i * chunkSize;
      const end = start + chunkSize;
      const slice = normalizedData.slice(start, end);
      this.audioData[ i ] = (slice.reduce((sum, value) => sum + Math.abs(value), 0) / slice.length || 0) * 5;
    }
  }

  _drawBars() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Gradient color
    this.ctx.fillStyle = this.ctx.createLinearGradient(0, 0, 0, this.canvas.height);
    this.ctx.fillStyle.addColorStop(0, '#ff5f6d');
    this.ctx.fillStyle.addColorStop(1, '#ffc371');

    for (let i = 0; i < this.numBars; i++) {
      const x = i * this.barWidth;
      const centerY = this.canvas.height / 2;
      const barHeight = this.interpolate(this.prevAudioData[ i ] * this.maxBarHeight, this.audioData[ i ] * this.maxBarHeight, this.alpha);
      this.prevAudioData[ i ] = barHeight / this.maxBarHeight;
      // Draw the bar with rounded corners
      this.roundRect(this.ctx, x + this.barWidth * 0.1, centerY - barHeight / 2, this.barWidth * 0.8, barHeight, this.barRadius);
    }

    this.animationFrameInd = requestAnimationFrame(this._drawBars.bind(this));
  }
}
