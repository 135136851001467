import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { HttpClientService } from '../http-client/http-client.service';
import { CommonLanguage } from '../transport.interface';
import { ApiUrl } from '../api-url';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  constructor(private _http: HttpClientService, private _translate: TranslocoService) {
  }

  langsFroAI(): Observable<CommonLanguage[]> {
    return this._http.doGet(ApiUrl.AI_LANGS_LIST);
  }

  getBrowserLang(): string {
    if (navigator.language) {
      return navigator.language.split('-')[ 0 ];
    }
    return 'en';
  }

  checkForRtl(lang: string, reload = false) {
    if ([ 'ar', 'he', 'fa', 'ur' ].indexOf(lang) >= 0) {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
    if (reload) {
      window.location.reload();
    }
  }

  isRtl(lang: string) {
    return [ 'ar', 'he', 'fa', 'ur' ].indexOf(lang) >= 0;
  }

  isActiveLangRtl(): boolean {
    return this.isRtl(this._translate.getActiveLang());
  }
}
