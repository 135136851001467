import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent, MatDatepickerToggle } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment/moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@app/app.config';
import { HistoryFilter } from '@shared/transport.interface';
import * as _moment from 'moment';
import { IonButtons, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { calendarOutline, closeOutline } from 'ionicons/icons';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-history-filter',
  standalone: true,
  providers: [ provideMomentDateAdapter(MY_FORMATS) ],
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatPrefix,
    TranslocoPipe,
    MatDatepicker,
    MatDatepickerInput,
    ReactiveFormsModule,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    MatDatepickerToggle,
    MatSuffix
  ],
  templateUrl: './history-filter.component.html',
  styleUrl: './history-filter.component.scss'
})
export class HistoryFilterComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  @Input() data: any;

  protected fromCtrl = new FormControl({ value: null, disabled: true });
  protected toCtrl = new FormControl({ value: null, disabled: true });
  invalidFilter = false;
  defFilter: HistoryFilter;

  constructor() {
    addIcons({ closeOutline, calendarOutline });
  }

  ngOnInit(): void {
    if (this.data?.filter) {
      this.defFilter = this.data.filter;
      if (this.defFilter?.dateFilter?.fromDate) {
        this.fromCtrl.setValue(moment(this.defFilter.dateFilter.fromDate));
      }
      if (this.defFilter?.dateFilter?.toDate) {
        this.toCtrl.setValue(moment(this.defFilter.dateFilter.toDate));
      }
    }
  }

  fromDateSelected($event: MatDatepickerInputEvent<any, any>): void {
    const date = $event.value as Moment;
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.fromCtrl.setValue(date);
    this.checkFilter();
  }

  toDateSelected($event: MatDatepickerInputEvent<any, any>): void {
    const date = $event.value as Moment;
    date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    this.toCtrl.setValue(date);
    this.checkFilter();
  }

  applyFilter(): void {
    this.closeModal({
      dateFilter: {
        fromDate: this.fromCtrl.value?.valueOf(),
        toDate: this.toCtrl.value?.valueOf(),
      }
    })
  }

  private checkFilter(): void {
    this.invalidFilter = false;
    if (this.fromCtrl.value?.valueOf() && this.toCtrl.value?.valueOf()) {
      if (this.fromCtrl.value?.valueOf() > this.toCtrl.value?.valueOf()) {
        this.invalidFilter = true;
      }
    }
  }

  clearFilter(): void {
    this.fromCtrl.reset();
    this.toCtrl.reset();
    this.closeModal({
      dateFilter: {}
    });
  }

  closeModal(data?: any): void {
    this._modalCtrl.dismiss(data);
  }
}
