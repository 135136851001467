<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>
      {{ 'title.members' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" class="cursor-pointer text-4xl" (click)="closePage()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-page flex flex-col w-full items-center flex-auto min-w-0 overflow-hidden">
  <ion-content>
    <div class="flex flex-col items-center w-full flex-auto overflow-x-hidden relative bg-white">
      <div class="flex flex-col w-full p-5 flex-auto">
        <div class="flex flex-col w-full flex-auto">
          @for (user of users; track user.id; let ind = $index; let first = $first; let last = $last) {
            <div class="py-2.5" [ngClass]="{'border-t border-t-gray-300': !first}">
              <app-user-card [me]="user"></app-user-card>
            </div>
          } @empty {
            <div class="flex flex-col justify-center mt-5 items-center">
              <ion-icon name="search-outline" class="text-6xl"></ion-icon>
              <div class="title text-2xl mt-2.5">{{ 'title.no.members' | transloco }}</div>
            </div>
          }
        </div>
      </div>
    </div>
  </ion-content>
</div>
