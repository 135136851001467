import { Component, inject, Input, OnChanges, OnInit, Signal, SimpleChanges } from '@angular/core';
import { UserService } from '@shared/service/user.service';
import { DialogService } from '@shared/service/dialog.service';
import { HistoryFilter, Paging, RoomSummary, RoomUser } from '@shared/transport.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { LoaderComponent } from '@shared/component/loader/loader.component';
import { RoomHistoryCardComponent } from '@shared/component/room-history-card/room-history-card.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe, DatePipe } from '@angular/common';
import { PrettyDatePipe } from '@shared/pipe/pretty-date.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/user/user-state.service';
import { UtcDatePipe } from '@shared/pipe/utc-date.pipe';
import { InitialsAvatarComponent } from '@shared/component/ui/initials-avatar/initials-avatar.component';
import { DateLineComponent } from '@shared/component/ui/date-line/date-line.component';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/angular/standalone';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@UntilDestroy()
@Component({
  selector: 'app-summary-history',
  standalone: true,
  imports: [
    LoaderComponent,
    RoomHistoryCardComponent,
    TranslocoPipe,
    AsyncPipe,
    InitialsAvatarComponent,
    PrettyDatePipe,
    DatePipe,
    DateLineComponent,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    UtcDatePipe,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  ],
  templateUrl: './summary-history.component.html',
  styleUrl: './summary-history.component.scss'
})
export class SummaryHistoryComponent implements OnInit, OnChanges {
  @Input() filterChanged = false;
  @Input() filter: HistoryFilter;

  private _userService = inject(UserService);
  private _dialogService = inject(DialogService);
  private _store = inject(Store);

  protected me: Signal<RoomUser> = this._store.selectSignal(UserState.getUser);
  summaries: RoomSummary[] = [];
  loading = false;
  upgradeTariff = false;
  private _paging: Paging = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
  };
  protected loadingMore = false;
  protected empty = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterChanged?.currentValue === true) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this._userService.historySummaries(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize, this.filter)
      .pipe(untilDestroyed(this), finalize(() => this.loading = false))
      .subscribe({
        next: res => {
          this._paging.totalPages = res.total;
          this.summaries = res.list;
        },
        error: err => {
          this.upgradeTariff = true;
        }
      });
  }

  loadMoreData(ev: InfiniteScrollCustomEvent): void {
    if (this.loadingMore || this.empty) {
      return;
    }
    this.loadingMore = true;
    this._paging.currentPage++;
    this._userService.historySummaries(this._paging.pageSize, (this._paging.currentPage - 1) * this._paging.pageSize)
      .pipe(untilDestroyed(this), finalize(() => this.loadingMore = false))
      .subscribe(res => {
        this.summaries = this.summaries.concat(res.list);
        if (!res.list.length || res.list.length < this._paging.pageSize) {
          this.empty = true;
        }
        ev.target.complete().then();
      });
  }

  deleteSummary(summary: RoomSummary): void {
    this._dialogService.deleteSummary(summary)
      .subscribe(res => {
        this.deleteSummaryFromArray(res);
      });
  }

  shareSummary(summary: RoomSummary): void {

  }

  downloadPdf(summary: RoomSummary): void {
    alert('Not implemented');
  }

  showSummary(summary: RoomSummary): void {
    this._dialogService.showSummary(summary)
      .then(res => {
        this.deleteSummaryFromArray(res);
      });
  }

  private deleteSummaryFromArray(res: RoomSummary) {
    if (res) {
      const ind = this.summaries.findIndex(s => s.id === res.id);
      if (ind >= 0) {
        this.summaries.splice(ind, 1);
      }
    }
  }
}
