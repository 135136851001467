import { ChangeDetectorRef, Component, EnvironmentInjector, inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgStyle } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition(':leave', [
        animate('100ms ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {
  @Input() childComponentType: any;
  @Input() data: any;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  closeModal: (result?: any) => void;

  private _cdr = inject(ChangeDetectorRef);
  animationState: string = '';

  constructor(private environmentInjector: EnvironmentInjector) {
    setTimeout(() => this.animationState = 'in', 10);
  }

  ngOnInit(): void {
    const componentRef = this.container.createComponent(this.childComponentType, {
      environmentInjector: this.environmentInjector
    });
    // @ts-ignore
    componentRef.instance.data = this.data;
    // @ts-ignore
    componentRef.instance.closeModal = (result: any) => {
      this.animationState = 'leave';
      this._cdr.detectChanges();
      setTimeout(() => {
        this.closeModal(result);
      }, 100); // Match the animation duration
    };
  }

  closeWindow(): void {
    this.animationState = 'leave';
    this._cdr.detectChanges();
    setTimeout(() => {
      this.closeModal(null);
    }, 100); // Match the animation duration
  }
}
